import React from 'react';
import _get from 'lodash/get';

import { useAccess } from 'utils/hooks';
import { useShopperGroupsList, useShopperGroupsParams } from '../hooks';
import { ShopperGroupSelectorContext } from '../context';

export default function Provider({
  isConfigurable = false,
  selectedId = '',
  entity = '',
  onChange = () => {},
  onSubmit = () => {},
  children
}) {
  const { hasFeatureAccess } = useAccess();
  const hasAccess = hasFeatureAccess('SHOPPER_GROUPS');
  const loadData = hasAccess && isConfigurable;

  const {
    isFetching,
    optionsList,
    selectedOption,
    setSelectedOption
  } = useShopperGroupsList(entity, selectedId, loadData);

  useShopperGroupsParams(selectedOption);

  const isConfigured = _get(selectedOption, 'is_configured');
  const onSubmitNew = () => onSubmit(isConfigured);

  return (
    <ShopperGroupSelectorContext.Provider
      value={{
        optionsList,
        isFetching,
        selectedOption,
        hasAccess,
        isConfigurable,
        setSelectedOption,
        onChange,
        onSubmitNew
      }}
    >
      {children}
    </ShopperGroupSelectorContext.Provider>
  );
}
