import { useEffect, useRef, useReducer } from 'react';
import { CustomToast } from 'components';
import { logError } from 'utils';
import _get from 'lodash/get';
import { get, post, put, deleteRequest } from 'utils';

function findMethod(method) {
  const axiosMethod =
    method === 'GET'
      ? get
      : method === 'post'
      ? post
      : method === 'put'
      ? put
      : method === 'delete'
      ? deleteRequest
      : null;
  return axiosMethod;
}

async function fetchData({ paramsRef, method, dispatch, optionsRef, apiKey }) {
  try {
    const axiosMethod = findMethod(method);
    if (!axiosMethod) {
      dispatch({
        type: 'error',
        payload: new Error('No method type passed')
      });
      return;
    }
    const promise = axiosMethod(
      { apiKey, ...optionsRef.current },
      {
        params: paramsRef.current
      }
    );
    dispatch({
      type: 'loading'
    });
    const res = await promise;
    const responseData = res.data;

    dispatch({
      type: 'loaded',
      payload: responseData
    });
    return;
  } catch (err) {
    logError(err);
    let msg = _get(err, 'displayMessage.message');
    CustomToast({
      isNotified: err.notified,
      type: 'error',
      msg: msg ? msg : 'Something went wrong'
    });
    dispatch({
      type: 'error',
      payload: err || 'Something went wrong'
    });
  }
}

function useAPI(
  apiKey,
  { method = 'GET', params = {}, options = {}, defaultValue }
) {
  const initialState = {
    isLoading: true,
    error: null,
    data: defaultValue || null
  };
  const [state, dispatch] = useReducer(useAPIReducer, initialState);
  const paramsRef = useRef(params);
  const optionsRef = useRef(options);
  useEffect(() => {
    fetchData({
      dispatch,
      paramsRef,
      optionsRef,
      apiKey,
      method
    });
  }, [apiKey, method]);

  const reload = async function({ reloadParams }) {
    paramsRef.current = { ...paramsRef.current, ...reloadParams };
    await fetchData({
      dispatch,
      paramsRef,
      optionsRef,
      apiKey,
      method
    });
  };
  return [{ ...state }, reload];
}

function useAPIReducer(state, action) {
  switch (action.type) {
    case 'loaded':
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload
      };
    case 'loading': {
      return {
        ...state,
        isLoading: true,
        error: null
        // data: null
      };
    }

    case 'error':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: null
      };

    default:
      throw new Error('No action type passed');
  }
}

export default useAPI;
