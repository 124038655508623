export default function getRuleAppliesOn(
  isApplicableOnReturns = false,
  isApplicableOnExchanges = false
) {
  const entities = [];
  if (isApplicableOnReturns) entities.push('Returns');
  if (isApplicableOnExchanges) entities.push('Exchanges');

  return entities.length === 2
    ? entities.join(' & ')
    : entities.length === 1
    ? `${entities[0]} Only`
    : 'N/A';
}
