import { useContext, useMemo } from 'react';
import _get from 'lodash/get';

import { AuthContext } from 'context/AuthContext';
import { findEcommPlatform } from 'utils/DICTIONARY';

/**
 * @typedef {Object} EcommPlatformInfo E-commerce platfrom information of the loggein user's store
 * @property {import('utils/types').EcommPlatformSource} ecommPlatformSource E-commerce platform source
 * @property {String} ecommPlatformName E-commerce platform name in human readable format
 * @property {String} ecommPlatformPlanName E-commerce platform plan name
 */

/**
 * @returns {EcommPlatformInfo} ecommPlatform information
 */
export default function useEcomPlatform() {
  const { storeProperties } = useContext(AuthContext);

  const ecommPlatform = _get(storeProperties, 'ecommerce_platform', null);
  const ecommPlatformSource = _get(ecommPlatform, 'source', '');
  const ecommPlatformPlanName = _get(ecommPlatform, 'plan_name', '');

  const ecommPlatformName = useMemo(() => {
    return findEcommPlatform(ecommPlatformSource);
  }, [ecommPlatformSource]);

  return {
    ecommPlatformName,
    ecommPlatformSource,
    ecommPlatformPlanName
  };
}
