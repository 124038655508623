import { ConnectStore, Connected } from 'views/ConnectStore';

export default [
  {
    path: '/connect-store/done',
    name: 'Connect Store - Done',
    component: Connected,
    exact: true
  },
  {
    path: '/connect-store',
    name: 'Connect Store',
    component: ConnectStore
  }
];
