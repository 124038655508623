import React from 'react';
import styles from './index.module.scss';

import { ReactComponent as PackageIcon } from 'assets/rr-illustrations/package.svg';

export default function TableZeroState({
  title = 'No records found!',
  helptext = 'Records will be shown here once they are available.'
}) {
  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <PackageIcon className={styles.icon} />
      </div>
      <div className={styles.title}>{title}</div>
      <p className={styles.helptext}>{helptext}</p>
    </div>
  );
}
