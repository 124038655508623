import { WelcomeTour, WelcomeTourTrigger } from './modules/WelcomeTour';
import { HelpTour, HelpTourTrigger } from './modules/HelpTour';
import FeaturesChecklist from './modules/FeaturesChecklist';

export default {
  WelcomeTour,
  WelcomeTourTrigger,
  FeaturesChecklist,
  HelpTour,
  HelpTourTrigger
};
