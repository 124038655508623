import React, { useContext, useRef } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';
import { DICTIONARY, If } from 'utils';

export default function ShopperGroupIncentive() {
  const { retex } = useContext(RetexContext);
  const shopperGroupIncentiveEntitiesMap = useRef(
    DICTIONARY('shopper_group_override_entities') || {}
  ).current;

  const shopperGroupName = _get(retex, 'shopper_group_name', '');
  const overrides = _get(retex, 'shopper_group_overrides_applied', []);
  const hasIncentives = Array.isArray(overrides) && overrides.length;

  const renderAppliedIncentives = !hasIncentives
    ? null
    : overrides.map(incent => (
        <div key={incent + 'ewsaddmk'} className="shopper-incentive-tags">
          {shopperGroupIncentiveEntitiesMap[incent]}
        </div>
      ));

  return (
    <>
      <If test={shopperGroupName}>
        <UiKit.GridRow gap="20">
          <div className={styles.cell}>
            <span className={styles.label}>Shopper Group Name</span>
            <p className={styles.value}>{shopperGroupName}</p>
          </div>
        </UiKit.GridRow>
      </If>
      <If test={hasIncentives}>
        <UiKit.GridRow gap="20">
          <div className={styles.cell}>
            <span className={styles.label}>
              Applied shopper group overrides
            </span>
            <p className={styles.value}>
              <div className="d-flex flex-wrap">{renderAppliedIncentives}</div>
            </p>
          </div>
        </UiKit.GridRow>
      </If>
    </>
  );
}
