import React from 'react';
import { Row } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import FeatureCard from './FeatureCard';
import { useAccess } from 'utils/hooks';

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {import("utils/types").FeatureCard[]} props.items
 * @param {string} props.shepherdId
 * @param {boolean} props.disabled
 * @param {boolean} props.forceEnable
 * @param {boolean} props.hideDisabled
 */
export default function FeatureCards({
  id = '',
  items = [],
  shepherdId = '',
  disabled = false,
  forceEnable = false,
  hideDisabled = false
}) {
  const { configuration, hasFeatureAccess, hasSubFeatureAccess } = useAccess();

  const isEnabled = _get(configuration, `${id}.enabled`, false);
  const hasItems = _isArray(items) && !_isEmpty(items);

  if ((!isEnabled && !forceEnable) || !hasItems) {
    return null;
  }

  const { enabledCards, disabledCards, lockedCards } = items.reduce(
    (acc, item, index) => {
      const featureKey = `${id}.submenu.${item.id}`;
      const hasAccess = item.featureId
        ? hasFeatureAccess(item.featureId)
        : item.featureSlug
        ? hasSubFeatureAccess(item.featureSlug)
        : true;

      const isEnabled = _get(configuration, `${featureKey}.enabled`);
      const submenuConfig = _get(configuration, `${featureKey}.submenu`);
      const isActive = isEnabled || forceEnable;
      const list = isActive
        ? !hasAccess
          ? acc.lockedCards
          : acc.enabledCards
        : acc.disabledCards;

      list.push(
        <FeatureCard
          {...item}
          key={`${item.id}-${index}`}
          locked={!isActive}
          disabled={disabled}
          config={submenuConfig}
          hideDisabled={hideDisabled}
          shepherdId={item.shepherdId}
          featureId={item.featureId}
          featureSlug={item.featureSlug}
        />
      );
      return acc;
    },
    { enabledCards: [], disabledCards: [], lockedCards: [] }
  );

  return (
    <Row
      data-shepherd={shepherdId}
      className={cn('animated fadeIn', styles.container)}
    >
      {enabledCards}
      {!hideDisabled && disabledCards}
      {lockedCards}
    </Row>
  );
}
