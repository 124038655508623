import { series, reflectAll } from 'async';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { RETEX_STATUS } from 'dictionary';
import approveQualification from './approveQualification';

export default function approveAllQualificationRetexes(
  approve = true,
  retexes = [],
  onProcessing = () => {},
  onUpdateRetex = () => {},
  onFinally = () => {}
) {
  if (!_isArray(retexes)) return;

  const processRetex = (retex = {}) => (callback = () => {}) => {
    const id = _get(retex, 'id', null);

    onProcessing(id);
    approveQualification(
      {
        params: { routeParam: id, isApproved: approve },
        config: { headers: { 'X-Override-Request-State': true } }
      },
      data => {
        onUpdateRetex(id, data);
        callback(null);
      },
      err => callback(err)
    );
  };

  const apiFunctions = retexes.reduce((acc, retex) => {
    const status = _get(retex, 'status', null);
    const isAllowed = _get(retex, 'is_checked', false);
    const isValid = status === RETEX_STATUS.AWAITING_QUALIFICATION_APPROVAL;

    if (isAllowed && isValid) acc.push(processRetex(retex));
    return acc;
  }, []);

  series(reflectAll(apiFunctions), (_, results) => onFinally(results));
}
