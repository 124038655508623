import React from 'react';
import { renderToString } from 'react-dom/server';

import { ProgressIndicator, StepText } from 'containers/TourGuides/components';
import {
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import ADMIN_ROUTES from 'config/nav/admin/routes';

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getConfigureExchangesSteps({
  push = () => {},
  onShow = () => {},
  onHide = () => {}
}) {
  return [
    {
      title: 'Exchange Configuration',
      text: renderToString(
        <>
          <StepText>
            Exchange Configuration are one of the most important configurations
            to get right in Return Rabbit.
          </StepText>
          <ProgressIndicator current={1} total={6} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.CONFIGURE_RR.RETURN_MANAGEMENT
        });
      },
      buttons: [{ text: "Let's go", type: 'next' }],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: { element: getShepherdSelector('card-exchanges'), on: 'right' },
      canClickTarget: true,
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.CONFIGURE_RR.RETURN_MANAGEMENT,
          selector: this.attachTo.element
        });
      },
      advanceOn: {
        selector: `${getShepherdSelector('card-exchanges')} a`,
        event: 'click'
      },
      text: renderToString(
        <>
          <StepText>
            This is the section where you can configure you exchange
            preferences. This section is what can bring you additional revenue
            so this is probably where you would want to spend a lot of time.{' '}
          </StepText>
          <br />
          <StepText callout={false}>
            Click <code>Manage</code> button to continue.
          </StepText>
          <ProgressIndicator current={2} total={6} />
        </>
      ),
      buttons: [{ text: 'Back', type: 'back', secondary: true }]
    },
    {
      attachTo: {
        element: getShepherdSelector('exchanges-allow-toggle'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>
            This is the global exchange toggle. If you turn it off, your
            shoppers will not see any exchange options or any exchange language
            throughout your shopper portal.
            <br />
            <br />
            We have seen our merchants retain up to 75% return transactions via
            exchanges so understanding this section is very crucial to maximise
            your ROI from Return Rabbit.
          </StepText>
          <ProgressIndicator current={3} total={6} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('exchanges-benefits'),
        on: 'top'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>
            This is where you can configure your exchange product catalog,
            exchange recommendations & any incentives that you would like to
            offer your shoppers if they choose to exchange.
          </StepText>
          <ProgressIndicator current={4} total={6} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('exchanges-features'),
        on: 'top'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>
            This is where you can control many advanced exchange preferences
            like discount forwarding and much more to boost customer loyalty
            towards your brand.
          </StepText>
          <ProgressIndicator current={5} total={6} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('exchanges-monetary-incentives'),
        on: 'top'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>
            This section houses the extra monetary incentives that you can offer
            your shoppers to nudge them towards exchanging more, thus retaining
            your revenue.
          </StepText>
          <ProgressIndicator current={6} total={6} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Done', type: 'next' }
      ]
    }
  ];
}
