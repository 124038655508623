import moment from 'moment';

export default function isDateBetween(startDate = '', endDate = '') {
  const start = moment(startDate);
  const end = moment(endDate);

  if ((start && !start.isValid()) || (endDate && !end.isValid())) {
    throw new Error('Invalid date provided');
  }

  return moment().isSameOrAfter(start) && moment().isSameOrBefore(end);
}
