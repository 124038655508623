import React from 'react';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import styles from './index.module.scss';

import { If } from 'utils';

const DEFAULT_COMPONENT = () => null;

export default function BarChartTooltip({
  color = null,
  value = '',
  labelKey = 'indexValue',
  formatLabel = val => val,
  formatValue = val => val,
  component: Component = DEFAULT_COMPONENT,
  ...restChartProps
}) {
  const renderValue = formatValue(value);
  const label = formatLabel(_get(restChartProps, labelKey, 'Value'));

  const isCustom = Component !== DEFAULT_COMPONENT && _isFunction(Component);

  return (
    <div className={styles.tooltip}>
      <If test={!isCustom}>
        <div style={{ backgroundColor: color }} className={styles.colorBlock} />
        <div>
          {label}: {renderValue}
        </div>
      </If>
      <If test={isCustom}>
        <Component
          {...restChartProps}
          color={color}
          label={label}
          value={renderValue}
        />
      </If>
    </div>
  );
}
