import React from 'react';

export default function Wrench() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        style={{ fill: 'var(--icon-color)' }}
        fillRule="nonzero"
        d="M14.786 2.506a.5.5 0 0 1 .81.148C15.86 3.237 16 3.86 16 4.5a4.5 4.5 0 0 1-5.88 4.285l-5.219 7.014a.5.5 0 0 1-.755.055l-4-4a.5.5 0 0 1 .055-.755l7.014-5.22A4.503 4.503 0 0 1 11.5 0c.64 0 1.263.14 1.846.405a.5.5 0 0 1 .148.809L11.707 3 13 4.293l1.786-1.787zm.137 1.278l-1.57 1.57a.5.5 0 0 1-.707 0l-2-2a.5.5 0 0 1 0-.708l1.57-1.57A3.378 3.378 0 0 0 11.5 1a3.5 3.5 0 0 0-3.225 4.863.5.5 0 0 1-.162.595l-6.85 5.098 3.181 3.181 5.098-6.85a.5.5 0 0 1 .595-.162A3.5 3.5 0 0 0 15 4.5c0-.242-.026-.481-.077-.716z"
      />
    </svg>
  );
}
