const INCENTIVE_TYPE = {
  FIXED: 'fixed',
  PERCENT: 'percentage'
};

const INCENTIVE_OPTIONS = [
  { label: 'Fixed Incentive', value: INCENTIVE_TYPE.FIXED },
  { label: 'Percentage Incentive', value: INCENTIVE_TYPE.PERCENT }
];

export { INCENTIVE_TYPE, INCENTIVE_OPTIONS };
