import React, { useContext } from 'react';
import _get from 'lodash/get';

import { CycleRecords } from 'components';
import { RetexContext } from 'components/RetexDetails';

export default function CycleRetexes() {
  const {
    orderService,
    retex,
    retexIds,
    retexesDataMap,
    isFetchingRetexes,
    onGotoRetex
  } = useContext(RetexContext);

  const retexId = _get(retex, 'id', '');
  const osId = _get(orderService, 'id', '');
  const osName = _get(orderService, 'name', '');

  const gotoRetex = retex => {
    const retexId = _get(retex, 'id', '');
    const retexName = _get(retex, 'name', '');
    onGotoRetex(retexId, osId, retexName, osName);
  };

  return (
    <CycleRecords
      id={retexId}
      ids={retexIds}
      dataMap={retexesDataMap}
      isFetching={isFetchingRetexes}
      onChange={gotoRetex}
    />
  );
}
