import React from 'react';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

import { connectError } from 'utils/dataConnectors';
import ErrorPage from './ErrorPage';
import NotFound from './NotFound';
import { preventErrorLog } from 'utils/methods';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: this.props.hasError };
  }

  componentDidMount() {
    // When unknown errors are thrown, the UI needs to fallback to the error state (Sarthak)
    // window.onerror = (message, source, lineno, colno, error) => {
    //   this.handleError(error, {});
    // };
  }

  componentDidUpdate(prevProps) {
    if (this.state.hasError && prevProps.location !== this.props.location) {
      this.setState({
        hasError: false
      });
      return;
    }
    if (this.props.hasError && !this.state.hasError) {
      this.setState({
        hasError: true
      });
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.handleError(error, info);
  }

  handleError(error, info) {
    if (preventErrorLog() || this.state.hasError) return;

    this.setState({ hasError: true });
    // Log to external reporting service
    LogRocket.captureException(error, {
      extra: { componentStack: info.componentStack }
    });
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => scope.setExtra(key, info[key]));
      Sentry.captureException(error);
    });
  }

  render() {
    if (!this.state.hasError) return this.props.children;
    const { errorObject } = this.props;
    let message = null;
    if (typeof errorObject === 'string') {
      message = errorObject;
    } else if (typeof errorObject === 'object' && errorObject !== null) {
      message =
        typeof errorObject.displayMessage === 'string'
          ? errorObject.displayMessage
          : 'Something went wrong. Please try again later.';
    }
    if (
      typeof errorObject === 'object' &&
      errorObject !== null &&
      errorObject.statusCode === 404 &&
      errorObject.method === 'get'
    ) {
      return <NotFound />;
    }
    return <ErrorPage homeButton message={message} />;
  }
}

export default connectError(ErrorBoundary);
