import React, { useContext } from 'react';

import { DOCUMENTAION_LINKS } from 'dictionary';
import { AuthContext } from 'context/AuthContext';

const { RR_PRIVACY_POLICY, RR_TERMS_OF_USE } = DOCUMENTAION_LINKS;

export default function DefaultFooter() {
  const { appType } = useContext(AuthContext);

  return (
    <span>
      Powered by{' '}
      <a
        href="https://returnrabbit.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Return Rabbit
      </a>
      <br />
      {appType.retex && (
        <>
          Build {process.env.REACT_APP_CURRENT_GIT_SHA} - Version{' '}
          {process.env.REACT_APP_VERSION}
          <br />
        </>
      )}
      <a href={RR_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>{' '}
      |{' '}
      <a href={RR_TERMS_OF_USE} target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>
    </span>
  );
}
