import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { SectionContext } from '../Section';

export default function Highlight({
  shepherdId = '',
  delay = 500, // in milliseconds
  className = '',
  disabled = false,
  children = null
}) {
  const { isFocused, onClearFocus } = useContext(SectionContext);

  const containerClassName = useMemo(() => {
    return cn(className, { [styles.highlight]: isFocused && !disabled });
  }, [className, isFocused, disabled]);

  const onInteract = () => {
    if (disabled) return;
    onClearFocus();
  };

  return (
    <div
      onClick={onInteract}
      data-shepherd={shepherdId}
      data-highlight={isFocused && !disabled}
      // onMouseMove={onInteract}
      className={containerClassName}
      style={{ animationDelay: `${delay}ms` }}
    >
      {children}
    </div>
  );
}
