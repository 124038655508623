import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const axiosPublicInstance = axios.create({
  baseURL: API_BASE_URL,
  // withCredentials: true,
  headers: { 'Content-Type': 'application/json' }
});

export const axiosPrivateInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' }
});
