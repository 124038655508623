import _isString from 'lodash/isString';
import _isEmpty from 'lodash/isEmpty';

function isValidEmail(email) {
  return _isString(email) && !_isEmpty(email)
    ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    : false;
}

export default isValidEmail;
