import _isArray from 'lodash/isArray';

import { get, patch, logError } from 'utils';

export function getFeatureFlags(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  get({ apiKey: 'configure.featureFlags.get' }, { params })
    .then(({ data }) => onSuccess(_isArray(data) ? data : []))
    .catch(err => onError(err))
    .finally(onFinally);
}

export function updateFeatureFlag(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  patch({ apiKey: 'configure.featureFlags.patch' }, { params })
    .then(({ data }) => onSuccess(data))
    .catch(err => {
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
