const ROOT_PATHS = {
  HOME: '/home',
  ORDERS: '/orders',
  TRACKING: '/tracking',
  EMAILS: '/emails',
  SETTINGS: '/settings',
  HELP_AND_SUPPORT: '/help-and-support'
};

export default {
  HOME: {
    ROOT: ROOT_PATHS.HOME
  },
  ORDERS: {
    ROOT: ROOT_PATHS.ORDERS
  },
  TRACKING: {
    ROOT: ROOT_PATHS.TRACKING
  },
  EMAILS: {
    ROOT: ROOT_PATHS.EMAILS
  },
  SETTINGS: {
    ROOT: ROOT_PATHS.SETTINGS,
    MANAGE_SUBSCRIPTION: `${ROOT_PATHS.SETTINGS}/manage-subscription`,
    USER_MANAGEMENT: `${ROOT_PATHS.SETTINGS}/manage-users`
  },
  HELP: {
    ROOT: ROOT_PATHS.HELP_AND_SUPPORT
  }
};
