import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { If } from 'utils';
import { useNotifications } from 'utils/hooks';
import { Loader } from 'components';
import NotificationList from '../NotificationList';
import {
  EndOfNotifications,
  NoNotifications,
  NotificationError,
  NotificationSkeleteonLoader
} from '../../stubs';

export default function AllNotifications({ className = '' }) {
  const {
    allNotificationsList,
    fetchAllNotifications,
    ifPolling,
    hasMore,
    allNotificationsError,
    loadedAllNotifications
  } = useNotifications();

  return (
    <div className={className} id="all_cont">
      <If test={!allNotificationsError} otherwise={NotificationError}>
        <If
          test={loadedAllNotifications}
          otherwise={NotificationSkeleteonLoader}
        >
          <If test={!ifPolling} otherwise={NotificationSkeleteonLoader}>
            <If test={allNotificationsList.length} otherwise={NoNotifications}>
              <InfiniteScroll
                dataLength={allNotificationsList.length} //This is important field to render the next data
                next={fetchAllNotifications}
                hasMore={hasMore}
                loader={<Loader loading={true} />}
                scrollableTarget="all_cont"
                scrollThreshold="1px"
                endMessage={EndOfNotifications}
              >
                <NotificationList list={allNotificationsList} listType="all" />
              </InfiniteScroll>
            </If>
          </If>
        </If>
      </If>
    </div>
  );
}
