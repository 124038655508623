import React, { useContext, useRef } from 'react';
import _get from 'lodash/get';
import styles from '../index.module.scss';

import { findAllValues } from 'utils/DICTIONARY';
import { OrderServiceContext } from 'components/OrderServiceDetails';
import ExternalLink from '../../ExternalLink';
import Actions from './Actions';
import { getFormattedHomePickupDate } from '../../../methods';

export default function ShippingInfo() {
  const { orderService } = useContext(OrderServiceContext);
  const statusMap = useRef(findAllValues('fulfillmentStatusChoices')).current;
  const methodMap = useRef(findAllValues('retailerShippingMethodChoices'))
    .current;
  const pickupSlotMap = useRef(findAllValues('pickup_slot_choices')).current;

  const shippingInfo = _get(orderService, 'shipping_info', {});

  const shippingStatus = _get(shippingInfo, 'status', '');
  const shippingMethod = _get(shippingInfo, 'method', '');

  const storeDetails = _get(shippingInfo, 'store_details', {});
  const trackingDetails = _get(shippingInfo, 'tracking_details', {});
  const homePickupDetails = _get(shippingInfo, 'home_pickup_details', {});

  const trackingCompany = _get(trackingDetails, 'tracking_company', 'N/A');
  const trackingNumber = _get(trackingDetails, 'tracking_number', '');
  const trackingUrl = _get(trackingDetails, 'tracking_url', '');

  const storeAddress1 = _get(storeDetails, 'address_1', '');
  const storeAddress2 = _get(storeDetails, 'address_2', '');
  const storeCity = _get(storeDetails, 'city', '');
  const storeProvince = _get(storeDetails, 'province', '');
  const storeZip = _get(storeDetails, 'zip', '');
  // const storeCountry = _get(storeDetails, 'country', '');

  const homePickupDate = _get(homePickupDetails, 'pickup_date', '');
  const homePickupSlot = _get(homePickupDetails, 'pickup_slot', '');

  const isSendLabel = shippingMethod === 'SEND_LABEL';
  const isSelfShip = shippingMethod === 'SELF_SHIP';
  const isStoreReturn = shippingMethod === 'STORE_RETURN';
  const isHomePickup = shippingMethod === 'HOME_PICKUP';
  const isNoShip = shippingMethod === 'NO_SHIP';

  const formattedShippingStatus = _get(statusMap, shippingStatus, 'N/A');
  const formattedShippingMethod = _get(methodMap, shippingMethod, 'N/A');
  const formattedPickupSlot = _get(pickupSlotMap, homePickupSlot, 'N/A');
  const formatterPickupDate = getFormattedHomePickupDate(homePickupDate);

  return (
    <>
      <div className={styles.row}>
        <span className={styles.label}>Status</span>
        <h4 className={styles.value}>{formattedShippingStatus}</h4>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Shipping Method</span>
        <span className={styles.value}>{formattedShippingMethod}</span>
      </div>
      {(isSendLabel || isSelfShip || isNoShip) && (
        <>
          <div className={styles.row}>
            <span className={styles.label}>Tracking Number</span>
            <ExternalLink
              allowCopy
              href={trackingUrl}
              text={trackingNumber}
              fallback={<span className={styles.value}>N/A</span>}
            />
          </div>
          <div className={styles.row}>
            <span className={styles.label}>Carrier</span>
            <span className={styles.value}>{trackingCompany}</span>
          </div>
        </>
      )}
      {isStoreReturn && (
        <div className={styles.row}>
          <span className={styles.label}>Store address</span>
          <div className={styles.column}>
            <span className={styles.value}>
              {storeAddress1} {storeAddress2 && `, ${storeAddress2}`}
            </span>
            <span className={styles.value}>
              {storeCity}, {storeProvince} - {storeZip}
            </span>
          </div>
        </div>
      )}
      {isHomePickup && (
        <div className={styles.row}>
          <span className={styles.label}>Pickup Date</span>
          <div className={styles.column}>
            <span className={styles.value}>{formatterPickupDate}</span>
            <span className={styles.value}>{formattedPickupSlot}</span>
          </div>
        </div>
      )}
      {!isNoShip && (
        <div className={styles.actions}>
          <Actions />
        </div>
      )}
    </>
  );
}
