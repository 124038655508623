import numbro from 'numbro';
import { findCurrency } from 'utils/DICTIONARY';

export default function formatPrice(value, currency = '') {
  if (Number.isNaN(value)) return value;

  const formattedCurrency = findCurrency(currency.toUpperCase());

  const formattedVal = value
    ? numbro(value).format({
        thousandSeparated: true,
        mantissa: 2
      })
    : 0;

  return `${formattedCurrency}${formattedVal}`;
}
