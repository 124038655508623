import React from 'react';
import classnames from 'classnames';

import { UiKit } from 'components';

export default function AddButton({
  title = '',
  loading = false,
  disabled = false,
  customType = 'primary',
  customClass = '',
  customWrapClass = '',
  processingText = 'Loading',
  onClick = () => {},
  children = null
}) {
  return (
    <UiKit.Button
      onClick={onClick}
      disabled={disabled}
      processing={loading}
      customType={customType}
      processingText={processingText}
      className={classnames('CustomTable__AddButton', {
        disabled: disabled,
        [customClass]: !!customClass
      })}
      wrapperClassName={classnames('CustomTable__AddButton--wrapper', {
        [customWrapClass]: !!customWrapClass
      })}
    >
      {children || title}
    </UiKit.Button>
  );
}
