import { useState, useRef, useCallback, useEffect } from 'react';
import _get from 'lodash/get';

import { getRetexesOfOrderService } from 'utils/services';
import { modifyRetexes } from '../methods';

export default function useRetexes(osData = {}, show = true) {
  const [retexes, setRetexes] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  // const isLoadedRef = useRef(false);
  const inProgressRef = useRef(false);

  const onLoadRetexes = useCallback(() => {
    const id = _get(osData, 'id', '');
    const status = _get(osData, 'status', '');

    if (id && !inProgressRef.current) {
      inProgressRef.current = true;
      setIsFetching(true);
      getRetexesOfOrderService(
        { routeParams: { id } },
        data => {
          const retexes = modifyRetexes(status, data);
          setRetexes(retexes);
        },
        () => {},
        () => {
          inProgressRef.current = false;
          setIsFetching(false);
        }
      );
    }
  }, [osData]);

  useEffect(() => {
    if (show) {
      onLoadRetexes();
    }
  }, [show, onLoadRetexes]);

  const onUpdateRetex = useCallback(
    (id = null, updates = {}, forceReload = false) => {
      if (!id) return;
      if (forceReload) {
        setRetexes([]);
        // isLoadedRef.current = false;
        onLoadRetexes();
      } else {
        setRetexes(currRetexes => {
          return currRetexes.map(retex => {
            return id === retex.id ? { ...retex, ...updates } : retex;
          });
        });
      }
    },
    [onLoadRetexes]
  );

  return { retexes, isFetching, onUpdateRetex, onLoadRetexes };
}
