import React, { Component } from 'react';

import Events from './Events';

export default class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.events.length - 1
    };
  }

  openBubble = index => this.setState({ open: index });

  render() {
    const events = this.props.events;
    if (events.length < 1) return null;
    const actualLineWidth =
      (document.getElementById('order-page').offsetWidth - 150) / events.length;
    return (
      <div className="Timeline">
        <Events
          open={this.state.open}
          openBubble={this.openBubble}
          events={events}
          actualLineWidth={actualLineWidth}
        />
        <svg height={20} width={42}>
          <circle cx={11} cy={10} r={3} fill="#004165" />
          <circle cx={21} cy={10} r={3} fill="#004165" />
          <circle cx={31} cy={10} r={3} fill="#004165" />
        </svg>
        <div className="event futureGray ">
          <div className="event-bubble futureOpacity">
            May 2016
            <div className="event-title">Expected Delivery</div>
          </div>
          <svg height={20} width={20}>
            <circle cx={10} cy={11} r={5} fill="rgba(162, 164, 163, 0.37)" />
          </svg>
        </div>
      </div>
    );
  }
}
