import React from 'react';
import Notification from '../Notification';
import { AnimatePresence } from 'framer-motion';

import { If } from 'utils';
import { useNotifications } from 'utils/hooks';

export default function NotificationList({
  list = [],
  listType = '',
  animated = false
}) {
  const { onMarkNotificationList } = useNotifications();

  const onMarkAsRead = id => {
    onMarkNotificationList(id, listType);
  };

  const renderNotifications = list.map(n => (
    <Notification
      key={n.id + 'notification_list'}
      details={n}
      markReadCb={onMarkAsRead}
    />
  ));

  return (
    <If test={animated} otherwise={renderNotifications}>
      <AnimatePresence>{renderNotifications}</AnimatePresence>
    </If>
  );
}
