import React, { useContext } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { TableHeading } from 'components/Tables';
import { Section, StoreDetails } from './components';
import { ProtectedLink, UiKit } from 'components';
import { Card } from './components';
import { AuthContext } from 'context/AuthContext';
import ADMIN_ROUTES from 'config/nav/admin/routes';
import { ReactComponent as RightArrowIcon } from 'assets/img/icons/arrow-right.svg';
import { useAccess } from 'utils/hooks';

const MANAGE_SUBSCRIPTION_ACCESS_PATH =
  'admin.submenu.settings.submenu.manage_subscription.enabled';

export default function AccountSettings() {
  const { hasPageAccess } = useAccess();
  const {
    userData,
    isExternalUser,
    subscriptionDetails,
    toggleChangePasswordModal
  } = useContext(AuthContext);

  const email = _get(userData, 'email', '');
  const planName = _get(
    subscriptionDetails,
    'plan_info.name',
    'Not Subscribed'
  );
  const canManageSubscription = hasPageAccess(MANAGE_SUBSCRIPTION_ACCESS_PATH);

  return (
    <div className="animated fadeIn">
      <TableHeading title="Account Settings" />
      <UiKit.GridRow templateColumns="1fr" gap="42">
        <Section title="Store Details">
          <StoreDetails />
        </Section>
        <Section title="Return Rabbit Details">
          <UiKit.GridRow templateColumns="1fr 1fr 1fr" gap="16">
            <Card className={styles.card}>
              <Card.Title>Account Email</Card.Title>
              <Card.Value>{email}</Card.Value>
              <UiKit.Button
                customType="outline"
                onClick={toggleChangePasswordModal}
                disabled={isExternalUser}
                className={styles.button}
              >
                Change Password
              </UiKit.Button>
            </Card>
            <Card className={styles.card}>
              <Card.Title>Return Rabbit Subscription Plan</Card.Title>
              <Card.Value>{planName}</Card.Value>
              <ProtectedLink
                to={ADMIN_ROUTES.SETTINGS.MANAGE_SUBSCRIPTION}
                id="manage-susbcription-redirect-btn"
                _key="admin.submenu.settings.submenu.manage_subscription.enabled"
                tooltipPosition="bottom"
              >
                <UiKit.Button
                  disabled={!canManageSubscription}
                  className={styles.button}
                >
                  Manage Subscription <RightArrowIcon />
                </UiKit.Button>
              </ProtectedLink>
            </Card>
          </UiKit.GridRow>
        </Section>
      </UiKit.GridRow>
    </div>
  );
}
