import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { RETEX_STATUS } from 'dictionary';

export default function getQualificationRetex(retexes = []) {
  if (!_isArray(retexes)) return null;

  return retexes.find(retex => {
    const status = _get(retex, 'status', '');
    return status === RETEX_STATUS.AWAITING_QUALIFICATION_APPROVAL;
  });
}
