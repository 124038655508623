import React, { useState, useEffect, useContext } from 'react';
import Rheostat from 'rheostat';
import _get from 'lodash/get';
import cn from 'classnames';

import { ReactTablesContext } from 'components/ReactTable';

export default function TableRefinementRange({
  facetKey = '',
  formatter = val => val
}) {
  const {
    refine,
    facets,
    refinements,
    clearRefinement,
    facetsToRefinementKeyMap,
    searchState: { isFetchingFacets }
  } = useContext(ReactTablesContext);
  const refinementKey = _get(facetsToRefinementKeyMap, facetKey, facetKey);

  const [currentValues, setCurrentValues] = useState(null);
  const [minMax, setMinMax] = useState(null);

  const minFacet = _get(facets, `${facetKey}[0]`);
  const maxFacet = _get(facets, `${facetKey}[1]`);
  const refinementData = _get(refinements, `${refinementKey}.values`, {});

  const updateComponent = () => {
    const range = { min: minFacet, max: maxFacet };
    if (minFacet !== undefined && maxFacet !== undefined) {
      setMinMax(range);
      updateCurrentValues(range);
    }
  };

  useEffect(() => {
    if (!isFetchingFacets) {
      updateComponent();
    }
    // eslint-disable-next-line
  }, [minFacet, maxFacet, isFetchingFacets]);

  const updateCurrentValues = range => {
    const rangeMin = _get(range, 'min');
    const rangeMax = _get(range, 'max');

    if (!currentValues) {
      const { min = rangeMin, max = rangeMax } = refinementData;
      setCurrentValues({ min, max });
    } else if (refinementKey in refinements) {
      const { min: currentMin, max: currentMax } = currentValues;
      const newMinValue = Math.min(Math.max(currentMin, rangeMin), rangeMax);
      const newMaxValue = Math.min(currentMax, rangeMax);
      const newValues = { min: newMinValue, max: newMaxValue };
      setCurrentValues(newValues);
    } else {
      setCurrentValues({ min: rangeMin, max: rangeMax });
    }
  };

  const onValuesUpdated = sliderState => {
    const [min, max] = _get(sliderState, 'values', []);
    setCurrentValues({ min, max });
  };

  const doRefine = sliderState => {
    const [min, max] = _get(sliderState, 'values', []);
    refine({
      type: 'range',
      attribute: refinementKey,
      value: { min, max }
    });
  };

  const onChange = sliderState => {
    const [min, max] = _get(sliderState, 'values', []);
    const slideMin = _get(sliderState, 'min');
    const slideMax = _get(sliderState, 'max');
    const minRefinement = _get(refinementData, 'min');
    const maxRefinement = _get(refinementData, 'max');

    if (slideMin === min && slideMax === max) {
      return clearRefinement({ attribute: refinementKey });
    }
    if (slideMin === minRefinement && slideMax === maxRefinement) {
      return;
    }
    doRefine(sliderState);
  };

  if (!currentValues || !minMax) return null;

  const { min: allowedMin, max: allowedMax } = minMax;
  const { min, max } = currentValues;

  return allowedMin !== allowedMax ? (
    <div>
      <Rheostat
        min={allowedMin}
        max={allowedMax}
        values={[min, max]}
        disabled={isFetchingFacets}
        onChange={onChange}
        onValuesUpdated={onValuesUpdated}
        className={cn('ais-RangeSlider', { disabled: isFetchingFacets })}
      />
      <div className="range-value">
        <div className="value">{formatter(min)}</div>
        <span className="separator"> - </span>
        <div className="value">{formatter(max)}</div>
      </div>
    </div>
  ) : (
    <div className="text-muted">No filters available!</div>
  );
  // }
}
