import React from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import cn from 'classnames';
import styles from './index.module.scss';

import { useAccess } from 'utils/hooks';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/chevron-left.svg';
import { If } from 'utils';
import { FeatureUpsell } from 'components';

/**
 * @param {Object} props
 * @param {boolean} props.disabled
 * @param {import("utils/types").FeatureCardSubFeatures[]} props.items
 */
export default function SubFeatures({ items, disabled = false }) {
  const { hasFeatureAccess, hasSubFeatureAccess } = useAccess();

  if (!items || !Array.isArray(items)) return null;

  const { active, locked } = items.reduce(
    (acc, item) => {
      const id = _get(item, 'id', '');
      const tag = _get(item, 'tag', '');
      const title = _get(item, 'title', '');
      const link = _get(item, 'link', {});
      const shepherdId = _get(item, 'shepherdId', '');
      const featureId = _get(item, 'featureId', '');
      const featureSlug = _get(item, 'featureSlug', '');

      const hasAccess = featureId
        ? hasFeatureAccess(featureId)
        : featureSlug
        ? hasSubFeatureAccess(featureSlug)
        : true;

      const list = hasAccess ? acc.active : acc.locked;

      list.push(
        <Link
          key={id}
          to={link.url}
          data-shepherd={shepherdId}
          className={cn(styles.container, { [styles.locked]: !hasAccess })}
        >
          <div className={styles.titleWrapper}>
            <h5 className={styles.title}>{title}</h5>
            {tag && <span className={styles.tag}>{tag}</span>}
          </div>
          <If test={hasAccess} otherwise={<FeatureUpsell.LockBadge />}>
            <ArrowIcon className={styles.arrowIcon} />
          </If>
        </Link>
      );

      return acc;
    },
    { active: [], locked: [] }
  );

  return (
    <>
      {active}
      {locked}
    </>
  );
}
