import React, { useContext, useState } from 'react';
import styles from './index.module.scss';

import { post } from 'utils';
import { DOCUMENTAION_LINKS } from 'dictionary';
import { AuthContext } from 'context/AuthContext';
import { CustomToast, UiKit, Callout } from 'components';
import Checkbox from '../Checkbox';

const { RR_TERMS_OF_USE, RR_PRIVACY_POLICY } = DOCUMENTAION_LINKS;

export default function Modal() {
  const { isTermsAccepted, updateState } = useContext(AuthContext);
  const [checked, setChecked] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const onAcceptTerms = () => {
    setIsProcessing(true);
    post(
      { apiKey: 'termsAndConditions' },
      { params: { tos_pp_accepted: true } }
    )
      .then(() => {
        updateState({ isTermsAccepted: true });
        CustomToast({
          type: 'success',
          msg: 'Successfully registered your response.'
        });
      })
      .catch(err => {
        CustomToast({
          isNotified: err.notified,
          type: 'error',
          msg:
            'Something went wrong! Please try again later or contact customer support.'
        });
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <UiKit.Modal
      centered
      backdrop="static"
      isCrossIconHidden
      isCancelBtnHidden
      title="We are updating our Terms of Service and Privacy Policy"
      isOpen={!isTermsAccepted}
      onConfirm={onAcceptTerms}
      isProcessing={isProcessing}
      isConfirmDisabled={!checked}
      confirmBtnText="Accept and Continue"
      confirmBtnProcessingText="Accepting..."
      className={styles.modal}
      contentClassName={styles.content}
      backdropClassName={styles.backdrop}
      footerClassName={styles.footer}
      confirmBtnClassName={styles.button}
    >
      <Callout className="mb-4" type="warning">
        <Callout.Title>
          We’re growing while working towards bringing you the best returns
          experience.
        </Callout.Title>
        <Callout.Content>
          Check out our new{' '}
          <a href={RR_TERMS_OF_USE} target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href={RR_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </Callout.Content>
      </Callout>
      <Checkbox checked={checked} onToggle={() => setChecked(curr => !curr)} />
    </UiKit.Modal>
  );
}
