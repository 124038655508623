import React, { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import cn from 'classnames';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { ReactComponent as InfoIcon } from 'assets/img/alert-icons/info.svg';

export default function ShopperGroupIncentiveIcon({
  type = '',
  incentives = [],
  // groupName = '',
  iconClassName = '',
  message = 'Shopper group incentive applied'
}) {
  const show = useMemo(() => {
    if (!_isArray(incentives) || _isEmpty(incentives)) return false;
    return incentives.some(incentive => incentive === type);
  }, [type, incentives]);

  if (!type || !show) return null;

  const id = `${type.toLowerCase()}-incentive`;
  const iconClass = cn(styles.icon, iconClassName);

  return (
    <>
      <InfoIcon id={id} className={iconClass} />
      <UncontrolledTooltip target={id}>{message}</UncontrolledTooltip>
    </>
  );
}
