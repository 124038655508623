import React, { useContext } from 'react';
import { Collapse, Row } from 'reactstrap';
import cn from 'classnames';
import _isFunction from 'lodash/isFunction';

import { Loader, UiKit } from 'components';
import TableClearFiltersButton from './TableClearFiltersButton';
import { ReactTablesContext } from 'components/ReactTable';

export default function TableFiltersWrapper({
  show = false,
  children = null,
  canClearFilters = true,
  onToggle = null,
  className = '',
  contentClassName = ''
}) {
  const { initializedFacets } = useContext(ReactTablesContext);
  const canClose = _isFunction(onToggle);

  const containerClass = cn('table-filters', {
    [className]: !!className
  });

  const contentClass = cn('filters-row', {
    [contentClassName]: !!contentClassName
  });

  return (
    <Collapse isOpen={show} className={containerClass}>
      <Loader loading={!initializedFacets}>
        <div className="table-filters__container">
          <Row className={contentClass}>{children}</Row>
          {canClearFilters && (
            <div className="footer">
              <TableClearFiltersButton />
              {canClose && (
                <UiKit.Button
                  onClick={onToggle}
                  customType="outline"
                  className="cancel-button"
                  wrapperClassName="cancel-button-wrapper"
                >
                  Close
                </UiKit.Button>
              )}
            </div>
          )}
        </div>
      </Loader>
    </Collapse>
  );
}
