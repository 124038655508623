import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { AppNavbarBrand } from '@coreui/react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledTooltip,
  UncontrolledButtonDropdown
} from 'reactstrap';
import _startCase from 'lodash/startCase';

import { If } from 'utils';
import { UiKit } from 'components';
import { AuthContext } from 'context/AuthContext';
import rrLogo from 'assets/img/brand/rr-logo.svg';
import logout from 'assets/img/icons/logout.svg';
import password from 'assets/img/icons/password.svg';
import profile from 'assets/img/icons/profile.svg';
import trackingRabbitLogo from 'assets/img/brand/tracking-rabbit-logo.png';

export default function DefaultHeader() {
  const {
    appType,
    userData = {},
    isExternalUser,
    toggleChangePasswordModal = () => {}
  } = useContext(AuthContext);
  const { avatarPicUrl, firstName, lastName, email } = userData;

  const logo = useRef(appType.shipmentTracking ? trackingRabbitLogo : rrLogo)
    .current;
  const appName = useRef(
    appType.shipmentTracking ? 'Tracking Rabbit' : 'Return Rabbit'
  ).current;

  const avatar =
    avatarPicUrl || `https://ui-avatars.com/api/?name=${firstName} ${lastName}`;

  return (
    <UiKit.MaxWidthContainer className="d-flex align-items-center justify-content-between">
      <AppNavbarBrand tag={'div'}>
        <Link to="/">
          <img src={logo} alt={appName} height="32" />
        </Link>
      </AppNavbarBrand>
      <Nav navbar>
        <UncontrolledButtonDropdown>
          <DropdownToggle nav className="name-block">
            <div className="name-col">{_startCase(firstName)}</div>
            <img src={avatar} className="img-avatar" alt="Admin" />
          </DropdownToggle>
          <DropdownMenu right className="display__dropdown">
            <DropdownItem
              header
              className="dropdown-item"
              id="user-email-dropdown"
            >
              <img src={profile} alt="profile" />
              <span href="#">{email} </span>
              <UncontrolledTooltip
                placement="right"
                target="user-email-dropdown"
              >
                {email}
              </UncontrolledTooltip>
            </DropdownItem>
            <If test={!isExternalUser}>
              <div onClick={() => toggleChangePasswordModal()}>
                <DropdownItem className="dropdown-item">
                  <img src={password} alt="support" />
                  Change Password
                </DropdownItem>
              </div>
            </If>
            <Link to="/logout">
              <DropdownItem className="dropdown-item">
                <img src={logout} alt="logout" />
                Logout
              </DropdownItem>
            </Link>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Nav>
    </UiKit.MaxWidthContainer>
  );
}
