import WH_ROUTES, { URL_PREFIX } from 'config/nav/warehouse/routes';
import TAB_ID from './tabs';

export default {
  URL_PREFIX,
  OVERVIEW: {
    ROOT: WH_ROUTES.OVERVIEW.ROOT,
    IN_TRANSIT: `${WH_ROUTES.OVERVIEW.ROOT}/?tab=${TAB_ID.IN_TRANSIT}`,
    RECEIVED: `${WH_ROUTES.OVERVIEW.ROOT}/?tab=${TAB_ID.RECEIVED}`,
    PROCESSED: `${WH_ROUTES.OVERVIEW.ROOT}/?tab=${TAB_ID.PROCESSED}`
  },

  ITEM_INSPECTION: {
    ROOT: `${URL_PREFIX}/inspection/:id`,
    RELATED_REQUESTS: `${URL_PREFIX}/inspection/:id/related`
  }
};
