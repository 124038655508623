const reducer = (state, action) => {
  switch (action.type) {
    case 'handleReasonChange':
      return { ...state, reason: action.val };
    case 'handleInputChange':
      return { ...state, otherReason: action.val, otherReasonInvalid: false };
    case 'setOtherReasonInvalid':
      return { ...state, otherReasonInvalid: action.val };
    case 'setReasonOptions':
      return { ...state, reason: action.reason, options: action.options };
    case 'setProcessing':
      return { ...state, isProcessing: action.val };
    default:
      return action.val;
  }
};

export default reducer;
