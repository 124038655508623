import React, { useContext, useState } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { deleteRetailerNote } from 'utils/services';
import Note from './Note';
import { NotesContext } from '../../context';
import { RetexContext } from 'components/RetexDetails';

export default function Notes() {
  const { notes, onDelete } = useContext(NotesContext);
  const { retex } = useContext(RetexContext);
  const [deletingIds, setDeletingIds] = useState({});

  const onNoteDelete = (id, index) => {
    const retexId = _get(retex, 'id', '');
    const isDeleting = _get(deletingIds, id, false);

    if (!id || !retexId || isDeleting) return;

    setDeletingIds(prevIds => ({ ...prevIds, [id]: true }));
    deleteRetailerNote(
      { routeParam: `${retexId}/${id}` },
      () => onDelete(index),
      () => {},
      () =>
        setDeletingIds(prevIds => {
          const ids = { ...prevIds };
          delete ids[id];
          return ids;
        })
    );
  };

  const renderNotes = notes.map((data, index) => {
    const id = _get(data, 'id', index);
    return (
      <Note
        key={id}
        data={data}
        deletingIds={deletingIds}
        onDelete={() => onNoteDelete(id, index)}
      />
    );
  });

  return <ul className={styles.container}>{renderNotes}</ul>;
}
