import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { ReactComponent as InfoIcon } from 'assets/img/icons/warning_darker.svg';

export default function InfoBanner({
  icon = null,
  children = null,
  gap = '20',
  iconSize = '40',
  isInvalid = false,
  className = '',
  iconClassName = '',
  messageClassName = ''
}) {
  if (!children) return null;

  const containerClass = cn(styles.container, {
    [styles.invalid]: isInvalid,
    [className]: !!className
  });
  const iconClass = cn(styles.icon, { [iconClassName]: !!iconClassName });
  const messageClass = cn(styles.msg, {
    [messageClassName]: !!messageClassName
  });

  return (
    <UiKit.GridRow
      gap={gap}
      templateColumns={`${iconSize}px 1fr`}
      className={containerClass}
    >
      {icon || (
        <InfoIcon width={iconSize} height={iconSize} className={iconClass} />
      )}
      <div className={messageClass}>{children}</div>
    </UiKit.GridRow>
  );
}
