import { get } from 'utils';

export default async function getCountries(continentCode) {
  try {
    const { data } = await get(
      { apiKey: 'settings.locations.facilities.locationsMap.getCountries' },
      { params: { continent: continentCode } }
    );
    return { countries: data, data };
  } catch (err) {
    return { err };
  }
}
