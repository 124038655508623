import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _startsWith from 'lodash/startsWith';

import { scrollToTop } from 'utils/methods';
import ADMIN_ROUTES from 'config/nav/admin/routes';
import OVERVIEW_ROUTES from 'views/Overview/stubs/routes';

const SCROLL_PREVENT_PATHNAME = [
  ADMIN_ROUTES.REQUESTS.ROOT,
  OVERVIEW_ROUTES.AGED_REQUESTS,
  OVERVIEW_ROUTES.ADMIN_APPROVAL,
  OVERVIEW_ROUTES.LOCKED_REQUESTS,
  OVERVIEW_ROUTES.SELF_SHIP_REQUESTS,
  OVERVIEW_ROUTES.AWAITING_QUALIFICATION
];

export default function useAutoScroll() {
  const { listen } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    const unsubscribe = listen(loc => {
      const preventScroll = SCROLL_PREVENT_PATHNAME.some(path =>
        _startsWith(loc.pathname, path)
      );
      if (preventScroll) return;
      scrollToTop({ behavior: 'auto' });
    });

    return () => unsubscribe();
  }, [listen, pathname]);
}
