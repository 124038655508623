const PLUGINS = [
  'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount'
];

const TOOLBAR = `undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent`;

const MENUBAR = 'edit insert format';

const MENU = {
  // file: {
  //   title: "File",
  //   items: "newdocument restoredraft | preview | print ",
  // },
  edit: {
    title: 'Edit',
    items: 'undo redo | cut copy paste | selectall'
    //   items: "undo redo | cut copy paste | selectall | searchreplace",
  },
  // view: {
  //   title: "View",
  //   items:
  //     "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen",
  // },
  insert: {
    title: 'Insert',
    items: 'image link media inserttable | anchor | insertdatetime'
    //   items:
    //     "image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime",
  },
  format: {
    title: 'Format',
    items:
      'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align lineheight | forecolor backcolor | removeformat'
    //   items:
    //     "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat",
  }
  // tools: {
  //   title: 'Tools',
  //     items: "spellchecker spellcheckerlanguage | code wordcount",
  // },
  // table: {
  //   title: "Table",
  //   items: "inserttable | cell row column | tableprops deletetable",
  // },
  // help: { title: "Help", items: "help" },
};

export { PLUGINS, TOOLBAR, MENUBAR, MENU };
