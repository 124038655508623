import { getCustomSelectStyles } from 'components/UiKit/methods';

const { control: defaultControl, ...restStyles } = getCustomSelectStyles({});

export default {
  ...restStyles,
  control: (provided, state) => {
    const { isFocused, isDisabled } = state;
    return {
      ...defaultControl(provided, state),
      minHeight: 'unset',
      height: '35px',
      transition: 'none',
      borderRadius: isFocused ? '6px 6px 0 0' : '6px',
      backgroundColor: isDisabled ? '#e4e7ea' : '#ffffff',
      border: isDisabled
        ? '1px solid #e4e7ea'
        : isFocused
        ? '1px solid transparent'
        : '1px solid #fbede5',
      boxShadow: isDisabled ? 'none' : isFocused ? '0 0 4px 0 #f2c6ab' : 'none',
      '&:hover': {
        border: '1px solid #f2c6ab'
      }
    };
  },
  singleValue: provided => ({ ...provided, color: '#85898c' }),
  dropdownIndicator: provided => ({ ...provided, padding: '6.5px 8px' })
};
