import React, { useMemo } from 'react';
import styles from './index.module.scss';

import { formatRequestStatus } from 'views/Requests/methods';
import { ReactComponent as LockIcon } from 'assets/img/icons/lock-fill.svg';

export default function RequestStatus({ status = '', isLocked = false }) {
  const formattedStatus = useMemo(() => formatRequestStatus(status), [status]);

  return (
    <div className={styles.status}>
      {formattedStatus}
      {isLocked && <LockIcon className={styles.lockIcon} />}
    </div>
  );
}
