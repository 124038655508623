import axios from 'axios';

import { get } from 'utils';

const CancelToken = axios.CancelToken;

const getData = (
  { method = get, apiEndpoint = '', params = {} },
  callback = () => {}
) => {
  const source = CancelToken.source();
  method({ apiKey: apiEndpoint }, { params, cancelToken: source.token })
    .then(res => callback(null, res))
    .catch(err => callback(err, {}));
  return source;
};

export default getData;
