import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { Skeleton, UiKit, CycleRecords } from 'components';

export default function LoaderSkeleton({
  isLoading = false,
  className = '',
  children = null
}) {
  const expandableCardClassName = cn(
    'd-flex',
    'align-items-center',
    'justify-content-between',
    styles.card,
    styles.leftFloat
  );
  return !isLoading ? (
    children
  ) : (
    <UiKit.GridRow gap="90" templateColumns="1fr 1fr" className={className}>
      <UiKit.GridRow gap="16" templateColumns="1fr">
        <div>
          <Skeleton width="140px" height="16px" />
          <div className="d-flex justify-content-between">
            <Skeleton width="200px" />
            <Skeleton width="130px" />
          </div>
        </div>
        <UiKit.GridRow
          templateColumns="68px 1fr"
          gap="16"
          className={styles.card}
        >
          <Skeleton width="68px" height="68px" />
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex justify-content-between">
              <Skeleton width="70%" />
              <Skeleton width="82px" />
            </div>
            <Skeleton width="65%" />
            <Skeleton width="100px" />
          </div>
        </UiKit.GridRow>
      </UiKit.GridRow>
      <UiKit.GridRow gap="20" templateColumns="1fr">
        <CycleRecords.Loader />
        <UiKit.GridRow gap="15" templateColumns="1fr">
          <div className={expandableCardClassName}>
            <Skeleton width="92px" className="mb-0" />
            <Skeleton width="26px" height="26px" className="mb-0" />
          </div>
          <div className={expandableCardClassName}>
            <Skeleton width="125px" className="mb-0" />
            <Skeleton width="26px" height="26px" className="mb-0" />
          </div>
          <div className={expandableCardClassName}>
            <Skeleton width="93px" className="mb-0" />
            <Skeleton width="26px" height="26px" className="mb-0" />
          </div>
        </UiKit.GridRow>
      </UiKit.GridRow>
    </UiKit.GridRow>
  );
}
