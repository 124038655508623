import React, { Component } from 'react';
import { Field, Form } from 'formik';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import Select from 'react-select';
import { If } from 'utils';

class StepTwoForm extends Component {
  render() {
    const { list } = this.props;
    const selectedValue =
      list.find(el => el.value === this.props.values.retailer_name) || null;
    return (
      <Form>
        <div className="form-group">
          <Field
            className={
              'form-control login-input' +
              (this.props.errors.email && this.props.touched.email
                ? ' is-invalid'
                : '')
            }
            onChange={this.props.handleChange}
            name="email"
            id="exampleInputEmail1"
            type="email"
            aria-describedby="emailHelp"
            placeholder="Email"
            disabled={true}
          />
          <div className="invalid-feedback">{this.props.errors.email}</div>
        </div>
        <If test={list && list.length > 0}>
          <div className="form-group retailer-select">
            <Select
              onChange={e =>
                this.props.setValues(
                  Object.assign({}, this.props.values, {
                    retailer_name: e ? e.value : null
                  })
                )
              }
              options={list}
              name="retailer"
              placeholder="Select retailer"
              // className={`confirmCancel__select cancel-height mb-3`}
              className="react-select-container cancel-height mb-3"
              classNamePrefix="react-select"
              id="cancel-reason"
              value={selectedValue}
              // clearable={false}
            />
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {this.props.errors.retailer}
            </div>
          </div>
        </If>
        <div className="form-group">
          <Field
            autoFocus={!list || list.length === 0}
            className={
              'form-control login-input' +
              (this.props.errors.password ? ' is-invalid' : '')
            }
            disabled={this.props.loading}
            name="password"
            id="exampleInputNumber"
            type="password"
            aria-describedby="emailHelp"
            placeholder="Password"
          />
          <div className="invalid-feedback">{this.props.errors.password}</div>
        </div>
        <LaddaButton
          type="submit"
          className="btn btn-block btn-primary login-button"
          loading={this.props.loading}
          data-style={SLIDE_UP}
          data-spinner-size={30}
          data-spinner-color="#ddd"
          data-spinner-lines={12}
        >
          Login
        </LaddaButton>
      </Form>
    );
  }
}
export default StepTwoForm;
