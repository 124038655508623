import React from 'react';
import cn from 'classnames';

import { If } from 'utils';
import { UiKit } from 'components';
import errorPng from 'assets/rr-illustrations/lost.png';

const RELOAD_DEFAULT_FUNCTION = () => {};

export default function PageErrorUi({
  isError = false,
  hideImage = false,
  onReload = RELOAD_DEFAULT_FUNCTION,
  reloadButtonText = 'Try reloading',
  reloadButtonType = 'outline',
  msg = 'Unable to load data at the moment. Please try again later!',
  wrapperClassName = '',
  contentClassName = '',
  imageClassName = '',
  children = null
}) {
  if (!isError) return children;

  const isReloadButtonVisible =
    onReload &&
    onReload !== RELOAD_DEFAULT_FUNCTION &&
    onReload instanceof Function;

  const wrapCssClass = cn('page-error-ui', {
    [wrapperClassName]: !!wrapperClassName
  });
  const contentCssClass = cn('page-error-ui--content', {
    [contentClassName]: !!contentClassName
  });
  const imageCssClass = cn('page-error-ui--image', {
    [imageClassName]: !!imageClassName
  });

  return (
    <div className={wrapCssClass}>
      <div className={contentCssClass}>
        <h5>
          <span role="img" aria-label="info">
            ⚠️
          </span>{' '}
          <span>{msg}</span>
        </h5>
        <If test={isReloadButtonVisible}>
          <UiKit.Button customType={reloadButtonType} onClick={onReload}>
            {reloadButtonText}
          </UiKit.Button>
        </If>
      </div>
      {!hideImage && (
        <img className={imageCssClass} src={errorPng} alt="lost" />
      )}
    </div>
  );
}
