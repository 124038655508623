import React from 'react';

export default function Help() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        style={{ fill: 'var(--icon-color)' }}
        fillRule="nonzero"
        d="M7.5 16a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15zm0-1a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zM6.335 4.948a.5.5 0 1 1-.67-.742c1.313-1.185 3.756-.896 4.24.874.236.86.032 1.47-.557 2.156-.092.107-.42.467-.474.528C8.267 8.454 8 9.037 8 10a.5.5 0 1 1-1 0c0-1.235.368-2.04 1.123-2.897.064-.072.387-.425.466-.518.392-.457.488-.744.352-1.241-.261-.953-1.788-1.134-2.606-.396zM7.5 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
      />
    </svg>
  );
}
