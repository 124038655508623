import { post, logError, user } from 'utils';

export default function logout(callback = () => {}) {
  user.logout();
  post({ apiKey: 'logout', ignoreReadModeCheck: true })
    .then(({ data }) => callback(data))
    .catch(() => {
      const error = new Error('Logout API failed');
      logError(error);
      callback();
    });
}
