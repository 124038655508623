import { parallel } from 'async';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { getOrderService, getRetex } from 'utils/services';
import { activeServiceRequestChoices } from 'utils/DICTIONARY';
import { COMMENT_OPTIONS } from '../stubs';

const DISPOSITION_MODE_KEY = 'disposition.mode';
const DISPOSITION_COMMENT_KEY = 'disposition.comment';
const OS_FIELDS = [
  'id',
  'name',
  'status',
  'payment_info',
  'on_hold',
  'on_hold_reason'
];

export default function loadPageData(
  { osId, retexId },
  onSuccess = () => {},
  onError = () => {}
) {
  if (!osId) {
    throw new Error('OS Id must be provided to the function');
  }

  parallel(
    {
      orderService: callback => {
        getOrderService(
          { routeParam: osId, fields: OS_FIELDS.join(',') },
          data => callback(null, data),
          error => callback(error, null)
        );
      },
      retex: callback => {
        getRetex(
          { routeParam: retexId },
          data => callback(null, data),
          error => callback(error, null)
        );
      }
    },
    (err, result) => {
      if (err) return onError(err);
      const { orderService, retex } = result;

      const status = _get(retex, 'status', '');
      const mode = _get(retex, DISPOSITION_MODE_KEY, '');
      const comment = _get(retex, DISPOSITION_COMMENT_KEY, '');
      const activeStatuses = activeServiceRequestChoices();
      const isInProgress = activeStatuses.includes(status);

      const defaultComment = isInProgress
        ? null
        : { label: 'N/A', value: 'N/A' };

      const dispositionComment =
        COMMENT_OPTIONS.find(({ value }) => value === comment) ||
        defaultComment;
      const dispositionMode = mode !== 'resale' ? 'scrap' : 'resale';

      _set(retex, DISPOSITION_COMMENT_KEY, dispositionComment);
      _set(retex, DISPOSITION_MODE_KEY, dispositionMode);
      onSuccess(orderService, retex);
    }
  );
}
