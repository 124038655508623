import React, { useRef, useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import cn from 'classnames';
import styles from './index.module.scss';

import { Skeleton } from 'components';
import { ReactComponent as Arrow } from 'assets/img/icons/down-only.svg';

export default function ExpandableSection({
  title = '',
  badgeText = '',
  isError = false,
  isLoading = false,
  isDisabled = false,
  errorMsg = 'Unable to get the data. Please try again later.',
  className = '',
  contentClassName = '',
  headerClassName = '',
  titleClassName = '',
  children = null,
  arrowClassName = '',
  onFirstTimeOpen = () => {}
}) {
  const [show, setShow] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const hasSectionExpandedOnce = useRef(false);

  useEffect(() => {
    if (isDisabled) setShow(false);
  }, [isDisabled]);

  const toggleTimeline = () => {
    if (isLoading || isDisabled) return;
    setShow(currShow => {
      if (!hasSectionExpandedOnce.current) {
        hasSectionExpandedOnce.current = true;
        onFirstTimeOpen();
      }
      return !currShow;
    });
  };

  const buttonCollapseAttribute = show ? 'open' : 'closed';
  const containerClass = cn(styles.container, className);
  const contentClass = cn(styles.content, contentClassName);
  const headerClass = cn(styles.head, headerClassName, {
    [styles.loading]: isLoading,
    [styles.pressed]: isPressed
  });
  const arrowClass = cn(arrowClassName);
  const titleClass = cn(styles.title, titleClassName);

  return (
    <div className={containerClass}>
      <div
        data-show={show}
        className={headerClass}
        onClick={toggleTimeline}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
      >
        <div className={titleClass}>
          <h5 className={styles.label}>{title}</h5>
          {badgeText && <span className={styles.badge}>{badgeText}</span>}
        </div>
        {isLoading ? (
          <Skeleton width="26px" height="26px" className="mb-0" />
        ) : (
          <button
            disabled={isLoading}
            data-collapse={buttonCollapseAttribute}
            className={arrowClass}
          >
            <Arrow />
          </button>
        )}
      </div>
      <Collapse isOpen={show}>
        <div className={contentClass}>
          {isError ? <p className={styles.error}>{errorMsg}</p> : children}
        </div>
      </Collapse>
    </div>
  );
}
