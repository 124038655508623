import ROUTES from './routes';

/**
 * @type {import("utils/types").FeatureCard}
 */
export default [
  {
    icon: null,
    id: 'exchanges',
    shepherdId: 'card-catalog-exchange',
    title: 'Catalog Exchange',
    description:
      'Configure a list of products, types and categories that are allowed to appear while the shopper is exchanging from your entire catalog.',
    link: {
      title: 'Manage',
      url: ROUTES.CATALOG_EXCHANGE
    }
  },
  {
    icon: null,
    id: 'exchanges',
    shepherdId: 'card-exchange-recommendations',
    title: 'Exchange Recommendations',
    description:
      'Configure the product recommendation rules to encourage shoppers to exchange.',
    link: {
      title: 'Manage',
      url: ROUTES.PRODUCT_RECOMMENDATION
    },
    featureId: 'EXCHANGE_RECOMMENDATIONS'
  },
  {
    icon: null,
    id: 'exchanges',
    shepherdId: 'card-exchange-incentives',
    title: 'Exchange Incentives',
    description:
      'Control what and how your customers find out about the incentives offered if they exchange instead of return.',
    link: {
      title: 'Manage',
      url: ROUTES.EXCHANGE_INCENTIVES
    }
  }
];
