import { localStore, findDomain } from 'utils';
import ROUTES from './routes';

const FEATURE_ROUTES = [
  {
    name: 'Home',
    id: 'admin.submenu.home.enabled',
    url: ROUTES.HOME.ROOT,
    icon: 'icon-tracking-app-home',
    shepherdId: 'sidebar-home'
  },
  {
    name: 'Orders',
    id: 'admin.submenu.orders.enabled',
    url: ROUTES.ORDERS.ROOT,
    icon: 'icon-tracking-app-orders',
    shepherdId: 'sidebar-orders'
  },
  {
    name: 'Tracking Page',
    id: 'admin.submenu.tracking.enabled',
    url: ROUTES.TRACKING.ROOT,
    icon: 'icon-tracking-app-brand-page',
    shepherdId: 'sidebar-tracking-page'
  },
  {
    name: 'Email Templates',
    id: 'admin.submenu.emails.enabled',
    url: ROUTES.EMAILS.ROOT,
    icon: 'icon-tracking-app-email',
    shepherdId: 'sidebar-email-templates'
  },
  {
    name: 'Settings',
    id: 'admin.submenu.settings.enabled',
    url: ROUTES.SETTINGS.ROOT,
    icon: 'icon-setting',
    shepherdId: 'sidebar-settings',
    children: [
      {
        name: 'Manage Subscription',
        id: 'admin.submenu.settings.submenu.manage_subscription.enabled',
        url: ROUTES.SETTINGS.MANAGE_SUBSCRIPTION,
        shepherdId: 'sidebar-manage-subscription'
      },
      {
        name: 'Manage Users',
        id: 'admin.submenu.settings.submenu.manage_users.enabled',
        url: ROUTES.SETTINGS.USER_MANAGEMENT,
        shepherdId: 'sidebar-manage-users'
      }
    ]
  },
  {
    name: 'Help & Support',
    id: 'admin.submenu.help_and_support.enabled',
    url: ROUTES.HELP.ROOT,
    icon: 'icon-tracking-app-help-and-support',
    shepherdId: 'sidebar-help-and-support'
  }
];

function getNavFooterLinks() {
  let brandedPageDomain = null;
  try {
    let domains = JSON.parse(localStore.get('customerPortalDomains')) || [];
    brandedPageDomain = findDomain(domains);
  } catch (e) {}
  const links = [];
  if (brandedPageDomain) {
    links.push({
      name: 'Branded Page',
      url: brandedPageDomain,
      htmlAnchor: true,
      target: '_blank',
      icon: 'icon-customer-portal'
    });
  }
  return links;
}

const sidebarTop = () => ({ items: FEATURE_ROUTES });

const sidebarFooter = () => ({ items: getNavFooterLinks() });

export { sidebarTop, sidebarFooter };
