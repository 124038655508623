import React, { useMemo } from 'react';
import _toNumber from 'lodash/toNumber';
import cn from 'classnames';

export default function GridRow({
  gap = 0,
  templateColumns = '',
  templateRows = 'auto',
  className = '',
  styles = {},
  children = null,
  ...divElementProps
}) {
  const columnRatio = useMemo(() => {
    if (templateColumns) return templateColumns;
    const totalChildren = children instanceof Array ? children.length : 1;
    return Array(totalChildren)
      .fill(1)
      .map(fragment => `${fragment}fr`)
      .join(' ');
  }, [templateColumns, children]);

  const attachClass = useMemo(() => {
    return cn('uiKit__element uiKit__gridRow', { [className]: !!className });
  }, [className]);

  const attachStyles = {
    display: 'grid',
    gridTemplateRows: templateRows,
    gridTemplateColumns: columnRatio,
    gap: _toNumber(gap) ? `${gap}px` : gap,
    ...styles
  };

  return (
    <div {...divElementProps} className={attachClass} style={attachStyles}>
      {children}
    </div>
  );
}
