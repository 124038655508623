import React, { useMemo } from 'react';
import { animated } from '@react-spring/web';

export default function ArcLabel({
  label = '',
  style = {},
  isLoading = false
}) {
  const textStyles = useMemo(() => {
    return {
      fontSize: 9,
      fontWeight: '800',
      fill: isLoading ? '#888888' : '#0b131a'
    };
  }, [isLoading]);

  return (
    <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
      <text textAnchor="middle" dominantBaseline="central" style={textStyles}>
        {label}
      </text>
    </animated.g>
  );
}
