import React from 'react';
import moment from 'moment';
import styles from './index.module.scss';

import ErrorPage from 'containers/DefaultLayout/ErrorPage';

const {
  REACT_APP_SERVER_UP: SERVER_UP,
  REACT_APP_SERVER_UPTIME: UPTIME,
  REACT_APP_SERVER_MESSAGE: MESSAGE
} = process.env;

export default function MaintenanceBreak({ children = null }) {
  const isServerUp = SERVER_UP !== 'false';
  const serverUpTime = UPTIME || null; // null | ISO-string<"YYYY-MM-DDTHH:MMZ">
  const message =
    MESSAGE || "We're undergoing scheduled maintenance at this point.";

  const isValidUptime = moment(serverUpTime, moment.ISO_8601, true).isValid();

  if (!isServerUp && isValidUptime) {
    const now = moment();
    const uptime = moment(serverUpTime);

    let uptimeInfo = '';
    const isUptimeToday = now.isSame(uptime, 'day');
    const hasExceededTime = now.isSameOrAfter(uptime);

    if (hasExceededTime) {
      uptimeInfo = <strong>We will be back in service soon.</strong>;
    } else if (isUptimeToday) {
      const formattedUptime = uptime.format('h:mm a');
      uptimeInfo = (
        <>
          We will be back later today at <strong>{formattedUptime}</strong>.
        </>
      );
    } else {
      const formattedUptime = uptime.format('D MMMM  YYYY, h:mm a');
      uptimeInfo = (
        <>
          Please check back later at <strong>{formattedUptime}</strong>.
        </>
      );
    }

    return (
      <ErrorPage
        tabTitle="Return Rabbit | Maintenance Break"
        message={
          <div className={styles.message}>
            <p className={styles.text}>{message}</p>
            <p className={styles.text}>{uptimeInfo}</p>
          </div>
        }
        imageContainerClassName={styles.imageContainer}
        imageClassName={styles.image}
      />
    );
  }

  return children;
}
