import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';

export default function useShopperGroupsParams(selectedOption) {
  const { hash } = useLocation();
  const { replace = () => {} } = useHistory();

  useEffect(() => {
    if (selectedOption) {
      const optionValue = _get(selectedOption, 'value', null);
      const isConfigured = _get(selectedOption, 'is_configured');

      if (optionValue === null) {
        replace({ search: '', hash });
      } else if (isConfigured) {
        replace({ search: `?shopper_group=${optionValue}`, hash });
      } else {
        replace({ search: `?shopper_group=${optionValue}&new=true`, hash });
      }
    }
  }, [selectedOption, hash, replace]);

  return null;
}
