import has from 'lodash/has';

/**
 * Thin Wrapper around lodash/has
 * @param  {Obj} obj
 * @param  {string} path
 * @return {Boolean} true is path exists
 */
export default function pathExists(obj, path) {
  return has(obj, path);
}
