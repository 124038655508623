import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _size from 'lodash/size';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import styles from './index.module.scss';

import { uploadFile } from 'utils/services';
import { CustomToast, UiKit } from 'components';

registerPlugin(
  FilePondPluginImageValidateSize,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType
);

export default function FileUpload({
  label = '',
  imgSrc = '',
  tags = '',
  disabled = false,
  aspectRatio = '0.66',
  maxFileSize = '4', // size in MB
  newImageInfo = () => {},
  buttonText = 'Upload new image',
  className = '',
  labelClassName = '',
  contentClassName = '',
  buttonClassName = '',
  buttonWrapperClassName = '',
  children = null
}) {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const prevFilesRef = useRef(files);
  const pondRef = useRef(null);

  const isDisabled = disabled || isUploading;
  const containerClass = cn(styles.container, className);
  const labelClass = cn(styles.label, labelClassName);
  const contentClass = cn(styles.content, contentClassName);
  const buttonClass = cn(styles.button, buttonClassName);
  const buttonWrapperClass = cn(styles.actions, buttonWrapperClassName);

  useEffect(() => {
    const files = !imgSrc
      ? []
      : [{ source: imgSrc, options: { type: 'local' } }];
    setFiles(files);
  }, [imgSrc]);

  useEffect(() => {
    const prevFiles = prevFilesRef.current;
    if (_size(prevFiles) > 0 && _size(files) === 0) {
      newImageInfo('');
    }
  }, [files, newImageInfo]);

  const load = (url, onLoad, onError) => {
    fetch(url)
      .then(res => res.blob())
      .then(onLoad)
      .catch(onError);
  };

  const process = (...dataset) => {
    const [, file, , load, error, progress] = dataset;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      setIsUploading(true);
      uploadFile(
        {
          file: reader.result,
          tags: ['browser_upload', ...tags.split(',')].join(',')
        },
        ({ lengthComputable, loaded, total }) => {
          progress(lengthComputable, loaded, total);
        },
        data => {
          const url = _get(data, 'secure_url', '');
          newImageInfo(url);
          load('Image uploaded');
        },
        err => {
          const msg = 'Something went wrong while uploading your file';
          error(msg);
          CustomToast({ isNotified: err.notified, msg, type: 'error' });
        },
        () => setIsUploading(false)
      );
    });
  };

  const onUpdateFiles = fileItems => {
    prevFilesRef.current = files;
    const newFiles = fileItems.map(({ file }) => file);
    setFiles(newFiles);
  };

  const initiateImageUpload = () => {
    if (isDisabled) return;
    const browse = _get(pondRef.current, 'browse', () => {});
    browse();
  };

  return (
    <div className={containerClass}>
      {label && <UiKit.Label text={label} className={labelClass} />}
      <div className={contentClass}>
        <FilePond
          ref={pondRef}
          files={files}
          instantUpload
          allowRevert={false}
          disabled={isDisabled}
          server={{ process, load, revert: null }}
          stylePanelLayout="integrated"
          acceptedFileTypes={['image/*']}
          styleLoadIndicatorPosition="right"
          styleProgressIndicatorPosition="right"
          stylePanelAspectRatio={aspectRatio}
          onupdatefiles={onUpdateFiles}
        />
        <div className={buttonWrapperClass}>
          <p className={styles.maxSize}>
            Max <span>{maxFileSize}MB</span>
          </p>
          <button
            disabled={isDisabled}
            className={buttonClass}
            onClick={initiateImageUpload}
          >
            {buttonText}
          </button>
        </div>
        {children}
      </div>
    </div>
  );
}
