import React, { useContext, useMemo } from 'react';
import { Alert } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { useEcomPlatform } from 'utils/hooks';
import { AuthContext } from 'context/AuthContext';
import infoIcon from 'assets/img/alert-icons/info.svg';
import notifyIcon from 'assets/img/alert-icons/bell.svg';
import warnIcon from 'assets/img/alert-icons/alert-circle.svg';
import warnIconWhite from 'assets/img/alert-icons/alert-circle-white.svg';
import progressIcon from 'assets/img/alert-icons/refresh-cw.svg';

const COLORS = {
  info: 'info',
  warn: 'warning',
  error: 'danger',
  inProgress: 'info'
};

const ICONS = {
  info: infoIcon,
  warn: warnIcon,
  error: warnIconWhite,
  inProgress: progressIcon
};

export default function AppLevelAlert() {
  const { syncStatus, appLevelAlert } = useContext(AuthContext);
  const { ecommPlatformName } = useEcomPlatform();
  const isSyncCompleted = _get(syncStatus, 'completed', false);

  const alert = useMemo(() => {
    if (_isEmpty(appLevelAlert) && !isSyncCompleted) {
      return {
        context: 'inProgress',
        message: `Syncing your Orders, Products and Locations from ${ecommPlatformName}.`
      };
    }
    return Object.assign({}, appLevelAlert);
  }, [appLevelAlert, isSyncCompleted, ecommPlatformName]);

  const color = _get(COLORS, alert.context, 'info');
  const icon = _get(ICONS, alert.context, notifyIcon);
  const iconClassName = cn({ rotate: alert.context === 'inProgress' });

  if (_isEmpty(alert) && isSyncCompleted) return null;

  return (
    <div className="AppLevelAlert">
      <Alert color={color} className="AppLevelAlert__message">
        <img src={icon} alt="alert" className={iconClassName} /> {alert.message}
      </Alert>
    </div>
  );
}
