import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isPlainObject';

import { getRetailerNotes } from 'utils/services';
import { RetexContext } from 'components/RetexDetails';
import { getAllowedStatusMapForDelete } from '../methods';

export default function useNotes() {
  const [notes, setNotes] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { retex, isProcessing: isDisabled } = useContext(RetexContext);
  const deleteRetexStatusMap = useRef(getAllowedStatusMapForDelete()).current;

  const retexId = _get(retex, 'id', '');
  const retexStatus = _get(retex, 'status', '');
  const canCreate = retexStatus !== 'AWAITING_COMPLETION';
  const canDelete = _get(deleteRetexStatusMap, retexStatus, false);

  useEffect(() => {
    if (retexId) {
      setIsError(false);
      setIsLoading(true);
      getRetailerNotes(
        retexId,
        data => {
          setNotes(data);
          setIsLoading(false);
        },
        () => setIsError(true),
        () => setIsLoading(false)
      );
    }
  }, [retexId]);

  const onCreate = useCallback(
    note => {
      if (!_isObject(note) || !canCreate) return;
      setNotes(currNotes => [...currNotes, note]);
    },
    [canCreate]
  );

  const onDelete = useCallback(
    index => {
      if (!_isNumber(index) || !canDelete) return;
      setNotes(currNotes => {
        const notes = [...currNotes];
        notes.splice(index, 1);
        return notes;
      });
    },
    [canDelete]
  );

  return {
    notes,
    isError,
    isLoading,
    isDisabled,
    canCreate,
    canDelete,
    onCreate,
    onDelete
  };
}
