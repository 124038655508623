import React from 'react';
import { renderToString } from 'react-dom/server';

import { ProgressIndicator, StepText } from 'containers/TourGuides/components';
import {
  scrollToHandler,
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import CONFIGURE_ROUTES from 'views/Configure/stubs/routes';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getShippingSteps({
  push = () => {},
  onShow = () => {},
  onHide = () => {},
  hasAccess = () => false
}) {
  const hasInternationalLabelAccess = hasAccess('SEND_LABEL_INTERNATIONAL');
  const stepsCount = hasInternationalLabelAccess ? 11 : 9;

  const steps = [
    {
      title: 'Shipping Settings',
      text: renderToString(
        <>
          <StepText>
            Let's set up your shipping methods. If you have not set up a
            Shipping Aggregator yet, please do that first.
            <br />
            <br />
            You will not be able to offer shipping labels and enable Prepaid
            Return Shipping Label option until you do that.
          </StepText>
          <ProgressIndicator current={1} total={stepsCount} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({ push, path: CONFIGURE_ROUTES.SHIPPING });
      },
      buttons: [{ text: "Let's go", type: 'next' }],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: getShepherdSelector('card-shipping-methods'),
        on: 'right'
      },
      canClickTarget: true,
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: CONFIGURE_ROUTES.SHIPPING,
          selector: this.attachTo.element
        });
      },
      text: renderToString(
        <>
          <StepText>
            Let's look at how to configure different shipping methods you want
            to offer your shoppers.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on <code>Manage</code> button to start the setup.
          </StepText>
          <ProgressIndicator current={2} total={stepsCount} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector('card-shipping-methods')} a`,
        event: 'click'
      },
      buttons: [{ text: 'Back', secondary: true, type: 'back' }],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: `${getShepherdSelector(
          'shipping-methods-home-container'
        )} ${getShepherdSelector('shipping-methods-cards-list-container')}`,
        on: 'top'
      },
      text: renderToString(
        <>
          <StepText>
            You can enable/disable any shipping methods here.
            <br />
            <br />
            You can edit additional configuration like the fees and much more by
            clicking the edit icon.
          </StepText>
          <ProgressIndicator current={3} total={stepsCount} />
        </>
      ),
      scrollToHandler: el => scrollToHandler(el, { block: 'start' }),
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      buttons: [
        { text: 'Back', secondary: true, type: 'back' },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: `${getShepherdSelector(
          'shipping-methods-config-container'
        )} > .section-container > .section > .section__children`,
        on: 'top'
      },
      text: renderToString(
        <>
          <StepText>
            This section lets you modify additional rules like if you want your
            shoppers to include packing slips generated by Return Rabbit for
            your warehouse team.
          </StepText>
          <ProgressIndicator current={4} total={stepsCount} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: getShepherdSelector(
          'prepaid-return-shipping-label-shipping-method-card'
        ),
        on: 'bottom'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: CONFIGURE_ROUTES.SHIPPING_METHODS,
          selector: this.attachTo.element
        });
      },
      canClickTarget: true,
      title: 'Prepaid Return Shipping Label',
      text: renderToString(
        <>
          <StepText>
            Let's dive deeper into the Prepaid Shipping Label option.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on{' '}
            <code>
              <EditIcon width={12} height={12} />
            </code>{' '}
            icon to enter the setup mode.
          </StepText>
          <ProgressIndicator current={5} total={stepsCount} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector(
          'prepaid-return-shipping-label-shipping-method-card'
        )} .CustomTable__Action`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'send-label-basic-details',
      attachTo: {
        element: `${getShepherdSelector(
          'shipping-main-container'
        )} ${getShepherdSelector('shipping-label-container')}`,
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>
            Configure the text that your shoppers will see when they look at the
            list of available shipping methods.
          </StepText>
          <ProgressIndicator current={6} total={stepsCount} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'send-label-domestic-charges',
      attachTo: {
        element: `${getShepherdSelector('domestic')} ${getShepherdSelector(
          'shipping-method-fees-domestic'
        )}`,
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            Configure your domestic shipping fees here. You can charge a flat
            fee or choose to pass the cost to the shopper.
            <br />
            <br />
            When passing the cost to the shopper, you can also choose to allow
            them to select from multiple labels available. Otherwise, the
            cheapest label will be selected by default.
          </StepText>
          <ProgressIndicator current={7} total={stepsCount} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'send-label-domestic-waive-offs',
      attachTo: {
        element: `${getShepherdSelector('domestic')} ${getShepherdSelector(
          'shipping-method-waiveof-domestic'
        )}`,
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            Configure the waive offs that you can apply for specific scenarios.
            <br />
            <br />
            <strong>Order threshold</strong>: This will waive off the shipping
            fee for any orders above a certain amount.
            <br />
            <br />
            <strong>Exchange waive-off</strong>: This will waive off the
            shipping fee for any requests that contain at least on exchange.
          </StepText>
          <ProgressIndicator current={8} total={stepsCount} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    }
  ];

  const internationalLabelConfigSteps = !hasInternationalLabelAccess
    ? []
    : [
        {
          id: 'send-label-international-toggle',
          attachTo: {
            element: `${getShepherdSelector(
              'international'
            )} ${getShepherdSelector('shipping-method-toggle-international')}`,
            on: 'right'
          },
          text: renderToString(
            <>
              <StepText>
                Enable international shipping labels from here.
                <br />
                <br />
                When turned on, this will allow your shoppers to get
                international shipping labels.
              </StepText>
              <ProgressIndicator current={9} total={stepsCount} />
            </>
          ),
          buttons: [
            { text: 'Back', type: 'back', secondary: true },
            { text: 'Got it', type: 'next' }
          ],
          when: { show: onShow, hide: onHide }
        },
        {
          id: 'send-label-international-charges',
          attachTo: {
            element: `${getShepherdSelector(
              'international'
            )} ${getShepherdSelector('shipping-method-fees-international')}`,
            on: 'right'
          },
          text: renderToString(
            <>
              <StepText>
                Configure the shipping fee for international labels. You can
                charge a flat fee or choose to pass the cost to the shopper.
              </StepText>
              <ProgressIndicator current={10} total={stepsCount} />
            </>
          ),
          buttons: [
            { text: 'Back', type: 'back', secondary: true },
            { text: 'Got it', type: 'next' }
          ],
          when: { show: onShow, hide: onHide }
        }
      ];

  return [
    ...steps,
    ...internationalLabelConfigSteps,
    {
      id: 'send-label-configuration-flags',
      attachTo: {
        element: `${getShepherdSelector(
          'send-label-additional-features-container'
        )} .additional-features__list`,
        on: 'top'
      },
      text: renderToString(
        <>
          <StepText>
            Configure additional settings here.
            <br />
            <br />
            <strong>Schedule pickup</strong>: If the carrier allows it, the
            shoppers will be able to schedule a pick-up for the shipment.
            <br />
            <br />
            <strong>Support QR</strong>: If the carrier allows it, the shoppers
            will be able to get a QR Code which will then be used by the carrier
            to print the labels for them.
          </StepText>
          <ProgressIndicator current={stepsCount} total={stepsCount} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Done', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    }
  ];
}
