import _get from 'lodash/get';
import _set from 'lodash/set';

export default function getMaxSavingsInBilling(plans) {
  const planPriceVariantMap = plans.reduce((acc, plan) => {
    const planId = _get(plan, 'id', '');
    const monthlyPrice = _get(plan, 'prices.monthly.price');
    const yearlyPrice = _get(plan, 'prices.yearly.price');
    if (monthlyPrice >= 0) _set(acc, `${planId}.monthly`, monthlyPrice);
    if (yearlyPrice >= 0) _set(acc, `${planId}.yearly`, yearlyPrice / 12);
    return acc;
  }, {});

  const maxSavings = Object.keys(planPriceVariantMap).reduce((acc, planId) => {
    const annualPlanMonthlyPrice = _get(
      planPriceVariantMap,
      `${planId}.yearly`,
      0
    );
    const monthlyPlanMonthlyPrice = _get(
      planPriceVariantMap,
      `${planId}.monthly`,
      0
    );

    const savings =
      ((monthlyPlanMonthlyPrice - annualPlanMonthlyPrice) * 100) /
      monthlyPlanMonthlyPrice;

    return Math.max(acc, savings ? Math.floor(savings) : 0);
  }, 0);

  return { maxSavings, planPriceVariantMap };
}
