import { Skeleton } from 'components';
import React, { useMemo } from 'react';

export default function LoaderSkeleton({
  isLoading = true,
  count = 2,
  children = null
}) {
  const content = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, index) => (
        <li className="additional-features__loader" key={index}>
          <div className="d-flex justify-content-between mb-5">
            <Skeleton width="50%" height="20px" className="mb-0" />
            <Skeleton width="36px" height="20px" className="mb-0" />
          </div>
          <Skeleton width="100px" height="14px" />
          <Skeleton width="100%" height="14px" />
          <Skeleton width="40%" height="14px" />
        </li>
      ));
  }, [count]);

  if (!isLoading) return children;

  return <ul className="additional-features__loader-wrapper">{content}</ul>;
}
