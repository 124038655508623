import React from 'react';
import Loader from './Loader';

export default function Loading({
  loading = true,
  children = null,
  ...restProps
}) {
  if (loading) {
    return <Loader loading {...restProps} />;
  }
  return children;
}
