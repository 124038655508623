import React from 'react';
import cn from 'classnames';
import { Col } from 'reactstrap';

import { If } from 'utils';

export default function TableFilterLayout({
  size = 12,
  title = '',
  customClass = '',
  children = null
}) {
  const wrapClass = cn('filter-col', { [customClass]: !!customClass });

  return (
    <Col lg={size} className={wrapClass}>
      <If test={title}>
        <h6 className="filter-title">{title}</h6>
        <div className="content">{children}</div>
      </If>
      <If test={!title}>{children}</If>
    </Col>
  );
}
