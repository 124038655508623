import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

/**
 * schema for "data" > Array of Objects
 * [
 *   {
 *     color: <valid_colour_value>,
 *     label: <String>
 *   },
 *   ... // rest of the objects
 * ]
 */
export default function Legend({
  data = [],
  className = '',
  formatLabel = () => ''
}) {
  const containerClassName = useMemo(
    () => cn(styles.legends, { [className]: !!className }),
    [className]
  );

  const items = useMemo(() => {
    return data.map((dataset, index) => {
      const formattedLabel = formatLabel(dataset) || dataset.label;
      return (
        <li key={index}>
          <div style={{ backgroundColor: dataset.color }} />
          <div>{formattedLabel}</div>
        </li>
      );
    });
  }, [data, formatLabel]);

  return <ul className={containerClassName}>{items}</ul>;
}
