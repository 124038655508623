import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import _get from 'lodash/get';

import { AuthContext } from 'context/AuthContext';

export default function CanRoute({
  _key = '',
  component: Component,
  ...routeParams
}) {
  const { configuration } = useContext(AuthContext);
  const displayConfig = configuration || null;

  if (!displayConfig) return null;
  const isEnabled = _get(displayConfig, _key, false);

  return (
    <Route
      {...routeParams}
      render={props =>
        isEnabled ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}
