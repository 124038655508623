import './polyfill';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import setup from './config/setup';
import { AppProvider } from 'context/AppContext';
import { AuthProvider } from 'context/AuthContext';
import { GoogleAnalyticsProvider } from './utils/googleAnalytics';
import { PageScroll } from './components';
import { unregister } from './registerServiceWorker';
// import registerServiceWorker from './registerServiceWorker';

// disable ServiceWorker

setup();

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <AppProvider>
        <GoogleAnalyticsProvider>
          <AuthProvider>
            <PageScroll.Provider>
              <App />
            </PageScroll.Provider>
          </AuthProvider>
        </GoogleAnalyticsProvider>
      </AppProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

unregister();
// disable ServiceWorker
// registerServiceWorker();
