export const actionIdMap = {
  'rr-low-balance': 'rr-low-balance',
  'rr-no-balance': 'rr-no-balance',
  'no-default-warehouse': 'no-default-warehouse',
  'no-aggregator-setup': 'no-aggregator-setup'
};

export const doActionTemplateId = (actionId, { redirectFn = () => {} }) => {
  switch (actionId) {
    case actionIdMap['rr-low-balance']: {
      redirectFn('/settings/manage-subscription');
      break;
    }
    case actionIdMap['rr-no-balance']: {
      redirectFn('/settings/manage-subscription');
      break;
    }
    case actionIdMap['no-default-warehouse']: {
      redirectFn('/configure/shipping/facilities');
      break;
    }
    case actionIdMap['no-aggregator-setup']: {
      redirectFn('/configure/shipping/aggregators');
      break;
    }

    default: {
    }
  }

  return null;
};
