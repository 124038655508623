import React from 'react';
import styles from './index.module.scss';

export default function UsageTracker({ used = 0, total = 0 }) {
  const usedPercent = (used / total) * 100;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <strong>Plan usage</strong>
        <p>
          {used}/{total}
        </p>
      </div>
      <div
        className={styles.progress}
        style={{ '--width': `${usedPercent}%` }}
      />
    </div>
  );
}
