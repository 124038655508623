import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';

import Title from './Title';
import Request from './Init/Request';
import PasswordForm from './PasswordForm';

export default function ResetPassword() {
  return (
    <div id="login-row">
      <Card className="border-0">
        <CardBody>
          <CardTitle>
            <Title />
          </CardTitle>
        </CardBody>
      </Card>
      <Route path="/reset-password/next/" component={PasswordForm} />
      <Route path="/reset-password/" component={Request} exact />
      <div className="text-right">
        <Link to="/login" className="route-switch-link">
          Login?
        </Link>
      </div>
    </div>
  );
}
