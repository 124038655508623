import localStore from './localStore';

function isLoggedIn() {
  const wasLoggedIn = localStore.get('rrloggedInStoreName');
  if (!wasLoggedIn) {
    logout();
  }
  return wasLoggedIn;
}

function set(data) {
  return localStore.put('userData', data);
}

function get() {
  const data = localStore.get('userData');
  return data ? JSON.parse(data) : null;
}

function isPasswordChangeRequired() {
  const data = localStore.get('passwordChangeRequired');
  return data ? JSON.parse(data) : null;
}

function logout() {
  localStore.clear('rrloggedInStoreName');
  localStore.clear('userData');
  // localStore.clear('fromLogin');
  localStore.clear('userAccess');
  localStore.clear('syncStatus');
  localStore.clear('configuration');
  localStore.clear('accessBlocked');
  localStore.clear('appLevelAlert');
  localStore.clear('storeProperties');
  localStore.clear('tempRetexApproval');
  localStore.clear('tr-home-date-range');
  localStore.clear('subscriptionDetails');
  localStore.clear('rr-warehouse-filters');
  localStore.clear('onboarding_completed');
  localStore.clear('customerPortalDomains');
  localStore.clear('emailSettingsDefaults');
  localStore.clear('passwordChangeRequired');
  localStore.clear('setupCheckListComplete');
  localStore.clear('overview_metrics_since');
  localStore.clear('rr__last-quota-exhaust-reminder-date');
  localStore.clear('dashboard_analytics_units');
  localStore.clear('dashboard_analytics_dateRange');
  localStore.clear('insights_analytics_product');
  localStore.clear('insights_analytics_dateRange');
  localStore.clear('state-os-init');
  localStore.clear('OrderService_ProductCatalog__searchStates');
  localStore.clear('insights_analytics_productVsRequestReasonsType');
  localStore.clear('rr-requests-filters');
  localStore.clear('rr-orders-filters');

  document.body.classList.remove('header-fixed');
  document.body.classList.remove('sidebar-lg-show');
  document.body.classList.remove('sidebar-fixed');
  document.body.classList.remove('sidebar-minimized');
  document.body.classList.remove('brand-minimized');
}

export default {
  isLoggedIn,
  logout,
  get,
  set,
  isPasswordChangeRequired
};
