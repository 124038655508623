import React from 'react';
import { Spinner } from 'reactstrap';

import error from 'assets/img/icons/error.svg';
import warning from 'assets/img/icons/alert.svg';
import success from 'assets/img/icons/success.svg';
import info from 'assets/img/icons/info.svg';

const TYPES = { success, error, info, warning };

const CHECK_TYPE = type => {
  return (
    type === 'success' ||
    type === 'error' ||
    type === 'info' ||
    type === 'warning'
  );
};

const ToastMsg = ({ msg, type = '' }) => {
  if (typeof msg === 'object') {
    msg = msg.message || null;
  }
  if (type === 'warn') {
    type = 'warning';
  }
  return (
    <div className={`customToast__screen ${type}`}>
      {CHECK_TYPE(type) ? <img src={TYPES[type]} alt={TYPES[type]} /> : null}
      {type === 'processing' ? <Spinner color="primary" /> : null}
      <div className="customToast__screen-text">
        {msg || 'Something went wrong.'}
      </div>
    </div>
  );
};

export default ToastMsg;
