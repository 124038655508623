import React from 'react';
import styles from './index.module.scss';

import { UiKit } from 'components';

export default function Section({ title = '', children = null }) {
  return (
    <UiKit.GridRow templateColumns="1fr" gap="16">
      <h5 className={styles.title}>{title}</h5>
      {children}
    </UiKit.GridRow>
  );
}
