import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { useReactTable } from 'components/ReactTable';

export default function ClearFiltersButton({
  className = '',
  children = 'Clear All Filters'
}) {
  const {
    checkIfRefined,
    clearAllRefinements = () => {},
    searchState: { isFetchingFacets }
  } = useReactTable();

  const isFilterActive = checkIfRefined();
  const isButtonDisabled = !isFilterActive || isFetchingFacets;

  return (
    <button
      onClick={clearAllRefinements}
      disabled={isButtonDisabled}
      className={cn(styles.btn, className)}
    >
      {children}
    </button>
  );
}
