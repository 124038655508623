import React from 'react';
import { renderToString } from 'react-dom/server';

import { StepText } from 'containers/TourGuides/components';
import { getShepherdSelector } from 'containers/TourGuides/methods';

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getSteps() {
  return [
    {
      attachTo: {
        element: getShepherdSelector('sidebar-help-and-support'),
        on: 'right'
      },
      title: 'Visit later to Setup Checklist',
      text: renderToString(
        <>
          <StepText>You can find the setup checklist here!</StepText>
        </>
      ),
      buttons: [{ text: 'Done', type: 'next' }]
    }
  ];
}
