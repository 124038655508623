import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { ReactComponent as LockIcon } from 'assets/img/icons/lock-fill.svg';
import { If } from 'utils';

/**
 * @param {object} props
 * @param {'sm'|'md'|"lg"} [props.size='sm']
 * @param {string} [props.text='LOCKED']
 * @param {string} [props.className]
 */
export default function LockBadge({ size = 'md', text = '', className = '' }) {
  return (
    <UiKit.Badge className={cn(styles.container, styles[size], className)}>
      <LockIcon />
      <If test={text}>{text}</If>
    </UiKit.Badge>
  );
}
