import moment from 'moment';
import momentTz from 'moment-timezone';
const formatDate = date => {
  return moment.unix(date).format('MMM Do, YY ');
};
const formatDateWithTimezone = (date, timezone) => {
  return momentTz.tz(date, timezone).format('ll');
};

const formatDateAndTimeWithTimezone = (date, timezone) => {
  return momentTz.tz(date, timezone).format('lll');
};

export default formatDate;
export { formatDateWithTimezone, formatDateAndTimeWithTimezone };
