import React from 'react';

export default function IsThisGift(props) {
  let { val } = props;
  if (val === null || val === undefined || val === false) {
    return null;
  } else {
    return (
      <span>
        {' '}
        <i className="fas fa-gift" />
      </span>
    );
  }
}
