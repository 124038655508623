import moment from 'moment';
import { getDeafaultMinDateFilter } from 'utils/methods';

export default function getFilters() {
  const searchParams = new URLSearchParams(window.location.href);
  const minDate = searchParams.get('min_date');
  const maxDate = searchParams.get('max_date');

  return {
    date: {
      type: 'range',
      values: {
        min: minDate || getDeafaultMinDateFilter(),
        max:
          maxDate ||
          moment()
            .endOf('day')
            .utc()
            .endOf('day')
            .unix()
      }
    }
  };
}
