import React from 'react';
import { Tooltip } from 'reactstrap';
import { If } from 'utils';

/**
 * REQUIRED ONE PARAMETES
 * @param {id} to detect tooltip for custom id
 *
 * Extra params
 * @children {text} text on which tooltip open
 * @param {isWaived} To display tooltip only when its waive off(can be customize inn fu)
 */

class TooltipItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    let { text, customClass, isWaived, id } = this.props;
    return (
      <span>
        <div className="row-tem " id={'toggle-' + id}>
          {this.props.children}
        </div>
        <If test={isWaived}>
          <Tooltip
            innerClassName={customClass || 'customTooltip'}
            autohide={true}
            arrowClassName={'customArrow'}
            placement={'top'}
            isOpen={this.state.tooltipOpen}
            target={'toggle-' + this.props.id}
            toggle={this.toggle}
          >
            {text}
          </Tooltip>
        </If>
      </span>
    );
  }
}

export default TooltipItem;
