import React, { useMemo } from 'react';
import moment from 'moment';
import cn from 'classnames';
import _toLower from 'lodash/toLower';
import _startCase from 'lodash/startCase';
import styles from './index.module.scss';

import { If } from 'utils';
import { ProtectedLink } from 'components';
import ADMIN_ROUTES from 'config/nav/admin/routes';
import TimelineRow from '../TimelineRow';
import { ReactComponent as ClockIcon } from 'assets/img/icons/timestamp.svg';

export default function TimelineRows({ dataList = [] }) {
  const rows = useMemo(() => {
    return dataList.map((data, index) => {
      const { name, action, action_by, action_time, status } = data;
      const particularTimeLineData = [
        {
          lg: 5,
          type: status || 'success',
          title: action,
          customClass: 'pl-0'
        },
        status === 'awaiting'
          ? {
              lg: 7,
              customClass: cn(styles.date, styles.bold, 'pl-0'),
              title: 'Awaiting action'
            }
          : {
              lg: 4,
              customClass: cn(styles.date, 'p-0'),
              CustomComponent: (
                <>
                  <div className={styles.actionTime}>
                    {moment(action_time).format('D MMM, YYYY - hh:mma')}{' '}
                  </div>
                  <If test={action_by}>
                    <>
                      by{' '}
                      <span className={styles.bold}>
                        {_startCase(_toLower(action_by))}
                      </span>
                    </>
                  </If>
                </>
              )
            },
        'id' in data
          ? {
              lg: 3,
              customClass: 'text-right px-0',
              CustomComponent: (
                <ProtectedLink
                  _key="admin.submenu.orders.enabled"
                  to={`${ADMIN_ROUTES.ORDERS.ROOT}/${data.id}`}
                  tooltipMessage="You don't have access to the Orders section. Please contact your super admin."
                  className={styles.order}
                >
                  {name}
                </ProtectedLink>
              )
            }
          : {
              lg: 3,
              customClass: cn(styles.fromNow, 'text-right', 'px-0'),
              CustomComponent:
                status === 'awaiting' ? null : (
                  <span>
                    {moment(action_time).fromNow()}
                    <ClockIcon className={styles.icon} />
                  </span>
                )
            }
      ];
      return <TimelineRow key={index} values={particularTimeLineData} />;
    });
  }, [dataList]);

  return <div className={styles.container}>{rows}</div>;
}
