import { get } from 'utils';

export default async function getContinents() {
  try {
    const { data } = await get(
      { apiKey: 'settings.locations.facilities.locationsMap.getContinent' },
      { params: {} }
    );
    return { continents: data };
  } catch (err) {
    return { err };
  }
}
