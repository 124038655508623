import { getCustomSelectStyles } from 'components/UiKit/methods';

export default function getStyles() {
  const {
    option: defaultOptionStyles,
    ...restStyles
  } = getCustomSelectStyles();

  return {
    ...restStyles,
    option: (provided, state) => {
      const { isSelected, value } = state;
      return {
        ...defaultOptionStyles(provided, state),
        display: 'flex',
        alignItems: 'center',
        gap: 12,

        '&:before': {
          content: '" "',
          display: 'block',
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: !value
            ? 'transparent'
            : isSelected
            ? '#166564'
            : '#d9d9d9',
          boxShadow: !value
            ? 'none'
            : isSelected
            ? 'inset 0 0 0 1.5px white, 0 0 0 1.5px #166564'
            : 'none'
        }
      };
    }
  };
}
