import React, { useContext, useRef } from 'react';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import styles from './index.module.scss';

import { getTourOptions } from 'containers/TourGuides/methods';
import { getSteps } from './methods';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';

export function WelcomeTour({ children }) {
  const { push } = useHistory();
  const steps = useRef(getSteps({ push })).current;
  const tourOptions = useRef(getTourOptions({ tourName: 'app-tour' })).current;

  return (
    <ShepherdTour steps={steps} tourOptions={tourOptions}>
      {children}
    </ShepherdTour>
  );
}

export function WelcomeTourTrigger() {
  const { updateState } = useContext(AuthContext);
  const tour = useContext(ShepherdTourContext);

  const onTourStart = () => {
    tour.start();
    updateState({ isTourShown: true, showTour: false });
  };

  return (
    <button className={styles.tourBtn} onClick={onTourStart}>
      Start
    </button>
  );
}
