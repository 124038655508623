import React, { useContext } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { UiKit, ProductVariantAttributes } from 'components';
import { RetexContext } from 'components/RetexDetails';
import { ReactComponent as BentArrow } from 'assets/img/icons/bent-arrow.svg';

export default function Variant({
  data = {},
  quantity = 0,
  subTitle = '',
  showIcon = false
}) {
  const { isApproved, isRejected } = useContext(RetexContext);

  const title = _get(data, 'title', '').toLowerCase();
  const imageUrl = _get(data, 'image_url', '');
  const optionValues = _get(data, 'option_values', {});
  const arrowClassName = cn(styles.arrow, {
    [styles.danger]: isRejected,
    [styles.success]: isApproved
  });

  return (
    <UiKit.GridRow
      gap="16"
      templateColumns="68px 1fr"
      className={styles.container}
    >
      <img src={imageUrl} alt="product thumbnail" className={styles.image} />
      <UiKit.GridRow templateColumns="1fr" className={styles.content}>
        <UiKit.GridRow templateColumns="1fr auto" gap="40">
          <strong className={styles.title}>{title}</strong>
          {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
        </UiKit.GridRow>
        <ProductVariantAttributes
          optionValues={optionValues}
          joinWith={<>&nbsp;&nbsp;|&nbsp;&nbsp;</>}
        />
        <span>Quantity: {quantity}</span>
      </UiKit.GridRow>
      {showIcon && <BentArrow className={arrowClassName} />}
    </UiKit.GridRow>
  );
}
