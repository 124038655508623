import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const HeaderContext = createContext({
  title: '',
  docUrl: '',
  isMounted: false,
  onSetTitle: () => {},
  onCancel: () => {}
});

export function HeaderProvider({ children = null }) {
  const { push, goBack, listen } = useHistory();
  const { pathname } = useLocation();
  const [title, setTitle] = useState('');
  const [, setRefreshToken] = useState(false);

  const docUrlRef = useRef('');
  const backRouteRef = useRef(null);
  const mountRouteRef = useRef(null);
  const isMounted = pathname === mountRouteRef.current;

  useEffect(() => {
    const unsubscribe = listen(newLocation => {
      if (
        mountRouteRef.current &&
        newLocation.pathname !== mountRouteRef.current
      ) {
        mountRouteRef.current = null;
        docUrlRef.current = '';
        setTitle('');
      }
    }, []);

    return () => unsubscribe();
  }, [listen]);

  const onSetTitle = useCallback(
    (title = '', { backRoute = '', docUrl = '' } = {}) => {
      if (!mountRouteRef.current) {
        mountRouteRef.current = pathname;
      }
      backRouteRef.current = backRoute;
      docUrlRef.current = docUrl;
      setTitle(title);
      setRefreshToken(token => !token);
    },
    [pathname]
  );

  const onCancel = useCallback(() => {
    if (backRouteRef.current) {
      push(backRouteRef.current);
    } else {
      goBack();
    }
  }, [push, goBack]);

  return (
    <HeaderContext.Provider
      value={{
        title,
        isMounted,
        docUrl: docUrlRef.current,
        onSetTitle,
        onCancel
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}
