import React, { useState, useContext } from 'react';
import _get from 'lodash/get';

import { If, user } from 'utils';
import { AuthContext } from 'context/AuthContext';
import { CustomToast, UiKit } from 'components';
import PasswordForm from './components/PasswordForm';
import { changePassword } from './services';

export default function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);
  const {
    isExternalUser,
    changePasswordModal: showModal,
    toggleChangePasswordModal: onModalToggle
  } = useContext(AuthContext);

  const handleSubmit = values => {
    if (!isPasswordStrong) return;
    setLoading(true);
    let { currentPassword, newPassword, confirmNewPassword } = values;
    const payload = {
      current_password: currentPassword,
      new_password: newPassword,
      re_new_password: confirmNewPassword
    };
    changePassword(payload, onPasswordChangeSuccess, onPasswordChangeFailure);
  };

  const onPasswordChangeSuccess = () => {
    setLoading(false);
    setSuccess(true);
    CustomToast({ msg: 'Successfully updated', type: 'success' });
    const timer = setTimeout(() => {
      onModalToggle();
      user.logout();
      // replace('/login', { fromLocation: pathname, fromChangePassword: true })
      window.location.href = '/login?fromChangePassword=true';
      clearTimeout(timer);
    }, 2000);
  };

  const onPasswordChangeFailure = err => {
    setLoading(false);
    CustomToast({
      isNotified: err.notified,
      type: 'error',
      msg: _get(
        err,
        'displayMessage.message',
        'Unable to change password at the moment, please try again later.'
      )
    });
  };

  if (isExternalUser) return null;

  return (
    <UiKit.Modal
      isOpen={showModal}
      title="Change Password"
      onClose={onModalToggle}
      contentClassName="ChangePassword__modal"
    >
      <If test={!isSuccess}>
        <PasswordForm
          loading={loading}
          handleSubmit={handleSubmit}
          triggerModal={onModalToggle}
          newPassStrengthtMetCb={setIsPasswordStrong}
          isPasswordStrong={isPasswordStrong}
        />
      </If>
      <If test={isSuccess}>
        <div className="text-center mt-4 mb-4 ChangePassword__success">
          <i class="far fa-check-circle fa-7x" />
          <p className="text">Password has been successfully changed.</p>
          <p>
            Redirecting you to login screen. Please login again with your new
            password.
          </p>
        </div>
      </If>
    </UiKit.Modal>
  );
}
