const PAGE_HEADER = {
  TITLE: 'Automation Rules',
  HELPTEXT:
    'Add various automation rules based on your operational policies. These rules will apply on a Return or Exchange item when the customers are making a service request.'
};

const NO_UPDATE_MSG =
  'To edit this rule please contact support@returnrabbit.com';

export { PAGE_HEADER, NO_UPDATE_MSG };
