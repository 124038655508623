import React, { useState } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { UiKit, ProtectedLink } from 'components';
import { useRequestAction } from 'views/Requests/hooks';

export default function RequestAction({
  data = {},
  retexes = [],
  isCompact = false,
  isDisabled = false,
  isApproving = true,
  isRefreshing = false,
  onBeforeProcessing = () => {},
  onSetProcessingId = () => {},
  onRetexUpdate = () => {},
  onAfterProcessing = () => {},
  className = ''
}) {
  const {
    show,
    text,
    type,
    disabled,
    isProcessing,
    tooltipMessage,
    processingText,
    alertModalData,
    onStartProcess
  } = useRequestAction(
    data,
    retexes,
    isApproving,
    onBeforeProcessing,
    onSetProcessingId,
    onRetexUpdate,
    onAfterProcessing
  );
  const [showAlert, setShowAlert] = useState();

  const id = _get(data, 'id', null);
  const elementId = `button-${id}`;
  const isButtonDisabled =
    disabled || isDisabled || isProcessing || isRefreshing;
  const buttonClassName = cn(styles.button, styles[type], {
    [styles.compact]: isCompact,
    [styles.danger]: !isApproving
  });

  const onClick = event => {
    event.stopPropagation();
    if (isButtonDisabled) return;
    if (alertModalData) return setShowAlert(true);
    onStartProcess();
  };

  const onCloseAlertModal = () => setShowAlert(false);

  const alertModalTitle = _get(alertModalData, 'title', '');
  const alertModalDescription = _get(alertModalData, 'description', '');
  const alertModalButtonText = _get(alertModalData, 'buttonText', '');
  const alertModalButtonProcessingText = _get(
    alertModalData,
    'buttonProcessingText',
    ''
  );

  if (!id || !show) return null;

  return (
    <>
      <ProtectedLink
        disabled
        id={elementId}
        tooltipAutohide={false}
        className={cn(styles.container, className)}
        hideTooltip={!tooltipMessage}
        tooltipMessage={tooltipMessage}
        tooltipInnerClassName={styles.tooltip}
        tooltipDelay={{ show: 100, hide: 100 }}
      >
        <UiKit.Button
          onClick={onClick}
          disabled={isButtonDisabled}
          processing={isProcessing || isRefreshing}
          processingText={isRefreshing ? 'Verifying' : processingText}
          className={buttonClassName}
          wrapperClassName={styles.buttonWrapper}
        >
          {text}
        </UiKit.Button>
      </ProtectedLink>
      <UiKit.Modal
        isOpen={showAlert}
        title={alertModalTitle}
        onClose={onCloseAlertModal}
        onConfirm={onStartProcess}
        confirmBtnType="danger"
        isProcessing={isProcessing}
        confirmBtnText={alertModalButtonText}
        confirmBtnProcessingText={alertModalButtonProcessingText}
        className={styles.alertModal}
      >
        <p className={styles.alertDescription}>{alertModalDescription}</p>
      </UiKit.Modal>
    </>
  );
}
