import React from 'react';
import { Formik } from 'formik';
import { Button, Input } from 'reactstrap';
import { isValidEmail } from 'utils/validators';

const StepOne = ({ requestLink, processing, success }) => {
  return (
    <Formik
      initialValues={{ email: '' }}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!isValidEmail(values.email)) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={values => {
        const formattedValues = Object.assign({}, values);
        formattedValues.email = formattedValues.email.toLowerCase();
        requestLink(formattedValues);
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Input
              type="email"
              name="email"
              onChange={handleChange}
              className="form-control login-input"
              aria-describedby="emailHelp"
              placeholder="Email"
              value={values.email}
              invalid={errors && errors['email']}
              disabled={success !== null || processing}
            />
          </div>
          <div className="form-group">
            <Button
              className="login-button"
              type="submit"
              disabled={
                processing || success !== null || (errors && errors['email'])
              }
            >
              {processing
                ? 'Processing...'
                : success !== null
                ? 'Sent'
                : 'Get Reset Link'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default StepOne;
