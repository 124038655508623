import React, { useContext, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { ShepherdTourContext } from 'react-shepherd';
import cn from 'classnames';
import styles from './index.module.scss';

import { ReactComponent as Arrow } from 'assets/img/icons/down-only.svg';

export default function TriggerBtn({
  text = '',
  description = '',
  isVisited = false,
  isExpanded = false,
  onExpand = () => {},
  onStarted = () => {},
  onCancelled = () => {},
  onCompleted = () => {}
}) {
  const tour = useContext(ShepherdTourContext);

  useEffect(() => {
    tour.on('cancel', onCancelled);
    tour.on('complete', onCompleted);
  }, [tour, onCancelled, onCompleted]);

  const onStartTour = () => {
    if (!tour) return;
    tour.start();
    onStarted();
  };

  const buttonText = isVisited ? 'Try again' : 'Try it';

  const containerClassName = cn(styles.container, {
    [styles.read]: isVisited,
    [styles.open]: isExpanded
  });
  const buttonClassName = cn({
    [styles.readBtn]: isVisited,
    [styles.unreadBtn]: !isVisited
  });

  return (
    <div className={containerClassName} onClick={onExpand}>
      <div className={styles.header}>
        <span className={styles.title}>{text}</span>
        <Arrow className={styles.arrow} />
      </div>
      <Collapse isOpen={isExpanded}>
        {description && <p className={styles.description}>{description}</p>}
        <button onClick={onStartTour} className={buttonClassName}>
          {buttonText}
        </button>
      </Collapse>
    </div>
  );
}
