import { get } from 'utils';

export default async function getCountries() {
  try {
    const { data } = await get(
      { apiKey: 'settings.locations.facilities.locationsMap.getCountries' },
      { params: {} }
    );
    return { data };
  } catch (err) {
    return { err };
  }
}
