import React, { PureComponent } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import HeaderIcon from './HeaderIcon';
import SubHeading from './SubHeading';

class SettingModal extends PureComponent {
  render() {
    const { loading, titleMsg, subHeading, toggle } = this.props;
    const subHeadingText =
      subHeading ||
      `User will not be login and account will be deleted permanently.
    This doesn’t impact any actions taken by user on past requests.
     If you would like user to be able access account later. Use Disabling instead.`;
    return (
      <div>
        <Modal
          isOpen={this.props.isModalOpen}
          toggle={toggle}
          className="configureModal"
          ref="wassup"
        >
          <ModalBody>
            <HeaderIcon title={titleMsg} closeModal={toggle} />
            {/* <Title customClass="configureModal-title" title={titleMsg} /> */}
            <SubHeading
              customClass="configureModal-subHeading"
              text={subHeadingText}
            />
            <div className="configureModal-button">
              <Button
                className="configureModal-button__cancel"
                onClick={this.props.close}
                disabled={loading}
              >
                Cancel
              </Button>{' '}
              <Button
                className="configureModal-button__success"
                onClick={this.props.success}
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Confirm'}
              </Button>{' '}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default SettingModal;
