export default {
  TITLE: 'Fees & Incentives',
  DESCRIPTION:
    'Find all your fees and monetary incentives in one place. View, edit, and update directly from this page or follow the configuration links.',

  SHIPPING_FEE: {
    TITLE: 'Shipping Fee',
    DESCRIPTION:
      'A snapshot of the shipping and return fees. Follow the edit links to configure.'
  },
  INCENTIVES: {
    TITLE: 'Incentives',
    DESCRIPTION:
      'List of the incentives you can provide you shoppers to increase your revenue retained.'
  }
};
