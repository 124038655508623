import React, { createContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Loader } from 'components';
import {
  useAutoScroll,
  useIdle,
  useNetlifySync,
  useRedirect
} from '../utils/hooks';
import rrFavicon from 'assets/img/brand/rr-favicon.svg';
import trFavicon from 'assets/img/brand/tr-favicon.svg';
import MaintenanceBreak from 'components/MaintenanceBreak';

export const AppContext = createContext({ isOnline: true });

export function AppProvider({ children = null }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const isRedirecting = useRedirect();
  const isUserIdle = useIdle();
  useNetlifySync(isOnline && !isRedirecting && !isUserIdle);
  useAutoScroll();

  const isTrackingApp = process.env.REACT_APP_TYPE === 'SHIPMENT_TRACKING';
  const appName = isTrackingApp ? 'Tracking Rabbit' : 'Return Rabbit';
  const appFavicon = isTrackingApp ? trFavicon : rrFavicon;

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));
    window.addEventListener('storage', ({ key }) => {
      if (key === 'userData') {
        window.location.reload();
      } else if (key === 'rrloggedInStoreName') {
        window.location.href = '/';
      }
    });
  }, []);

  return (
    <AppContext.Provider value={{ isOnline }}>
      <Helmet>
        <title>{appName}</title>
        <link rel="shortcut icon" href={appFavicon} />
      </Helmet>
      <div className="app AtlasGrotesk-Regular-Web">
        <Loader loading={isRedirecting}>
          <MaintenanceBreak>{children}</MaintenanceBreak>
        </Loader>
      </div>
    </AppContext.Provider>
  );
}
