import React from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

function Title({ emoji = '', ariaLabel = 'emoji', className = '', children }) {
  return (
    <div className={cn(styles.title, className)}>
      {emoji && (
        <span role="img" aria-label={ariaLabel} className="mr-2">
          {emoji}
        </span>
      )}
      {children}
    </div>
  );
}

function Content({ className = '', children }) {
  return <div className={cn(styles.content, className)}>{children}</div>;
}

// -----------------------------------------------------------------------------------------

/**
 * @typedef {Object} Props
 * @property {"info" | "warning" | "error" | "success"} type Defines the look and feel of the callout with distict background color
 * @property {string} className Defines the look and feel of the callout with distict background color
 * @property {React.ReactNode} children
 */

/**
 * @param {Props} props
 */
export default function Callout({
  type = '', // info, warning, error, success
  className = '',
  children = null
}) {
  const containerClassName = cn(styles.container, className, {
    [styles.info]: type === 'info',
    [styles.error]: type === 'error',
    [styles.warning]: type === 'warning',
    [styles.success]: type === 'success'
  });

  return <div className={containerClassName}>{children}</div>;
}

Callout.Title = Title;
Callout.Content = Content;
