import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './index.module.scss';

import { If } from 'utils';
import { useNotifications } from 'utils/hooks';
import NotificationBanner from '../NotificationBanner';
import { ReactComponent as LeftArrrowIcon } from 'assets/img/icons/new/left-carousel.svg';
import { ReactComponent as RightArrowIcon } from 'assets/img/icons/new/right-carousel.svg';

const variants = {
  enter: direction => {
    return {
      x: direction === 'right' ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: {
    display: 'none'
  }
};

const transition = {
  x: { type: 'spring', stiffness: 250, damping: 30 },
  opacity: { duration: 0.2 }
};

const slidersVariants = {
  hover: {
    scale: 1.2
  }
};

export default function BannerCarousel() {
  const {
    unreadNotificationsList,
    onMarkNotificationList
  } = useNotifications();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState('right');
  const [
    criticalAndAnnouncementBannerList,
    setCriticalAndAnnouncementBannerList
  ] = useState([]);

  useEffect(() => {
    if (Array.isArray(unreadNotificationsList)) {
      const newList = unreadNotificationsList.filter(n => {
        const isValidCategory =
          n.category === 'critical' || n.category === 'announcement';
        const isManual = !n.is_read && n.is_dismissable;
        const isAuto = !n.is_resolved && !n.is_dismissable;

        return isValidCategory && (isManual || isAuto);
      });
      setCriticalAndAnnouncementBannerList(newList);
      setCurrentIndex(prev => {
        if (prev >= newList.length) return 0;
        return prev;
      });
    }
  }, [unreadNotificationsList]);

  const markBannerAsRead = useCallback(
    id => {
      onMarkNotificationList(id, 'banner');
    },
    [onMarkNotificationList]
  );

  const alertsCount = useMemo(() => criticalAndAnnouncementBannerList.length, [
    criticalAndAnnouncementBannerList
  ]);

  const handleNext = useCallback(
    function() {
      setDirection('right');
      setCurrentIndex(prevIndex =>
        prevIndex + 1 === alertsCount ? 0 : prevIndex + 1
      );
    },
    [alertsCount]
  );

  const handlePrevious = useCallback(
    function() {
      setDirection('left');

      setCurrentIndex(prevIndex =>
        prevIndex - 1 < 0 ? alertsCount - 1 : prevIndex - 1
      );
    },
    [alertsCount]
  );

  const renderBanners = useMemo(
    () =>
      currentIndex >= 0 && alertsCount ? (
        <NotificationBanner
          details={criticalAndAnnouncementBannerList[currentIndex]}
          onDismissCb={markBannerAsRead}
        />
      ) : null,
    [
      alertsCount,
      criticalAndAnnouncementBannerList,
      currentIndex,
      markBannerAsRead
    ]
  );

  if (alertsCount === 0) return null;

  return (
    <div className={styles.notificationsBannerCarousel}>
      <div className={styles.carouselBannerList}>
        <div className="d-flex justify-content-between align-items-center my-2">
          <span>
            <span className={styles.bannerTitle}>Alerts</span>
            <span className={styles.alertsCount}>{alertsCount}</span>
          </span>
          <div className={styles.slideDirection}>
            <If test={alertsCount > 1}>
              <motion.div
                variants={slidersVariants}
                whileHover="hover"
                className={styles.left}
                onClick={handlePrevious}
              >
                <LeftArrrowIcon />
              </motion.div>
              <motion.div
                variants={slidersVariants}
                whileHover="hover"
                className={styles.right}
                onClick={handleNext}
              >
                <RightArrowIcon />
              </motion.div>
            </If>
          </div>
        </div>
        <AnimatePresence>
          <motion.div
            key={currentIndex}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={transition}
            className={styles.carouselContainer}
          >
            {renderBanners}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}
