import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';
import rrLogo from 'assets/img/brand/rr-logo.svg';
import trackingRabbitAppLogo from 'assets/img/brand/tracking-rabbit-logo.png';

export default function Logo({ linkTo = '/' }) {
  const { appType } = useContext(AuthContext);

  const isTrackingApp = _get(appType, 'shipmentTracking', 'false');

  const src = isTrackingApp ? trackingRabbitAppLogo : rrLogo;
  const alt = isTrackingApp ? 'Tracking Rabbit' : 'Return Rabbit';

  return (
    <div className={styles.container}>
      <Link to={linkTo}>
        <img src={src} alt={alt} className={styles.logo} />
      </Link>
    </div>
  );
}
