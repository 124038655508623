import React, { useRef, useContext, useMemo } from 'react';
import numbro from 'numbro';
import cn from 'classnames';
import _get from 'lodash/get';

import { findAllValues } from 'utils/DICTIONARY';
import { UiKit } from 'components';
import { ReactTablesContext } from 'components/ReactTable';

export default function TableRefinementList({
  facetKey = '',
  masterKey = '',
  excludeKeys = [],
  listClassName = '',
  listItemClassName = '',
  checkboxClassName = '',
  preventHiding = false
}) {
  const {
    facets,
    refine,
    refinements,
    facetsToRefinementKeyMap,
    searchState: { isFetchingFacets }
  } = useContext(ReactTablesContext);
  const listMap = useRef(findAllValues(masterKey)).current;

  const facetData = _get(facets, facetKey, {});
  const refinementKey = _get(facetsToRefinementKeyMap, facetKey, facetKey);
  const refinementData = _get(refinements, `${refinementKey}.values`, {});

  const ulClassName = useMemo(() => {
    return cn('table-filters__refinement-list', {
      [listClassName]: !!listClassName
    });
  }, [listClassName]);

  const liClassName = useMemo(() => {
    return cn({ [listItemClassName]: !!listItemClassName });
  }, [listItemClassName]);

  const checkClassName = useMemo(() => {
    return cn({ [checkboxClassName]: !!checkboxClassName });
  }, [checkboxClassName]);

  const createlistItems = (data = {}, formatTitle = val => val) => {
    const dataSet = { ...data };
    excludeKeys.forEach(key => delete dataSet[key]);
    return Object.keys(dataSet).map(key => {
      const title = formatTitle(key);
      const count = _get(facetData, key, 0);
      const isRefined = !!_get(refinementData, key, false);
      const value = { type: 'list', value: key, attribute: refinementKey };

      const formattedCount = numbro(count).format({
        thousandSeparated: true,
        mantissa: 0
      });
      const hasOptions = count > 0;
      const labelClassName = cn('filter-label', { 'text-muted': !hasOptions });
      return (
        <li key={key} className={liClassName}>
          <UiKit.Checkbox
            title={title}
            checked={isRefined}
            disabled={isFetchingFacets || !hasOptions}
            toggle={() => refine(value)}
            textClassName={labelClassName}
            wrapperClassName={checkClassName}
          >
            <span className="filter-count">{formattedCount}</span>
          </UiKit.Checkbox>
        </li>
      );
    });
  };

  const filters = !masterKey
    ? createlistItems(facetData)
    : createlistItems(listMap, key => _get(listMap, key, key));

  return preventHiding || Object.keys(facetData).length > 0 ? (
    <ul className={ulClassName}>{filters}</ul>
  ) : (
    <div className="text-muted">No filters available!</div>
  );
}
