import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import {
  Arrow,
  Lock,
  Carrot,
  Home,
  Package,
  Delivery,
  Trend,
  Wrench,
  Gear,
  Help,
  Redirect,
  HomeV2,
  // Box,
  Cover,
  Email,
  HelpV2
} from './lib';

export default function Icon({ type = '', className = '' }) {
  const Icon = useMemo(() => {
    switch (type) {
      case 'icon-getting-started':
        return Carrot;
      case 'icon-overview':
        return Home;
      case 'icon-orders':
        return Package;
      case 'icon-service-requests':
        return Delivery;
      case 'icon-reporting':
        return Trend;
      case 'icon-configure':
        return Wrench;
      case 'icon-setting':
        return Gear;
      case 'icon-help-and-support':
        return Help;
      case 'icon-customer-portal':
        return Redirect;
      case 'icon-tracking-app-home':
        return HomeV2;
      case 'icon-tracking-app-orders':
        return Package;
      case 'icon-tracking-app-brand-page':
        return Cover;
      case 'icon-tracking-app-email':
        return Email;
      case 'icon-tracking-app-help-and-support':
        return HelpV2;
      case 'arrow':
        return Arrow;
      case 'lock':
        return Lock;
      default:
        return () => null;
    }
  }, [type]);

  const containerClassName = cn(styles.icon, className);

  return (
    <span className={containerClassName}>
      <Icon />
    </span>
  );
}
