import React, { useCallback, useContext, useState } from 'react';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import styles from './index.module.scss';

import { CustomToast, UiKit, Callout } from 'components';
import { OrderServiceContext } from 'components/OrderServiceDetails';
import { post } from 'utils';
import Alert from '../Alert';

const IDS = {
  RETRY: 'RETRY',
  RESET_ID_AND_RETRY: 'RESET_ID_AND_RETRY'
};

export default function RegenerateLabel() {
  const { orderService, updateOsKeyValue = () => {} } = useContext(
    OrderServiceContext
  );
  const [processingButton, setProcessingButton] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const osId = _get(orderService, 'id', '');
  const shippingInfo = _get(orderService, 'shipping_info', {});
  const labelError = _get(shippingInfo, 'shipment_label_error_message', '');

  const onRetryLabelGeneration = useCallback(
    buttonId => {
      if (!buttonId) return;

      setProcessingButton(buttonId);
      post(
        { apiKey: 'requests.generate_label' },
        {
          params: {
            routeParams: { id: osId },
            reset_shipment_id: buttonId === IDS.RESET_ID_AND_RETRY
          }
        }
      )
        .then(({ data }) => {
          if (_isObject(data) && data.hasOwnProperty('shipping_info')) {
            updateOsKeyValue('shipping_info', data.shipping_info);
          }
          setShowModal(false);
          CustomToast({
            type: 'success',
            msg: 'Successfully generated shiping label.'
          });
        })
        .catch(err => {
          CustomToast({
            isNotified: err.notified,
            type: 'error',
            msg: 'Failed to generate label. Try again later.'
          });
        })
        .finally(() => setProcessingButton(null));
    },
    [osId, updateOsKeyValue]
  );

  const onToggleModal = useCallback(() => setShowModal(show => !show), []);

  if (!osId) return null;

  return (
    <UiKit.GridRow templateColumns="1fr" gap="16" className="w-100">
      <Alert type="error" tooltipMessage={labelError}>
        There was an error generating your shipping label
      </Alert>
      <UiKit.GridRow gap="8">
        <UiKit.Button
          customType="outline"
          onClick={onToggleModal}
          disabled={!!processingButton}
          className={styles.button}
          wrapperClassName={styles.buttonContainer}
        >
          Reset Shipment ID and Retry
        </UiKit.Button>
        <UiKit.Button
          onClick={() => onRetryLabelGeneration(IDS.RETRY)}
          disabled={!!processingButton}
          processingText="Processing..."
          processing={processingButton === IDS.RETRY}
          className={styles.button}
          wrapperClassName={styles.buttonContainer}
        >
          Retry Generation
        </UiKit.Button>
      </UiKit.GridRow>
      <UiKit.Modal
        isOpen={showModal}
        onClose={onToggleModal}
        confirmBtnType="danger"
        title="Reset Shipment ID"
        confirmBtnText="Reset and Retry"
        confirmBtnProcessingText="Processing..."
        isProcessing={processingButton === IDS.RESET_ID_AND_RETRY}
        onConfirm={() => onRetryLabelGeneration(IDS.RESET_ID_AND_RETRY)}
      >
        <p>We will choose the cheapest shipping label available.</p>
        <Callout type="warning">
          <Callout.Title emoji="🚧">
            This will reset the pricing and other critical details
          </Callout.Title>
          <Callout.Content>
            Resetting the shipment ID will reset all existing shipping details,
            including pricing and carrier information. Are you sure you want to
            proceed?
          </Callout.Content>
        </Callout>
      </UiKit.Modal>
    </UiKit.GridRow>
  );
}
