import { put } from 'utils';

function cancelRequest(params, success, error) {
  const request = put(
    {
      apiKey: 'overview.retex.cancel'
    },
    {
      params
    }
  );
  request
    .then(res => {
      success(res);
    })
    .catch(err => {
      error(err);
    });
}

export default cancelRequest;
