import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';

export default function Head({ headings = [], className = '', topOffset = 0 }) {
  const headers = useMemo(() => {
    if (!_isArray(headings)) return null;

    return headings.map((heading, index) => {
      if (!heading) return null;

      const lg = _get(heading, 'lg', null);
      const title = _get(heading, 'title', '');
      const customClass = _get(heading, 'customClass', '');
      const className = cn({ [customClass]: !!customClass });
      return (
        <Col key={index} lg={lg} className={className}>
          {title}
        </Col>
      );
    });
  }, [headings]);

  const wrapperClassName = useMemo(() => {
    return cn('no-gutters', 'table-head', { [className]: !!className });
  }, [className]);

  const offsetStyles = useMemo(() => {
    return { top: `${topOffset}px` };
  }, [topOffset]);

  if (!_isArray(headings) || _isEmpty(headings)) return null;

  return (
    <Row className={wrapperClassName} style={offsetStyles}>
      {headers}
    </Row>
  );
}
