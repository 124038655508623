import React, { useMemo } from 'react';
import _get from 'lodash/get';

import { useAccess } from 'utils/hooks';
import Card from './Card';

export default function Features({
  list = [],
  shepherdId = '',
  disabled = false,
  onToggle = () => {}
}) {
  const { hasSubFeatureAccess } = useAccess();

  const sortedList = useMemo(() => {
    const { active, locked } = list.reduce(
      (acc, feature) => {
        const slug = _get(feature, 'feature_slug', '');
        const hasAccess = slug ? hasSubFeatureAccess(slug) : true;
        const record = hasAccess ? acc.active : acc.locked;
        record.push({ ...feature, isLocked: !hasAccess });
        return acc;
      },
      { active: [], locked: [] }
    );

    return [...active, ...locked];
  }, [list, hasSubFeatureAccess]);

  const renderFeatureCards = sortedList.map((data, index) => {
    const id = _get(data, 'id', '');
    return (
      <Card
        key={id}
        data={data}
        index={index}
        onToggle={onToggle}
        disabled={disabled}
      />
    );
  });

  return (
    <ul data-shepherd={shepherdId} className="additional-features__list">
      {renderFeatureCards}
    </ul>
  );
}
