import React, { useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import modalStyles from '../index.module.scss';

import { UiKit } from 'components';
import { useAccess } from 'utils/hooks';
import ModalContent from '../ModalContent';

/**
 * @param {object} props;
 * @param {(import('dictionary').FEATURE_SLUGS)} props.featureSlug Feature slug to check if user has access to the feature
 * @param {string} props.className
 * @param {string} props.wrapperClassName
 * @param {import("react").ReactNode} props.fallback
 */
export default function ModalTrigger({
  featureSlug = '',
  className = '',
  wrapperClassName = '',
  fallback = null
}) {
  if (!featureSlug) {
    throw new Error(
      '"featureSlug" is a required prop in <ModalTrigger /> component'
    );
  }

  const { hasSubFeatureAccess } = useAccess();
  const [isOpen, setIsOpen] = useState(false);

  const hasAccess = hasSubFeatureAccess(featureSlug);

  if (hasAccess) return fallback;

  return (
    <>
      <UiKit.Button
        onClick={() => setIsOpen(true)}
        className={cn(styles.button, className)}
        wrapperClassName={cn(styles.container, wrapperClassName)}
      >
        View Details
      </UiKit.Button>
      <UiKit.Modal
        centered
        size="lg"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        contentClassName={modalStyles.modal}
      >
        <ModalContent featureSlug={featureSlug} />
      </UiKit.Modal>
    </>
  );
}
