import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { ReactComponent as CheckIcon } from 'assets/img/icons/check-green.svg';

export default function Step({
  title = '',
  isActive = false,
  completed = false
}) {
  return (
    <div
      className={cn(styles.step, {
        [styles.active]: isActive,
        [styles.completed]: completed
      })}
    >
      <span className={styles.indicator}>
        <CheckIcon className={cn(styles.check, { [styles.show]: completed })} />
      </span>
      <div className={styles.title}>{title}</div>
    </div>
  );
}
