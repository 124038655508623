import _get from 'lodash/get';

import { RETEX_STATUS } from 'dictionary';
import { approveRetex, approveQualification } from 'utils/services';

export default function getRetexActionData(
  data = {},
  isApproving = true,
  isRestocking = true,
  comment = '',
  customComment = ''
) {
  const id = _get(data, 'id', '');
  const status = _get(data, 'status', '');

  const textPrefix = isApproving ? 'Approve' : 'Reject';
  const processingTextPrefix = isApproving ? 'Approving' : 'Rejecting';

  const dataset = {
    text: '',
    type: '',
    show: true,
    processingText: '',
    toastMessage: { success: '', info: '', error: '' },
    alertModalData: null, // OR { title: "", description: "" } if we want to enforce confirmation modal before processing
    onTrigger: () => {}
  };

  if (status === RETEX_STATUS.AWAITING_QUALIFICATION_APPROVAL) {
    dataset.text = `${textPrefix} Qualification`;
    dataset.type = isApproving ? 'info' : 'danger';
    dataset.processingText = `${processingTextPrefix} Qualification`;
    dataset.onTrigger = approveQualification.bind(null, {
      params: {
        routeParam: id,
        isApproved: isApproving
      }
    });
    dataset.alertModalData = isApproving
      ? null
      : {
          title: 'Reject qualification for entire request',
          description:
            'If you reject any qualification line item, the entire request will be rejected and the shopper will have to recreate the request. Are you sure you want to continue?',
          buttonText: 'Reject entire request',
          buttonProcessingText: 'Reject entire request'
        };
    dataset.toastMessage = {
      error: 'Something went wrong. Please try again!',
      success: 'Successfully approved the qualification request',
      info: 'Successfully rejected the qualification request'
    };
  } else if (status === RETEX_STATUS.AWAITING_REFUND_APPROVAL) {
    dataset.text = `${textPrefix} Refund`;
    dataset.type = isApproving ? 'success' : 'danger';
    dataset.processingText = `${processingTextPrefix} Refund`;
    dataset.onTrigger = approveRetex.bind(null, {
      params: {
        routeParam: id,
        is_approved: isApproving,
        disposition: {
          mode: isRestocking ? 'resale' : 'scrap',
          comment: comment,
          custom_comment: customComment
        }
      }
    });
    dataset.toastMessage = {
      error: 'Something went wrong. Please try again!',
      success: 'Successfully approved the refund',
      info: 'Successfully rejected the refund'
    };
  } else if (status === RETEX_STATUS.AWAITING_EXCHANGE_APPROVAL) {
    dataset.text = `${textPrefix} Exchange`;
    dataset.type = isApproving ? 'success' : 'danger';
    dataset.processingText = `${processingTextPrefix} Exchange`;
    dataset.onTrigger = approveRetex.bind(null, {
      params: {
        routeParam: id,
        is_approved: isApproving,
        disposition: {
          mode: isRestocking ? 'resale' : 'scrap',
          comment: comment,
          custom_comment: customComment
        }
      }
    });
    dataset.toastMessage = {
      error: 'Something went wrong. Please try again!',
      success: 'Successfully approved the exchange',
      info: 'Successfully rejected the exchange'
    };
  } else {
    dataset.show = false;
  }

  return dataset;
}
