import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { Loader as Loading } from 'components';

export default function Loader({ isLoading = false, className = '' }) {
  const loaderClassName = useMemo(
    () => cn(styles.container, { [className]: !!className }),
    [className]
  );

  return <Loading loading={isLoading} className={loaderClassName} />;
}
