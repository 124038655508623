import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';

import Skeleton from 'components/Skeleton';

export default function Breadcrumb({ items = [], separator = '>' }) {
  if (!_isArray(items) || _isEmpty(items)) return null;

  const listItems = items.map(item => {
    const { label, onClick, loading = false, shepherdId } = item;
    return (
      <li
        key={uuidv4()}
        className="customBreadcrumb--item"
        data-separator={separator}
        data-shepherd={shepherdId}
      >
        {loading ? (
          <Skeleton width="100px" height="16px" className="mb-0" />
        ) : (
          <button
            onClick={onClick}
            disabled={loading || !onClick}
            className="customBreadcrumb--item__button"
          >
            {label}
          </button>
        )}
      </li>
    );
  });

  return <ul className="customBreadcrumb">{listItems}</ul>;
}
