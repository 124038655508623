import React from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import styles from './index.module.scss';

import { InfoBanner } from 'components';
import { ReactComponent as OfferFlag } from 'assets/img/icons/offer.svg';

import { If } from 'utils';
import { VALIDITY_MAP, CONTENT_FACTORY } from './stubs';

export default function FreeTrialBanner({
  referrer = '',
  trialPeriod = 0,
  className = '',
  forceMount = false
}) {
  const isValidTrial = _get(VALIDITY_MAP, `${referrer}.${trialPeriod}`, false);
  const contentGenerator = _get(CONTENT_FACTORY, referrer, () => null);
  const content = contentGenerator(trialPeriod);

  const show =
    (isValidTrial || forceMount) &&
    _isNumber(+trialPeriod) &&
    +trialPeriod > 0 &&
    !!content;

  return (
    <If test={show}>
      <InfoBanner
        className={cn(styles.container, className)}
        icon={
          <div className={styles.iconWrapper}>
            <OfferFlag className={styles.icon} />
          </div>
        }
        messageClassName={styles.message}
      >
        {content}
      </InfoBanner>
    </If>
  );
}
