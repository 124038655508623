import { useCallback, useContext, useDebugValue } from 'react';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _merge from 'lodash/merge';

import { AuthContext } from 'context/AuthContext';
import { FEATURE_ACCESS_MAP } from 'dictionary';

export default function useAccess() {
  const {
    configuration,
    featureAccess,
    planFeatures,
    featuresPlanMap,
    planFeaturesMap,
    uniqueCategoryPlanFeaturesMap,
    subscriptionDetails,
    syncSubscription
  } = useContext(AuthContext);

  const currentPlanSlug = _get(subscriptionDetails, 'plan_info.slug', '');

  useDebugValue('access', () => ({
    configuration,
    planFeatures,
    featuresPlanMap,
    planFeaturesMap,
    uniqueCategoryPlanFeaturesMap
  }));

  const hasSubFeatureAccess = useCallback(
    /**
     * @param {(import('dictionary').FEATURE_SLUGS)} featureSlug
     * @returns {boolean}
     */
    featureSlug => _get(featureAccess, featureSlug, false),
    [featureAccess]
  );

  const hasFeatureAccess = useCallback(
    /**
     *  @param {import('dictionary').FEATURE_IDS} featureId
     *  @returns {boolean}
     */
    featureId => {
      const featureSlugs = _get(FEATURE_ACCESS_MAP, featureId, []);
      return featureSlugs.some(slug => hasSubFeatureAccess(slug));
    },
    [hasSubFeatureAccess]
  );

  const hasPageAccess = useCallback(
    (displayMenuAccessPath = '') => {
      return _get(configuration, displayMenuAccessPath, false);
    },
    [configuration]
  );

  const getPlanInfoToAccess = useCallback(
    /**
     * @param {Object} args
     * @param {import('dictionary').FEATURE_SLUGS[]} args.slugs
     * @param {import('dictionary').FEATURE_IDS} args.id
     */
    ({ id = '', slugs = [] } = {}) => {
      const featureSlugs = _get(FEATURE_ACCESS_MAP, id) || slugs;

      const accessMap = featureSlugs.reduce((acc, slug) => {
        const data = _get(featuresPlanMap, slug, {});
        return _merge({}, acc, data);
      }, {});

      const subscribedPlan = planFeatures.find(plan => {
        const planSlug = _get(plan, 'slug', '');
        return planSlug === currentPlanSlug;
      });
      const requiredPlan = planFeatures.find(plan => {
        const planSlug = _get(plan, 'slug', '');
        return _get(accessMap, planSlug, false);
      });

      const requiredPlanFeatures = _get(requiredPlan, 'features', []);
      const subscribedPlanFeatures = _get(subscribedPlan, 'features', []);
      const newFeaturesCount =
        _size(requiredPlanFeatures) - _size(subscribedPlanFeatures);

      return { requiredPlan, newFeaturesCount };
    },
    [featuresPlanMap, planFeatures, currentPlanSlug]
  );

  return {
    configuration,
    planFeatures,
    featuresPlanMap,
    planFeaturesMap,
    uniqueCategoryPlanFeaturesMap,
    hasFeatureAccess,
    hasSubFeatureAccess,
    getPlanInfoToAccess,
    hasPageAccess,
    syncSubscription
  };
}
