import React, { useContext, useState, useCallback, useRef } from 'react';
import _get from 'lodash/get';

import { ReactTablesContext } from 'components/ReactTable';
import { CancelHoldRequest } from 'components';
import RequestsTableRow from './RequestsTableRow';

export default function RequestsTableRows({
  hideCTA = false,
  hideArrow = false,
  onReload = () => {},
  generateOsRowData = () => null,
  renderRetexTitle = () => null,
  generateRetexRowData = () => null
}) {
  const { data = [] } = useContext(ReactTablesContext);
  const [expandedId, setExpandedId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCancelHoldModalOpen, setIsCancelHoldModalOpen] = useState(false);
  const modalData = useRef(null);

  const onExpand = useCallback(
    (id, isProcessing = false) => {
      if (isDisabled) return;
      setExpandedId(currId => (isProcessing || currId !== id ? id : null));
      setIsDisabled(isProcessing);
    },
    [isDisabled]
  );

  const onOpenCancelHoldModal = useCallback(data => {
    modalData.current = data;
    setIsCancelHoldModalOpen(true);
  }, []);

  const onCloseCancelHoldModal = useCallback(() => {
    modalData.current = null;
    setIsCancelHoldModalOpen(false);
  }, []);

  const onBeforeProcessing = useCallback(
    (data, type) => {
      const id = _get(data, 'id', null);
      const status = _get(data, 'status', '');
      const isOnHold = _get(data, 'on_hold', false);
      const isUnlocking = type === 'unlock';
      const isCancelling = type === 'cancel';

      if (isUnlocking) {
        onOpenCancelHoldModal({
          type: 'hold',
          orderServiceID: id,
          extra: { holdVal: !isOnHold }
        });
      } else if (isCancelling) {
        onOpenCancelHoldModal({
          type: 'cancel',
          orderServiceID: id,
          orderServiceStatus: status
        });
      } else {
        return onExpand(id, true);
      }
    },
    [onExpand, onOpenCancelHoldModal]
  );

  const onPostCancelHoldModalProcessing = useCallback(() => {
    onReload(true);
    onCloseCancelHoldModal();
  }, [onReload, onCloseCancelHoldModal]);

  const rows = data.map((os, index) => {
    const id = _get(os, 'id', index);
    const isExpanded = expandedId === id;

    return (
      <RequestsTableRow
        key={`${id}-${index}`}
        data={os}
        hideCTA={hideCTA}
        hideArrow={hideArrow}
        onExpand={onExpand}
        isExpanded={isExpanded}
        isDisabled={isDisabled}
        onDisable={setIsDisabled}
        generateRowData={generateOsRowData}
        renderRetexTitle={renderRetexTitle}
        generateRetexRowData={generateRetexRowData}
        onBeforeProcessing={onBeforeProcessing}
        onAfterProcessing={onReload}
        onOpenCancelHoldModal={onOpenCancelHoldModal}
      />
    );
  });

  return (
    <>
      {rows}
      {modalData.current && (
        <CancelHoldRequest
          isOpen={isCancelHoldModalOpen}
          data={modalData.current}
          toggle={onCloseCancelHoldModal}
          done={onPostCancelHoldModalProcessing}
        />
      )}
    </>
  );
}
