import React from 'react';
import styles from './index.module.scss';

import { DOCUMENTAION_LINKS } from 'dictionary';
import { UiKit } from 'components';

const { RR_TERMS_OF_USE, RR_PRIVACY_POLICY } = DOCUMENTAION_LINKS;

export default function Checkbox({
  checked = false,
  disabled = false,
  onToggle = () => {},
  className = ''
}) {
  return (
    <UiKit.Checkbox
      checked={checked}
      toggle={onToggle}
      disabled={disabled}
      wrapperClassName={className}
    >
      <div className={styles.content} onClick={onToggle}>
        I confirm that I have read and accept the{' '}
        <a
          href={RR_TERMS_OF_USE}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href={RR_PRIVACY_POLICY}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          Privacy Policy
        </a>
      </div>
    </UiKit.Checkbox>
  );
}
