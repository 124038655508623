import getAggregatorSteps from './getAggregatorSteps';
import getShippingSteps from './getShippingSteps';
import getReturnManagementSteps from './getReturnManagementSteps';
import getAutomationRulesSteps from './getAutomationRulesSteps';
import getShopperPortalSteps from './getShopperPortalSteps';
import getRequestReasonsSteps from './getRequestReasonsSteps';
import getRefundMethodsSteps from './getRefundMethodsSteps';
import getCustomerCareSteps from './getCustomerCareSteps';
import getConfigureExchangesSteps from './getConfigureExchangesSteps';

export default function getTours({ push = () => {}, hasAccess = () => false }) {
  const shippingTours = [
    {
      id: 'shipping_aggregator',
      title: 'Shipping Aggregator Setup',
      description:
        'Connect to ShipEngine or ShipStation and manage your return shipping labels smoothly. No account yet? We’ll guide you on setting one up.',
      steps: getAggregatorSteps({ push })
    },
    {
      id: 'shipping_settings',
      title: 'Shipping Settings',
      description:
        'Configure shipping methods for returns. Ensure your Shipping Aggregator is set first to offer labels & prepaid returns.',
      steps: getShippingSteps({ push, hasAccess })
    }
  ];

  const returnManagementTours = [
    {
      id: 'automation_rules',
      title: 'Automation Rules',
      description:
        'Simplify return management with settings that streamline the admin and warehouse stages based on product type and price. Efficiently handle approvals and warehouse authorizations.',
      steps: getAutomationRulesSteps({ push })
    },
    {
      id: 'request_reasons',
      title: 'Request Reasons Settings',
      description:
        'Dive deep into shopper feedback with custom return reasons, enhancing both brand voice and product insights. Configure reasons per product, with detailed sub-reasons and service specifications.',
      steps: getRequestReasonsSteps({ push })
    },
    {
      id: 'refund_methods',
      title: 'Refund Methods',
      description:
        "Choose how you reimburse shoppers: via their Original Payment within seven days or instantly through Gift Card/Store Credit. Customize each method's settings to fit your brand and policy.",
      steps: getRefundMethodsSteps({ push })
    },
    {
      id: 'configure_exchanges',
      title: 'Configure Exchanges',
      description:
        'Mastering Exchange Configurations in Return Rabbit ensures a seamless shopping experience by offering or restricting the exchange option for your customers.',
      steps: getConfigureExchangesSteps({ push })
    },
    {
      id: 'return_management',
      title: 'Return Management Settings',
      description:
        "Dive into Return Rabbit's controls to shape your store's return policies and foster a seamless post-purchase experience.",
      steps: getReturnManagementSteps({ push })
    }
  ];

  const shopperPortalTours = [
    {
      id: 'shopper_portal',
      title: 'Shopper Portal Settings',
      description:
        "Customize your Shopper Portal to reflect your brand's voice! Edit your logo, colors, and other components to make it uniquely yours!",
      steps: getShopperPortalSteps({ push })
    },
    {
      id: 'customer_care',
      title: 'Setup Customer Care',
      description:
        'Provide and edit your support contact details. Displayed at the footer of every shopper portal page, it ensures customers have a clear path to assistance.',
      steps: getCustomerCareSteps({ push })
    }
  ];

  return [
    {
      section: 'Shipping',
      tours: shippingTours
    },
    {
      section: 'Return Management',
      tours: returnManagementTours
    },
    {
      section: 'Shopper Portal',
      tours: shopperPortalTours
    }
  ];
}
