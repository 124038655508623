import { get } from 'utils';

export default function getAggregatorCarriers(
  id,
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  get(
    { apiKey: 'configure.aggregator.carriers_list' },
    { params: { routeParams: { id } } }
  )
    .then(({ data }) => onSuccess(Array.isArray(data) ? data : []))
    .catch(err => onError(err))
    .finally(onFinally);
}
