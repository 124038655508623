import { put } from 'utils';

function holdRequest(params, success, error) {
  const request = put(
    {
      apiKey: 'overview.retex.hold'
    },
    {
      params
    }
  );
  request
    .then(res => {
      success(res);
    })
    .catch(err => {
      error(err);
    });
}

export default holdRequest;
