import React, { useRef } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import 'react-dates/initialize';
import '@coreui/icons/css/coreui-icons.min.css';
import 'ladda/dist/ladda-themeless.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.css';

import {
  LogoutRoute,
  ProtectedRoute,
  UnProtectedRoute,
  OnboardingRoute,
  ForcePasswordChangeRoute
} from './utils/routes';
import {
  ConnectLayout,
  DefaultLayout,
  LoginLayout,
  EmptyLayout,
  ExternalSignIn
} from './containers';
import { DeploymentAlert } from 'components';
import NotSupportedBrowser from './NotSupportedBrowser';
import ForceChangePassword from 'views/ForceChangePassword';
// import subscribeToPusher from './config/setup/__DEPRECATED__subscribeToPusher';

ThemedStyleSheet.registerInterface(aphroditeInterface);

export default function App() {
  // useEffect(() => {
  //   subscribeToPusher();
  // }, []);

  const isNotSupported = useRef(
    navigator.appName === 'Microsoft Internet Explorer' ||
      !!(
        navigator.userAgent.match(/Trident/) ||
        navigator.userAgent.match(/rv:11/)
      )
  ).current;

  if (isNotSupported) {
    return <NotSupportedBrowser />;
  }

  return (
    <>
      <DeploymentAlert />
      <Switch>
        <LogoutRoute path="/logout" />
        <UnProtectedRoute path="/connect-store" component={ConnectLayout} />
        <Redirect from="/connectStore" to="/connect-store" />
        <Route exact path="/signin" component={ExternalSignIn} />
        <UnProtectedRoute exact path="/login" component={LoginLayout} />
        <UnProtectedRoute path="/reset-password" component={LoginLayout} />
        <OnboardingRoute path="/onboarding" component={EmptyLayout} />
        <ForcePasswordChangeRoute
          path="/password-change"
          component={ForceChangePassword}
        />
        <ProtectedRoute path="/" component={DefaultLayout} />
      </Switch>
    </>
  );
}
