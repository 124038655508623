import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default function Value({
  bold = false,
  className = '',
  children = null
}) {
  return (
    <div className={cn(styles.container, { [styles.bold]: bold }, className)}>
      {children}
    </div>
  );
}
