import React, { useState, useEffect, useContext } from 'react';
import _get from 'lodash/get';
import cn from 'classnames';

import { TableSearch } from 'components/Tables';
import { ReactTablesContext } from 'components/ReactTable';

export default function ExplicitSearch({
  customClass = '',
  inputRef = null,
  placeholder = 'Search',
  onReady = () => {},
  hideButton = false,
  ...inputProps
}) {
  const {
    refine,
    clearRefinement,
    refinements
    // searchState: { isFetching }
  } = useContext(ReactTablesContext);
  const searchValue = _get(refinements, 'search', '');
  const [value, setValue] = useState(searchValue);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  useEffect(
    () => {
      // if (!isFetching) {
      onReady();
      // }
    },
    // eslint-disable-next-line
    []
    // [isFetching]
  );

  const submitHandler = e => {
    e.preventDefault();
    const { onBlur = () => {} } = inputProps;
    const callbackFunc = !value ? clearRefinement : refine;
    onBlur();
    callbackFunc({ attribute: 'search', type: 'search', value });
  };

  const onCrossIconClick = () => {
    // if (isFetching) return;
    const value = '';
    setValue(value);
    if (searchValue) {
      clearRefinement({ attribute: 'search', type: 'search', value });
    }
  };

  const className = cn('table-search', customClass);

  return (
    <form className={className} onSubmit={submitHandler}>
      <TableSearch
        inputRef={inputRef}
        value={value}
        onChange={setValue}
        onCancel={onCrossIconClick}
        placeholder={placeholder}
        {...inputProps}
      />
      {!hideButton && (
        <button
          className="table-filter-button"
          // disabled={isFetching}
        >
          Search
        </button>
      )}
    </form>
  );
}
