import React from 'react';
import { AuthConsumer } from 'context/AuthContext';

const connectUser = ConnectedComponent => {
  return props => (
    <AuthConsumer>
      {context => <ConnectedComponent {...props} userData={context.userData} />}
    </AuthConsumer>
  );
};

export default connectUser;
