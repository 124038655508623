import React from 'react';

import { EnterpriseShipping } from './Content';

export const MODAL_GENERATOR = {
  allow_international_order_serviceability: isEnabling => {
    const confirmationMessage = isEnabling
      ? 'You are about to enable the feature. Are you sure?'
      : `You are about to disable "Service International Orders". Your shoppers will not be able to create requests for international orders. This will also disable International Shipping settings in the "Prepaid Shipping Labels" section. Are you sure you want to continue?`;
    const confirmButtonText = isEnabling ? 'Confirm' : 'Continue';
    return { confirmationMessage, confirmButtonText };
  },
  enterprise_shipping: isEnabling => {
    const confirmationMessage = <EnterpriseShipping isEnabling={isEnabling} />;
    const confirmButtonText = isEnabling ? 'Enable' : 'Disable';
    return { confirmationMessage, confirmButtonText };
  }
};
