import React, { useRef } from 'react';
import { Formik, Form } from 'formik';

import { PasswordStrength, UiKit } from 'components';
import Footer from './Footer';
import { formValidatorSchema } from '../stubs';

const INITIAL_VALUES = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
};

export default function PasswordForm({
  loading = false,
  triggerModal = () => {},
  handleSubmit = () => {},
  newPassStrengthtMetCb = () => {},
  isPasswordStrong = false
}) {
  const formikRef = useRef(null);
  const triggerSubmit = () => formikRef.current.submitForm();

  const onSubmit = values => {
    handleSubmit(values, {
      formReference: formikRef.current,
      initialValues: INITIAL_VALUES
    });
  };

  return (
    <div className="ChangePassword">
      <Formik
        ref={formikRef}
        onSubmit={onSubmit}
        initialValues={INITIAL_VALUES}
        validationSchema={formValidatorSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
          /* and other goodies */
        }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <UiKit.Input
                isSecondary
                label="Current Password"
                type="password"
                name="currentPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.currentPassword}
                error={touched.currentPassword && errors.currentPassword}
              />
              <UiKit.Input
                isSecondary
                className="mt-4"
                label="New Password"
                type="password"
                name="newPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                error={touched.newPassword && errors.newPassword}
              />
              <PasswordStrength
                password={values.newPassword}
                onChange={newPassStrengthtMetCb}
              />
              <UiKit.Input
                isSecondary
                className="mt-4"
                label="Confirm Password"
                type="password"
                name="confirmNewPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmNewPassword}
                error={touched.confirmNewPassword && errors.confirmNewPassword}
              />
            </Form>
            <Footer
              processing={loading}
              onCancel={triggerModal}
              onConfirm={triggerSubmit}
              isPasswordStrong={isPasswordStrong}
            />
          </>
        )}
      </Formik>
    </div>
  );
}
