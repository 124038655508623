import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';

export default function Content({ children = null }) {
  const { isExternalUser, externalUserAccess } = useContext(AuthContext);

  return (
    <main
      className={cn('main', {
        [styles.offset]: isExternalUser && externalUserAccess
      })}
    >
      {children}
    </main>
  );
}
