import { useContext, useDebugValue } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';

import { AuthContext } from 'context/AuthContext';
import ADMIN_ROUTES from 'config/nav/admin/routes';

/**
 * @typedef {Object} SubscriptionData
 * @property {"SHOPIFY" | "STRIPE"} source
 * @property {Object | null} subscription
 * @property {boolean} isQuotaExhausted
 * @property {boolean} showSubscriptionModal
 * @property {function} updateSubscription
 * @property {function} refetchSubscription
 * @property {function} onToggleSubscriptionModal
 */

/**
 * @returns {SubscriptionData}
 */
export default function useSubscription() {
  const { push } = useHistory();
  const {
    subscriptionDetails: subscription = null,
    showSubscriptionModal = false,
    updateState = () => {},
    syncSubscription: refetchSubscription = () => {},
    updateSubscriptionDetails: updateSubscription = () => {}
  } = useContext(AuthContext);

  const source = _get(subscription, 'source', '');
  const canShowMetrics = _get(subscription, 'show_usage_metrics', false);
  const processedCount = _get(subscription, 'requests_processed_count', 0);
  const allowedRequestCount = _get(subscription, 'allowed_requests_count', 0);
  const isQuotaExhausted =
    canShowMetrics && processedCount >= allowedRequestCount;

  /**
   * Should only work if
   *   - Free Tier (i.e. `show_usage_metrics` is True), and
   *   - Quota Exhauseted (i.e. isQuotaExhausted is True)
   */
  const onToggleSubscriptionModal = () => {
    if (!canShowMetrics || !isQuotaExhausted) return;
    if (source === 'SHOPIFY') {
      updateState({ showSubscriptionModal: !showSubscriptionModal });
    } else if (source === 'STRIPE') {
      push(ADMIN_ROUTES.SETTINGS.MANAGE_SUBSCRIPTION);
    }
  };

  const data = {
    source,
    subscription,
    isQuotaExhausted,
    updateSubscription,
    refetchSubscription,
    showSubscriptionModal,
    onToggleSubscriptionModal
  };

  useDebugValue(data);

  return data;
}
