import _isArray from 'lodash/isArray';

import { get, logError } from 'utils';

export default function getPlanFeaturesMap(
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  get({ apiKey: 'planFeatures' })
    .then(({ data }) => onSuccess(_isArray(data) ? data : []))
    .catch(err => {
      onError(err);
      logError(err);
    })
    .finally(onFinally);
}
