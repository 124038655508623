import React from 'react';
import classnames from 'classnames';
import { Row, Col, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';

const renderValues = headings => {
  return headings.map(
    (
      { onClick, title, lg, CustomComponent, customClass, CustomCell },
      index
    ) => {
      if (CustomComponent) {
        return (
          <Col
            lg={lg || null}
            key={index}
            className={customClass ? customClass : ''}
          >
            {CustomComponent}
          </Col>
        );
      }
      if (CustomCell) {
        let { title, subTitle } = CustomCell;

        if (title && subTitle) {
          return (
            <Col
              className={`row-tem ${customClass ? customClass : ''}`}
              lg={lg || null}
              key={index}
            >
              <Row className="no-gutters">{title}</Row>
              <Row className="no-gutters">{subTitle}</Row>
            </Col>
          );
        }
      }
      return (
        <Col
          className={
            'row-tem ' +
            (onClick ? ' clickable ' : '') +
            (customClass ? customClass : '')
          }
          onClick={onClick}
          lg={lg || null}
          key={index}
        >
          {title}
        </Col>
      );
    }
  );
};
const CustomTableRow = ({
  href,
  values,
  disabled = false,
  showExtraData = false,
  extraData,
  onClick = () => {},
  customTableRowWrapClass,
  customTableRowClass,
  customTableRowExtraDataClass
}) => {
  if (href) {
    return (
      <Link to={href} className="CustomTable__linkedRow" onClick={onClick}>
        <Row className="no-gutters table-row">{renderValues(values)}</Row>
      </Link>
    );
  }
  const wrapClass = classnames('table-row__wrapper', {
    [customTableRowWrapClass]: customTableRowWrapClass,
    disabled: !!disabled
  });
  const rowClass = classnames('no-gutters', 'table-row', {
    [customTableRowClass]: customTableRowClass
  });
  const collapseClass = classnames('table-row__collapse', {
    [customTableRowExtraDataClass]: customTableRowExtraDataClass
  });
  return (
    <div className={wrapClass} onClick={!disabled ? onClick : () => {}}>
      <Row className={rowClass}>{renderValues(values)}</Row>
      {extraData && (
        <Collapse isOpen={showExtraData} timeout={100}>
          <Row className={collapseClass}>{renderValues(extraData)}</Row>
        </Collapse>
      )}
    </div>
  );
};
export default CustomTableRow;
