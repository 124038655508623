const CHECK_CODES = {
  CHAR12: 'CHAR12',
  UPPR: 'UPPR',
  LWR: 'LWR',
  NUM: 'NUM',
  SPECHAR: 'SPECHAR'
};

const CHECK_LIST_TEXT = {
  [CHECK_CODES.CHAR12]: 'At least 12 characters',
  [CHECK_CODES.UPPR]: 'Upper-case letter',
  [CHECK_CODES.LWR]: 'Lower-case letter',
  [CHECK_CODES.NUM]: 'Number',
  [CHECK_CODES.SPECHAR]: 'Special characters: ( ! @ # $ % ^ & * )'
};

export { CHECK_CODES, CHECK_LIST_TEXT };
