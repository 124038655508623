import React from 'react';
import { Layer, Feature } from 'react-mapbox-gl';

import mapIcon from 'assets/img/map/map-marker.svg';

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageUploaded: false
    };
  }
  handleImageLoad = () => {
    this.setState({
      isImageUploaded: true
    });
  };
  componentDidMount = () => {
    const mapIconElement = new Image();
    mapIconElement.crossOrigin = 'Anonymous';
    mapIconElement.src = mapIcon;
    this.setState({
      mapIconElement
    });
    mapIconElement.onload = this.handleImageLoad;
  };
  render() {
    const { address, draggable, onDragCallback } = this.props;
    const { isImageUploaded, mapIconElement } = this.state;
    if (!address || !address.longitude || !address.latitude) {
      return null;
    }
    // Adding custom images as markers: https://github.com/alex3165/react-mapbox-gl/issues/344
    return (
      <React.Fragment>
        {isImageUploaded && (
          <Layer
            type="symbol"
            id="marker"
            layout={{ 'icon-image': 'theme-map-marker' }}
            images={['theme-map-marker', mapIconElement]}
          >
            <Feature
              coordinates={[address.longitude, address.latitude]}
              draggable={draggable}
              onDragEnd={mapboxEvent => {
                onDragCallback([
                  mapboxEvent.lngLat.lng,
                  mapboxEvent.lngLat.lat
                ]);
              }}
            />
          </Layer>
        )}
      </React.Fragment>
    );
  }
}
