import React from 'react';
import { renderToString } from 'react-dom/server';

import { ProgressIndicator, StepText } from 'containers/TourGuides/components';
import {
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import ADMIN_ROUTES from 'config/nav/admin/routes';
import ROUTES from 'views/Configure/ReturnManagement/RequestReasons/stub/routes';

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getRequestReasonsSteps({
  push = () => {},
  onShow = () => {},
  onHide = () => {}
}) {
  return [
    {
      title: 'Request Reasons',
      text: renderToString(
        <>
          <StepText>
            Request reasons are one of the most important configurations to get
            right in Return Rabbit.
            <br />
            <br />
            This is the list of issues that the shoppers can select from to let
            you know why they're returning any item.
          </StepText>
          <ProgressIndicator current={1} total={8} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.CONFIGURE_RR.RETURN_MANAGEMENT
        });
      },
      buttons: [{ text: "Let's go", type: 'next' }],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: getShepherdSelector('card-request-reasons'),
        on: 'left'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.CONFIGURE_RR.RETURN_MANAGEMENT,
          selector: this.attachTo.element
        });
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText className="mb-1">
            You can even configure sub-reasons within your primary reasons to
            collect more granular data.
            <br />
            You can also have different reasons for different product types.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on <code>Manage</code> button to continue.
          </StepText>
          <ProgressIndicator current={2} total={8} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector('card-request-reasons')} a`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      title: 'Setup Request Reasons',
      text: renderToString(
        <>
          <StepText>
            Here you can view and setup request reasons to find out the answer
            of, why shopper returned an item.
          </StepText>
          <ProgressIndicator current={3} total={8} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: getShepherdSelector('request-reasons-add-new-btn'),
        on: 'left'
      },
      title: 'Create New Reason',
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>Click here to create a new request reason.</StepText>
          <ProgressIndicator current={3} total={8} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.ROOT,
          selector: this.attachTo.element
        });
      },
      advanceOn: {
        selector: `${getShepherdSelector(
          'request-reasons-add-new-btn'
        )} button`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: getShepherdSelector('request-reasons-name'),
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            Request Reason is what your shoppers see in the list of reasons to
            select from while creating a request.
          </StepText>
          <ProgressIndicator current={4} total={8} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.CREATE_REASON,
          selector: this.attachTo.element
        });
      },
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: getShepherdSelector('request-reasons-add-sub-reason'),
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            You can set up a follow up question to find out more about the
            customer's issue with the product.
          </StepText>
          <ProgressIndicator current={5} total={8} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: getShepherdSelector('request-reasons-services'),
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            Select whether you want the shopper to be able to only return, or
            exchange or have the option for both when they selected this request
            reason.
          </StepText>
          <ProgressIndicator current={6} total={8} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: getShepherdSelector('request-reasons-other-requirements'),
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            You can ask your shoppers to upload images if you want for any
            request reason you want.
            <br />
            <br />
            Qualification Approval means that your shoppers would not get the
            shipping label until someone from your team approves that request
            first.
          </StepText>
          <ProgressIndicator current={7} total={8} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: getShepherdSelector('request-reasons-keep-items'),
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            If you enable this flag, then whenever the shopper selects this
            request reason, we'll ask them to not ship the item back but we will
            still refund them back.
          </StepText>
          <ProgressIndicator current={8} total={8} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Done', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    }
  ];
}
