import React from 'react';
import cn from 'classnames';

function Label({ text = '', className = '' }) {
  const wrapperClass = cn('uiKit__element-label', {
    [className]: !!className
  });

  return <div className={wrapperClass}>{text}</div>;
}

export default Label;
