import _get from 'lodash/get';
import _toLower from 'lodash/toLower';

import localStore from 'utils/localStore';

export default function preventErrorLog() {
  const userData = JSON.parse(localStore.get('userData'));
  const email = _toLower(_get(userData, 'email', ''));

  return email.includes('infosec') && email.includes('auctane.com');
}
