import React from 'react';

export default function HomeV2() {
  return (
    <svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1">
      <title>Home 2</title>
      <defs>
        <polygon
          id="path-1"
          points="0 0 14.9396703 0 14.9396703 13.4457032 0 13.4457032"
        ></polygon>
      </defs>
      <g
        id="Version-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Components" transform="translate(-223.000000, -255.000000)">
          <g id="Home-2" transform="translate(223.000000, 255.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path
              d="M2.95063645,5.88264229 L1.86745878,6.86107201 L1.86745878,12.8279412 L1.86745878,12.8277941 C1.86745878,12.9915768 1.9244834,13.148739 2.02612197,13.2647073 C2.12759936,13.3804918 2.26537394,13.4455929 2.40904762,13.4457032 L5.83890608,13.4457032 C5.98257976,13.4455929 6.12035434,13.3804918 6.22183173,13.2647073 C6.32343806,13.148739 6.38049492,12.9915768 6.38049492,12.8277941 L6.38049492,10.3561942 C6.38049492,9.77502861 6.77373593,9.32634564 7.28308925,9.32634564 C7.79244258,9.32634564 8.18568359,9.77502861 8.18568359,10.3561942 L8.18568359,12.8277941 C8.18568359,12.9915768 8.24270821,13.148739 8.34434678,13.2647073 C8.44582417,13.3804918 8.58359875,13.4455929 8.72727242,13.4457032 L12.1571309,13.4457032 C12.3008046,13.4455929 12.4385791,13.3804918 12.5400565,13.2647073 C12.6416629,13.148739 12.6987197,12.9915768 12.6987197,12.8277941 L12.6987197,6.86092489 L11.6155098,5.88249517 L11.6155098,12.2094436 L9.26876455,12.2094436 L9.26876455,10.3557897 C9.26876455,9.11129119 8.37371333,8.09004919 7.28299255,8.09004919 C6.19227177,8.09004919 5.29722054,9.11129119 5.29722054,10.3557897 L5.29722054,12.2094436 L2.95044304,12.2094436 L2.95044304,5.88249517 L2.95063645,5.88264229 Z M7.46867334,8.75325203e-05 C7.35672517,0.00231543907 7.24311778,0.0455297534 7.13279629,0.123860119 L0.209642901,6.06494425 C0.031879226,6.25696857 -0.0931799922,6.67012146 0.0900818642,6.92512835 C0.273311187,7.18013525 0.658867065,7.21426112 0.881396995,6.99942727 L7.46880347,1.34938554 L14.05621,6.99942727 C14.2787399,7.21436721 14.6724617,7.18674824 14.8475576,6.92512835 C15.0012139,6.69554789 14.9634749,6.2562613 14.7279966,6.06494425 L7.80484319,0.123860119 C7.69097553,0.0368303088 7.58078418,-0.00210501043 7.46896614,8.75325203e-05 L7.46867334,8.75325203e-05 Z"
              id="Fill-1"
              style={{ fill: 'var(--icon-color)' }}
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
