import _get from 'lodash/get';

import { isRetexApproved, isRetexRejected } from 'utils/methods';

export default function checkRetexes(retexes = []) {
  const { areAllApproved, isAnyRejected } = retexes.reduce(
    (acc, retex) => {
      const status = _get(retex, 'status', '');
      acc.areAllApproved = acc.areAllApproved && isRetexApproved(status);
      acc.isAnyRejected = acc.isAnyRejected || isRetexRejected(status);
      return acc;
    },
    { areAllApproved: true, isAnyRejected: false }
  );

  return { areAllApproved, isAnyRejected };
}
