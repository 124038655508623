import { ROUTES } from '../stubs';

export default function getBreadcrumbItems(redirectFunc) {
  return [
    {
      label: 'Configure',
      onClick: () => redirectFunc(ROUTES.CONFIGURE)
    },
    {
      label: 'Return Management',
      onClick: () => redirectFunc(ROUTES.RETURN_MANAGEMENT)
    },
    { label: 'Additional Features' }
  ];
}
