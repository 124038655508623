import ADMIN_ROUTES from 'config/nav/admin/routes';

const BASE_URL = ADMIN_ROUTES.ORDERS.ROOT;
const ORDER_DETAILS = `${BASE_URL}/:orderId`;
const CREATE_NEW_REQUEST = `${BASE_URL}/new-request`;

export default {
  HOME: BASE_URL,

  ORDER_DETAILS,
  ORDER_DETAILS_OVERVIEW: `${ORDER_DETAILS}/overview`,
  ORDER_DETAILS_FULFILMENTS: `${ORDER_DETAILS}/fulfillments`,
  ORDER_DETAILS_REQUESTS: `${ORDER_DETAILS}/requests`,

  CREATE_NEW_REQUEST,
  CREATE_NEW_REQUEST_FOR_ORDER: `${CREATE_NEW_REQUEST}/:orderName`,
  CREATE_NEW_REQUEST_DETAILS: `${CREATE_NEW_REQUEST}/:orderName/details/:serviceId`
};
