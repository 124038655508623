import { RETEX_STATUS } from 'dictionary';

export function isRetexApproved(status) {
  return (
    status === RETEX_STATUS.REFUND_SUCCESS ||
    status === RETEX_STATUS.EXCHANGE_SUCCESS
  );
}

export function isRetexRejected(status) {
  return (
    status === RETEX_STATUS.CANCELLED ||
    status === RETEX_STATUS.REFUND_REJECTED ||
    status === RETEX_STATUS.EXCHANGE_REJECTED ||
    status === RETEX_STATUS.WH_AUTHORIZATION_REJECTED ||
    status === RETEX_STATUS.STORE_AUTHORIZATION_REJECTED ||
    status === RETEX_STATUS.QUALIFICATION_APPROVAL_REJECTED
  );
}

export function isRetexCancelled(status) {
  return status === RETEX_STATUS.CANCELLED;
}

export function isRetexAwatingAtAdmin(status) {
  return (
    status === RETEX_STATUS.AWAITING_QUALIFICATION_APPROVAL ||
    status === RETEX_STATUS.AWAITING_EXCHANGE_APPROVAL ||
    status === RETEX_STATUS.AWAITING_REFUND_APPROVAL
  );
}
