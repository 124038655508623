import { localStore, DICTIONARY } from 'utils';

let titleText = {
  AWAITING_PAYMENT_COMPLETION: 'Awaiting Payment',
  AWAITING_WH_AUTHORIZATION: 'At the warehouse',
  AWAITING_REFUND_APPROVAL: 'Refund Approval',
  AWAITING_STORE_AUTHORIZATION: 'At the store',
  AWAITING_EXCHANGE_APPROVAL: 'Exchange Approval',
  AWAITING_PAYMENT_RESOLUTION: 'Awaiting Resolution'
};

export default function mapTimelineData(timelineData) {
  let parseDictionary = JSON.parse(localStore.get('dictionary'));
  let rejectedStatus = parseDictionary.rejected_service_request_status;
  let inProgressStatus = parseDictionary.inprogress_request_status;
  let updateTimelineData = [],
    lastElementStatus;
  let requestTimelineData = timelineData.retex_timeline;
  let orderTimelineData = timelineData.order_timeline;
  if (requestTimelineData.length > 0) {
    updateTimelineData = requestTimelineData.map((data, index) => {
      if (requestTimelineData.length - 1 === index) {
        let status = rejectedStatus.includes(data.to_status)
          ? 'rejected'
          : 'success';
        return { ...data, status };
      } else {
        return { ...data, status: 'success' };
      }
    });
    lastElementStatus =
      requestTimelineData[requestTimelineData.length - 1].to_status;
    if (inProgressStatus.includes(lastElementStatus)) {
      updateTimelineData = [
        ...updateTimelineData,
        {
          action:
            titleText[lastElementStatus] ||
            DICTIONARY('retexStatusChoices.' + lastElementStatus),
          status: 'awaiting',
          action_by: null,
          action_time: null,
          to_status: null
        }
      ];
    }
  }

  const timeline = [...orderTimelineData, ...updateTimelineData];
  timeline.reverse();
  return timeline;
}
