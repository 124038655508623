import React, { useContext } from 'react';
import logout from 'assets/img/icons/logout.svg';
import profile from 'assets/img/icons/profile.svg';
import _startCase from 'lodash/startCase';
import { AppHeader } from '@coreui/react';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledTooltip,
  UncontrolledButtonDropdown
} from 'reactstrap';
import { AuthContext } from 'context/AuthContext';
import { Link } from 'react-router-dom';

const Header = () => {
  const { userData = {} } = useContext(AuthContext);

  const { avatarPicUrl, firstName, lastName, email } = userData;

  const avatar =
    avatarPicUrl || `https://ui-avatars.com/api/?name=${firstName} ${lastName}`;

  return (
    <AppHeader fixed id="empty-header">
      <Nav navbar className="ml-auto mr-3 pr-1">
        <UncontrolledButtonDropdown>
          <DropdownToggle nav className="name-block">
            <div className="name-col">{_startCase(firstName)}</div>
            <img src={avatar} className="img-avatar" alt="Admin" />
          </DropdownToggle>
          <DropdownMenu right className="display__dropdown">
            <DropdownItem
              header
              className="dropdown-item"
              id="user-email-dropdown"
            >
              <img src={profile} alt="profile" />
              <span href="#">{email} </span>
              <UncontrolledTooltip
                placement="right"
                target="user-email-dropdown"
              >
                {email}
              </UncontrolledTooltip>
            </DropdownItem>

            <Link to="/logout">
              <DropdownItem className="dropdown-item">
                <img src={logout} alt="logout" />
                Logout
              </DropdownItem>
            </Link>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Nav>
    </AppHeader>
  );
};

export default Header;
