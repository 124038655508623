import React, { useMemo } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import Card from './Card';
import { INFO, THEME } from './stub';

export default function FeatureInfo({ featureId = '', featureSlug = '' }) {
  const id = featureId || featureSlug;
  const data = INFO[id];
  const theme = _get(data, 'theme', THEME.RED);

  const stackCards = useMemo(() => {
    return Object.values(THEME).map(sideTheme => {
      if (sideTheme === theme) return null;

      const [key, dataset] = Object.entries(INFO).find(([_, info]) => {
        return sideTheme === _get(info, 'theme');
      });
      const cardData = { ...dataset, docUrl: '' };
      return <Card key={key} data={cardData} className={styles.stackCard} />;
    });
  }, [theme]);

  return (
    <div className={styles.container}>
      <Card data={data} className={styles.mainCard} />
      <div className={styles.stack}>{stackCards}</div>
    </div>
  );
}
