import { useContext, useEffect } from 'react';
import { ShepherdTourContext } from 'react-shepherd';

// import { USER_GUIDING } from 'dictionary';
import { AuthContext } from 'context/AuthContext';
import { acknowledgeNotification } from 'utils/services/notifications';

const IS_TESTING = false; // FOT TESTING PURPOSE ONLY

export default function useWelcomeTour() {
  const tour = useContext(ShepherdTourContext);
  const { isSuperuser, showTour, isTourShown, updateState } = useContext(
    AuthContext
  );

  // Worked with "User Guiding" 👇
  // useEffect(() => {
  //   if (isSuperuser && showTour && window.userGuiding) {
  //     window.userGuiding.previewGuide(USER_GUIDING.RR_TOUR_GUIDE_ID);
  //     if (!isTourShown) {
  //       acknowledgeNotification({ user_guiding: { enabled: false } }, () => {
  //         updateState({ isTourShown: true, showTour: false });
  //       });
  //     } else {
  //       updateState({ showTour: false });
  //     }
  //   }
  // }, [isSuperuser, showTour, isTourShown, updateState]);

  useEffect(() => {
    if (isSuperuser && showTour) {
      tour.start();
      if (!isTourShown) {
        acknowledgeNotification({ user_guiding: { enabled: false } }, () => {
          updateState({ isTourShown: true, showTour: false });
        });
      } else {
        updateState({ showTour: false });
      }
    }
  }, [tour, isSuperuser, showTour, isTourShown, updateState]);

  useEffect(() => {
    if (IS_TESTING) {
      acknowledgeNotification({ user_guiding: { enabled: true } });
    }
  }, []);
}
