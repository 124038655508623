import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import _get from 'lodash/get';

import {
  useFetch,
  useMutation,
  useNotifications,
  useSubscription
} from 'utils/hooks';
import {
  configureRetexPlans,
  getAllPlans,
  getMaxSavingsInBilling,
  getPlansOrderMap
} from 'utils/methods';
import { PLAN_TERM } from 'dictionary';
import { AuthContext } from 'context/AuthContext';

const SUBSCRIPTION_API_KEY = {
  SHOPIFY: 'billing.shopify.subscribe',
  STRIPE: 'billing.stripe.subscribe'
};

export default function useBilling({
  loadOnMount = true,
  payload = {},
  onSubscribed = () => {},
  onSubscriptionError = () => {},
  onSubscriptionFinally = () => {}
} = {}) {
  const {
    appType,
    featuresPlanMap,
    uniqueCategoryPlanFeaturesMap
  } = useContext(AuthContext);
  const { source, subscription, refetchSubscription } = useSubscription();
  const { fetchUnreadListWithTimerReset } = useNotifications();

  const [plans, setPlans] = useState([]);
  const [subscribedPlan, setSubscribedPlan] = useState(
    _get(subscription, 'plan_info')
  );
  const [processingPlan, setProcessingPlan] = useState(null);
  const [subscribedPlanMode, setSubscribedPlanMode] = useState(null);
  const [showDowngradeAlert, setShowDowngradeAlert] = useState(false);
  const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);

  const freezeRef = useRef(false);
  const allPlansRef = useRef([]);
  const inititalPlansRef = useRef([]);
  const plansOrderMapRef = useRef({});
  const downgradePlanSlugRef = useRef(null);
  const referrerRef = useRef(null);
  const eligibleTrialRef = useRef(null);
  const enterpriseNotifiedRef = useRef(false);
  const processingDataRef = useRef(null);

  const isTrackingApp = _get(appType, 'shipmentTracking', false);

  const { isError, isLoading, fetchData } = useFetch('billing.plans', {
    loadOnMount,
    errorMessage: 'Unable to load billing plans at the moment.',
    initialData: [],
    onSuccess: data => {
      const plans = _get(data, 'plans', []);
      const currentPlan = _get(data, 'current_plan', null);
      const paymentMode = _get(data, 'billing_mode', null);
      const isEnterpriseNotified = _get(data, 'enterprise_notified', false);
      const currentPlanName = _get(currentPlan, 'slug', '');
      const isOnFreePlan = currentPlanName === 'free';

      const billingPlans = configureRetexPlans({
        plans,
        currentPlan,
        isTrackingApp,
        featuresPlanMap,
        uniqueCategoryPlanFeaturesMap
      });

      inititalPlansRef.current = plans;
      referrerRef.current = _get(data, 'referrer', null);
      eligibleTrialRef.current = _get(data, 'merchant_eligible_trial', null);
      enterpriseNotifiedRef.current = isEnterpriseNotified;
      plansOrderMapRef.current = getPlansOrderMap(plans, isTrackingApp);
      allPlansRef.current = getAllPlans(billingPlans, plans, isTrackingApp);

      setPlans(billingPlans);
      setSubscribedPlan(currentPlan);
      setSubscribedPlanMode(isOnFreePlan ? PLAN_TERM.YEARLY : paymentMode);
    }
  });

  const { mutate: onSetupSubscription } = useMutation(
    SUBSCRIPTION_API_KEY[source],
    'POST',
    {
      payload,
      errorMessage: `Unable to subscribe to the plan at the moment. Please try again later.`,
      onSuccess: data => {
        const slug = _get(processingDataRef.current, 'slug', '');
        const redirectUrl = _get(data, 'redirect_url', '');
        const paymentMode = _get(data, 'payment_mode', PLAN_TERM.YEARLY);
        const isEnterpriseNotified = _get(data, 'enterprise_notified', false);

        if (redirectUrl) {
          freezeRef.current = true;
          return window.open(redirectUrl, '_top');
        }

        const isSubscribedToFreePlan = slug === 'free';
        const isSubscribedToEnterprisePlan = slug === 'enterprise';
        const newPlan = allPlansRef.current.find(
          plan => _get(plan, 'slug') === slug
        );
        const newMode = isSubscribedToFreePlan ? PLAN_TERM.YEARLY : paymentMode;

        if (!isSubscribedToEnterprisePlan) {
          const billingPlans = configureRetexPlans({
            plans: inititalPlansRef.current,
            currentPlan: { slug },
            isTrackingApp,
            featuresPlanMap,
            uniqueCategoryPlanFeaturesMap
          });
          setPlans(billingPlans);
          setSubscribedPlan(newPlan);
          setSubscribedPlanMode(newMode);
        }

        enterpriseNotifiedRef.current = isEnterpriseNotified;
        setShowEnterpriseModal(isSubscribedToEnterprisePlan);
        setShowDowngradeAlert(false);
        onSubscribed(data);
        fetchUnreadListWithTimerReset();
        refetchSubscription(true);
      },
      onError: onSubscriptionError,
      onFinally: () => {
        onSubscriptionFinally();
        if (!freezeRef.current) {
          processingDataRef.current = null;
          setProcessingPlan(null);
        }
      }
    }
  );

  const onSubscribe = ({
    slug = null,
    payload = {},
    skipValidation = false
  } = {}) => {
    if (!slug) return;

    if (!skipValidation) {
      const currentPlanSlug = _get(subscribedPlan, 'slug', '');
      const newPlanIndex = plansOrderMapRef.current[slug];
      const currentPlanIndex = plansOrderMapRef.current[currentPlanSlug];
      const isDowngrade = newPlanIndex < currentPlanIndex;

      if (isDowngrade) {
        downgradePlanSlugRef.current = slug;
        setShowDowngradeAlert(true);
        return false;
      }
    }

    processingDataRef.current = { slug };
    setProcessingPlan(slug);
    onSetupSubscription(payload);
  };

  const onCancelDowngrade = useCallback(() => setShowDowngradeAlert(false), []);

  const onCloseEnterpriseModal = useCallback(() => {
    setShowEnterpriseModal(show => !show);
  }, []);

  const subscribedPlanIndex = useMemo(() => {
    const subscribedPlanSlug = _get(subscribedPlan, 'slug', '');
    return plans.findIndex(({ slug }) => slug === subscribedPlanSlug);
  }, [plans, subscribedPlan]);

  const { maxSavings, planPriceVariantMap } = useMemo(
    () => getMaxSavingsInBilling(plans),
    [plans]
  );

  return {
    plans,
    isError,
    isLoading,
    subscribedPlan,
    subscribedPlanMode,
    subscribedPlanIndex,
    showDowngradeAlert,
    showEnterpriseModal,
    maxSavings,
    planPriceVariantMap,
    referrer: referrerRef.current,
    eligibleTrial: eligibleTrialRef.current,
    processingPlan,
    downgradePlanSlug: downgradePlanSlugRef.current,
    isNotifiedForEnterprise: enterpriseNotifiedRef.current,
    onReload: fetchData,
    onCancelDowngrade,
    onSubscribe,
    onCloseEnterpriseModal
  };
}
