import React, { useRef, useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import _isString from 'lodash/isString';
import styles from './index.module.scss';

export default function OverflowText({
  text = '',
  id = '',
  trimLength = 100,
  preventLineBreak = false,
  className = '',
  tooltipClassName = ''
}) {
  const elementId = useRef(id || `text-${uuidv4()}`).current;
  const textClass = cn(styles.text, className, {
    [styles.oneLiner]: preventLineBreak
  });
  const tooltipClass = cn(styles.tooltip, tooltipClassName);

  const { isTrimmed, trimmedText } = useMemo(() => {
    if (!_isString(text)) return { isTrimmed: false, trimmedText: '' };
    const isTrimmed = text.length > trimLength;
    const trimmedText = isTrimmed
      ? `${text.substring(0, trimLength)}...`
      : text;

    return { isTrimmed, trimmedText };
  }, [text, trimLength]);

  const showTooltip = isTrimmed || preventLineBreak;

  return (
    <>
      <p id={elementId} className={textClass} data-trimmed={isTrimmed}>
        {trimmedText}
      </p>
      {showTooltip && (
        <UncontrolledTooltip target={elementId} innerClassName={tooltipClass}>
          {text}
        </UncontrolledTooltip>
      )}
    </>
  );
}
