import React, { useEffect } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { useAccess, useFeatures } from 'utils/hooks';
import { Toggle, UiKit } from 'components';
import Loader from './Loader';

/**
 * @param {Object} props
 * @param {string} props.featureType - Feature type
 * @param {import("dictionary").FEATURE_SLUGS} props.featureSlug - Feature slug
 * @param {Function} props.isActiveCallback - Callback function to get the active status
 */
export default function FeatureFlagToggle({
  featureType = '',
  featureSlug = '',
  isActiveCallback = () => {}
}) {
  const {
    features,
    isLoading,
    isError,
    isConfirmModalOpen,
    isProcessing,
    openConfirmModal,
    closeConfirmModal,
    toggleFeature
  } = useFeatures({ includeFeatures: [featureType] });
  const { syncSubscription, hasSubFeatureAccess } = useAccess();

  const hasAccess = hasSubFeatureAccess(featureSlug);
  const feature = _get(features, '[0]', null);
  const id = _get(feature, 'id', null);
  const title = _get(feature, 'title', '');

  const isEnabled = hasAccess && _get(feature, 'is_enabled', false);
  const confirmButtonType = isEnabled ? 'danger' : 'success';
  const confirmButtonText = isEnabled ? 'Disable' : 'Enable';
  const confirmButtonProcessingText = isEnabled
    ? 'Disabling...'
    : 'Enabling...';

  useEffect(() => {
    syncSubscription();
  }, [syncSubscription]);

  useEffect(() => {
    isActiveCallback(isEnabled);
  }, [isEnabled, isActiveCallback]);

  if (!featureType || isError) return null;

  return (
    <Loader isLoading={isLoading}>
      <div
        className={cn(styles.container, { [styles.active]: isEnabled })}
        onClick={() => openConfirmModal(id)}
      >
        <Toggle isActive={isEnabled} />
        <span className={styles.label}>Activate</span>
      </div>
      <UiKit.Modal
        title={title}
        isOpen={isConfirmModalOpen}
        isProcessing={isProcessing}
        onClose={closeConfirmModal}
        onConfirm={toggleFeature}
        confirmBtnType={confirmButtonType}
        confirmBtnText={confirmButtonText}
        confirmBtnProcessingText={confirmButtonProcessingText}
      >
        <p>
          Are you sure you want to {isEnabled ? 'disable' : 'enable'} this
          feature?
        </p>
      </UiKit.Modal>
    </Loader>
  );
}
