import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip, Collapse } from 'reactstrap';
import { hex } from 'wcag-contrast';
import cn from 'classnames';

import { ReactComponent as LowContrastIcon } from 'assets/img/icons/optional.svg';
import { ReactComponent as GoodContrastIcon } from 'assets/img/icons/check-green.svg';

export default function ColorContrast({
  label = 'Color Contrast',
  color = '#ffffff',
  backgroundColor = '#ffffff',
  className = '',
  showTooltip = false,
  tooltipPosition = 'top',
  lowContrastThreshold = 3,
  showLowContrastMessage = false,
  lowContrastMessage = 'Your color contrast is very low. If you choose to use this color, some parts may be difficult to distingush.'
}) {
  const [contrastRatio, setContrastRatio] = useState(null);

  useEffect(() => {
    const contrast = hex(backgroundColor, color).toFixed(2);
    setContrastRatio(contrast);
  }, [color, backgroundColor]);

  const wrapClassName = cn('ThemeSettings__colorContrast', {
    [className]: !!className
  });

  const labelClassName = cn('ThemeSettings__colorContrast--label', {
    [`${className}--label`]: !!className
  });

  return (
    <>
      <div className={wrapClassName}>
        <div className={labelClassName}>{label}</div>
        <div className="ThemeSettings__colorContrast--value">
          <span>{contrastRatio}</span>
          <span id="contrast-ratio">
            {contrastRatio > 3 ? <GoodContrastIcon /> : <LowContrastIcon />}
          </span>
        </div>
        {showTooltip && (
          <UncontrolledTooltip
            fade={false}
            placement={tooltipPosition}
            target="contrast-ratio"
          >
            {contrastRatio < 3
              ? 'Consider changing the text color'
              : 'Contrast is looking good'}
          </UncontrolledTooltip>
        )}
      </div>
      <Collapse
        isOpen={showLowContrastMessage && contrastRatio < lowContrastThreshold}
      >
        <div className="flex-full almost-black mt-1">
          <strong>{lowContrastMessage}</strong>
        </div>
      </Collapse>
    </>
  );
}
