import React from 'react';
import { renderToString } from 'react-dom/server';

import { ProgressIndicator, StepText } from 'containers/TourGuides/components';
import {
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import ADMIN_ROUTES from 'config/nav/admin/routes';

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getReturnManagementSteps({
  push = () => {},
  onShow = () => {},
  onHide = () => {}
}) {
  return [
    {
      id: 'intro',
      title: 'Return Management Settings',
      text: renderToString(
        <>
          <StepText>
            This is where you can control all your store policies.
            <br />
            <br />
            Let's take a look at few now!
          </StepText>
          <ProgressIndicator current={1} total={5} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.CONFIGURE_RR.RETURN_MANAGEMENT
        });
      },
      buttons: [{ text: "Let's go", type: 'next' }],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: `${getShepherdSelector('card-shopper-groups')}`,
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>
            You can group your shoppers based on their order, return or
            exhanging patterns, or location.
            <br />
            <br />
            These groups can then be used to offer additional incentives or levy
            additional fees.
          </StepText>
          <ProgressIndicator current={2} total={5} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'fees-and-incentives',
      attachTo: {
        element: `${getShepherdSelector('card-fees-and-incentives')}`,
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            This section provides you complete visibility on the exisiting fees
            or incentives. You can also control the values from here.
          </StepText>
          <ProgressIndicator current={3} total={5} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: `${getShepherdSelector('card-tag-based-rules')}`,
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            This section allows you to configure your policies by leveraging
            various entities like tags on orders/products or discount codes. You
            can even fine tune to allow only exchanges.
            <br />
            <br />
            You can also configure tags to allow shoppers to keep the items if
            you want.
          </StepText>
          <ProgressIndicator current={4} total={5} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      attachTo: {
        element: `${getShepherdSelector('card-disposition')}`,
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            This section allows you to configure whether or not you want Return
            Rabbit to restock items back into your inventory when processing
            refunds or exchanges.
            <br />
            <br />
            You can still override this per request if you want before the final
            refund/exchange approval.
          </StepText>
          <ProgressIndicator current={5} total={5} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Done', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    }
  ];
}
