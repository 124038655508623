import React, { useContext } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from '../index.module.scss';

import { OS_STATUS } from 'dictionary';
import ResendOsEmail, { RESEND_EMAIL_TYPES } from 'components/ResendOsEmail';
import ExternalLink from 'components/OrderServiceDetails/components/ExternalLink';
import GeneratingLabel from './GeneratingLabel';
import RegenerateLabel from './RegenerateLabel';
import { OrderServiceContext } from 'components/OrderServiceDetails';
import { getResendShippingEmailButtonState } from 'components/OrderServiceDetails/methods';
import { ReactComponent as LinkIcon } from 'assets/img/icons/external-link.svg';

const LABEL_TYPE_MAP = {
  regular: 'Label',
  qr_code: 'QR'
};

export default function Actions() {
  const { orderService } = useContext(OrderServiceContext);

  const osId = _get(orderService, 'id', '');
  const status = _get(orderService, 'status', '');
  const shippingInfo = _get(orderService, 'shipping_info', {});
  const shippingAddress = _get(orderService, 'shipping_address', '');

  const customerEmail = _get(shippingAddress, 'email', '');
  const shippingMethod = _get(shippingInfo, 'method', '');
  const serviceLabel = _get(shippingInfo, 'service_label', '');
  const shipmentLabel = _get(shippingInfo, 'shipment_label', '');
  const labelType = _get(shippingInfo, 'label_type', '');
  const labelError = _get(shippingInfo, 'shipment_label_error_message', '');

  const isSendLabel = shippingMethod === 'SEND_LABEL';
  const needQualification = status === OS_STATUS.PENDING_QUALIFICATION;
  const willCreateLabel = !needQualification && isSendLabel && !shipmentLabel;
  const hasLabelFailed = willCreateLabel && labelError;
  const isGeneratingLabel = willCreateLabel && !labelError;

  const {
    isDisabled: isEmailTriggerDisabled,
    tooltipMessage: emailTriggerTooltipMessage
  } = getResendShippingEmailButtonState(orderService);

  if (isGeneratingLabel) {
    return <GeneratingLabel />;
  } else if (hasLabelFailed) {
    return <RegenerateLabel />;
  }
  return (
    <>
      <ExternalLink
        href={shipmentLabel}
        className={cn(styles.action, styles.inline)}
        text={
          <>
            <span>Shipping {LABEL_TYPE_MAP[labelType] || 'Label'}</span>
            <LinkIcon className={styles.linkIcon} />
          </>
        }
      />
      <ExternalLink
        href={serviceLabel}
        className={cn(styles.action, styles.inline)}
        text={
          <>
            <span>Packing Slip</span>
            <LinkIcon className={styles.linkIcon} />
          </>
        }
      />
      <ResendOsEmail
        osId={osId}
        customerEmail={customerEmail}
        type={RESEND_EMAIL_TYPES.OS_CONFIRMATION}
        isDisabled={isEmailTriggerDisabled}
        tooltipMessage={emailTriggerTooltipMessage}
        buttonClassName={styles.action}
      />
    </>
  );
}
