import _get from 'lodash/get';

import { OS_STATUS } from 'dictionary';

export default function getResendShippingEmailButtonState(orderService) {
  const status = _get(orderService, 'status', '');
  const shippingMethod = _get(orderService, 'shipping_info.method', '');
  const labelURL = _get(orderService, 'shipping_info.shipment_label', '');

  const data = {
    isDisabled: false,
    tooltipMessage: ''
  };

  if (status === OS_STATUS.PENDING_QUALIFICATION) {
    data.isDisabled = true;
    data.tooltipMessage = 'Please qualify the request to send this email';
  } else if (shippingMethod === 'SEND_LABEL' && !labelURL) {
    data.isDisabled = true;
    data.tooltipMessage = 'Please wait, shipment label is being generated';
  }

  return data;
}
