import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { getPaymentType, isOsPendingPayment } from 'utils/methods';
import { Highlight } from 'components/ReactTable';
import { CustomTableRow, OverflowMenu } from 'components/Tables/CustomTable';
import { RequestAction } from 'views/Requests/components';
import LineItemsTableRows from '../LineItemsTableRows';
import RequestStatus from '../../RequestStatus';
import { useRetexes } from 'views/Requests/hooks';
import { hasRequestTerminated, formatPrice } from 'views/Requests/methods';
import { ROUTES } from 'views/Requests/stubs';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/down-only.svg';
import { UiKit } from 'components';

export default function RequestsTableRow({
  data = {},
  hideCTA = false,
  hideArrow = false,
  isExpanded = false,
  isDisabled = false,
  onExpand = () => {},
  onDisable = () => {},
  renderRetexTitle = () => null,
  generateRowData = () => null,
  generateRetexRowData = () => null,
  onBeforeProcessing = () => {},
  onAfterProcessing = () => {},
  onOpenCancelHoldModal = () => {}
}) {
  const [processingId, setProcessingId] = useState(null);
  const { retexes, isFetching, onUpdateRetex } = useRetexes(data, isExpanded);

  const id = _get(data, 'id', '');
  const osName = _get(data, 'name', '');
  const orderName = _get(data, 'order_name', '');
  const status = _get(data, 'status', '');
  const amount = _get(data, 'amount', 0);
  const currency = _get(data, 'currency', '');
  const isOnHold = _get(data, 'on_hold', false);
  const isPendingPayment = isOsPendingPayment(status);
  const isRequestTerminated = hasRequestTerminated(status);

  const isLocked = isOnHold && !isRequestTerminated;
  const paymentType = getPaymentType(amount);
  const formattedPrice = formatPrice(Math.abs(amount), currency);
  const requestDetailsUrl = ROUTES.REQUEST_DETAILS.replace(':osId', id);
  const titleClass = cn('CustomCell-title', 'truncate', styles.compact);
  const subTitleClass = cn('CustomCell-subTitle', 'truncate', styles.compact);
  const arrowIconClass = cn(styles.arrowIcon, { [styles.active]: isExpanded });
  const containerClass = cn(styles.container, {
    [styles.locked]: isLocked,
    [styles.expanded]: isExpanded
  });
  const rowClass = cn(styles.osRow, { [styles.disabled]: isDisabled });

  const onPostApproval = useCallback(() => {
    setProcessingId(null);
    onDisable(false);
    onAfterProcessing(true);
  }, [onDisable, onAfterProcessing]);

  const DEFAULT_ROW_DATA = [
    {
      lg: 3,
      customClass: styles.leftOffset,
      CustomCell: {
        title: (
          <Link to={{ pathname: requestDetailsUrl, state: { osName } }}>
            <Highlight data={data} attribute="name" className="link-text" />
          </Link>
        ),
        subTitle: <div className={subTitleClass}>{orderName}</div>
      }
    },
    {
      lg: 2,
      CustomCell: {
        title: (
          <div className={titleClass}>
            <Highlight
              data={data}
              attribute="customer_name"
              className="text-capitalize"
            />
          </div>
        ),
        subTitle: (
          <div className={subTitleClass}>
            <Highlight data={data} attribute="customer_email" />
          </div>
        )
      }
    },
    {
      lg: 2,
      customClass: styles.amount,
      CustomCell: {
        title: formattedPrice,
        subTitle: <div className={subTitleClass}>{paymentType}</div>
      }
    },
    {
      lg: 3,
      customClass: 'light-text',
      title: <RequestStatus status={status} isLocked={isLocked} />
    }
  ];

  const overflowMenuItems = [];
  const disableMenu = isRequestTerminated || isPendingPayment;
  const values = generateRowData(data, isExpanded) || DEFAULT_ROW_DATA;

  if (!disableMenu) {
    if (!isLocked) {
      overflowMenuItems.push({
        title: 'Lock Request',
        onClick: () => {
          onOpenCancelHoldModal({
            type: 'hold',
            orderServiceID: id,
            extra: { holdVal: true }
          });
        }
      });
    }
    overflowMenuItems.push({
      title: 'Cancel Request',
      onClick: () => {
        onOpenCancelHoldModal({
          type: 'cancel',
          orderServiceID: id,
          orderServiceStatus: status
        });
      }
    });
  }

  values.push({
    lg: hideCTA ? 1 : 2,
    CustomComponent: (
      <UiKit.GridRow templateColumns="1fr auto" className="align-items-center">
        <div className={styles.buttonWrapper}>
          {!hideCTA && (
            <RequestAction
              isCompact
              data={data}
              className="w-100"
              retexes={retexes}
              isDisabled={isDisabled}
              isRefreshing={isFetching}
              onBeforeProcessing={onBeforeProcessing}
              onSetProcessingId={setProcessingId}
              onRetexUpdate={onUpdateRetex}
              onAfterProcessing={onPostApproval}
            />
          )}
        </div>
        <OverflowMenu
          alignToRight
          isDisabled={disableMenu}
          items={overflowMenuItems}
          className={styles.menu}
          onClick={e => e.stopPropagation()}
        />
      </UiKit.GridRow>
    )
  });

  return (
    <div className={containerClass}>
      {!hideArrow && <ArrowIcon className={arrowIconClass} />}
      <CustomTableRow
        key={id}
        values={values}
        onClick={() => onExpand(id)}
        customTableRowClass={rowClass}
        customTableRowWrapClass={styles.osRowWrapper}
      />
      <LineItemsTableRows
        isOpen={isExpanded}
        osId={id}
        osName={osName}
        retexes={retexes}
        isFetching={isFetching}
        processingId={processingId}
        onDisable={onDisable}
        onUpdateRetex={onUpdateRetex}
        isDisabled={isDisabled || isOnHold}
        generateRowData={generateRetexRowData}
        renderRetexTitle={renderRetexTitle}
        onAfterProcessing={onAfterProcessing}
      />
    </div>
  );
}
