/**
 * @param {HTMLElement} el Step Highlight element
 * @param {ScrollIntoViewOptions} scrollOptions Scroll options to override default scroll behavior
 */
export default function scrollToHandler(el, scrollOptions) {
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      ...scrollOptions
    });
  }
}
