import React, { useContext, useRef } from 'react';
import uuid from 'uuid/v4';
import styles from './index.module.scss';

import { HeaderContext } from 'context/HeaderContext';
import { InfoLink } from 'components/Tables/CustomTable';
import { ReactComponent as CrossIcon } from 'assets/img/icons/cross.svg';
import { Skeleton } from 'components';
import { If } from 'utils';

export default function CustomHeader() {
  const { title, docUrl, onCancel } = useContext(HeaderContext);
  const id = useRef(`info-${uuid()}`).current;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <If
          test={title}
          otherwise={<Skeleton width="200px" height="16px" className="m-0" />}
        >
          <div className={styles.title}>{title}</div>
          <InfoLink
            url={docUrl}
            id={id}
            placement="right"
            className={styles.info}
          />
        </If>
      </div>
      <CrossIcon className={styles.crossIcon} onClick={onCancel} />
    </div>
  );
}
