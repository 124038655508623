import ROUTES from 'views/Configure/stubs/routes';

const BASE_URL = ROUTES.SHIPPING_METHODS;

export default {
  CONFIGURE: ROUTES.HOME,
  SHIPPING: ROUTES.SHIPPING,
  HOME: BASE_URL,
  SHIPPING_METHOD_DETAILS_EDIT: `${BASE_URL}/:id/edit`,
  SHIPPING_METHOD_DETAILS: `${BASE_URL}/:id`
};
