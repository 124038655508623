import React, { useState, useMemo, useRef, useEffect } from 'react';
import classnames from 'classnames';
import _get from 'lodash/get';
import './index.scss';

import { UiKit } from 'components';
import { isValidEmail } from 'utils/validators';
import EmailBadges from './EmailBadges';

export default function MailingList({
  label = '',
  emails = {},
  error = '',
  disabled = false,
  inputRef = null,
  onUpdate = () => {},
  labelClassName = '',
  wrapperClassName = '',
  contentClassName = '',
  inputWrapClassName = '',
  inputClassName = '',
  emailsBlockClassName = '',
  placeholder = 'Type in the e-mail addresses separated by comma',
  emptyBlockMsg = 'No recipients added.'
}) {
  const [email, setEmail] = useState('');
  const [isPasted, setIsPasted] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState('');
  const inputContainerRef = useRef();
  const pasteDataRef = useRef(null);

  useEffect(() => {
    if (inputContainerRef.current) {
      const input = inputContainerRef.current.querySelector('input');
      const onPaste = e => {
        const emails = e.clipboardData.getData('text/plain');
        pasteDataRef.current = emails.split(',').reduce(
          (acc, email) => {
            const emailAddress = email.trim();
            const isValid = isValidEmail(emailAddress);
            if (isValid) {
              acc.validEmailsData[emailAddress] = true;
            } else {
              acc.invalidEmails.push(emailAddress);
            }
            return acc;
          },
          { validEmailsData: {}, invalidEmails: [] }
        );
        setIsPasted(true);
      };
      input.addEventListener('paste', onPaste);
      return () => document.removeEventListener('paste', onPaste);
    }
  }, []);

  useEffect(() => {
    if (isPasted) {
      const timer = setTimeout(() => {
        const emailsData = _get(pasteDataRef.current, 'validEmailsData', {});
        const invalidEmails = _get(pasteDataRef.current, 'invalidEmails', []);
        const inputText = invalidEmails.join(', ');
        setEmail(inputText);
        setIsEmailInvalid(!!inputText);
        onUpdate({ ...emailsData, ...emails });
        setIsPasted(false);
        clearTimeout(timer);
      }, 0);
    }
  }, [isPasted, emails, onUpdate]);

  const inputChangeHandler = e => {
    setEmail(e.target.value);
    setIsEmailInvalid(false);
  };

  const addEmail = email => {
    if (disabled) return;
    onUpdate({ [email]: true, ...emails });
  };

  const deleteEmail = mail => {
    if (disabled) return;
    const addedMails = { ...emails };
    delete addedMails[mail];
    onUpdate(addedMails);
  };

  const pickEmailAddress = e => {
    // if user presses comma (188) on keyboard || enter (13) key
    if (e.which === 188 || e.which === 13) {
      const email = e.target.value
        .trim()
        .split(',')
        .join('');
      const isEmailValid = isValidEmail(email);
      setIsEmailInvalid(!isEmailValid);
      if (isEmailValid) {
        setEmail('');
        addEmail(email);
      }
    }
  };

  const wrapperClass = useMemo(() => {
    return classnames('mailingList', {
      [wrapperClassName]: !!wrapperClassName
    });
  }, [wrapperClassName]);

  const contentClass = useMemo(() => {
    return classnames('mailingList__emails', {
      [contentClassName]: !!contentClassName
    });
  }, [contentClassName]);

  const inputWrapClass = useMemo(() => {
    return classnames('mailingList__emailInput', {
      [inputWrapClassName]: !!inputWrapClassName,
      error: isEmailInvalid
    });
  }, [isEmailInvalid, inputWrapClassName]);

  const errorMessage = useMemo(() => {
    if (isEmailInvalid) return 'Please enter a valid email address';
    return error;
  }, [error, isEmailInvalid]);

  return (
    <div className={wrapperClass}>
      {label && <UiKit.Label text={label} className={labelClassName} />}
      <div className={contentClass}>
        <div className={inputWrapClass} ref={inputContainerRef}>
          <UiKit.Input
            type="email"
            value={email}
            inputRef={inputRef}
            disabled={disabled}
            placeholder={placeholder}
            onKeyUp={pickEmailAddress}
            onChange={inputChangeHandler}
            inputClassName={inputClassName}
            error={errorMessage}
          />
        </div>
        <EmailBadges
          emails={Object.keys(emails)}
          disabled={disabled}
          onDelete={deleteEmail}
          wrapperClassName={emailsBlockClassName}
          emptyBlockMsg={emptyBlockMsg}
        />
      </div>
    </div>
  );
}
