import _get from 'lodash/get';

function createFeaturesList(
  plan,
  prevPlan,
  featuresPlanMap,
  uniqueCategoryPlanFeaturesMap
) {
  const planSlug = _get(plan, 'slug', '');
  const prevPlanSlug = _get(prevPlan, 'slug', '');
  const prevPlanName = _get(prevPlan, 'name', '');

  let usersCountFeature = null;
  const isEssentialsPlan = planSlug.includes('essential');
  const features = _get(
    uniqueCategoryPlanFeaturesMap,
    `${planSlug}.features`,
    []
  );

  const visibleFeatures = features.filter(feature => {
    const slug = _get(feature, 'slug', '');
    const isInFree = _get(featuresPlanMap, `${slug}.free`, false);
    const isAvailable = _get(featuresPlanMap, `${slug}.${planSlug}`, false);
    const inPrevPlan = _get(featuresPlanMap, `${slug}.${prevPlanSlug}`, false);
    const show = isEssentialsPlan ? isAvailable : isAvailable && !inPrevPlan;
    if (slug === 'APP_USERS') {
      usersCountFeature = feature;
    }
    return !isInFree && show;
  });

  if (usersCountFeature) {
    const usersCount = _get(usersCountFeature, 'meta_info.users_limit', '');
    visibleFeatures.unshift({
      ...usersCountFeature,
      category: `${usersCount} app users`
    });
  }

  if (isEssentialsPlan) {
    visibleFeatures.unshift({
      slug: 'PREV_PLAN_FEATURES',
      category: 'All free features'
    });
  } else if (prevPlanName) {
    visibleFeatures.unshift({
      slug: 'PREV_PLAN_FEATURES',
      category: `Everything in ${prevPlanName.replace(/Plan/g, '')}`
    });
  }

  return visibleFeatures;
}

export function configureRetexPlans({
  plans = [],
  currentPlan = {},
  isTrackingApp = false,
  featuresPlanMap = {},
  uniqueCategoryPlanFeaturesMap = {}
}) {
  if (isTrackingApp) return plans;

  const currentPlanName = _get(currentPlan, 'slug', '');
  const isSubscribedToEnterprise = currentPlanName === 'enterprise';

  let prevPlan = null;
  const plansList = [...plans];
  const skipPlans = ['free', 'enterprise'];

  // if subscribed to enterprise, then show enterprise + remove first paid plan from list

  if (isSubscribedToEnterprise) {
    skipPlans.pop(); // To render the enterprise plan
    plansList.splice(1, 1); // Remove the 2nd plan [First plan is 'free' plan]
  }

  return plansList.reduce((acc, plan) => {
    const slug = _get(plan, 'slug', '');
    if (!skipPlans.includes(slug)) {
      const features = createFeaturesList(
        plan,
        prevPlan,
        featuresPlanMap,
        uniqueCategoryPlanFeaturesMap
      );
      acc.push({ ...plan, features });
      prevPlan = plan;
    }
    return acc;
  }, []);
}

export function getAllPlans(paidPlans = [], plans = [], isTrackingApp = false) {
  if (isTrackingApp) return plans;

  const freePlan = plans.find(({ slug }) => slug === 'free');
  const enterprisePlan = plans.find(({ slug }) => slug === 'enterprise');
  return [freePlan, ...paidPlans, enterprisePlan];
}

export function getPlansOrderMap(plans = [], isTrackingApp = false) {
  const orderKey = isTrackingApp ? 'name' : 'slug';
  return plans.reduce((acc, plan, index) => {
    const key = _get(plan, orderKey, '');
    acc[key] = index;
    return acc;
  }, {});
}
