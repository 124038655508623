import React from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { useReactTable } from 'components/ReactTable';
import ClearFiltersButton from '../TableClearFiltersButton';
import { ReactComponent as CrossIcon } from 'assets/img/icons/close.svg';

const REFINEMENT_TYPES = ['search', 'range', 'list'];

export default function CurrentRefinements({ entityMap = {} }) {
  const {
    refinements,
    clearRefinement,
    searchState: { isFetchingData }
  } = useReactTable();

  const generateRangeItem = refinement => {
    const { min, max } = _get(refinements, `${refinement}.values`, {});
    const { label = '', formatter = val => val } = entityMap[refinement];
    const formattedMin = formatter(min);
    const formattedMax = formatter(max);
    const onDiscardFilter = () => {
      if (isFetchingData) return;
      clearRefinement({ attribute: refinement, type: 'range' });
    };
    return (
      <>
        <span className={styles.value}>{formattedMin}</span>
        <span className={styles.label}>{`< ${label} <`}</span>
        <span className={styles.value}>{formattedMax}</span>
        <span className={styles.discardFilter} onClick={onDiscardFilter}>
          <CrossIcon />
        </span>
      </>
    );
  };

  const generateListItem = refinement => {
    const appliedRefinements = _get(refinements, `${refinement}.values`, {});
    const { label = '', formatter = val => val } = entityMap[refinement];

    const badges = Object.keys(appliedRefinements).map(type => {
      const formattedType = formatter(type);
      const onDiscardFilter = () => {
        if (isFetchingData) return;
        clearRefinement({ attribute: refinement, type: 'list', value: type });
      };
      return (
        <span key={type} className={styles.listValue}>
          {formattedType}
          <span className={styles.discardFilter} onClick={onDiscardFilter}>
            <CrossIcon />
          </span>
        </span>
      );
    });
    return (
      <>
        <span className={styles.label}>{label}:</span> {badges}
      </>
    );
  };

  const generateSearchItem = refinement => {
    const searchQuery = _get(refinements, refinement, '');
    const { label = '', formatter = val => val } = entityMap[refinement];
    const onDiscardFilter = () => {
      clearRefinement({ attribute: refinement, type: 'search' });
    };

    return (
      <>
        <span className={styles.label}>{label}:</span>
        <span className={styles.value}>{formatter(searchQuery)}</span>
        <span className={styles.discardFilter} onClick={onDiscardFilter}>
          <CrossIcon />
        </span>
      </>
    );
  };

  const refinementBadges = Object.keys(entityMap).reduce((acc, refinement) => {
    if (refinement in refinements) {
      const type = _get(refinements, `${refinement}.type`, 'search');
      if (REFINEMENT_TYPES.includes(type)) {
        let renderItem = null;
        const className = cn(styles.filter, {
          [styles.disabled]: isFetchingData && type !== 'search'
        });

        if (type === 'range') {
          renderItem = generateRangeItem(refinement);
        } else if (type === 'list') {
          renderItem = generateListItem(refinement);
        } else if (type === 'search') {
          renderItem = generateSearchItem(refinement);
        }

        acc.push(
          <li key={refinement} className={className}>
            {renderItem}
          </li>
        );
      }
    }
    return acc;
  }, []);

  if (_isEmpty(refinementBadges)) return null;

  return (
    <ul className={styles.container}>
      <ClearFiltersButton className={styles.clearFiltersBtn} />
      {refinementBadges}
    </ul>
  );
}
