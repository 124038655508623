import React, { forwardRef } from 'react';
import styles from './index.module.scss';

export default forwardRef(function StickyFooter({ children }, ref) {
  return (
    <div className={styles.container} ref={ref}>
      {children}
    </div>
  );
});
