import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';

import {
  findAllValues,
  rejectedServiceRequestChoices,
  approvedServiceRequestChoices,
  orderServiceTerminalStatuses
} from 'utils/DICTIONARY';
import { getOrderService } from 'utils/services';
import { loadData } from '../services';
import { OS_STATUS } from 'dictionary';

export default function useOsDetails(osId = '', onLoaded = () => {}) {
  const [orderService, setOrderService] = useState({});
  const [retexes, setRetexes] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [processingId, setProcessingId] = useState(null);

  const osStatuses = useRef(findAllValues('order_service_statuses')).current;
  const terminalOsStatuses = useRef(orderServiceTerminalStatuses()).current;
  const retexStatuses = useRef(findAllValues('retexStatusChoices')).current;
  const approvalTypes = useRef(findAllValues('request_approval_type')).current;
  const rejectedRetexStatuses = useRef(rejectedServiceRequestChoices()).current;
  const approvedRetexStatuses = useRef(approvedServiceRequestChoices()).current;

  const onLoadData = useCallback(() => {
    setIsError(false);
    setIsLoading(true);
    loadData(
      osId,
      (orderService = {}, retexes = []) => {
        setOrderService(orderService);
        setRetexes(retexes);
        onLoaded(null, { orderService, retexes });
        setIsLoading(false);
      },
      err => {
        setIsError(true);
        onLoaded(err, null);
        setIsLoading(false);
      }
    );
  }, [osId, onLoaded]);

  useEffect(() => {
    onLoadData();
  }, [onLoadData]);

  const onReSync = useCallback(
    (params = {}) => {
      getOrderService({ routeParam: osId, ...params }, data =>
        setOrderService(currData => _merge({}, currData, data))
      );
    },
    [osId]
  );

  const updateOsKeyValue = (key = '', data = null) => {
    if (!key && !data) return;
    setOrderService(currData => {
      const clonedData = _cloneDeep(currData);
      _set(clonedData, key, data);
      return clonedData;
    });
  };

  const onUpdateRetex = useCallback((id = null, updates = {}) => {
    if (!id) return;
    setRetexes(currRetexes => {
      return currRetexes.map(retex => {
        const retexId = _get(retex, 'id', null);
        return id === retexId ? { ...retex, ...updates } : retex;
      });
    });
  }, []);

  const areAllRetexesSelected = useMemo(() => {
    return retexes
      .filter(retex => _get(retex, 'is_actionable', false))
      .every(retex => _get(retex, 'is_checked', false));
  }, [retexes]);

  const onToggleAllRetexes = useCallback(() => {
    setRetexes(currRetexes => {
      return currRetexes.map(retex => {
        const isActionable = _get(retex, 'is_actionable', false);
        const isChecked = isActionable ? !areAllRetexesSelected : false;
        return { ...retex, is_checked: isChecked };
      });
    });
  }, [areAllRetexesSelected]);

  const status = _get(orderService, 'status', '');
  const isAtStore = status === OS_STATUS.PENDING_AT_STORE;
  const isAtWarehouse = status === OS_STATUS.PENDING_AT_WAREHOUSE;
  const isAtAdmin =
    status === OS_STATUS.PENDING_AT_ADMIN ||
    status === OS_STATUS.PENDING_QUALIFICATION ||
    status === OS_STATUS.PENDING_PAYMENT_COMPLETION;
  const showAction = isAtAdmin || isAtStore || isAtWarehouse;

  return {
    isError,
    isLoading,
    orderService,
    retexes,
    osStatuses,
    retexStatuses,
    approvalTypes,
    terminalOsStatuses,
    rejectedRetexStatuses,
    approvedRetexStatuses,
    areAllRetexesSelected,
    isDisabled,
    processingId,
    showAction,
    onReSync,
    updateOsKeyValue,
    onLoadData,
    onUpdateRetex,
    setIsDisabled,
    setProcessingId,
    onToggleAllRetexes
  };
}
