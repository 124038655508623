import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';

import { get } from 'utils';
import {
  ExplicitSearch,
  Highlight,
  Pagination,
  Search,
  SearchState,
  SortDropdown,
  TableFilters
} from './components';
import {
  useTableFilters,
  TableFiltersProvider as ReactTableFiltersProvider
} from './context';
import {
  searchStateToUrl,
  urlToSearchState,
  urlToSearchStateOptions,
  getRefinements,
  refine,
  getTableData,
  getFacets
} from './utils';

const ReactTablesContext = React.createContext({
  data: [],
  facets: {},
  refinements: {},
  pagination: {
    currentPage: 1,
    setPage: () => {},
    totalPages: 0
  },
  reload: () => {}
});

const DEFAULT_FORMAT_FACETS = facets => facets;

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {import('utils').get | import('utils').post} [props.method]
 * @param {string} props.apiEndpoint
 * @param {string} props.facetsEndpoint
 * @param {import('utils/types').TableFiltersStorageKey} props.storageKey Key to be used to store filters in local storage
 * @param {object} props.filters
 * @param {object} props.options
 * @param {boolean} props.persistInUrl
 * @param {boolean} props.refresh
 * @param {string[]} props.rejectKeys
 * @param {string[]} props.ignoreFacetKeys
 * @param {string[]} props.persistKeysWhileRefresh
 * @param {object} props.facetsToRefinementKeyMap
 * @param {Function} props.formatFacets
 * @param {boolean} props.dataIncludesFacets
 */
const ReactTable = ({
  children,
  method = get,
  apiEndpoint,
  facetsEndpoint,
  storageKey,
  filters,
  options: initialOptions,
  persistInUrl,
  refresh,
  rejectKeys = [],
  ignoreFacetKeys = [],
  persistKeysWhileRefresh = [],
  facetsToRefinementKeyMap = {},
  formatFacets = DEFAULT_FORMAT_FACETS,
  dataIncludesFacets = false
}) => {
  const history = useHistory();
  const location = useLocation();
  const { retainedFilters, setRetainedFilters } = useTableFilters(storageKey);

  const [facets, setFacets] = useState({});
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isFetchingFacets, setIsFetchingFacets] = useState(!!facetsEndpoint);
  const [data, setData] = useState({});
  const [options, setOptions] = useState(findOptions());
  const [refinements, setRefinements] = useState(findState());
  const [initializedFacets, setInitializedFacets] = useState(false);
  const [dataFetchingError, setDataFetchingError] = useState(null);
  const [facetFetchingError, setFacetFetchingError] = useState(null);
  const requestRefs = useRef({ facets: null, data: null });

  const loadData = useCallback(
    ({ params }) => {
      setIsFetchingData(true);
      setDataFetchingError(null);
      setFacetFetchingError(null);
      if (dataIncludesFacets) setIsFetchingFacets(true);
      requestRefs.current.data = getTableData(
        { method, params, apiEndpoint },
        (err, res) => {
          const isCancelled = _get(err, '__CANCEL__', false);
          if (isCancelled) return;

          const { facets = null, ...data } = _get(res, 'data', {});
          const statusCode = _get(res, 'statusCode') || _get(res, 'status');
          if (statusCode !== 200) setDataFetchingError(err);
          else setData(data);
          if (facets && !facetsEndpoint) {
            setFacets(formatFacets(facets));
            setInitializedFacets(true);
            setIsFetchingFacets(false);
          }
          setIsFetchingData(false);
        }
      );
      if (facetsEndpoint) {
        setIsFetchingFacets(true);
        requestRefs.current.facets = getFacets(
          { params, facetsEndpoint },
          (err, res) => {
            const isCancelled = _get(err, '__CANCEL__', false);
            if (isCancelled) return;

            const facets = _get(res, 'data');
            const statusCode = _get(res, 'statusCode') || _get(res, 'status');
            if (statusCode !== 200) setFacetFetchingError(err);
            else setFacets(formatFacets(facets));
            setInitializedFacets(true);
            setIsFetchingFacets(false);
          }
        );
      }
    },
    [apiEndpoint, facetsEndpoint, dataIncludesFacets, method, formatFacets]
  );

  useEffect(() => {
    if (!_isEqual(retainedFilters, refinements)) {
      setRetainedFilters(refinements);
    }
    const { routeParam, routeParams, ...filterRefinements } = refinements;
    const reloadParams = getRefinements(filterRefinements, method);
    if (persistInUrl) {
      saveToUrl({ refinements: reloadParams, options, rejectKeys });
    }
    loadData({ params: { routeParam, routeParams, ...reloadParams } });
    return () => {
      const { data, facets } = requestRefs.current;
      if (data) data.cancel();
      if (facets) facets.cancel();
      requestRefs.current = { data: null, facets: null };
    };
    // eslint-disable-next-line
  }, [refinements, loadData, method, persistInUrl]);

  useEffect(() => {
    if (refresh) refetch();
    // eslint-disable-next-line
  }, [refresh]);

  const refetch = () => {
    const attachFilters = persistKeysWhileRefresh.reduce((acc, key) => {
      if (key in refinements) {
        acc[key] = refinements[key];
      }
      return acc;
    }, {});
    const _refinements = findState(attachFilters);
    setRefinements(_refinements);
  };

  function findOptions() {
    const state = urlToSearchStateOptions(location);
    if (state) return state;
    return initialOptions;
  }

  function findState(addedFilters = {}) {
    const state = urlToSearchState(location, rejectKeys);
    if (state) {
      return { ...state, ...filters, ...retainedFilters, ...addedFilters };
    } else {
      return { page: 1, ...filters, ...retainedFilters, ...addedFilters };
    }
  }

  const saveToUrl = ({ refinements = {}, options = {}, rejectKeys = [] }) => {
    try {
      options = Object.keys(options).reduce((acc, key) => {
        acc['options__' + key] = options[key];
        return acc;
      }, {});
      const pageSizeKeys = ['page_size', 'pageSize'];
      const rejectedRefinementKeys = [...pageSizeKeys, ...rejectKeys];

      setTimeout(() => {
        const currentRefinements = Object.keys(refinements).reduce(
          (acc, key) => {
            if (!rejectedRefinementKeys.includes(key)) {
              const value = refinements[key];
              if (!pageSizeKeys.includes(key) && !!value) {
                acc[key] = refinements[key];
              }
            }
            return acc;
          },
          {}
        );
        history.replace(
          searchStateToUrl(location, { ...currentRefinements, ...options }),
          location.state
        );
      }, 0);
    } catch (e) {
      console.log('e', e);
    }
  };

  const setPageLocal = page => setRefinements({ ...refinements, page: page });

  const totalPages = () => {
    let pageSize = _get(filters, 'page_size') || _get(filters, 'pageSize');
    pageSize = pageSize || 10;
    const count = _get(data, 'totalSize') || _get(data, 'total_records');
    return Math.ceil(count / pageSize) || 0;
  };

  const getMetadata = () => {
    return _omit(data, [
      'page',
      'results',
      'pageSize',
      'page_size',
      'totalSize',
      'total_records'
    ]);
  };

  return (
    <ReactTablesContext.Provider
      value={{
        searchState: {
          isFetchingData: isFetchingData,
          isFetchingFacets: isFetchingFacets,
          isFetching: isFetchingData || isFetchingFacets,
          isDataFetchingError: !!dataFetchingError,
          isFacetsFetchingError: !!facetFetchingError,
          error: dataFetchingError || facetFetchingError,
          isEmpty: (_get(data, 'results') || []).length === 0
        },
        data: _get(data, 'results', []),
        metadata: getMetadata(),
        dataIncludesFacets,
        reload: refetch,
        initializedFacets,
        pagination: {
          currentPage: refinements.page,
          setPage: setPageLocal,
          totalPages: totalPages()
        },
        facets,
        refinements,
        facetsToRefinementKeyMap,
        checkIfRefined: () => {
          const facetKeys = _isObject(facets) ? Object.keys(facets) : [];
          const refinementKeys = _isObject(facetsToRefinementKeyMap)
            ? Object.values(facetsToRefinementKeyMap)
            : [];
          const allFacetKeys = [...new Set([...facetKeys, ...refinementKeys])];
          return allFacetKeys.some(facet => {
            if (ignoreFacetKeys.includes(facet)) return false;
            const refinementKey = _get(facetsToRefinementKeyMap, facet, facet);
            return refinementKey in refinements;
          });
        },
        setOptions: opt => {
          const updatedOptions = { ...options, ...opt };

          if (persistInUrl) {
            saveToUrl({
              refinements,
              options: updatedOptions
            });
          }

          setOptions(updatedOptions);
        },
        options,
        clearRefinement: function({ attribute, type, value }) {
          const _refinements = { ...refinements };
          if (!_refinements[attribute]) return;

          if (type === 'list') {
            delete _refinements[attribute].values[value];
            if (Object.keys(_refinements[attribute].values).length < 1) {
              delete _refinements[attribute];
            }
          } else {
            delete _refinements[attribute];
          }
          setRefinements({ ..._refinements, page: 1 });
        },
        clearAllRefinements: function() {
          const newRefinements = { ...refinements };
          const facetKeys = _isObject(facets) ? Object.keys(facets) : [];
          const refinementKeys = _isObject(facetsToRefinementKeyMap)
            ? Object.values(facetsToRefinementKeyMap)
            : [];
          const allFacetKeys = [...new Set([...facetKeys, ...refinementKeys])];
          allFacetKeys.forEach(facet => {
            const refinementKey = _get(facetsToRefinementKeyMap, facet, facet);
            if (ignoreFacetKeys.includes(refinementKey)) return;
            delete newRefinements[refinementKey];
          });
          setRefinements({ ...newRefinements, page: 1 });
        },
        refine: function(obj) {
          let _refinements = { ...refinements };
          _refinements = refine({ ...obj, _refinements });
          if (_isEqual(refinements, _refinements)) {
            return;
          }
          setRefinements({ ..._refinements, page: 1 });
        }
      }}
    >
      {children}
    </ReactTablesContext.Provider>
  );
};

const ReactTablesConsumer = ReactTablesContext.Consumer;

const useReactTable = () => useContext(ReactTablesContext);

export {
  ReactTable,
  ReactTablesContext,
  ReactTablesConsumer,
  Highlight,
  Pagination,
  SearchState,
  Search,
  ExplicitSearch,
  SortDropdown,
  TableFilters,
  useReactTable,
  ReactTableFiltersProvider
};
