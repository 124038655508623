import { get, logError } from 'utils';

export default function getAggregatorsList(
  onSuccess = (_list = []) => {},
  onError = () => {},
  onFinally = () => {}
) {
  get({ apiKey: 'configure.aggregator.list' })
    .then(({ data }) => onSuccess(Array.isArray(data) ? data : []))
    .catch(err => {
      const error = new Error(err);
      error.message = 'Fetch aggregators list failed';
      logError(error);
      onError(err);
    })
    .finally(onFinally);
}
