import React from 'react';
import { Address } from 'components/Common';

const Events = ({ events, actualLineWidth, open, openBubble }) => {
  return events.map((event, index) => {
    const lastEvent = index >= events.length - 1;
    const lineWidth = lastEvent ? 100 : actualLineWidth;
    const address = event.address;
    const customClass = open === index ? 'open' : '';
    return (
      <React.Fragment key={index}>
        <div className={'event ' + customClass} data-index={index}>
          <div className="event-bubble">
            <Address address={address} />
            <div className="event-title">{event.status}</div>
          </div>
          <svg height={20} width={20} onClick={() => openBubble(index)}>
            <circle cx={10} cy={11} r={5} fill="#004165" />
          </svg>
          <div className="event-time">{event.createdAt}</div>
        </div>

        <svg height={5} width={lineWidth} className="line-width">
          <line
            x1={0}
            y1={0}
            x2={300}
            y2={0}
            style={{ stroke: '#004165', strokeWidth: 5 }}
          />
          Sorry, your browser does not support inline SVG.
        </svg>
      </React.Fragment>
    );
  });
};

export default Events;
