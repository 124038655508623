import React from 'react';

export default [
  {
    lg: 4,
    title: 'Action'
  },
  {
    lg: 4,
    title: (
      <>
        <span>Stage</span>
        <span>Rule Name</span>
      </>
    )
  },
  {
    lg: 3,
    title: 'Applicable On',
    customClass: 'pl-4'
  }
];
