import React from 'react';

export default function Home() {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14">
      <defs>
        <path id="ittwldav0a" d="M0 0L14.881 0 14.881 13.8 0 13.8z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-18 -65) translate(18 65)">
            <mask id="rmhf1wxi5b" fill="#fff">
              <use xlinkHref="#ittwldav0a" />
            </mask>
            <path
              style={{ fill: 'var(--icon-color)' }}
              mask="url(#rmhf1wxi5b)"
              d="M11.465 12.075c0 .318-.257.575-.575.575h-6.9c-.317 0-.575-.257-.575-.575V5.411L7.44 1.386l4.025 4.025v6.664zm.983-7.308l-4.6-4.6c-.224-.223-.586-.223-.81 0l-4.6 4.6-2.3 2.3c-.206.24-.178.604.063.81.215.185.532.185.748 0l1.317-1.316v5.514c0 .953.772 1.725 1.724 1.725h6.9c.953 0 1.725-.772 1.725-1.725V6.561l1.317 1.317c.241.206.604.178.81-.063.185-.216.185-.532 0-.748l-2.294-2.3z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
