import { parallel } from 'async';

import { getConfiguration, getSubscription, getPlanFeaturesMap } from '.';

export default function getAccessData(callback = () => {}) {
  parallel(
    {
      configuration: cb => {
        getConfiguration(
          data => cb(null, data),
          err => cb(err)
        );
      },
      subscription: cb => {
        getSubscription(
          data => cb(null, data),
          err => cb(err)
        );
      },
      planFeatures: cb => {
        getPlanFeaturesMap(
          data => cb(null, data),
          err => cb(err)
        );
      }
    },
    callback
  );
}
