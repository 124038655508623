import React from 'react';

export default function Redirect() {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16">
      <g fill="none" fillRule="evenodd">
        <g
          style={{ fill: 'var(--icon-color)', stroke: 'var(--icon-color)' }}
          fillRule="nonzero"
          strokeWidth=".25"
        >
          <g>
            <g>
              <path
                d="M.27 1.246c-.154.014-.27.143-.27.298v12.51c0 .164.133.298.298.298h12.51c.165 0 .298-.134.298-.298V7.799c.002-.108-.055-.208-.147-.262-.093-.054-.208-.054-.3 0-.093.054-.15.154-.148.262v5.957H.596V1.841h5.957c.108.002.208-.054.262-.147.054-.093.054-.208 0-.3-.054-.093-.154-.15-.262-.148H.27h0zM9.675.054c-.106.005-.202.067-.25.161-.05.095-.044.209.013.298.058.09.159.142.265.137h3.156L6.217 7.291c-.088.072-.129.188-.103.3.026.11.112.197.224.223.11.025.227-.015.298-.104l6.642-6.641v3.155c-.002.108.055.208.148.262.092.054.207.054.3 0s.15-.154.148-.262V.352c0-.164-.134-.298-.298-.298H9.675h0z"
                transform="translate(-16 -734) translate(17 734) translate(0 .095)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
