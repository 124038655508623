import React, { useMemo } from 'react';
import cn from 'classnames';
import _isString from 'lodash/isString';

export default function ErrorMessage({
  msg = '',
  hide = false,
  className = ''
}) {
  const wrapperClassName = useMemo(() => {
    return cn('uiKit__error-msg', { [className]: !!className });
  }, [className]);

  if (!msg || !_isString(msg) || hide) return null;

  return <p className={wrapperClassName}>{msg}</p>;
}
