import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { logError } from 'utils';
import { isRetexAwatingAtAdmin } from 'utils/methods';
import { CustomToast, UiKit } from 'components';
import { getRetexActionData } from 'views/Requests/methods';

export default function RetexAction({
  data = {},
  isCompact = false,
  isApproving = true,
  isRestocking = true,
  comment = '',
  customComment = '',
  isDisabled = false,
  onBeforeProcessing = () => {},
  onAfterProcessing = () => {}
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const status = _get(data, 'status', '');

  const {
    show,
    text,
    type,
    processingText,
    alertModalData,
    toastMessage,
    onTrigger
  } = useMemo(
    () =>
      getRetexActionData(
        data,
        isApproving,
        isRestocking,
        comment,
        customComment
      ),
    [data, isApproving, isRestocking, comment, customComment]
  );

  const onStartProcessing = () => {
    const isValidAction = isRetexAwatingAtAdmin(status);
    if (!isValidAction) return;

    setIsProcessing(true);
    onBeforeProcessing();
    onTrigger(
      data => {
        onAfterProcessing(null, data);
        const type = isApproving ? 'success' : 'info';
        const msg = _get(toastMessage, type, '');
        if (msg) CustomToast({ type, msg });
      },
      err => {
        logError(err);
        onAfterProcessing(err, null);
        CustomToast({
          isNotified: err.notified,
          type: 'error',
          msg: _get(toastMessage, 'error', false)
        });
      },
      () => setIsProcessing(false)
    );
  };

  const onStartProcess = () => {
    if (alertModalData) return setShowAlert(true);
    onStartProcessing();
  };

  const onCloseAlertModal = () => setShowAlert(false);

  const alertModalTitle = _get(alertModalData, 'title', '');
  const alertModalDescription = _get(alertModalData, 'description', '');
  const alertModalButtonText = _get(alertModalData, 'buttonText', '');
  const alertModalButtonProcessingText = _get(
    alertModalData,
    'buttonProcessingText',
    ''
  );
  const buttonClassName = cn(styles.button, styles[type], {
    [styles.danger]: !isApproving,
    [styles.compact]: isCompact
  });

  if (!show) return null;

  return (
    <>
      <UiKit.Button
        onClick={onStartProcess}
        disabled={isDisabled}
        processing={isProcessing}
        processingText={processingText}
        wrapperClassName={styles.container}
        className={buttonClassName}
      >
        {text}
      </UiKit.Button>
      <UiKit.Modal
        isOpen={showAlert}
        title={alertModalTitle}
        onClose={onCloseAlertModal}
        onConfirm={onStartProcessing}
        confirmBtnType="danger"
        isProcessing={isProcessing}
        confirmBtnText={alertModalButtonText}
        confirmBtnProcessingText={alertModalButtonProcessingText}
        className={styles.alertModal}
      >
        <p className={styles.alertDescription}>{alertModalDescription}</p>
      </UiKit.Modal>
    </>
  );
}
