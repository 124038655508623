import React from 'react';
import { NavLink } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import styles from './index.module.scss';

export default function Link({
  shepherdId = '',
  to = '/',
  isLocked = false,
  isExternal = false,
  target = '_blank',
  children = null
}) {
  if (isLocked) {
    return (
      <>
        <div
          id={shepherdId}
          data-shepherd={shepherdId}
          className={styles.lockedLink}
        >
          {children}
        </div>
        <UncontrolledTooltip
          target={shepherdId}
          placement="right"
          boundariesElement="viewport"
        >
          You do not have permission to access this part of the portal. Please
          contact your admin for further help.
        </UncontrolledTooltip>
      </>
    );
  }

  if (isExternal) {
    return (
      <a
        data-shepherd={shepherdId}
        className={styles.link}
        href={to}
        target={target}
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink
      to={to}
      data-shepherd={shepherdId}
      className={styles.link}
      activeClassName={styles.active}
    >
      {children}
    </NavLink>
  );
}
