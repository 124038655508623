import { get } from 'utils';

export default async function getStates(...countryIds) {
  try {
    const { data } = await get(
      { apiKey: 'settings.locations.facilities.locationsMap.getStates' },
      { params: { country_ids: countryIds.join(',') } }
    );
    if (data instanceof Object && Object.keys(data).length === 0) {
      return { data: [] };
    } else {
      return { data };
    }
  } catch (err) {
    return { err };
  }
}
