import _isArray from 'lodash/isArray';

import { get, logError } from 'utils';

export default function getOrderServiceTimeline(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  return get({ apiKey: 'requests.timeline' }, { params })
    .then(({ data }) => {
      const retexes = _isArray(data) ? data : [];
      onSuccess(retexes);
    })
    .catch(err => {
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
