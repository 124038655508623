import { get } from 'utils';

export default function fetchRetailers(
  params = {},
  onSuccess = () => {},
  onError = () => {}
) {
  get({ apiKey: 'retailerListByEmail', noTokenRequired: true }, { params })
    .then(({ data }) => onSuccess(data))
    .catch(onError);
}
