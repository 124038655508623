import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { UiKit } from 'components';
import ROUTES from 'config/nav/admin/routes';
import { formatNumber } from 'utils';

export default function Action({
  planData = null,
  compact = false,
  className = '',
  buttonType = ''
}) {
  const planName = _get(planData, 'name', '').replace(/Plan/g, '');
  const planSlug = _get(planData, 'slug', '');
  const annualPrice = _get(planData, 'plan_price.yearly.price', 0);
  const monthlyPrice = _get(planData, 'plan_price.monthly.price', 0);
  const annualPlanMonthlyRate = annualPrice / 12;

  const type = buttonType || compact ? 'outline' : 'success';
  const redirectRoute = `${ROUTES.SETTINGS.MANAGE_SUBSCRIPTION}#${planSlug}`;
  const discount = Math.floor(
    ((monthlyPrice - annualPlanMonthlyRate) * 100) / monthlyPrice
  );
  const formattedAnnualPrice = formatNumber(annualPlanMonthlyRate);

  if (!planData) return null;

  return (
    <div className={cn(styles.action, className)}>
      <Link to={redirectRoute} className={styles.link}>
        <UiKit.Button customType={type} className={styles.button}>
          Get {planName} Plan
        </UiKit.Button>
      </Link>
      <div className={styles.price}>
        <strong className={styles.rate}>${formattedAnnualPrice}/mo</strong>
        {discount > 0 && (
          <span className={styles.discount}>{discount}% off</span>
        )}
      </div>
    </div>
  );
}
