import { post, logError } from 'utils';

export default function createStripeCustomerPortalSession(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  const request = post(
    { apiKey: 'billing.stripe.createCustomerPortalSession' },
    { params }
  );
  request
    .then(res => onSuccess(res.data))
    .catch(err => {
      logError({
        ...err,
        customMessage: "Unable to create Stripe's Customer portal session"
      });
      onError(err);
    })
    .finally(onFinally);
}
