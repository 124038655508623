import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import { getUrlParameter } from 'utils';
import { Title } from '../components';

export default function Connected() {
  return (
    <div id="login-row">
      <Card className="border-0">
        <CardBody>
          <CardTitle>
            {' '}
            <Title />
          </CardTitle>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <p className="text-center">
            Your store{' '}
            <strong>
              <u>{getUrlParameter('store')}</u>
            </strong>{' '}
            is being connected to Return Rabbit. You will receive an email when
            the process is complete at{' '}
            <strong>{getUrlParameter('email')}</strong>
          </p>
        </CardBody>
      </Card>
    </div>
  );
}
