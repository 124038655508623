import axiosSetup from './axiosSetup';
import debugSetup from './debugSetup';
// import setupHubspot from './setupHubspot';
// import setupUserGuiding from './setupUserGuiding';
// import setupGoogleAnalytics from './setupGoogleAnalytics';

export default function setup() {
  axiosSetup();
  debugSetup();
  // setupHubspot();
  // setupUserGuiding();
  // setupGoogleAnalytics();
}
