import { get, logError } from 'utils';

export default function syncAggregatorCarriers(
  id,
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  get(
    { apiKey: 'configure.aggregator.sync_carriers' },
    { params: { routeParams: { id } } }
  )
    .then(({ data }) => onSuccess(data))
    .catch(err => {
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
