import React from 'react';
import styles from './index.module.scss';

import Skeleton from 'components/Skeleton';

export default function RecordsLoader() {
  return (
    <div className={styles.container}>
      <div>
        <Skeleton width="80px" className="mb-1" />
        <div className="d-flex justify-content-end">
          <Skeleton width="32px" height="18px" className="mb-0" />
          <Skeleton width="32px" height="18px" className="ml-2 mb-0" />
        </div>
      </div>
    </div>
  );
}
