import React, { Component } from 'react';
class Loader extends Component {
  render() {
    var customClass = 'spinner ' + this.props.customClass;
    return (
      <div className={customClass}>
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    );
  }
}
export default Loader;
