import React from 'react';
import { renderToString } from 'react-dom/server';

import { DOCUMENTAION_LINKS } from 'dictionary';
import { ProgressIndicator, StepText } from 'containers/TourGuides/components';
import {
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import { ROUTES } from 'views/Configure/ReturnManagement/AutomationRules/stubs';

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getAutomationRulesSteps({
  push = () => {},
  onShow = () => {},
  onHide = () => {}
}) {
  return [
    {
      id: 'intro',
      title: 'Automation Rules',
      text: renderToString(
        <>
          <StepText>
            Automation rules let you automate your return processes so that the
            entire process becomes very quick and streamlined both for you and
            your shoppers.
          </StepText>
          <ProgressIndicator current={1} total={8} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({ push, path: ROUTES.RETURN_MANAGEMENT });
      },
      buttons: [{ text: "Let's go", type: 'next' }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'automation-rules-card',
      attachTo: {
        element: getShepherdSelector('card-automation-rules'),
        on: 'left'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.RETURN_MANAGEMENT,
          selector: this.attachTo.element
        });
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText className="mb-1">
            You can automate your both or either your refund or exchange
            approval on the basis of shipping events here.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on <code>Manage</code> button to continue.
          </StepText>
          <ProgressIndicator current={2} total={8} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector('card-automation-rules')} a`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'automation-rules-table',
      attachTo: {
        element: getShepherdSelector('automation-rules-table-header'),
        on: 'top'
      },
      text: renderToString(
        <>
          <StepText>
            Find all the existing rules here. By default, Return Rabbit set up a
            warehouse step automation rule for your store.
            <br />
            <br />
            In case you don't use the warehouse interface, please don't delete
            this rule.
          </StepText>
          <ProgressIndicator current={3} total={8} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'automation-rules-add-new-button',
      attachTo: {
        element: getShepherdSelector('automation-rules-add-new-rule-btn'),
        on: 'left'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.HOME,
          selector: this.attachTo.element
        });
      },
      title: 'Add new rule',
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>Click here to create a new automation rule.</StepText>
          <ProgressIndicator current={4} total={8} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector(
          'automation-rules-add-new-rule-btn'
        )} button`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'new-rule-toggle',
      attachTo: {
        element: getShepherdSelector('automation-rule-active-toggle'),
        on: 'right'
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>Activate your automation rule with this toggle.</StepText>
          <ProgressIndicator current={5} total={8} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'new-rule-name',
      attachTo: {
        element: getShepherdSelector('automation-rule-name'),
        on: 'right'
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>
            Give your rule a name that you can identify easily across the
            portal.
          </StepText>
          <ProgressIndicator current={6} total={8} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'new-rule-action',
      attachTo: {
        element: getShepherdSelector('automation-rule-select-action'),
        on: 'right'
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>
            Select the action that you want to automate with this rule.
            <br />
            <br />
            To know more about the automation rules, please read our
            documentation{' '}
            <a
              href={DOCUMENTAION_LINKS.CONFIGURE.AUTOMATION_RULES}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </StepText>
          <ProgressIndicator current={7} total={8} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'new-rule-parameters',
      attachTo: {
        element: getShepherdSelector('automation-rule-set-params'),
        on: 'right'
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>
            Select when this rule should be applied.
            <br />
            <br />
            You can choose to apply them on Returns and/or Exchanges for all
            products, or products within a specific price range.
          </StepText>
          <ProgressIndicator current={8} total={8} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    }
  ];
}
