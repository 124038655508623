import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import _get from 'lodash/get';
import { preventErrorLog } from './methods';

export default function logError(error) {
  const appMode = _get(window, 'config.APP_MODE', null);

  if (!appMode || preventErrorLog()) return;

  if (appMode === 'DEVELOPMENT') {
    return console.log('Log Error: ', error);
  }

  LogRocket.captureException(error);
  Sentry.captureException(error);
}
