import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default function Container({
  hasAccess = false,
  className = '',
  children = null
}) {
  return (
    <div
      data-locked={!hasAccess}
      className={cn(
        styles.container,
        { [styles.locked]: !hasAccess },
        className
      )}
    >
      {children}
    </div>
  );
}
