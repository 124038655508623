import React, { useMemo } from 'react';
import cn from 'classnames';

export default function FadingCircle({ loading, customClass }) {
  const className = useMemo(() => {
    return cn('sk-fading-circle', { [customClass]: !!customClass });
  }, [customClass]);

  return !loading ? null : (
    <div class={className}>
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>
  );
}
