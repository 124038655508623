import numbro from 'numbro';
import _isNumber from 'lodash/isNumber';

export default function formatNumber(
  num,
  format = {},
  trimDecimalIfZero = true
) {
  // Old Version
  // let formattedNumber = numbro(num).format({ average: true, mantissa: 1 });
  // if (formattedNumber.indexOf(".0") > -1) {
  //   formattedNumber = formattedNumber.replace(".0", "");
  // }
  // return formattedNumber;

  // New version
  if (!_isNumber(num)) return '';

  let formattedNumber = numbro(num).format({
    thousandSeparated: true,
    mantissa: 2,
    ...format
  });

  if (trimDecimalIfZero && formattedNumber.indexOf('.00') > -1) {
    formattedNumber = formattedNumber.replace('.00', '');
  }

  return formattedNumber;
}
