import { get, logError } from 'utils';

export default function getRetex(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  return get({ apiKey: 'retexes.get' }, { params })
    .then(({ data }) => onSuccess(data))
    .catch(err => {
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
