import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { ReactComponent as SearchIcon } from 'assets/img/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/close.svg';

export default function TableSearch({
  inputRef = null,
  value = '',
  disabled = false,
  placeholder = 'Search',
  onChange = () => {},
  onCancel = () => {},
  className = '',
  containerClassName = '',
  searchIconClassName = '',
  cancelIconClassName = '',
  ...inputProps
}) {
  const containerClass = cn(styles.container, containerClassName);
  const inputClass = cn('form-control', className);
  const searchIconClass = cn(
    styles.icon,
    styles.searchIcon,
    searchIconClassName
  );
  const cancelIconClass = cn(
    styles.icon,
    styles.crossIcon,
    {
      [styles.show]: !!value,
      [styles.disabled]: disabled
    },
    cancelIconClassName
  );
  return (
    <div className={containerClass}>
      <div className={searchIconClass}>
        <SearchIcon />
      </div>
      <input
        type="text"
        ref={inputRef}
        value={value}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        className={inputClass}
        aria-label="Search"
        {...inputProps}
      />
      <div className={cancelIconClass} onClick={onCancel}>
        <CloseIcon />
      </div>
    </div>
  );
}
