import _get from 'lodash/get';

import { post } from 'utils';

export default function login(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  post({ apiKey: 'login' }, { params })
    .then(({ data }) => onSuccess(data))
    .catch(err => {
      const res = JSON.parse(JSON.stringify(err)).response.data;
      const message = res.notified
        ? null
        : _get(res.displayMessage, 'message') || 'Something went wrong';
      onError(message);
    })
    .finally(onFinally);
}
