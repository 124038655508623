import React, { useMemo } from 'react';
import cn from 'classnames';

import { DOCUMENTAION_LINKS } from 'dictionary';

export default function CommonFooter({ className = '' }) {
  const wrapperClassName = useMemo(() => {
    return cn('CommonFooter__text', 'max-width-800', {
      [className]: !!className
    });
  }, [className]);

  return (
    <div className={wrapperClassName}>
      Any changes made in these settings are immediately reflected in your
      store’s service portal.
      <br />
      Please visit our{' '}
      <a
        href={DOCUMENTAION_LINKS.RR_DOCUMENTATION}
        target="_blank"
        rel="noopener noreferrer"
      >
        knowledge base
      </a>{' '}
      or contact{' '}
      <a href="mailto:support@returnrabbit.com">support@returnrabbit.com</a>, if
      case you have any questions.
    </div>
  );
}
