import React, { useState, useContext, useRef } from 'react';
import _get from 'lodash/get';
import cn from 'classnames';

import { TableSearch } from 'components/Tables';
import { ReactTablesContext } from 'components/ReactTable';

export default function Search({
  delay = 1000,
  inputRef = null,
  customClass,
  preventDisable = false,
  placeholder = 'Search',
  ...inputProps
}) {
  const {
    refine,
    refinements,
    clearRefinement,
    searchState: { isFetchingData }
  } = useContext(ReactTablesContext);
  const searchValue = _get(refinements, 'search', '');
  const [value, setValue] = useState(searchValue);
  const timeout = useRef();

  const className = cn('table-search', {
    active: value.length > 0,
    [customClass]: !!customClass
  });

  const doRefine = value => {
    refine({ type: 'search', attribute: 'search', value: value });
  };

  const onChange = val => {
    setValue(val);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      doRefine(val);
    }, delay);
  };

  const onCrossIconClick = () => {
    if (isFetchingData) return;
    const value = '';
    setValue(value);
    if (searchValue) {
      clearRefinement({ attribute: 'search', type: 'search', value });
    }
  };

  return (
    <div className={className}>
      <TableSearch
        inputRef={inputRef}
        value={value}
        onChange={onChange}
        onCancel={onCrossIconClick}
        placeholder={placeholder}
        disabled={!preventDisable && isFetchingData}
        {...inputProps}
      />
    </div>
  );
}
