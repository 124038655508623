import React from 'react';
import { Row, Col } from 'reactstrap';
import cn from 'classnames';
import styles from './index.module.scss';

export default function RequestTimeline({ values = [] }) {
  const renderValues = values.reduce((acc, data, index) => {
    if (data) {
      const {
        lg = null,
        type = '',
        title = '',
        customClass = '',
        CustomComponent = null
      } = data;

      if (CustomComponent) {
        acc.push(
          <Col key={index} lg={lg} className={customClass}>
            {CustomComponent}
          </Col>
        );
      } else if (type) {
        const className = cn('d-flex', 'align-items-center', customClass);
        const indicatorClassName = cn(styles.indicator, {
          [styles.success]: type === 'success',
          [styles.awaiting]: type === 'awaiting',
          [styles.rejected]: type === 'rejected'
        });
        acc.push(
          <Col key={index} lg={lg} className={className}>
            <div className={styles.indicatorWrapper}>
              <span className={indicatorClassName} />
            </div>
            <span className={styles.title}>{title}</span>
          </Col>
        );
      } else {
        acc.push(
          <Col key={index} lg={lg} className={customClass}>
            {title}
          </Col>
        );
      }
    }
    return acc;
  }, []);

  return <Row className={styles.container}>{renderValues}</Row>;
}
