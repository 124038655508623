const Reason = [
  {
    label: "Customer doesn't want to return anymore",
    value: 'want-to-keep'
  },
  {
    label: 'Wrong refund method selected',
    value: 'wrong-payment-method'
  },
  {
    label: 'Wrong shipping method selected',
    value: 'wrong-shipping-method'
  },
  {
    label: 'Selected wrong items to return / exchange',
    value: 'selected-wrong-items'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export default Reason;
