import { post } from 'utils';

export default function requestLink(
  params = {},
  onSuccess = () => {},
  onError = () => {}
) {
  post(
    { apiKey: 'authentication.requestLink', noTokenRequired: true },
    { params }
  )
    .then(({ data }) => onSuccess(data))
    .catch(onError);
}
