import { ROUTES as EXCHANGE_ROUTES } from '../../Exchanges/stubs';
import { ROUTES as REFUND_METHOD_ROUTES } from '../../RefundMethods/stubs';
import { ROUTES as FEES_INCENTIVE_ROUTES } from '../../FeesAndIncentives/stubs';

const INCENTIVES_LINK = {
  BONUS_CASH: EXCHANGE_ROUTES.BONUS_CASH_INCENTIVE,
  UNIT_RESTOCKING_FEE: FEES_INCENTIVE_ROUTES.UNIT_RESTOCKING_FEE,
  RETURN_PROCESSING_FEE: FEES_INCENTIVE_ROUTES.RETURN_PROCESSING_FEE,
  GIFT_CARD: REFUND_METHOD_ROUTES.HOME,
  ORIGINAL_PAYMENT_METHOD: REFUND_METHOD_ROUTES.HOME
};

export default INCENTIVES_LINK;
