import { useState, useRef, useContext, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

import { AuthContext } from 'context/AuthContext';
import { CustomToast } from 'components';
import { getRequestActionData } from '../methods';
import { ROUTES } from 'views/Requests/stubs';

export default function useRequestAction(
  osData = {},
  retexes = [],
  isApproving = true,
  onBeforeProcessing = () => {},
  onSetProcessingId = () => {},
  onUpdateRetexData = () => {},
  onAfterProcessing = () => {}
) {
  const { push } = useHistory();
  const { path } = useRouteMatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { configuration } = useContext(AuthContext);
  const freezeRef = useRef(false);
  const apiResponseRef = useRef(null);

  const isDetailsPage = path === ROUTES.REQUEST_DETAILS;
  const hasStoreAccess = _get(configuration, 'store.enabled', false);
  const hasWarehouseAccess = _get(configuration, 'warehouse.enabled', false);

  const {
    text,
    disabled,
    type,
    show,
    tooltipMessage,
    processingText,
    toastMessage,
    alertModalData,
    onTrigger
  } = getRequestActionData(
    osData,
    retexes,
    isApproving,
    isDetailsPage,
    hasStoreAccess,
    hasWarehouseAccess,
    onSetProcessingId,
    onUpdateRetexData,
    push
  );

  // useDebugValue({ text, disabled, type, processingText });

  useEffect(() => {
    if (!freezeRef.current && isProcessing && !_isEmpty(retexes)) {
      freezeRef.current = true;
      setIsUpdating(true);
      onTrigger().then(results => {
        apiResponseRef.current = results;
        setIsProcessing(false);
        onAfterProcessing();
      });
    }
  }, [isProcessing, retexes, onTrigger, onAfterProcessing]);

  useEffect(() => {
    if (isUpdating && !isProcessing) {
      const apiResponse = apiResponseRef.current;
      const reponseList = _isArray(apiResponse) ? apiResponse : [];
      const isError = reponseList.some(res => !!_get(res, 'error'));
      const isNotified = reponseList.some(res => !!_get(res, 'error.notified'));
      const type = isError ? 'error' : isApproving ? 'success' : 'info';
      const msg = _get(toastMessage, type, '');
      if (msg) CustomToast({ isNotified, msg, type });
      apiResponseRef.current = null;
      freezeRef.current = false;
      setIsUpdating(false);
    }
  }, [isProcessing, isApproving, isUpdating, toastMessage]);

  const onStartProcess = () => {
    const isProcessing = !['unlock', 'cancel'].includes(type);
    setIsProcessing(isProcessing);
    onBeforeProcessing(osData, type);
  };

  return {
    text,
    show,
    type,
    disabled,
    isProcessing,
    tooltipMessage,
    processingText,
    alertModalData,
    onStartProcess
  };
}
