import React from 'react';
import _get from 'lodash/get';

import Logo from '../Logo';
import NavLinks from '../NavLinks';

export default function Nav({
  show = true,
  homePath = '',
  config = {},
  className = '',
  children = null
}) {
  if (!show) return null;

  const features = _get(config, 'items', []);

  return (
    <>
      {homePath && <Logo linkTo={homePath} />}
      <NavLinks features={features} className={className} />
      {children}
    </>
  );
}
