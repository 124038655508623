import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { PillSwitchContext } from '../../context';

export default function Pill({ label = '', value = null, disabled = false }) {
  const {
    activeValue = null,
    isDisabled = false,
    pillCSS = {},
    tabClassName = '',
    activeTabClassName = '',
    onChange = () => {}
  } = useContext(PillSwitchContext);

  const isPillDisabled = isDisabled || disabled;

  const pillClassName = cn(styles.pill, {
    [styles.active]: activeValue === value,
    [styles.disabled]: isPillDisabled,
    [tabClassName]: !!tabClassName,
    [activeTabClassName]: !!activeTabClassName && activeValue === value
  });

  const onClickHandler = () => {
    if (isPillDisabled) return;
    onChange(value);
  };

  return (
    <li onClick={onClickHandler} style={pillCSS} className={pillClassName}>
      {label}
    </li>
  );
}
