import React from 'react';

export default function Trends() {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15">
      <defs>
        <path id="d1sdrja9ea" d="M0 0L11.567 0 11.567 14.446 0 14.446z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-19 -216) translate(19 216)">
              <mask id="sby4efqqjb" fill="#fff">
                <use xlinkHref="#d1sdrja9ea" />
              </mask>
              <path
                style={{ fill: 'var(--icon-color)' }}
                d="M10.54 13.431H1.015V1.008h6.499v2.538c0 .28.227.508.507.508h2.539l-.02 9.377zm1.015-9.979V3.43c-.01-.045-.025-.087-.046-.127-.023-.047-.054-.09-.091-.127l-.036-.033L8.415.178 8.382.142C8.364.124 8.343.108 8.32.094L8.262.056C8.24.044 8.218.034 8.194.026 8.17.016 8.146.008 8.12.003c-.032-.004-.064-.004-.096 0H.508C.228.003 0 .23 0 .51V13.94c0 .28.227.507.508.507h10.55c.28 0 .507-.227.507-.507V3.51v.043c.003-.03.003-.06 0-.091V3.5c-.002-.017-.005-.033-.01-.049z"
                mask="url(#sby4efqqjb)"
              />
            </g>
            <path
              style={{ fill: 'var(--icon-color)' }}
              d="M3.244 6.712c-.28 0-.508.227-.508.508v4.569c0 .28.228.507.508.507s.508-.227.508-.507v-4.57c0-.28-.228-.507-.508-.507M5.782 4.173c-.28 0-.507.228-.507.508v7.108c0 .28.227.507.507.507s.508-.227.508-.507V4.68c0-.28-.227-.508-.508-.508M7.813 6.204v5.585c0 .28.228.507.508.507s.508-.227.508-.507V6.204c0-.28-.228-.508-.508-.508s-.508.228-.508.508"
              transform="translate(-19 -216) translate(19 216)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
