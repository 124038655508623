import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { OS_STATUS } from 'dictionary';
import { UiKit } from 'components';
import {
  approveAdminPendingRetexes,
  approveAllQualificationRetexes
} from 'utils/services';
import hasRequestTerminated from './hasRequestTerminated';
import { ReactComponent as LinkIcon } from 'assets/img/sidebar/customer-portal.svg';

export default function getRequestActionData(
  data = {},
  retexes = [],
  isApproving = true,
  isDetailsPage = false,
  hasStore = false,
  hasWarehouse = false,
  onSetProcessing = () => {},
  onUpdateRetexData = () => {},
  push = () => {}
) {
  const status = _get(data, 'status', '');
  const isLocked = _get(data, 'on_hold', false);
  const hasOsTerminated = hasRequestTerminated(status);

  const dataset = {
    text: '',
    type: '',
    show: true,
    processingText: '',
    tooltipMessage: '',
    toastMessage: { success: '', info: '', error: '' },
    onTrigger: () => Promise.resolve(),
    disabled:
      !isLocked &&
      !_isEmpty(retexes) &&
      retexes.every(retex => !retex.is_checked),
    alertModalData: null // OR { title: "", description: "" } if we want to enforce confirmation modal before processing
  };

  if (isLocked && !hasOsTerminated) {
    dataset.text = 'Unlock';
    dataset.type = 'unlock';
    dataset.processingText = 'Unlocking';
  } else if (status === OS_STATUS.PENDING_PAYMENT_COMPLETION) {
    dataset.text = isDetailsPage ? 'Cancel Request' : 'Cancel';
    dataset.type = 'cancel';
    dataset.disabled = false;
    dataset.processingText = 'Cancelling';
  } else if (status === OS_STATUS.PENDING_QUALIFICATION) {
    dataset.text = isApproving ? 'Qualify' : 'Reject';
    dataset.type = 'qualification';
    dataset.toastMessage = {
      error: 'Something went wrong. Please try again!',
      success: 'Successfully approved the qualification request',
      info: 'Successfully rejected the qualification request'
    };
    dataset.onTrigger = () => {
      return new Promise(resolve => {
        approveAllQualificationRetexes(
          isApproving,
          retexes,
          onSetProcessing,
          onUpdateRetexData,
          resolve
        );
      });
    };
    dataset.processingText = isApproving ? 'Qualifying' : 'Rejecting';
    dataset.alertModalData = isApproving
      ? null
      : {
          title: 'Reject qualification for entire request',
          description:
            'If you reject any qualification line item, the entire request will be rejected and the shopper will have to recreate the request. Are you sure you want to continue?',
          buttonText: 'Reject entire request',
          buttonProcessingText: 'Reject entire request'
        };
  } else if (status === OS_STATUS.PENDING_AT_ADMIN) {
    dataset.text = isApproving ? 'Approve' : 'Reject';
    dataset.type = 'admin-approval';
    dataset.toastMessage = {
      error: 'Something went wrong. Please try again!',
      success: 'Successfully approved the request',
      info: 'Successfully rejected the request'
    };
    dataset.onTrigger = () => {
      return new Promise(resolve => {
        approveAdminPendingRetexes(
          isApproving,
          retexes,
          onSetProcessing,
          onUpdateRetexData,
          resolve
        );
      });
    };
    dataset.processingText = isApproving ? 'Approving' : 'Rejecting';
  } else if (status === OS_STATUS.PENDING_AT_STORE) {
    const retex = retexes.find(retex => {
      const status = _get(retex, 'status', null);
      return status === 'AWAITING_STORE_AUTHORIZATION';
    });
    dataset.text = isDetailsPage ? (
      'Approve at Store'
    ) : (
      <UiKit.GridRow
        gap={4}
        templateColumns="auto auto"
        className="align-items-center"
      >
        Store <LinkIcon width={12} height={12} />
      </UiKit.GridRow>
    );
    dataset.type = 'store-approval';
    dataset.disabled = !hasStore;
    if (hasStore && retex) {
      dataset.onTrigger = () => {
        return new Promise(resolve => {
          const retexId = _get(retex, 'id', '');
          resolve();
          push(`/s/service/${retexId}`);
        });
      };
    }
    if (!hasStore) {
      dataset.tooltipMessage = (
        <>You don’t have access to the store. You can’t approve this request.</>
      );
    }
  } else if (status === OS_STATUS.PENDING_AT_WAREHOUSE) {
    const retex = retexes.find(retex => {
      const status = _get(retex, 'status', null);
      return status === 'AWAITING_WH_AUTHORIZATION';
    });
    dataset.text = isDetailsPage ? (
      'Approve at Warehouse'
    ) : (
      <UiKit.GridRow
        gap={4}
        templateColumns="auto auto"
        className="align-items-center"
      >
        Warehouse <LinkIcon width={12} height={12} />
      </UiKit.GridRow>
    );
    dataset.type = 'warehouse-approval';
    dataset.disabled = !hasWarehouse;
    if (hasWarehouse && retex) {
      dataset.onTrigger = () => {
        return new Promise(resolve => {
          const retexName = _get(retex, 'name', '');
          resolve();
          push(`/w/inspection/${encodeURIComponent(retexName)}`);
        });
      };
    }
    if (!hasWarehouse) {
      dataset.tooltipMessage = (
        <>
          You don’t have access to the warehouse. You can’t approve this
          request.
        </>
      );
    }
  } else {
    dataset.show = false;
  }

  return dataset;
}
