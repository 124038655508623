import React from 'react';
import { StepText, ProgressIndicator } from 'containers/TourGuides/components';
import { renderToString } from 'react-dom/server';

import {
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import ADMIN_ROUTES from 'config/nav/admin/routes';
import CONFIGURE_ROUTES from 'views/Configure/stubs/routes';

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getSteps({ push }) {
  return [
    {
      beforeShowPromise() {
        return beforeShowPromise({ push, path: ADMIN_ROUTES.OVERVIEW.ROOT });
      },
      title: 'Welcome to Return Rabbit!',
      text: renderToString(
        <>
          <StepText>
            We're so happy to have you. Let us take you through a tour of our
            portal.
            <br />
            <br />
            For any assistance, please feel free to reach us on{' '}
            <a
              href="mailto:support@returnrabbit.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@returnrabbit.com
            </a>
          </StepText>
          <ProgressIndicator current={1} total={22} />
        </>
      ),
      buttons: [{ text: 'Start tour', type: 'next' }]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-customer-portal'),
        on: 'top'
      },
      beforeShowPromise() {
        return beforeShowPromise({ push, path: ADMIN_ROUTES.OVERVIEW.ROOT });
      },
      title: 'Shopper Portal URL',
      text: renderToString(
        <>
          <StepText>
            This is the link which your shoppers can access to place new service
            requests on their existing orders. Your shopper portal is highly
            configurable.
            <br />
            <br />
            You should include the returns portal link on your Returns Policy
            page within your store front so shoppers can access this link
            easily.
          </StepText>
          <ProgressIndicator current={2} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-overview'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ push, path: ADMIN_ROUTES.OVERVIEW.ROOT });
      },
      title: 'Overview Section',
      text: renderToString(
        <>
          <StepText>
            Overview section contains basic analytics while much deeper analysis
            can be found on the Analytics section.
            <br />
            <br />
            This is also where you will find all the service requests which need
            your attention.
          </StepText>
          <ProgressIndicator current={3} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('overview-workload'),
        on: 'top'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.OVERVIEW.ROOT,
          selector: this.attachTo.element
        });
      },
      title: 'Your Workload',
      text: renderToString(
        <>
          <StepText>
            Your workload contains the requests that require your attention in
            order to be resolved. There are different cards under this section
            as per the state of the request.
            <br />
            <br />
            Any request which is automated as per your automation rules, will
            not show up here as this only shows the list of items which need
            manual intervention.
          </StepText>
          <ProgressIndicator current={4} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-orders'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ push, path: ADMIN_ROUTES.ORDERS.ROOT });
      },
      title: 'Store Orders',
      text: renderToString(
        <>
          <StepText>
            This section includes all of your store order. As soon as a new
            order is created, we sync it automatically.
            <br />
            <br />
            You can also create new requests for your shoppers directly from the
            admin portal by clicking on Create Request button.
          </StepText>
          <ProgressIndicator current={5} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-requests'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ push, path: ADMIN_ROUTES.REQUESTS.ROOT });
      },
      title: 'Service Requests',
      text: renderToString(
        <>
          <StepText>
            This is a list of all the service requests placed by your shoppers.
            While Overview section shows only the list of items which need an
            action from your team, this one is a full list of all your requests.
          </StepText>
          <ProgressIndicator current={6} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-analytics'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ push, path: ADMIN_ROUTES.ANALYTICS.ROOT });
      },
      title: 'Analytics Section',
      text: renderToString(
        <>
          <StepText>
            The analytics section contains in-depth data points, dashboards and
            insights that are built from your returns data and shopper
            behaviour.
            <br />
            <br />
            You can not only utilize this data to make highly impactful
            decisions about your returns process and policies but also
            understand what is getting returned, how often and why! There's also
            an option to schedule custom reports to be sent to your email as per
            your preferance.
          </StepText>
          <ProgressIndicator current={7} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-configure'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.CONFIGURE_RR.ROOT,
          delay: 300
        });
      },
      title: 'Configure Section',
      highlightClass: 'shepherd-target-custom-highlight',
      text: renderToString(
        <>
          <StepText>
            This is the section where you can control the configurations for
            your return policies, shopper portal UI and the shipping settings.
          </StepText>
          <ProgressIndicator current={8} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-configure-return-management'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.CONFIGURE_RR.ROOT,
          selector: this.attachTo.element
        });
      },
      title: 'Return Management',
      highlightClass: 'shepherd-target-custom-highlight',
      canClickTarget: true,
      advanceOn: {
        selector: getShepherdSelector('sidebar-configure-return-management'),
        event: 'click'
      },
      text: renderToString(
        <>
          <StepText>
            This is where you can set up refund methods, request reasons,
            exchanges, tag based policies and automation rules.
          </StepText>
          <br />
          <StepText callout={false}>
            Click <code>Return Management</code> button to continue.
          </StepText>
          <ProgressIndicator current={9} total={22} />
        </>
      ),
      buttons: [{ text: 'Back', type: 'back', secondary: true }]
    },
    {
      attachTo: { element: getShepherdSelector('card-exchanges'), on: 'left' },
      canClickTarget: true,
      title: 'Configure Exchanges',
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: CONFIGURE_ROUTES.RETURN_MANAGEMENT,
          selector: this.attachTo.element
        });
      },
      advanceOn: {
        selector: `${getShepherdSelector('card-exchanges')} a`,
        event: 'click'
      },
      highlightClass: 'shepherd-target-custom-highlight-link',
      text: renderToString(
        <>
          <StepText>
            This is the section where you can configure you exchange
            preferences. This section is what can bring you additional revenue
            so this is probably where you would want to spend a lot of time.
          </StepText>
          <br />
          <StepText callout={false}>
            Click <code>Manage</code> button to continue.
          </StepText>
          <ProgressIndicator current={10} total={22} />
        </>
      ),
      buttons: [{ text: 'Back', type: 'back', secondary: true }]
    },
    {
      attachTo: {
        element: getShepherdSelector('exchanges-allow-toggle'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          // push,
          // path: CONFIGURE_ROUTES.EXCHANGES,
          selector: this.attachTo.element
        });
      },
      title: 'Allow Exchanges',
      text: renderToString(
        <>
          <StepText>
            This is the global exchange toggle. If you turn it off, your
            shoppers will not see any exchange options or any exchange language
            throughout your shopper portal.
            <br />
            <br />
            We have seen our merchants retain up to 75% return transactions via
            exchanges so understanding this section is very crucial to maximise
            your ROI from Return Rabbit.
          </StepText>
          <ProgressIndicator current={11} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('exchanges-benefits'),
        on: 'top'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: CONFIGURE_ROUTES.EXCHANGES,
          selector: this.attachTo.element
        });
      },
      title: 'Exchanges Benefits',
      text: renderToString(
        <>
          <StepText>
            This is where you can configure your exchange product catalog,
            exchange recommendations & any incentives that you would like to
            offer your shoppers if they choose to exchange.
          </StepText>
          <ProgressIndicator current={12} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('exchanges-features'),
        on: 'top'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: CONFIGURE_ROUTES.EXCHANGES,
          selector: this.attachTo.element
        });
      },
      title: 'Exchanges Configuration',
      text: renderToString(
        <>
          <StepText>
            This is where you can control many additional exchange preferences
            like allowing exchanges to be servicable again, offer discount
            forwarding and much more.
          </StepText>
          <ProgressIndicator current={13} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-configure-shipping'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.CONFIGURE_RR.ROOT,
          selector: this.attachTo.element
        });
      },
      canClickTarget: true,
      title: 'Shipping Settings',
      advanceOn: {
        selector: getShepherdSelector('sidebar-configure-shipping'),
        event: 'click'
      },
      highlightClass: 'shepherd-target-custom-highlight',
      text: renderToString(
        <>
          <StepText>
            This is where you control all your shipping preferences like
            Shipping Label preferences, shipping fee deductions & control your
            shipping carriers.
          </StepText>
          <br />
          <StepText callout={false}>
            Click <code>Shipping</code> button to continue.
          </StepText>
          <ProgressIndicator current={14} total={22} />
        </>
      ),
      buttons: [{ text: 'Back', type: 'back', secondary: true }]
    },
    {
      attachTo: {
        element: getShepherdSelector('card-aggregators'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.CONFIGURE_RR.SHIPPING,
          selector: this.attachTo.element
        });
      },
      canClickTarget: true,
      title: 'Aggregators',
      advanceOn: {
        selector: `${getShepherdSelector('card-aggregators')} a`,
        event: 'click'
      },
      highlightClass: 'shepherd-target-custom-highlight-link',
      text: renderToString(
        <>
          <StepText>
            Setting up an aggregator is necessary to provide shipping labels to
            your shoppers. You can either set up your ShipEngine account or
            others supported by Return Rabbit.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on <code>Manage</code> button to continue.
          </StepText>
          <ProgressIndicator current={15} total={22} />
        </>
      ),
      buttons: [{ text: 'Back', type: 'back', secondary: true }]
    },
    {
      title: 'Preferred Partners',
      beforeShowPromise,
      text: renderToString(
        <>
          <StepText className="mb-2">
            Here you can setup one of the Return Rabbit's preffered Aggregator
            partner to offer shipping labels to the shoppers for seamless
            experience.
          </StepText>
          <ProgressIndicator current={16} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('aggregator-card-shipengine'),
        on: 'top'
      },
      title: 'ShipEngine Setup',
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: CONFIGURE_ROUTES.AGGREGATORS,
          selector: this.attachTo.element
        });
      },
      text: renderToString(
        <>
          <StepText className="mb-2">
            ShipEngine is our preferred partner and our plaform of choice. With
            ShipEngine, you get auto-refunds, QR codes and much more.
          </StepText>
          <ProgressIndicator current={17} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-configure-shopper-portal'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      canClickTarget: true,
      title: 'Shopper Portal Settings',
      advanceOn: {
        selector: getShepherdSelector('sidebar-configure-shopper-portal'),
        event: 'click'
      },
      highlightClass: 'shepherd-target-custom-highlight',
      text: renderToString(
        <>
          <StepText>
            This section helps you tune your shopper portal to fully match your
            brand and theme.
          </StepText>
          <br />
          <StepText callout={false}>
            Click <code>Shopper Portal</code> button to continue.
          </StepText>
          <ProgressIndicator current={18} total={22} />
        </>
      ),
      buttons: [{ text: 'Back', type: 'back', secondary: true }]
    },
    {
      attachTo: {
        element: getShepherdSelector('shopper-portal-cards'),
        on: 'bottom'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: CONFIGURE_ROUTES.SHOPPER_PORTAL,
          selector: this.attachTo.element
        });
      },
      text: renderToString(
        <>
          <StepText>
            You can configure your logo, theme colors, login page, e-mail
            templates and so much more!
          </StepText>
          <ProgressIndicator current={19} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-settings'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      title: 'Settings',
      text: renderToString(
        <>
          <StepText>
            This section will help you Manage Your Subscription, Users & your
            API tokens.
          </StepText>
          <ProgressIndicator current={20} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ]
    },
    {
      attachTo: {
        element: getShepherdSelector('sidebar-help-and-support'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      title: 'Help',
      canClickTarget: true,
      advanceOn: {
        selector: getShepherdSelector('sidebar-help-and-support'),
        event: 'click'
      },
      highlightClass: 'shepherd-target-custom-highlight',
      text: renderToString(
        <>
          <StepText>
            We're always here to help you with whatever you might need. You can
            access our Product Wiki, help section or re-take this tour from
            here.
          </StepText>
          <br />
          <StepText callout={false}>
            Click <code>Help</code> button to continue.
          </StepText>
          <ProgressIndicator current={21} total={22} />
        </>
      ),
      buttons: [{ text: 'Back', type: 'back', secondary: true }]
    },
    {
      attachTo: {
        element: getShepherdSelector('card-take-tour'),
        on: 'bottom'
      },
      title: 'Welcome Tour',
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ADMIN_ROUTES.HELP_AND_SUPPORT.ROOT,
          selector: this.attachTo.element
        });
      },
      text: renderToString(
        <>
          <StepText>
            If you ever wish to revist this tour, you can always come to the
            Help page and start the tour here.
          </StepText>
          <ProgressIndicator current={22} total={22} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Done', type: 'next' }
      ]
    }
  ];
}
