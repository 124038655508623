import React, { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _isPlainObject from 'lodash/isPlainObject';

import { AlertBanner, ContainerCard, StoreConnectHeader } from '../components';

const ConnectStoreContext = createContext({
  referrer: '',
  trialPeriod: '',
  isConnecting: false,
  connect: (connectData = {}) => false,
  isError: false,
  errorMessage: ''
});

const OAUTH_BEGIN_API_ROUTE = {
  SHOPIFY: '/auth/shopify/begin/',
  WOOCOMMERCE: '/auth/woo/begin/'
};

/**
 * @param {Object} arguments
 * @param {import("utils/types").EcommPlatformSource} arguments.store
 * @param {import("react").ReactNode} arguments.children
 */
export function ConnectStoreProvider({ store = '', children = null }) {
  const { search } = useLocation();
  const [isConnecting, setIsConnecting] = useState(false);

  const { referrer, free_trial: trialPeriod, error, msg } = qs.parse(
    search.slice(1)
  );
  const isError = error === '1';
  const errorMessage = isError && msg ? msg : '';

  const connect = (data = null) => {
    const route = OAUTH_BEGIN_API_ROUTE[store];

    if (!_isPlainObject(data) || _isEmpty(data) || !route) return;

    setIsConnecting(true);

    const queryData = { ...data };
    if (referrer && trialPeriod) {
      queryData.referrer = referrer;
      queryData.trial_period = trialPeriod;
    }
    const queryString = qs.stringify(queryData);
    window.location = `${process.env.REACT_APP_BASE_URL}${route}?${queryString}`;
  };

  return (
    <ConnectStoreContext.Provider
      value={{
        connect,
        isConnecting,
        referrer,
        trialPeriod,
        isError,
        errorMessage
      }}
    >
      <AlertBanner />
      <ContainerCard title={<StoreConnectHeader store={store} />}>
        {children}
      </ContainerCard>
    </ConnectStoreContext.Provider>
  );
}

export function useConnectStore() {
  const context = useContext(ConnectStoreContext);

  if (!context) {
    throw new Error(
      'useConnectStore must be used within a ConnectContextProvider'
    );
  }

  return context;
}
