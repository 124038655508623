import React, { Component } from 'react';
import { Alert, Card, CardBody, Button, CardTitle } from 'reactstrap';

import { If, getUrlParameter } from 'utils';
import { resetPassword } from 'utils/services';
import { PasswordStrength } from 'components';

class Request extends Component {
  state = {
    success: null,
    error: null,
    processing: null,
    isPasswordStrong: false
  };

  componentDidMount() {
    const email =
      getUrlParameter(window.location, 'email').replace(' ', '+') || null;
    const token = getUrlParameter(window.location, 'reset_token') || null;
    this.setState({
      email,
      token
    });
  }

  changePassword = () => {
    const { email, token, password = '', confirmPassword = '' } = this.state;

    if (password === '' || confirmPassword === '') {
      this.setState({
        error:
          (!password && 'Please enter password and confirm password') ||
          (!confirmPassword && 'Please enter confirm password')
      });
      return;
    }
    if (password.length < 6) {
      this.setState({
        error: 'Password must be atleast 6 characters long.'
      });
      return;
    }
    if (password !== confirmPassword) {
      this.setState({
        error: 'Passwords do not match.'
      });
      return;
    }
    const obj = {
      reset_token: token,
      email,
      password: password,
      confirmed_password: confirmPassword
    };
    this.setState({
      processing: true
    });
    resetPassword(
      obj,
      () => {
        this.setState({
          success: true,
          processing: false
        });
      },
      () => {
        this.setState({
          success: false,
          processing: false
        });
      }
    );
  };
  handlePasswordInput = e => {
    this.setState({
      password: e.target.value,
      error: null
    });
  };
  confirmPasswordInput = e => {
    this.setState({
      confirmPassword: e.target.value,
      error: null
    });
  };

  passwordStrengthMetCb = isPasswordStrong => {
    this.setState({
      isPasswordStrong
    });
  };

  render() {
    const { success, error, processing } = this.state;
    const disableInputs = processing || success !== null;
    return (
      <Card className="login-card">
        <CardBody>
          <CardTitle className="text-center">Change your password</CardTitle>
          <div>
            <form>
              <div className="form-group">
                <input
                  className="form-control login-input"
                  onChange={this.handleChange}
                  name="email"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  disabled={true}
                  value={this.state.email}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control login-input"
                  name="email"
                  id="exampleInputEmail1"
                  type="password"
                  aria-describedby="emailHelp"
                  placeholder="New Password"
                  onChange={this.handlePasswordInput}
                  value={this.state.password}
                  disabled={disableInputs}
                />
              </div>
              <PasswordStrength
                password={this.state.password}
                onChange={this.passwordStrengthMetCb}
              />
              <div className="form-group">
                <input
                  className="form-control login-input"
                  name="email"
                  id="exampleInputEmail1"
                  type="password"
                  aria-describedby="emailHelp"
                  placeholder="Confirm New Password"
                  onChange={this.confirmPasswordInput}
                  value={this.state.confirmed_password}
                  disabled={disableInputs}
                />
              </div>
              <If test={processing === false}>
                <Alert color={success ? 'success' : 'danger'}>
                  {success
                    ? 'Your password has been reset successfully. Please login with your new password.'
                    : "Something went wrong. Please check if you've already used the link to reset password."}
                </Alert>
              </If>
              <If test={error}>
                <Alert color="danger">{error}</Alert>
              </If>
              <div className="form-group">
                <Button
                  style={{ width: '100%' }}
                  color="primary"
                  type="button"
                  className="login-button"
                  onClick={this.changePassword}
                  disabled={
                    this.state.success ||
                    disableInputs ||
                    !this.state.isPasswordStrong
                  }
                >
                  {processing
                    ? 'Processing...'
                    : success === true
                    ? 'Done'
                    : success === false
                    ? 'Error'
                    : 'Reset Password '}
                </Button>
              </div>
            </form>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default Request;
