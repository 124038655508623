import React, { useContext, useRef, useEffect } from 'react';
import _get from 'lodash/get';
import _size from 'lodash/size';
import styles from './index.module.scss';

import { UiKit, DispositionMode } from 'components';
import { RetexContext } from 'components/RetexDetails';
import { COMMENT_OPTIONS } from '../../stubs';

const KEYS = {
  MODE: 'disposition.mode',
  COMMENT: 'disposition.comment',
  CUSTOM_COMMENT: 'disposition.custom_comment'
};
const MODE_OPTIONS = [
  { label: 'Restock', value: 'resale' },
  { label: 'Don’t restock', value: 'scrap' }
];
const MAX_CHARS = 200;

export default function Disposition() {
  const {
    retex,
    orderService,
    isDisabled,
    isApproved,
    isRejected,
    isAwatitingAtAdmin,
    onUpdateRetex
  } = useContext(RetexContext);
  const textareaRef = useRef();

  const dispositionMode = _get(retex, KEYS.MODE, null);
  const comment = _get(retex, KEYS.COMMENT, null);
  const customComment = _get(retex, KEYS.CUSTOM_COMMENT, '');
  const isLocked = _get(orderService, 'on_hold', false);

  const showCustomInput = _get(comment, 'value') === 'Other';
  const charactersUsed = _size(customComment);
  const disableActions = isDisabled || isLocked || !isAwatitingAtAdmin;

  useEffect(() => {
    if (showCustomInput && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [showCustomInput]);

  const onModeChange = mode => {
    onUpdateRetex({ disposition: { mode } });
  };

  const onSelectComment = comment => {
    onUpdateRetex({ disposition: { comment } });
  };

  const onCustomCommentChange = comment => {
    const customComment = comment.substring(0, MAX_CHARS);
    onUpdateRetex({ disposition: { custom_comment: customComment } });
  };

  return (
    <UiKit.GridRow templateColumns="1fr" gap="15">
      <h3 className={styles.title}>Disposition</h3>
      {isApproved || isRejected ? (
        <DispositionMode mode={dispositionMode} />
      ) : (
        <UiKit.Radio
          inline
          name="product-disposition"
          options={MODE_OPTIONS}
          selectedOption={dispositionMode}
          onChange={onModeChange}
          disable={disableActions}
          wrapperClassName={styles.restockContainer}
          className={styles.restock}
          textClassName={styles.restockLabel}
        />
      )}
      <UiKit.Select
        isClearable
        label="Additional Comment"
        options={COMMENT_OPTIONS}
        value={comment}
        onChange={onSelectComment}
        isDisabled={disableActions}
        placeholder="Select a comment(Optional)"
        wrapperClassName="mt-1"
      />
      {showCustomInput && (
        <div className={styles.customComment}>
          <div className={styles.wordLimit}>
            {charactersUsed} / {MAX_CHARS} characters
          </div>
          <UiKit.Textarea
            ref={textareaRef}
            label="Enter your comment"
            value={customComment}
            onChange={onCustomCommentChange}
            disabled={disableActions}
            placeholder="Please enter the comment here."
            rows="4"
          />
        </div>
      )}
    </UiKit.GridRow>
  );
}
