import React, { useContext } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { UiKit } from 'components';
import { AuthContext } from 'context/AuthContext';
import { ReactComponent as EyeIcon } from 'assets/img/icons/eye.svg';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';

export default function ExternalUserBanner({
  className = '',
  hasMaxWidth = false
}) {
  const { isExternalUser, externalUserAccess, storeProperties } = useContext(
    AuthContext
  );

  const storeName = _get(storeProperties, 'store_name', '');
  const storeUniqueName = _get(storeProperties, 'store_unique_name', '');

  const isReadMode = externalUserAccess === 'READ';
  const mode = isReadMode ? 'Read Only' : 'Edit';
  const icon = isReadMode ? (
    <EyeIcon className={styles.readIcon} />
  ) : (
    <EditIcon className={styles.writeIcon} />
  );

  if (!isExternalUser || !externalUserAccess) return null;

  return (
    <div
      className={cn(styles.container, className, {
        [styles.readMode]: isReadMode,
        [styles.writeMode]: !isReadMode
      })}
    >
      <UiKit.MaxWidthContainer
        className={cn(styles.wrapper, { [styles.stretch]: !hasMaxWidth })}
      >
        <UiKit.GridRow
          templateColumns="180px 1fr 180px"
          className={styles.content}
        >
          <div className={styles.mode}>
            {icon}
            <strong>{mode} Access</strong>
          </div>
          <p className={styles.helptext}>
            Visiting &quot;{storeName}&quot; ({storeUniqueName}) with{' '}
            <strong>{mode} access</strong>.{' '}
            <If test={!isReadMode}>
              <span>Changes will reflect on the </span>
              <strong>Merchant portal of the Client</strong>.
            </If>
          </p>
        </UiKit.GridRow>
      </UiKit.MaxWidthContainer>
    </div>
  );
}
