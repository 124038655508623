import _isString from 'lodash/isString';

export default function createTooltipId(keyword = '') {
  if (!_isString(keyword)) return null;

  return keyword
    .toLowerCase()
    .split(' ')
    .join('-');
}
