import React from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { formatPrice } from 'utils/formatters';
import { OverflowText, ProductVariantAttributes } from 'components';

export default function PurchasedVariant({
  data = {},
  amount = 0,
  quantity = 0,
  currency = '',
  attributeShowCount = 2,
  containerClassName = ''
}) {
  const imageUrl = _get(data, 'image_url', '');
  const title = _get(data, 'title', '').toLowerCase();
  const optionValues = _get(data, 'option_values', {});

  const price = formatPrice(amount, currency);
  const containerClass = cn(styles.container, 'light-text', containerClassName);

  return (
    <div className={containerClass}>
      <img src={imageUrl} alt="line item" className={styles.image} />
      <div className={styles.info}>
        <OverflowText
          text={title}
          preventLineBreak
          className={styles.title}
          tooltipClassName={styles.tooltip}
        />
        <ProductVariantAttributes
          optionValues={optionValues}
          showCount={attributeShowCount}
        />
        <div className={styles.row}>
          <span>Quantity: {quantity}</span>
          &nbsp;|&nbsp;
          <span>Sub-total: {price}</span>
        </div>
      </div>
    </div>
  );
}
