import React from 'react';
import styles from './index.module.scss';

import { useNotifications } from 'utils/hooks';
import { UiKit } from 'components';

export default function MarkAllAsRead() {
  const { markAllAsRead, isReadingNotification } = useNotifications();
  return (
    <div>
      <UiKit.Button
        customType="outline"
        onClick={markAllAsRead}
        className={styles.markBtn}
        wrapperClassName={styles.markBtnWrapper}
        disabled={isReadingNotification}
      >
        Mark all as read
      </UiKit.Button>
    </div>
  );
}
