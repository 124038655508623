import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import Error from '../Error';
import { InfoLink } from 'components/Tables/CustomTable';

export default function Container({
  title = '',
  id = '',
  docUrl = '',
  description = '',
  cssStyles = {},
  legend = null,
  children = null,
  compact = false,
  isError = false,
  onReload = () => {},
  className = '',
  headerClassName = '',
  titleClassName = '',
  contentClassName = ''
}) {
  const containerClass = useMemo(
    () => cn(styles.container, { [className]: !!className }),
    [className]
  );

  const headerClass = useMemo(
    () => cn(styles.header, { [headerClassName]: !!headerClassName }),
    [headerClassName]
  );

  const titleClass = useMemo(
    () => cn(styles.title, { [titleClassName]: !!titleClassName }),
    [titleClassName]
  );

  const contentClass = useMemo(() => {
    return cn(styles.content, {
      [styles.compact]: !!compact,
      [contentClassName]: !!contentClassName
    });
  }, [compact, contentClassName]);

  return (
    <div className={containerClass} style={cssStyles}>
      <div className={headerClass}>
        <div className={styles.headerContent}>
          <div className={titleClass}>
            <h4>{title}</h4>
            {docUrl && <InfoLink id={id} url={docUrl} />}
          </div>
          {description && <p className={styles.description}>{description}</p>}
        </div>
        {legend && <div className={styles.legend}>{legend}</div>}
      </div>
      <div className={contentClass}>
        <Error isError={isError} onReload={onReload}>
          {children}
        </Error>
      </div>
    </div>
  );
}
