import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { If } from 'utils';
import { InfoBanner } from 'components';
import { ReactComponent as FlagIcon } from 'assets/img/icons/flag.svg';

export default function InfoFlagBanner({
  title = '',
  subTitle = '',
  className = ''
}) {
  if (!title && !subTitle) return null;

  return (
    <InfoBanner
      className={cn(styles.container, className)}
      icon={
        <div className={styles.iconWrapper}>
          <FlagIcon className={styles.icon} />
        </div>
      }
      messageClassName={styles.message}
    >
      <If test={title}>
        <div className={styles.title}>{title}</div>
      </If>
      <If test={subTitle}>
        <div className={styles.subTitle}>{subTitle}</div>
      </If>
    </InfoBanner>
  );
}
