import React, { useContext } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';

export default function ServiceReason() {
  const { retex } = useContext(RetexContext);

  const reason = _get(retex, 'request_reason', '');
  const subReason = _get(retex, 'request_sub_reasons', {});
  const subReasonQuestion = _get(retex, 'request_sub_reasons.question', '');
  const subReasonAnswers = _get(retex, 'request_sub_reasons.answers', []);

  const hasSubReason = subReason && !_isEmpty(subReason);

  const subReasonAnswersList = subReasonAnswers.map((answer, index) => {
    return (
      <li key={index} className={styles.value}>
        {answer}
      </li>
    );
  });

  return (
    <UiKit.GridRow gap="20">
      <div className={styles.cell}>
        <span className={styles.label}>Return Reason</span>
        <p className={styles.value}>{reason}</p>
      </div>
      {hasSubReason && (
        <div className={styles.cell}>
          <span className={styles.label}>Sub-Reason</span>
          <span className={styles.helptext}>{subReasonQuestion}</span>
          <ul className={styles.list}>{subReasonAnswersList}</ul>
        </div>
      )}
    </UiKit.GridRow>
  );
}
