import _empty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

import { post, logError } from 'utils';

export default function getProducts(
  ids = [],
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  const hasNoIds = _empty(ids);
  if (hasNoIds) return onSuccess([]);

  post({ apiKey: 'products.post' }, { params: { product_ids: ids } })
    .then(({ data }) => {
      const products = _isArray(data) ? data : [];
      onSuccess(products);
    })
    .catch(error => {
      logError(
        new Error({
          error,
          meta: {
            productIds: ids,
            errorMsg: 'fetching products failed'
          }
        })
      );
      onError(error);
    })
    .finally(onFinally);
}
