import React from 'react';

function SubHeading({ customClass, text }) {
  return (
    <div className={customClass ? customClass : 'text-center'}>
      {text || ''}
    </div>
  );
}

export default SubHeading;
