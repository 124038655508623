import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';
import qs from 'qs';
import styles from './index.module.scss';

import { If } from 'utils';
import { ROUTES } from '../../stubs';
import { ReactComponent as BackIcon } from 'assets/img/icons/previous-page.svg';

export default function ContainerCard({
  title = 'Connect with Return Rabbit',
  children = null
}) {
  const { pathname, search } = useLocation();

  const { referrer, free_trial } = qs.parse(search.slice(1));
  const queryParams = qs.stringify({ referrer, free_trial });

  const isRootPage = pathname === ROUTES.ROOT;
  const backRoute = `${ROUTES.ROOT}${queryParams ? `?${queryParams}` : ''}`;

  return (
    <Card className={styles.card}>
      <CardBody className={styles.body}>
        <div className={styles.header}>
          <If test={!isRootPage}>
            <Link to={backRoute} className={styles.link}>
              <BackIcon /> Back
            </Link>
          </If>
          <CardTitle className={styles.title}>{title}</CardTitle>
        </div>
        {children}
      </CardBody>
    </Card>
  );
}
