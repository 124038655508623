import React from 'react';
import styles from './index.module.scss';

export default function ErrorFallback({
  isError = false,
  onReload = () => {},
  children = null
}) {
  return !isError ? (
    children
  ) : (
    <div className={styles.container}>
      <p>Unable to fetch the data!</p>
      <button onClick={onReload}>Try Reloading</button>
    </div>
  );
}
