import { logError, put } from 'utils';

export default function acknowledgeNotification(
  params = {},
  onDone = () => {}
) {
  put({ apiKey: 'notifications.acknowledge' }, { params })
    .then(() => onDone())
    .catch(err => {
      logError(err);
      onDone(err);
    });
}
