import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import Loader from '../Loader';

export default function Content({
  isLoading = false,
  cssStyles = {},
  className = '',
  loaderClassName = '',
  children = null
}) {
  const containerClassName = useMemo(
    () => cn(styles.container, { [className]: !!className }),
    [className]
  );

  return (
    <div className={containerClassName} style={cssStyles}>
      <Loader isLoading={isLoading} className={loaderClassName} />
      {children}
    </div>
  );
}
