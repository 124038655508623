import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';

import { If } from 'utils';
import ErrorMessage from './ErrorMessage';
import { getInputClassName } from './methods';
import { ReactComponent as LockIcon } from 'assets/img/icons/lock-stroke.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/icons/delete-muted.svg';
import { ReactComponent as SearchIcon } from 'assets/img/icons/search.svg';

export default function Input({
  label = '',
  helptext = '',
  value = '',
  error = '',
  hideErrorMessage = false,
  locked = false,
  disabled = false,
  readOnly = false,
  searchIcon = false,
  deletable = false,
  isSecondary = false,
  onChange = () => {},
  onDelete = () => {},
  inputContainerStyle = {},
  wrapperStyle = {},
  labelStyle = {},
  inputStyle = {},
  className = '',
  labelClassName = '',
  inputContainerClassName = '',
  inputWrapperClassName = '',
  inputClassName = '',
  errorClass = '',
  children = null,
  inputRef = null,
  ...inputHtmlProps
}) {
  const isDisabled = locked || disabled || readOnly;
  const inputReference = uuidv4();
  const attachClass = getInputClassName({
    isSecondary,
    deletable,
    isDisabled,
    value,
    error
  });

  const onInputChange = e => onChange(e, e.target.value);

  const wrapperClass = cn('uiKit__element', {
    [className]: !!className
  });
  const labelWrapClass = cn('uiKit__element--labelWrapper', {
    [`${className}__labelWrapper`]: !!className,
    [labelClassName]: !!labelClassName
  });
  const inputContainerClass = cn('uiKit__input__container', {
    [inputContainerClassName]: !!inputContainerClassName
  });
  const inputWrapClass = cn('uiKit__input--wrapper', {
    [`${className}__inputWrapper`]: !!className,
    [inputWrapperClassName]: !!inputWrapperClassName
  });
  const inputClass = cn('uiKit__input', {
    [attachClass]: !!attachClass,
    [`${className}__input`]: !!className,
    [inputClassName]: !!inputClassName
  });
  const errorClassName = cn('uiKit__input--error', {
    [errorClass]: !!errorClass
  });

  return (
    <div className={wrapperClass} style={wrapperStyle}>
      <If test={label}>
        <div className={labelWrapClass}>
          <label htmlFor={inputReference} style={labelStyle}>
            {label}
          </label>
          <p>{helptext}</p>
        </div>
      </If>
      <div className={inputContainerClass} style={inputContainerStyle}>
        <div className={inputWrapClass}>
          <input
            {...inputHtmlProps}
            ref={inputRef}
            id={inputReference}
            value={value}
            onChange={!isDisabled ? onInputChange : null}
            style={inputStyle}
            className={inputClass}
            disabled={isDisabled}
          />
          <If test={searchIcon}>
            <SearchIcon className="lockIcon" />
          </If>
          <If test={locked}>
            <LockIcon className="lockIcon" />
          </If>
          {children}
        </div>
        <If test={deletable}>
          <button
            type="button"
            onClick={onDelete}
            className="uiKit__input--action"
            disabled={readOnly}
          >
            <DeleteIcon className="deleteIcon" />
          </button>
        </If>
      </div>
      <ErrorMessage
        msg={error}
        className={errorClassName}
        hide={isDisabled || hideErrorMessage}
      />
    </div>
  );
}
