import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Alert } from 'reactstrap';

import { If } from 'utils';
import { fetchRetailers, requestLink } from 'utils/services';
import StepTwo from './StepTwo';
import StepOne from './StepOne';

const defaultState = {
  step: 1,
  email: '',
  success: null,
  processing: null,
  retailerList: []
};

export default class Request extends Component {
  state = defaultState;
  handleChange = e => {
    this.setState({
      email: e.target.value
    });
  };

  fetchRetailerList = ({ email }) => {
    fetchRetailers(
      { email },
      payload => {
        if (payload.retailers.length > 1) {
          this.setState({
            email,
            step: 2,
            retailerList: payload.retailers.map(entry => {
              return { value: entry, label: entry };
            })
          });
        } else {
          this.requestLink({ email });
        }
      },
      () => {
        this.setState({ loading: false });
        // this.setError('msg');
      }
    );
  };
  reset = () => {
    this.setState(Object.assign({}, defaultState));
  };
  requestLink = values => {
    this.setState({ processing: true });
    requestLink(
      values,
      () => this.setState({ success: true, processing: false }),
      () => this.setState({ success: false, processing: false })
    );
  };
  render() {
    const { success, processing, step } = this.state;
    return (
      <Card className="request-link login-card">
        <CardBody>
          <CardTitle className="text-center">Reset your password</CardTitle>
          <p className="text-light description">
            To reset your password, enter the email address you use to sign in.
          </p>
          <div>
            <If test={step === 1}>
              <StepOne
                requestLink={this.fetchRetailerList}
                processing={processing}
                success={success}
              />
            </If>
            <If test={step === 2}>
              <StepTwo
                requestLink={this.requestLink}
                processing={processing}
                success={success}
                email={this.state.email}
                list={this.state.retailerList}
              />
            </If>

            <If test={success !== null}>
              <Alert color={success ? 'success' : 'danger'}>
                {success
                  ? `Check your ${this.state.email} inbox for instructions from us on how to reset your password.`
                  : 'Something went wrong. Please try again in sometime.'}
              </Alert>
            </If>
          </div>
          <If test={step === 2}>
            <span className="text-right reset-link" onClick={this.reset}>
              Reset form
            </span>
          </If>
        </CardBody>
      </Card>
    );
  }
}
