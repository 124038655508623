import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
// import * as mixpanel from 'mixpanel-browser';

import { logError } from 'utils';
import localStore from '../localStore.js';

export default function identifyUser() {
  const logRocketApp = process.env.REACT_APP_LOG_ROCKET_APP_ID;
  const sentryApp = process.env.REACT_APP_SENTRY_APP_ID;
  // const mixpanelApp = process.env.REACT_APP_MIXPANEL_ID;

  try {
    let user = localStore.get('userData') || {};
    if (!user || typeof user !== 'string') {
      return;
    }
    user = JSON.parse(user);
    if (!user || !user.userId) {
      return;
    }
    const { userId, email, firstName, lastName } = user;
    const name = `${firstName} ${lastName}`;
    // if (mixpanelApp) {
    // const userProperties = {
    //   $first_name: firstName,
    //   $last_name: lastName
    // };
    // mixpanel.identify(userId);
    // mixpanel.people.set(userProperties);
    // }
    if (logRocketApp) {
      LogRocket.identify(userId, { name, email });
    }
    if (sentryApp) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: userId, username: name, email });
      });
    }
  } catch (e) {
    logError(e);
  }
}
