import React, { useContext } from 'react';

import { Loader } from 'components';
import { TableZeroState } from 'components/Tables';
import Error from './Error';
import { ReactTablesContext } from '../..';

export default function SearchState({
  noRecordsTitle,
  noRecordsHelptext,
  errorMessage = 'There was an error fetching the data',
  children = null
}) {
  const { searchState } = useContext(ReactTablesContext);
  const { isFetchingData, isDataFetchingError, isEmpty } = searchState;

  if (!isFetchingData && isDataFetchingError) {
    return <Error message={errorMessage} />;
  } else if (!isFetchingData && isEmpty) {
    return (
      <TableZeroState title={noRecordsTitle} helptext={noRecordsHelptext} />
    );
  }

  return (
    <>
      <Loader loading={isFetchingData} className="table-loader" />
      {children}
    </>
  );
}
