import { put } from 'utils';

export default function approveRetex(
  payload = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  put({ apiKey: 'retex.process' }, payload)
    .then(({ statusCode, data }) => {
      if (statusCode === 208) {
        const err = new Error('Already processed.');
        err.displayMessage = 'Request was already processed.';
        err.statusCode = 208;
        throw err;
      }
      onSuccess(data);
    })
    .catch(onError)
    .finally(onFinally);
}
