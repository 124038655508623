import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import cn from 'classnames';
import _get from 'lodash/get';
import _has from 'lodash/has';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { ReactTablesContext } from 'components/ReactTable';

const PICKER_DATE_FORMAT = 'M j, Y';
const MOMENT_DATE_FORMAT = 'MMM D, YYYY';
const DEFAULT_MIN = moment()
  .subtract(2, 'y')
  .startOf('D')
  .unix();
const DEFAULT_MAX = moment()
  .endOf('D')
  .unix();

export default function TableRefinementDate({
  facetKey = '',
  isMilliSeconds = false
}) {
  const {
    refine,
    facets,
    refinements,
    clearRefinement,
    facetsToRefinementKeyMap,
    searchState: { isFetchingFacets }
  } = useContext(ReactTablesContext);

  const refinementKey = _get(facetsToRefinementKeyMap, facetKey, facetKey);
  const hasFacetsKey = _has(facets, facetKey, false);
  const hasDateFilter = _has(refinements, refinementKey, false);

  const refinementData = _get(refinements, `${refinementKey}.values`, {});
  const facetData = _get(facets, facetKey, [DEFAULT_MIN, DEFAULT_MAX]);

  const datePickerClassName = cn(styles.datePicker, {
    [styles.noDatePlaceholder]: !hasDateFilter && !hasFacetsKey
  });

  const [minDate, maxDate] = facetData;
  let { min: minRefinementDate, max: maxRefinementDate } = refinementData;
  if (isMilliSeconds) {
    minRefinementDate /= 1000;
    maxRefinementDate /= 1000;
  }

  const facetStartDate = moment.unix(minDate).utc();
  const facetEndDate = moment.unix(maxDate).utc();
  const refinementStartDate = moment.unix(minRefinementDate).utc();
  const refinementEndDate = moment.unix(maxRefinementDate).utc();
  const isEndDateToday = moment()
    .utc()
    .isSame(refinementEndDate, 'day');
  const daysDifference = refinementEndDate.diff(refinementStartDate, 'days');

  const isLast7daysFilter = isEndDateToday && daysDifference === 6;
  const isLast30daysFilter = isEndDateToday && daysDifference === 29;
  const isOfEntireRange =
    !(refinementKey in refinements) ||
    (facetStartDate.isSame(refinementStartDate, 'day') &&
      facetEndDate.isSame(refinementEndDate, 'day'));

  const minAllowedDate = facetStartDate.format(MOMENT_DATE_FORMAT);
  const maxAllowedDate = facetEndDate.format(MOMENT_DATE_FORMAT);
  let fromDate = minAllowedDate;
  let toDate = maxAllowedDate;
  if (refinementKey in refinements) {
    fromDate = refinementStartDate.format(MOMENT_DATE_FORMAT);
    toDate = refinementEndDate.format(MOMENT_DATE_FORMAT);
  }

  const doRefine = days => () => {
    const min = moment()
      .endOf('day')
      .utc()
      .startOf('day')
      .subtract(days - 1, 'days')
      .unix();
    const max = moment()
      .endOf('day')
      .utc()
      .endOf('day')
      .unix();
    const value = {
      min: isMilliSeconds ? min * 1000 : min,
      max: isMilliSeconds ? max * 1000 : max
    };
    refine({ attribute: 'date', type: 'range', value: value });
  };

  const resetDate = () => {
    clearRefinement({ attribute: 'date', type: 'range' });
  };

  const onUpdateDateRange = ([rangeMin, rangeMax]) => {
    if (rangeMin && rangeMax) {
      const min = moment(rangeMin)
        .endOf('day')
        .utc()
        .startOf('day')
        .unix();
      const max = moment(rangeMax)
        .endOf('day')
        .utc()
        .endOf('day')
        .unix();
      const value = {
        min: isMilliSeconds ? min * 1000 : min,
        max: isMilliSeconds ? max * 1000 : max
      };
      refine({ attribute: 'date', type: 'range', value });
    }
  };

  return minDate && maxDate ? (
    <div className="date-range">
      <div className="select-buttons">
        <Button
          data-selected={isLast7daysFilter}
          onClick={doRefine(7)}
          disabled={isFetchingFacets}
        >
          Last 7 Days
        </Button>
        <Button
          data-selected={isLast30daysFilter}
          onClick={doRefine(30)}
          disabled={isFetchingFacets}
        >
          Last 30 Days
        </Button>
        <Button
          data-selected={isOfEntireRange}
          onClick={resetDate}
          disabled={isFetchingFacets}
        >
          All Time
        </Button>
      </div>
      <div className="custom-dates">
        <UiKit.DatePicker
          date={[fromDate, toDate]}
          className="range-input"
          setDate={onUpdateDateRange}
          inputWrapClassName={datePickerClassName}
          flatpickerProps={{
            disabled: isFetchingFacets,
            placeholder: MOMENT_DATE_FORMAT,
            options: {
              mode: 'range',
              dateFormat: PICKER_DATE_FORMAT,
              minDate: minAllowedDate,
              maxDate: 'today'
            }
          }}
        />
      </div>
    </div>
  ) : (
    <div className="text-muted">No filters available!</div>
  );
}
