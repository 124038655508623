import React, { useRef, useState, useEffect, useCallback } from 'react';
import Cropper from 'react-cropper';
import cn from 'classnames';
import _get from 'lodash/get';

import { If } from 'utils';
import { uploadFile } from 'utils/services';
import { CustomToast } from 'components';
import { EditAction } from 'views/Configure/ShopperPortal/components';

import styles from './index.module.scss';
import placeholder from 'assets/img/icons/placeholder.svg';

import ZoomInIcon from 'assets/img/icons/new/zoom-in-icon.svg';
import ZoomOutIcon from 'assets/img/icons/new/zoom-out-icon.svg';

import 'cropperjs/dist/cropper.css';

const EDIT_ICON_POSITTION = { top: '15px', left: '20px' };
const CROPPER_MAX_ZOOM = 4;

const Upload = ({
  isProcessing = false,
  imageSrc = '',
  sizeLimit = 2, // size in MB
  editIconPosition = EDIT_ICON_POSITTION,
  onCropped = () => {},
  onEditing = () => {}
}) => {
  const [newUrl, setNewUrl] = useState('');
  const [isHoveringOnEditIcon, setIsHoveringOnEditIcon] = useState(false);
  const [openCropper, setOpenCropper] = useState(false);
  const [editImage, setEditImage] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cropper, setCropper] = useState();
  const [cropZoom, setCropZoom] = useState(0);
  const fileTypeRef = useRef();

  const formRef = useRef(null);
  const postCropCallback = useRef(onCropped);

  useEffect(() => {
    if (newUrl) {
      postCropCallback.current(newUrl, () => setUploadProgress(0));
    }
  }, [newUrl]);

  useEffect(() => {
    if (onEditing) {
      onEditing(editImage);
    }
  }, [editImage, onEditing]);

  const onEditHoverStart = () => setIsHoveringOnEditIcon(true);
  const onEditHoverEnd = () => setIsHoveringOnEditIcon(false);

  const resetImage = () => {
    setUploadedFile(null);
    setOpenCropper(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const onFileChange = file => {
    let fileData = _get(file, 'target.files[0]', null);
    if (!fileData) return;

    const fileSize = _get(fileData, 'size', 0) / 1000000; // Size in MB

    if (fileSize > sizeLimit) {
      return CustomToast({
        type: 'error',
        msg: 'File size should be less than 2MB'
      });
    }

    fileTypeRef.current = _get(fileData, 'type', '');
    const reader = new FileReader();
    reader.onload = function() {
      setUploadedFile(reader.result);
      setOpenCropper(true);
    };
    reader.readAsDataURL(fileData);
  };

  const onUpload = imageData => {
    if (!uploadedFile) return;
    setUploadingImage(true);
    uploadFile(
      {
        file: imageData,
        tags: 'browser_upload,tracking-page'
      },
      ({ loaded, total }) => {
        const percent = Math.round((loaded / total) * 100) - 5;
        setUploadProgress(percent);
      },
      data => {
        const url = _get(data, 'secure_url', '');
        setNewUrl(url);
      },
      err => {
        CustomToast({
          isNotified: err.notified,
          type: 'error',
          msg: 'Something went wrong while uploading your file'
        });
      },
      () => {
        setUploadingImage(false);
        setEditImage(false);
        resetImage();
      }
    );
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      const croppedImg = cropper
        .getCroppedCanvas()
        .toDataURL(fileTypeRef.current);
      onUpload(croppedImg);
    }
  };

  const cropWrapperClass = cn(
    styles.configurator,
    styles.wrapper,
    'imageCropperConfigure',
    'imageCropperWrapper',
    {
      highlight: isHoveringOnEditIcon
      // preview: isPreview
    }
  );

  const actionButtonsClassname = cn('py-2 px-3', {
    [styles.uploadActions]: uploadedFile,
    [styles.centered]: !uploadedFile
  });

  const zoomIn = useCallback(e => {
    e.stopPropagation();
    setCropZoom(preVal =>
      preVal >= CROPPER_MAX_ZOOM ? CROPPER_MAX_ZOOM : preVal + 0.5
    );
  }, []);

  const zoomOut = useCallback(e => {
    e.stopPropagation();
    setCropZoom(preVal => (preVal <= 0 ? 0 : preVal - 0.5));
  }, []);

  return (
    <div className={cropWrapperClass}>
      <EditAction
        isActive={editImage}
        styles={editIconPosition}
        onClick={() => setEditImage(prevState => !prevState)}
        onMouseOver={onEditHoverStart}
        onMouseLeave={onEditHoverEnd}
        className={styles.editIcon}
      />
      {editImage && openCropper ? (
        <Cropper
          style={{ height: '100%' }}
          zoomTo={cropZoom}
          // initialAspectRatio={1}
          preview=".img-preview"
          src={uploadedFile}
          viewMode={3}
          // minCropBoxHeight={10}
          // minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={instance => {
            setCropper(instance);
          }}
          guides={true}
          // dragMode={'move'}
          center
          cropBoxResizable={false}
        />
      ) : imageSrc ? (
        <div
          className={styles.brandImage}
          style={{ backgroundImage: `url(${imageSrc})` }}
          data-shepherd="login-page-brand-image"
        />
      ) : (
        <div className={styles.placeholder + ' mt-3 mb-3'}>
          <div className={styles.placeholderImage}>
            <img src={placeholder} alt="placeholder" />
            <div className={styles.text}>No image Available</div>
          </div>
        </div>
      )}
      <If test={editImage}>
        <div className={styles.uploadWrapper}></div>
        <div className={actionButtonsClassname}>
          <If test={!uploadingImage}>
            <div className="d-flex justify-content-between align-items-center">
              <form ref={formRef} className={styles.form}>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={onFileChange}
                  className={styles.uploadInput}
                />
                <span>Size Limit: 2MB</span>
              </form>
              <If test={uploadedFile}>
                <div>
                  <button onClick={resetImage} className={styles.resetBtn}>
                    Reset
                  </button>
                  <button onClick={getCropData} className={styles.confirmBtn}>
                    Apply
                  </button>
                </div>
              </If>
            </div>
          </If>
          <If test={uploadingImage || isProcessing}>
            <progress id="file" value={uploadProgress} max="100" />
          </If>
        </div>
      </If>
      <If test={editImage}>
        <If test={!uploadingImage}>
          <If test={uploadedFile}>
            <div className={styles.sideActions}>
              <img src={ZoomInIcon} alt="zoom-in" onClick={zoomIn} />
              <img src={ZoomOutIcon} alt="zoom-out" onClick={zoomOut} />
            </div>
          </If>
        </If>
      </If>
    </div>
  );
};

const Container = ({
  height = '588px',
  width = '58%',
  className = '',
  children = null
}) => (
  <div style={{ height, width }} className={className}>
    {children}
  </div>
);

export { Container, Upload };
