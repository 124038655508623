import React, { useContext } from 'react';

import { If } from 'utils';
import { NotificationsBanner } from 'components';
import { AuthContext } from 'context/AuthContext';

const NotificationsBannerPortal = () => {
  const { appType } = useContext(AuthContext);

  return (
    <If test={appType.retex}>
      <NotificationsBanner.Container />
    </If>
  );
};

export default NotificationsBannerPortal;
