import React from 'react';
import styles from './index.module.scss';
import { ICON_BG_COLOR_CODES, NOTIFICATION_ICON } from '../../stubs';

const NotificationIcon = ({ type }) => {
  const NotificationIcon = NOTIFICATION_ICON[type];
  const notificationIconStyle = {
    backgroundColor: ICON_BG_COLOR_CODES[type]
  };
  return (
    <div className={styles.notificationIcon} style={notificationIconStyle}>
      {NotificationIcon}
    </div>
  );
};

export default NotificationIcon;
