import { post } from 'utils';

export default function createRetailerNote(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  post({ apiKey: 'retailerNotes.post' }, { params })
    .then(res => onSuccess(res.data))
    .catch(onError)
    .finally(onFinally);
}
