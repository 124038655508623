import React, { useContext, useRef } from 'react';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';

import { getTourOptions } from 'containers/TourGuides/methods';
import { getSteps } from './methods';

export function HelpTour({ children }) {
  const steps = useRef(getSteps()).current;
  const tourOptions = useRef(getTourOptions({ tourName: 'show-help' })).current;

  return (
    <ShepherdTour steps={steps} tourOptions={tourOptions}>
      {children}
    </ShepherdTour>
  );
}

export function HelpTourTrigger({
  onClick = () => {},
  children = null,
  ...buttonProps
}) {
  const tour = useContext(ShepherdTourContext);

  const clickHandler = () => {
    tour.start();
    onClick();
  };

  return (
    <button onClick={clickHandler} {...buttonProps}>
      {children}
    </button>
  );
}
