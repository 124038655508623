import React, { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import { AppFooter, AppHeader } from '@coreui/react';
import cn from 'classnames';

import { CustomLazyLoad } from 'utils';
import { ExternalUserBanner } from 'components';
import ErrorBoundary from './ErrorBoundary';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import ChangePassword from 'views/ChangePassword';
import { OnlineCheck } from 'components/Common';
import { AuthContext } from 'context/AuthContext';

const RetexAppOnboarding = CustomLazyLoad(() => import('views/OnBoarding'));
const TrackingAppOnboarding = CustomLazyLoad(() =>
  import('views/OnboardingTrackingApp')
);

export default function EmptyLayout() {
  const { pathname } = useLocation();
  const { appType } = useContext(AuthContext);

  const Component = useMemo(() => {
    if (appType.retex) return RetexAppOnboarding;
    else if (appType.shipmentTracking) return TrackingAppOnboarding;
    return () => null;
  }, [appType]);

  const containerClassName = cn('default-layout-container', {
    retexAppContainer: appType.retex,
    trackingAppContainer: appType.shipmentTracking
  });

  return (
    <div className="app EmptyLayout">
      <div className="app-body">
        <ExternalUserBanner
          hasMaxWidth
          className="EmptyLayout__externalUserBanner"
        />
        <AppHeader fixed id="app-header">
          <DefaultHeader />
        </AppHeader>
        <main className="main">
          <OnlineCheck />
          <ErrorBoundary location={pathname}>
            <Container fluid className={containerClassName}>
              <ToastContainer />
              <Component />
            </Container>
            <ChangePassword />
          </ErrorBoundary>
          <AppFooter>
            <DefaultFooter />
          </AppFooter>
        </main>
      </div>
    </div>
  );
}
