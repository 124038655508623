import React, { useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { UiKit } from 'components';

const INCENTIVE_TYPE = {
  FIXED: 'fixed',
  PERCENT: 'percentage'
};

const INCENTIVE_OPTIONS = [
  { label: 'Fixed Incentive', value: INCENTIVE_TYPE.FIXED },
  { label: 'Percentage Incentive', value: INCENTIVE_TYPE.PERCENT }
];

export default function IncentiveType({
  data = {},
  isDisabled = false,
  onChange = () => {},
  errors = {}
}) {
  const incentiveType = _get(
    data,
    'incentives.discount_type',
    INCENTIVE_OPTIONS[0]
  );

  const selectedOption = _get(data, 'incentives.discount_type.value', null);
  const incentiveValue = _get(data, 'incentives.incentive_amount', '0');
  const incentiveMaxAmount = _get(
    data,
    'incentives.ceiling_incentive_amount',
    ''
  );
  const typeError = _get(errors, `incentives.discount_type`, null);
  const valueError = _get(errors, `incentives.incentive_amount`, null);
  const maxAmountError = _get(
    errors,
    `incentives.ceiling_incentive_amount`,
    null
  );

  const { isFixedType, isPercentType } = useMemo(() => {
    return {
      isFixedType: selectedOption === INCENTIVE_TYPE.FIXED,
      isPercentType: selectedOption === INCENTIVE_TYPE.PERCENT
    };
  }, [selectedOption]);

  const valueInputPlaceholder = useMemo(() => {
    const label = isFixedType ? '$4' : isPercentType ? '10%' : '';
    return label ? `say, ${label}` : '';
  }, [isFixedType, isPercentType]);

  const inputWrapperClassName = useMemo(() => {
    return cn(styles.valueInputWrapper, {
      [styles.fixed]: isFixedType,
      [styles.percent]: isPercentType
    });
  }, [isPercentType, isFixedType]);

  const renderErrors = useMemo(() => {
    const hasError = !!(typeError || valueError || maxAmountError);
    if (!hasError) return null;

    return (
      <UiKit.GridRow gap="10" templateColumns="1fr">
        {typeError && <div className={styles.error}>{typeError}</div>}
        {valueError && <div className={styles.error}>{valueError}</div>}
        {maxAmountError && <div className={styles.error}>{maxAmountError}</div>}
      </UiKit.GridRow>
    );
  }, [typeError, valueError, maxAmountError]);

  const capInputWrapperClassName = cn(styles.valueInputWrapper, styles.fixed);

  const onIncentiveTypeChange = option => {
    onChange(`incentives.discount_type`, option);
    const ifOptionValuePercentage = _get(option, 'value');
    if (ifOptionValuePercentage) {
      onChange(
        `incentives.incentive_amount`,
        Math.trunc(Number(incentiveValue))
      );
    }
  };

  const onInputChange = event => {
    const { name, value } = event.target;

    let regexToBeUsed = /^[0-9]*$/;
    if (isFixedType && name === 'incentives.incentive_amount') {
      regexToBeUsed = /^\d*(\.)?(\d{0,2})?$/;
    }
    if (isPercentType && name === 'incentives.ceiling_incentive_amount') {
      regexToBeUsed = /^\d*(\.)?(\d{0,2})?$/;
    }
    const isValid = regexToBeUsed.test(value);

    if (!isValid) return;
    onChange(name, value);
  };

  return (
    <UiKit.GridRow gap="10" templateColumns="1fr">
      <UiKit.GridRow gap="16" templateColumns="2fr 1fr">
        <UiKit.Select
          label={<b>Incentive Type</b>}
          value={incentiveType}
          options={INCENTIVE_OPTIONS}
          onChange={onIncentiveTypeChange}
          placeholder="Select an option"
          isDisabled={isDisabled}
          error={!!typeError}
        />
        <UiKit.Input
          label={<b>Value</b>}
          name={`incentives.incentive_amount`}
          value={incentiveValue}
          onChange={onInputChange}
          placeholder={valueInputPlaceholder}
          disabled={isDisabled || !incentiveType}
          inputWrapperClassName={inputWrapperClassName}
          error={!!valueError}
        />
      </UiKit.GridRow>
      {isPercentType && (
        <UiKit.GridRow
          gap="16"
          templateColumns="2fr 1fr"
          className="align-items-center"
        >
          <span className={styles.capLabel}>Enter the cap for incentive</span>
          <UiKit.Input
            name={`incentives.ceiling_incentive_amount`}
            value={
              incentiveMaxAmount === null || incentiveMaxAmount === 'null'
                ? ''
                : incentiveMaxAmount
            }
            onChange={onInputChange}
            placeholder="say, $4"
            disabled={isDisabled}
            inputWrapperClassName={capInputWrapperClassName}
            error={!!maxAmountError}
          />
        </UiKit.GridRow>
      )}
      {renderErrors}
    </UiKit.GridRow>
  );
}
