import get from 'lodash/get';
import isFilledArray from './isFilledArray';
import pathExists from './pathExists';

/**
 * Validate if there is a filled Array at the path specified inside the object
 * Useful for checeking if an ID Array has data before making API calls
 * @param  {Obj} obj
 * @param  {string} path
 * @return {Boolean}
 */
export default function filledArrayPathExists(obj, path) {
  if (!obj || !pathExists(obj, path)) {
    return false;
  }
  const arr = get(obj, path);
  return isFilledArray(arr);
}
