import { parallel } from 'async';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { getOrderService, getRetexesOfOrderService } from 'utils/services';
import { modifyRetexes } from 'views/Requests/methods';

export default function loadPageData(
  osId = '',
  onSuccess = () => {},
  onError = () => {}
) {
  if (!osId) {
    throw new Error('OS Id must be provided to the function');
  }

  parallel(
    {
      orderService: callback => {
        getOrderService(
          { routeParam: osId },
          data => callback(null, data),
          error => callback(error, null)
        );
      },
      retexes: callback => {
        getRetexesOfOrderService(
          { routeParams: { id: osId } },
          retexes => callback(null, retexes),
          error => callback(error, null)
        );
      }
    },
    (err, result) => {
      if (err) return onError(err);
      const { orderService, retexes } = result;

      const osStatus = _get(orderService, 'status', '');
      const orderName = _get(orderService, 'order_info.name', '');
      _set(orderService, 'order_name', orderName);
      const modifiedRetexes = modifyRetexes(osStatus, retexes);

      onSuccess(orderService, modifiedRetexes);
    }
  );
}
