import React from 'react';

export default function Lock() {
  return (
    <svg width="14" height="21" viewBox="0 0 14 21">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#1b2228" transform="translate(-782.000000, -107.000000)">
          <g transform="translate(782.000000, 107.000000)">
            <path d="M2.07356705,4.92443295 C2.07356705,2.2046868 4.27834272,0 6.998,0 C9.71765728,0 11.9224329,2.20477567 11.9224329,4.92443295 L11.9224329,9.07156705 C11.9224329,9.5009609 11.5742614,9.84913248 11.1448675,9.84913248 C10.7154737,9.84913248 10.3673021,9.5009609 10.3673021,9.07156705 L10.3673021,4.92443295 C10.3673021,3.06365222 8.85886959,1.55504199 6.99791114,1.55504199 C5.13695269,1.55504199 3.62852018,3.06365222 3.62852018,4.92443295 L3.62852018,9.07156705 C3.62852018,9.5009609 3.2803486,9.84913248 2.85095475,9.84913248 C2.42138318,9.84913248 2.07338933,9.5009609 2.07338933,9.07156705 L2.07356705,4.92443295 Z" />
            <path d="M7.77556543,13.4776527 L8.55313086,13.4776527 C8.98252471,13.4776527 9.33069629,13.1294811 9.33069629,12.7000872 C9.33069629,12.2706934 8.98252471,11.9225218 8.55313086,11.9225218 L5.44286914,11.9225218 C5.01347529,11.9225218 4.66530371,12.2706934 4.66530371,12.7000872 C4.66530371,13.1294811 5.01347529,13.4776527 5.44286914,13.4776527 L6.22043457,13.4776527 L6.22043457,15.810349 C6.22043457,16.2397428 6.56860615,16.5879144 6.998,16.5879144 C7.42739385,16.5879144 7.77556543,16.2397428 7.77556543,15.810349 L7.77556543,13.4776527 Z M2.85113248,8.29417935 C1.27640698,8.29417935 0,9.57058633 0,11.1451341 L0,17.3656575 C0,18.9403831 1.27640698,20.21679 2.85113248,20.21679 L11.1448675,20.21679 C12.719593,20.21679 13.996,18.9403831 13.996,17.3656575 L13.996,11.1451341 C13.996,9.5705419 12.719593,8.29417935 11.1448675,8.29417935 L2.85113248,8.29417935 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
