import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { UiKit } from 'components';

export default function Layout({ children, className }) {
  const containerClassName = cn(styles.container, className);

  return (
    <UiKit.GridRow
      gap="60"
      templateColumns="3fr 4fr"
      className={containerClassName}
    >
      {children}
    </UiKit.GridRow>
  );
}
