import React, { useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import cn from 'classnames';
import uuid from 'uuid/v4';
import _get from 'lodash/get';
import _isString from 'lodash/isString';
import 'flatpickr/dist/flatpickr.css';
import './index.scss';

import Button from '../Button';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/calendar.svg';

/**
 * Flatpicker docs: https://flatpickr.js.org/
 * Options: https://flatpickr.js.org/options/
 */
export default function DatePicker({
  label = '',
  date = null,
  setDate = () => {},
  flatpickerProps = {},
  error = '',
  className = '',
  cancelable = false,
  placeholder = 'Select Date',
  errorClass = '',
  labelClassName = '',
  inputClassName = '',
  inputWrapClassName = ''
}) {
  const inputId = useRef(uuid()).current;
  const isDisabled = _get(flatpickerProps, 'disabled', false);
  const showError = error && _isString(error) && !isDisabled;
  const isSingleType = _get(flatpickerProps, 'options.mode') === 'single';

  const wrapperClass = cn('datePicker', {
    [className]: !!className,
    disabled: !!isDisabled
  });

  const labelClass = cn(labelClassName, {
    [`${className}__label`]: !!className
  });

  const inputClass = cn(inputClassName, {
    [`${className}__input`]: !!className
  });

  const inputWrapClass = cn('datePicker__inputWrapper', inputWrapClassName, {
    [`${className}__inputWrapper`]: !!className
  });

  const errorClassName = cn('uiKit__input--error', {
    [errorClass]: !!errorClass
  });

  const onClear = () => {
    const resetValue = isSingleType ? null : [];
    setDate(resetValue);
  };

  return (
    <div className={wrapperClass}>
      {label && (
        <label className={labelClass} htmlFor={inputId}>
          {label}
        </label>
      )}
      <div className="datePicker__row">
        <div className={inputWrapClass}>
          <Flatpickr
            id={inputId}
            value={date}
            onChange={setDate}
            className={inputClass}
            placeholder={placeholder}
            {...flatpickerProps}
          />
          <CalendarIcon className="calendar-icon" />
        </div>
        {cancelable && (
          <Button
            customType="inline"
            className="datePicker__clearBtn"
            onClick={onClear}
          >
            Clear
          </Button>
        )}
      </div>
      {showError && <p className={errorClassName}>{error}</p>}
    </div>
  );
}
