import { useCallback, useState } from 'react';
import _get from 'lodash/get';
import _merge from 'lodash/merge';

import { patch, post, put, deleteRequest, logError } from 'utils';
import { CustomToast } from 'components';

const DEFAULT = {
  isPublicAPI: false,
  ignoreReadModeCheck: false,
  payload: {},
  routeParam: '',
  routeParams: {},
  axiosConfig: {},
  onValidate: () => true,
  onSuccess: () => {},
  onError: () => {},
  onFinally: () => {},
  successMessage: '',
  errorMessage: ''
};

const API_METHODS = {
  POST: () => post,
  PUT: () => put,
  PATCH: () => patch,
  DELETE: () => deleteRequest
};

/**
 * @callback OnValidate
 * @param {Object.<string, *>} payload API payload data
 * @returns {boolean} Return true if API should trigger, else false to prevent API call.
 */

/**
 * @callback OnSuccess
 * @param {Object} responseData reponse.data value
 * @param {Object} response API response
 */

/**
 * @callback OnError
 * @param {Object} error Error or AxiosError object
 * @param {Object} apiResponseData API Response
 */

/**
 * Configuration of the hook
 * @typedef {Object} Config
 * @property {boolean} [isPublicAPI] True to use Public Axios instance | False to use Private Axios instance.
 * @property {boolean} [ignoreReadModeCheck] To skip the check of read-mode and trigger the API.
 * @property {Object.<string, *>} [payload] API payload.
 * @property {string} [routeParam] API endpoint fragment to be added at the end of the API endpoint, usually dynamic UUID or slug.
 * @property {Object.<string, *>} [routeParams] Define the route dynamic parameters to that replaces the placeholder fragments (withing [] brackets) in the API endpoint.
 * @property {import('axios').AxiosRequestConfig} [axiosConfig] Axios Request Config object.
 * @property {OnValidate} [onValidate] Method to validate the payload. THe API is triggered only if this function returns a truthy value.
 * @property {OnSuccess} [onSuccess] Callback on successful data fetching.
 * @property {OnError} [onError] Callback on API request failure.
 * @property {Function} [onFinally] Callback on API request's `finally` block.
 * @property {string} [successMessage] Custom toast message in case the API returns with success status.
 * @property {string} [errorMessage] Custom toast message in case the API returns with failed status.
 */

/**
 * useMutation arguments
 * @param {string} apiKey
 * @param {"POST" | "PUT" | "PATCH" | "DELETE"} method
 * @param {Config} config
 */

export default function useMutation(apiKey, method, config = {}) {
  if (!apiKey) {
    throw new Error('API Key is required in the useMutation hook');
  } else if (!(method in API_METHODS)) {
    throw new Error(`"${method}" is not valid api method`);
  }

  const payload = _get(config, 'payload', DEFAULT.payload);
  const routeParam = _get(config, 'routeParam', DEFAULT.routeParam);
  const routeParams = _get(config, 'routeParams', DEFAULT.routeParams);
  const axiosConfig = _get(config, 'axiosConfig', DEFAULT.axiosConfig);
  const onValidate = _get(config, 'onValidate', DEFAULT.onValidate);
  const onSuccess = _get(config, 'onSuccess', DEFAULT.onSuccess);
  const onError = _get(config, 'onError', DEFAULT.onError);
  const onFinally = _get(config, 'onFinally', DEFAULT.onFinally);
  const errorMessage = _get(config, 'errorMessage', DEFAULT.errorMessage);
  const successMessage = _get(config, 'successMessage', DEFAULT.successMessage);
  const isPublicAPI = _get(config, 'isPublicAPI', DEFAULT.isPublicAPI);
  const ignoreReadModeCheck = _get(
    config,
    'ignoreReadModeCheck',
    DEFAULT.ignoreReadModeCheck
  );

  const [isProcessing, setIsProcessing] = useState(false);

  const mutate = useCallback(
    (payloadOverrides = {}) => {
      const updatedPayload = _merge({}, payload, payloadOverrides);
      const isValid = onValidate(updatedPayload);
      if (!isValid) return;

      const methodGen = _get(API_METHODS, method);
      const request = methodGen();

      setIsProcessing(true);
      request(
        { apiKey, ignoreReadModeCheck, noTokenRequired: isPublicAPI },
        {
          params: { routeParam, routeParams, ...updatedPayload },
          config: axiosConfig
        }
      )
        .then(response => {
          onSuccess(response.data, response);
          if (successMessage) {
            CustomToast({ type: 'success', msg: successMessage });
          }
        })
        .catch(error => {
          let errorObj = new Error(error);
          if (errorMessage && !error.notified) {
            errorObj = new Error(errorMessage);
            errorObj.data = { ...error };
            CustomToast({ type: 'error', msg: errorMessage });
          }
          logError(errorObj);
          onError(error, _get(error, 'response.data.data', null));
        })
        .finally(() => {
          setIsProcessing(false);
          onFinally();
        });
    },
    [
      method,
      apiKey,
      isPublicAPI,
      ignoreReadModeCheck,
      payload,
      routeParam,
      routeParams,
      axiosConfig,
      successMessage,
      errorMessage,
      onValidate,
      onSuccess,
      onError,
      onFinally
    ]
  );

  return { mutate, isProcessing };
}
