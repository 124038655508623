import React from 'react';
import cn from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';

import { If } from 'utils';
import { ReactComponent as ViewIcon } from 'assets/img/icons/eye.svg';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/icons/delete-muted.svg';

export default function Actions({
  id = '',
  viewBtnId = '',
  editBtnId = '',
  deleteBtnId = '',
  customClass = '',
  disableViewBtn = false,
  disableEditBtn = false,
  disableDeleteBtn = false,
  view = () => {},
  edit = () => {},
  remove = () => {},
  permissions = { view: false, edit: false, delete: false },
  tooltipMessage = { view: '', edit: '', delete: '' }
}) {
  if (!permissions.edit && !permissions.delete && !permissions.view) {
    return null;
  }

  const className = cn('CustomTable__Actions', {
    [customClass]: !!customClass
  });

  const viewBtnClassName = cn('CustomTable__Action', 'edit', {
    disabled: !!disableViewBtn
  });
  const editBtnClassName = cn('CustomTable__Action', 'edit', {
    disabled: !!disableEditBtn
  });
  const deleteBtnClassName = cn('CustomTable__Action', 'delete', {
    disabled: !!disableDeleteBtn
  });

  const onView = () => {
    if (disableViewBtn) return;
    view(id);
  };

  const onEdit = () => {
    if (disableEditBtn) return;
    edit(id);
  };

  const onDelete = () => {
    if (disableDeleteBtn) return;
    remove(id);
  };

  return (
    <div className={className}>
      <If test={permissions.view}>
        <button id={viewBtnId} onClick={onView} className={viewBtnClassName}>
          <ViewIcon />
        </button>
        <If test={!!tooltipMessage.view}>
          <UncontrolledTooltip target={viewBtnId} fade={false}>
            {tooltipMessage.view}
          </UncontrolledTooltip>
        </If>
      </If>
      <If test={permissions.delete}>
        <button
          id={deleteBtnId}
          onClick={onDelete}
          className={deleteBtnClassName}
        >
          <DeleteIcon />
        </button>
        <If test={!!tooltipMessage.delete}>
          <UncontrolledTooltip target={deleteBtnId} fade={false}>
            {tooltipMessage.delete}
          </UncontrolledTooltip>
        </If>
      </If>
      <If test={permissions.edit}>
        <button id={editBtnId} onClick={onEdit} className={editBtnClassName}>
          <EditIcon />
        </button>
        <If test={!!tooltipMessage.edit}>
          <UncontrolledTooltip target={editBtnId} fade={false}>
            {tooltipMessage.edit}
          </UncontrolledTooltip>
        </If>
      </If>
    </div>
  );
}
