import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { findCurrency } from 'utils/DICTIONARY';
import Input from '../UiKit/Input';

export default function PriceInput({
  label = '',
  name = '',
  value = '',
  step = 0.1,
  min = 0,
  max = '',
  inputRef = null,
  onChange = () => {},
  currency = 'USD',
  disabled = false,
  isSecondary = false,
  placeholder = 'say, $2',
  error = '',
  hideErrorMessage = false,
  wrapperStyle = null,
  className = ''
}) {
  const currencySymbol = useMemo(() => {
    return findCurrency(currency);
  }, [currency]);

  const currencyClassName = useMemo(() => {
    return cn(styles.currency, {
      [styles.disabled]: disabled,
      [styles.invalid]: error
    });
  }, [disabled, error]);

  const onInputChange = useCallback(
    event => {
      const isValid = /^\d*([.])?\d*$/.test(event.target.value);
      if (!isValid) return;
      onChange(event.target.value, event);
    },
    [onChange]
  );

  const onInputBlur = useCallback(
    event => {
      const value = event.target.value;
      const formattedValue = value ? `${+value}` : '';
      event.target.value = formattedValue;
      onChange(formattedValue, event);
    },
    [onChange]
  );

  const inputClassName = cn(styles.input, {
    [styles.paddingLeft]: currencySymbol
  });

  return (
    <Input
      inputRef={inputRef}
      type="number"
      label={label}
      name={name}
      value={value}
      onBlur={onInputBlur}
      onChange={onInputChange}
      min={min}
      max={max}
      step={step}
      error={error}
      disabled={disabled}
      placeholder={placeholder}
      isSecondary={isSecondary}
      hideErrorMessage={hideErrorMessage}
      inputClassName={inputClassName}
      inputContainerClassName={styles.inputContainer}
      wrapperStyle={wrapperStyle}
      className={className}
    >
      <span className={currencyClassName}>{currencySymbol}</span>
    </Input>
  );
}
