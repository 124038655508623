import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import CustomHeader from './CustomHeader';
import DefaultHeader from './DefaultHeader';
import { HeaderContext } from 'context/HeaderContext';

export default function Header() {
  const { isMounted } = useContext(HeaderContext);

  const contentClassName = cn(styles.content, { [styles.rollUp]: isMounted });

  return (
    <header className={styles.container}>
      <div className={contentClassName}>
        <div className={cn(styles.wrapper, styles.default)}>
          <DefaultHeader />
        </div>
        <div className={cn(styles.wrapper, styles.custom)}>
          <CustomHeader />
        </div>
      </div>
    </header>
  );
}
