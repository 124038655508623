import React, { useMemo } from 'react';

import { If } from 'utils';
import { useNotifications } from 'utils/hooks';
import NotificationList from '../NotificationList';
import {
  AllReadComponent,
  EndOfNotifications,
  NotificationError,
  NotificationSkeleteonLoader
} from '../../stubs';

export default function UnreadNotifications({ className = '' }) {
  const {
    unreadNotificationsList,
    unreadNotificationsError,
    isFetchingUnreadNotifications
  } = useNotifications();

  const unreadList = useMemo(
    () => unreadNotificationsList.filter(n => !n.is_read),
    [unreadNotificationsList]
  );

  return (
    <div className={className}>
      <If test={!unreadNotificationsError} otherwise={NotificationError}>
        <If
          test={!isFetchingUnreadNotifications}
          otherwise={NotificationSkeleteonLoader}
        >
          <If test={unreadList.length} otherwise={AllReadComponent}>
            <NotificationList list={unreadList} listType="unread" animated />
            {EndOfNotifications}
          </If>
        </If>
      </If>
    </div>
  );
}
