import React, { useRef, forwardRef } from 'react';
import uuid from 'uuid/v4';
import cn from 'classnames';
import _isString from 'lodash/isString';
import _size from 'lodash/size';

import { If } from 'utils';

function Textarea(
  {
    label = '',
    value = '',
    onChange = () => {},
    rows = 6,
    disabled = false,
    error = '',
    className = '',
    wrapperClassName = '',
    labelWrapperClassName = '',
    labelClassName = '',
    errorClassName = '',
    ...textareaProps
  },
  ref
) {
  const elementId = useRef(uuid()).current;
  const isEmpty = !value || (_isString(value) && _size(value) === 0);

  const wrapperClass = cn('uiKit__element', {
    [wrapperClassName]: !!wrapperClassName
  });
  const labelWrapClass = cn('uiKit__element--labelWrapper', {
    [labelWrapperClassName]: !!labelWrapperClassName
  });
  const textareaClass = cn('uiKit__textarea', {
    empty: isEmpty,
    'not-empty': !isEmpty,
    invalid: !!error,
    [className]: !!className
  });
  const errorClass = cn('uiKit__textarea--error', {
    [errorClassName]: !!errorClassName
  });

  return (
    <div className={wrapperClass}>
      <If test={label && typeof label === 'string'}>
        <div className={labelWrapClass}>
          <label htmlFor={elementId} className={labelClassName}>
            {label}
          </label>
        </div>
      </If>
      <textarea
        ref={ref}
        id={elementId}
        value={value}
        onChange={e => onChange(e.target.value, e)}
        className={textareaClass}
        rows={rows}
        disabled={disabled}
        {...textareaProps}
      />
      <If test={error && !disabled}>
        <p className={errorClass}>{error}</p>
      </If>
    </div>
  );
}

export default forwardRef(Textarea);
