import { get, logError } from 'utils';

export default function getConfiguredShopperList(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  get({ apiKey: 'configure.configuredShopperGroups.get' }, { params })
    .then(res => onSuccess(res))
    .catch(err => {
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
