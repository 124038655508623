import React, { Fragment } from 'react';

export default function FetchOptionsKeyValues({
  options = {},
  joinWith = ', ',
  customClass = ''
}) {
  const optionKeys = Object.keys(options);

  return optionKeys.map((key, index) => {
    const isLastElement = optionKeys.length - 1 === index;

    return (
      <Fragment key={key}>
        <span className={customClass ? customClass : 'product__info'}>
          {`${key}: ${options[key]}`}
        </span>
        {isLastElement ? '' : joinWith}
      </Fragment>
    );
  });
}
