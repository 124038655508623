import React from 'react';
import cn from 'classnames';

export default function Loader({
  loading = false,
  className = '',
  spinnerClassName = '',
  children = null
}) {
  const spinnerClass = cn('spinner', spinnerClassName);

  if (loading) {
    return (
      <div className={className}>
        <div className={spinnerClass} />
      </div>
    );
  }

  return children;
}
