import _get from 'lodash/get';
import _has from 'lodash/has';
import _isArray from 'lodash/isArray';

export function createPlanFeaturesMap(featurePlans = []) {
  const plans = _isArray(featurePlans) ? featurePlans : [];

  const planFeaturesMap = plans.reduce((acc, data) => {
    const key = _get(data, 'slug', '');
    acc[key] = data;
    return acc;
  }, {});

  const uniqueCategoryPlanFeaturesMap = Object.entries(planFeaturesMap).reduce(
    (acc, [key, data]) => {
      const features = _get(data, 'features', []);
      const uniqueCategoryFeatures = features.reduce((acc, feature) => {
        const category = _get(feature, 'category', '');
        if (!_has(acc, category)) {
          acc[category] = feature;
        }
        return acc;
      }, {});
      acc[key] = { ...data, features: Object.values(uniqueCategoryFeatures) };
      return acc;
    },
    {}
  );

  const featuresPlanMap = plans.reduce((acc, data) => {
    const planSlug = _get(data, 'slug', '');
    const features = _get(data, 'features', []);
    features.forEach(feature => {
      const featureSlug = _get(feature, 'slug', '');
      if (!_has(acc, featureSlug)) {
        acc[featureSlug] = {};
      }
      acc[featureSlug] = { ...acc[featureSlug], [planSlug]: true };
    });
    return acc;
  }, {});

  return { planFeaturesMap, featuresPlanMap, uniqueCategoryPlanFeaturesMap };
}

export function createFeaturesAccessMap(features = []) {
  return features.reduce((acc, feature) => {
    const featureSlug = _get(feature, 'slug', '');
    acc[featureSlug] = _get(feature, 'is_enabled', false);
    return acc;
  }, {});
}
