import {
  Container,
  Content,
  Legend,
  Tooltip,
  Loader,
  ArcLabel
} from './components';

const Chart = {
  Container,
  Content,
  Legend,
  LineChartTooltip: Tooltip.LineChartTooltip,
  BarChartTooltip: Tooltip.BarChartTooltip,
  Loader,
  ArcLabel
};

export default Chart;
export { Container, Content, Legend, Tooltip, Loader, ArcLabel };
