import React from 'react';
import { renderToString } from 'react-dom/server';

import { ProgressIndicator, StepText } from 'containers/TourGuides/components';
import {
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import ROUTES from 'views/Configure/stubs/routes';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getShopperPortalSteps({
  push = () => {},
  onShow = () => {},
  onHide = () => {}
}) {
  return [
    {
      id: 'intro',
      title: 'Shopper Portal Settings',
      beforeShowPromise() {
        return beforeShowPromise({ push, path: ROUTES.SHOPPER_PORTAL });
      },
      text: renderToString(
        <>
          <StepText>
            Let's configure how your shopper portal to match your brand theme.
          </StepText>
          <ProgressIndicator current={1} total={12} />
        </>
      ),
      buttons: [{ text: "Let's go", type: 'next' }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'shopper-portal-card',
      attachTo: {
        element: getShepherdSelector('card-theme'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.SHOPPER_PORTAL,
          selector: this.attachTo.element
        });
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>
            This section will enable you to configure your logo, favicon, brand
            & button colors.
          </StepText>
          <br />
          <StepText callout={false}>
            Click <code>Manage</code> button to begin.
          </StepText>
          <ProgressIndicator current={2} total={12} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector('card-theme')} a`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'theme-essentials',
      attachTo: {
        element: getShepherdSelector('shopper-portal-meta-data'),
        on: 'top'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>
            You can edit your brand logo, store title and favicon here. Just
            click on the{' '}
            <code>
              <EditIcon width={12} height={12} />
            </code>{' '}
            icon to set up.
          </StepText>
          <ProgressIndicator current={3} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'theme-colors',
      attachTo: {
        element: getShepherdSelector('shopper-portal-theme-colors'),
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            Your brand's primary color lives here. This will be used throughout
            the shopper portal as accent color and highlight color.
          </StepText>
          <ProgressIndicator current={4} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'theme-primary-button',
      attachTo: {
        element: getShepherdSelector('shopper-portal-btn-theme'),
        on: 'top'
      },
      text: renderToString(
        <>
          <StepText>
            Your buttons will be used throughout the shopper portal for tasks
            that the shopper can perform. Please make sure that the text is
            readable while configuring this.
          </StepText>
          <ProgressIndicator current={5} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'sidenav-shopper-portal',
      attachTo: {
        element: getShepherdSelector('sidebar-configure-shopper-portal'),
        on: 'right'
      },
      canClickTarget: true,
      title: "Let's explore more",
      text: renderToString(
        <>
          <StepText>
            Click here to go back to the shopper portal configurations.
          </StepText>
          <ProgressIndicator current={6} total={12} />
        </>
      ),
      advanceOn: {
        selector: getShepherdSelector('sidebar-configure-shopper-portal'),
        event: 'click'
      },
      beforeShowPromise() {
        return beforeShowPromise({ push, path: ROUTES.THEME });
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'login-page-card',
      attachTo: {
        element: getShepherdSelector('card-login-page'),
        on: 'right'
      },
      canClickTarget: true,
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.SHOPPER_PORTAL,
          selector: this.attachTo.element
        });
      },
      text: renderToString(
        <>
          <StepText>
            You can configure your login page here. This is the page that your
            shoppers see for the first time when they access Return Rabbit
            portal.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on <code>Manage</code> button to continue.
          </StepText>
          <ProgressIndicator current={7} total={12} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector('card-login-page')} a`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'login-page-phone-number-support',
      attachTo: {
        element: '.additional-features__listItem',
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>Allow your shoppers to login via phone number.</StepText>
          <ProgressIndicator current={9} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'login-page-templates',
      attachTo: {
        element: getShepherdSelector('login-page-templates'),
        on: 'right'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>Choose a template to paint your login page.</StepText>
          <ProgressIndicator current={8} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'login-page-brand-image',
      attachTo: {
        element: getShepherdSelector('login-page-brand-image'),
        on: 'left'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>
            You can add your brand image here by clicking on{' '}
            <code>
              <EditIcon width={12} height={12} />
            </code>{' '}
            icon.
          </StepText>
          <ProgressIndicator current={10} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'login-page-interaction',
      attachTo: {
        element: getShepherdSelector('login-page-input-interactions'),
        on: 'left'
      },
      text: renderToString(
        <>
          <StepText>
            Use this area to edit the fonts and colors of the icons that appear
            on the shopper portal.
          </StepText>
          <ProgressIndicator current={11} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'login-page-done',
      attachTo: {
        element: getShepherdSelector('login-page-copy-portal-url'),
        on: 'left'
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>
            Once all is done, you can see how your shopper portal looks by going
            to this URL.
            <br />
            <br />
            You can also contact us for a custom URL!
          </StepText>
          <ProgressIndicator current={12} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Done', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    }
  ];
}
