import React, { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import cn from 'classnames';
import styles from './index.module.scss';

import { ReactComponent as DocIcon } from 'assets/img/alert-icons/alert-circle.svg';

export default function InfoLink({
  url = '',
  id = 'info-icon-link',
  message = 'Know More',
  placement = 'top',
  className = '',
  infoOnly = false
}) {
  const wrapperClassName = useMemo(() => {
    return cn(styles.link, { [className]: !!className });
  }, [className]);

  if (!url && !infoOnly) return null;

  return (
    <a
      id={id}
      href={url}
      target="_blank"
      onClick={!infoOnly ? () => {} : e => e.preventDefault()}
      rel="noopener noreferrer"
      className={wrapperClassName}
    >
      <DocIcon />
      <UncontrolledTooltip target={id} placement={placement}>
        {message}
      </UncontrolledTooltip>
    </a>
  );
}
