import React, { createContext } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { PageErrorUi, UiKit } from 'components';
import {
  LoaderSkeleton,
  Header,
  KeepItemBanner,
  ProductVariants,
  ServiceReason,
  ShopperComment,
  AttachedImages,
  StatusAlert,
  AppliedRules,
  Timeline,
  InternalNotes,
  Disposition,
  AmountBreakdown,
  Actions,
  CycleRetexes,
  ShopperGroupIncentive
} from './components';
import { LockedReason } from 'components/OrderServiceDetails/components';
import { useRetex, useRetexes } from './hooks';
import { If } from 'utils';

export const RetexContext = createContext({
  retex: {},
  orderService: {},
  retexStatuses: {},
  approvalTypes: {},
  automatedRuleTypes: {},
  isApproved: false,
  isRejected: false,
  isCancelled: false,
  isAwatitingAtAdmin: false,
  isDisabled: false,
  showAction: false,
  onLoadData: () => {},
  onGotoRetex: () => {},
  onUpdateRetex: () => {},
  setIsDisabled: () => {},
  onUpdateOrderService: () => {}
});

const DEFAULT_ON_LOADED_CALLBACK = () => {};

export default function RetexDetails({
  osId = '',
  retexId = '',
  containerClassName = '',
  footerClassName = '',
  onGotoRetex = () => {},
  onLoaded = DEFAULT_ON_LOADED_CALLBACK
}) {
  const { isError = false, isLoading = false, ...contextData } = useRetex(
    osId,
    retexId,
    onLoaded
  );
  const retexesData = useRetexes(osId);

  const isLocked = _get(contextData.orderService, 'on_hold', false);
  const lockReason = _get(contextData.orderService, 'on_hold_reason', '');
  const wrapperClassName = cn(styles.container, containerClassName);

  return (
    <RetexContext.Provider
      value={{ onGotoRetex, ...retexesData, ...contextData }}
    >
      <PageErrorUi isError={isError} onReload={contextData.onLoadData}>
        <LoaderSkeleton isLoading={isLoading}>
          <div className={wrapperClassName}>
            <UiKit.GridRow gap="90">
              <UiKit.GridRow templateColumns="1fr" gap="20">
                <Header />
                <KeepItemBanner />
                <ProductVariants />
                <ServiceReason />
                <ShopperComment />
                <ShopperGroupIncentive />
                <AttachedImages />
              </UiKit.GridRow>
              <UiKit.GridRow templateColumns="1fr" gap="20">
                <UiKit.GridRow
                  gap="20"
                  templateColumns="1fr auto"
                  className={styles.row}
                >
                  <StatusAlert />
                  <CycleRetexes />
                </UiKit.GridRow>
                <If test={contextData.showAction}>
                  <LockedReason reason={isLocked ? lockReason : null} />
                </If>
                <UiKit.GridRow templateColumns="1fr" gap="15">
                  <AppliedRules />
                  <Timeline />
                  <InternalNotes />
                </UiKit.GridRow>
                <Disposition />
                <AmountBreakdown />
              </UiKit.GridRow>
            </UiKit.GridRow>
            <Actions className={footerClassName} />
          </div>
        </LoaderSkeleton>
      </PageErrorUi>
    </RetexContext.Provider>
  );
}
