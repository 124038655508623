import React, { useContext } from 'react';
import moment from 'moment';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { useEcomPlatform } from 'utils/hooks';
import { RetexContext } from 'components/RetexDetails';
import { getQualificationRetex } from 'components/RetexDetails/methods';
import { ReactComponent as ClockIcon } from 'assets/img/icons/clock.svg';
import { ReactComponent as LockIcon } from 'assets/img/icons/lock-fill.svg';
import { ReactComponent as CrossIcon } from 'assets/img/icons/cross-danger.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/check-success.svg';
import { OS_STATUS, RETEX_STATUS } from 'dictionary';

export default function StatusAlert() {
  const {
    isApproved,
    isRejected,
    isCancelled,
    retex,
    orderService,
    retexesDataMap,
    onGotoRetex
  } = useContext(RetexContext);
  const { ecommPlatformName } = useEcomPlatform();

  const retexId = _get(retex, 'id', '');
  const retexStatus = _get(retex, 'status', '');
  const modifiedAt = _get(retex, 'modified_at', 0);
  const modifiedBy = _get(retex, 'modified_by', '');
  const approvalType = _get(retex, 'approval_type', null);
  const osId = _get(orderService, 'id', '');
  const osName = _get(orderService, 'name', '');
  const osStatus = _get(orderService, 'status', '');
  const paymentLink = _get(orderService, 'payment_info.order_payment_link', '');
  const draftOrderLink = _get(
    orderService,
    'payment_info.draft_order_link',
    ''
  );
  const isOnHold = _get(orderService, 'on_hold', false);
  const isAwaitingEvent = approvalType === 'EVENT_BASED';
  const isAwaitingQualification =
    retexStatus === RETEX_STATUS.AWAITING_QUALIFICATION_APPROVAL;
  const isPaymentPending = osStatus === OS_STATUS.PENDING_PAYMENT_COMPLETION;
  const modifiedDate = modifiedAt
    ? moment.unix(modifiedAt).format('MMM D, YYYY, h:mm A')
    : null;

  let icon = null;
  let title = null;
  let description = null;
  let iconClassName = null;
  let animate = false;

  if (isApproved) {
    icon = <CheckIcon />;
    title = `Line Item approved ${modifiedBy ? `by ${modifiedBy}` : ''}`;
    description = modifiedDate;
    iconClassName = cn(styles.icon, styles.checkIcon);
  } else if (isCancelled) {
    icon = <CrossIcon />;
    title = `Line Item cancelled ${modifiedBy ? `by ${modifiedBy}` : ''}`;
    description = modifiedDate;
    iconClassName = cn(styles.icon, styles.crossIcon);
  } else if (isRejected) {
    icon = <CrossIcon />;
    title = `Line Item rejected ${modifiedBy ? `by ${modifiedBy}` : ''}`;
    description = modifiedDate;
    iconClassName = cn(styles.icon, styles.crossIcon);
  } else if (isOnHold) {
    icon = <LockIcon />;
    title = 'Request have been locked';
    description = 'All the line items are locked at the moment';
    iconClassName = cn(styles.icon, styles.lockIcon);
  } else if (isAwaitingQualification) {
    const retexesMap = { ...retexesDataMap };
    delete retexesMap[retexId];
    const retexeses = Object.values(retexesMap);
    const qualificationRetex = getQualificationRetex(retexeses);

    if (!qualificationRetex) {
      return <div />; // DO NOT REMOVE: Required
    }

    const id = _get(qualificationRetex, 'id', '');
    const name = _get(qualificationRetex, 'name', '');

    const gotoRetex = () => onGotoRetex(id, osId, name, osName);

    animate = true;
    icon = <ClockIcon />;
    title = 'Awaiting qualification approval for other line item';
    iconClassName = cn(styles.icon, styles.clockIcon);
    description = (
      <>
        Please qualify{' '}
        <span onClick={gotoRetex} className={styles.link}>
          {name}
        </span>{' '}
        to continue.
      </>
    );
  } else if (isPaymentPending) {
    icon = <ClockIcon />;
    title = 'Payment pending by the customer';
    description = (
      <>
        Shopper is expected to pay.&nbsp;
        <If test={paymentLink}>
          <a
            href={paymentLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Payment Link
          </a>
        </If>
        <If test={paymentLink && draftOrderLink}>&nbsp;|&nbsp;</If>
        <If test={draftOrderLink}>
          <a
            href={draftOrderLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {ecommPlatformName} Link
          </a>
        </If>
      </>
    );
    iconClassName = cn(styles.icon, styles.clockIcon);
  } else if (isAwaitingEvent) {
    icon = <ClockIcon />;
    title = 'Awaiting an automation event';
    description = 'This request will be processed automatically';
    iconClassName = cn(styles.icon, styles.clockIcon);
  } else {
    return <div />; // DO NOT REMOVE: Required
  }

  const containerClassName = cn(styles.container, {
    [styles.animated]: animate
  });

  return (
    <div className={containerClassName}>
      <div className={iconClassName}>{icon}</div>
      <div className={styles.content}>
        <strong>{title}</strong>
        <p>{description}</p>
      </div>
    </div>
  );
}
