import React, { useContext } from 'react';
import cn from 'classnames';

import { ReactTablesContext } from 'components/ReactTable';
import { ReactComponent as FilterIcon } from 'assets/img/icons/filter.svg';

export default function TableFilterButton({
  expand = false,
  onClick = () => {},
  textWhenActive = 'Filters*',
  textWhenInactive = 'Filters',
  className = ''
}) {
  const {
    checkIfRefined,
    searchState: { isFetchingFacets, isFacetsFetchingError }
  } = useContext(ReactTablesContext);

  const isFilterActive = checkIfRefined();

  const buttonClassName = cn('table-filter-button', {
    active: isFilterActive,
    expanded: expand,
    [className]: !!className
  });

  const buttonText = isFilterActive ? textWhenActive : textWhenInactive;

  return (
    <button
      onClick={onClick}
      disabled={isFetchingFacets || isFacetsFetchingError}
      className={buttonClassName}
    >
      <div className="table-filter-button__icon">
        {isFetchingFacets ? (
          <i className="fa fa-spinner fa-spin ml-2 mr-2" />
        ) : (
          <FilterIcon />
        )}
      </div>
      <span>{buttonText}</span>
    </button>
  );
}
