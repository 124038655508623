import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

import { If } from 'utils';
import ROUTES from 'views/Configure/stubs/routes';

export function EnterpriseShipping({ isEnabling = false }) {
  const configs = [
    { title: 'Service Zones', link: ROUTES.ZONES },
    { title: 'Service Lanes', link: ROUTES.LANES }
  ].map(({ title, link }) => (
    <li key={title} className={styles.listItem}>
      <Link to={link}>{title}</Link>
    </li>
  ));

  return (
    <If
      test={isEnabling}
      otherwise="You are about to disable the feature. Are you sure?"
    >
      Before enabling this feature, make sure you have set up the following:
      <ul className={styles.list}>{configs}</ul>
      Are you sure you want to continue?
    </If>
  );
}
