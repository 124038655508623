const PARAMETER_TYPE = {
  ALL: 'ALL',
  RANGE: 'RANGE'
};

const PARAMETER_LABEL = {
  [PARAMETER_TYPE.ALL]: 'All products',
  [PARAMETER_TYPE.RANGE]: 'Specific price range'
};

const INFINITE_VALUE = 999999;

export { PARAMETER_TYPE, PARAMETER_LABEL, INFINITE_VALUE };
