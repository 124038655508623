import React from 'react';
import cn from 'classnames';

import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/x-circle.svg';

export default function EditAction({
  isActive = false,
  className = '',
  styles = {},
  onClick = () => {},
  onMouseOver = () => {},
  onMouseLeave = () => {}
}) {
  const wrapClassName = cn('ThemeSettings__editAction', {
    [className]: !!className
  });
  return (
    <div
      style={styles}
      className={wrapClassName}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {isActive ? (
        <CloseIcon className="close" />
      ) : (
        <EditIcon className="edit" />
      )}
    </div>
  );
}
