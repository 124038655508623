import _isString from 'lodash/isString';

import { get, logError } from 'utils';

export default function getRetexTimeline(
  retexId = '',
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  if (!retexId || !_isString(retexId)) {
    throw new Error('Please provide a valid Retex Id');
  }

  get({ apiKey: 'retexes.timeline' }, { params: { retex_id: retexId } })
    .then(({ statusCode, data }) => {
      if (statusCode === 208) {
        const err = new Error('Already Done');
        err.displayMessage = 'Request was already processed.';
        err.statusCode = 208;
        throw err;
      }
      onSuccess(data);
    })
    .catch(err => {
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
