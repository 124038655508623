import React from 'react';

import errorImage from 'assets/rr-illustrations/lost.png';

export default function Error({ message = '' }) {
  return (
    <div className="react-tables__error">
      <div className="react-tables__error--imageWrapper">
        <img src={errorImage} alt="Not-found" />
      </div>
      <div className="react-tables__error--message">
        <h4>{message}</h4>
        <p>
          We are sorry for this inconvenience. Please try again later.
          <br />
          If the problem persists, please reach out to{' '}
          <a href="mailto:support@returnrabbit.com">support@returnrabbit.com</a>
        </p>
      </div>
    </div>
  );
}
