import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import { If } from 'utils';
import { useFetch, useIdle, useMutation } from 'utils/hooks';
import { AuthContext } from 'context/AuthContext';

const PAGE_START = 1;
const UNREAD_PAGE_SIZE = 100000;
const LAST_DAYS = 30;
const MIN_CREATED = moment()
  .subtract(LAST_DAYS, 'days')
  .unix();
const ALL_PAGE_SIZE = 20;
const ALL_NOTIF_POLL_PERIOD = 300000;
const ALL_NOTIF_LOADER_DELAY = 2000;

export const NotificationsContext = createContext({
  unreadNotificationsList: [],
  isFetchingUnreadNotifications: false,
  unreadNotificationsError: false,
  fetchUnreadNotifications: () => {},

  allNotificationsList: [],
  isFetchingAllNotifications: false,
  allNotificationsError: false,
  fetchAllNotifications: () => {},

  markAllAsRead: () => {},
  isReadingNotification: false,
  loadedAllNotifications: false,
  hasMore: false,
  ifPolling: false,
  onMarkNotificationList: () => {},
  closeDropdownCb: () => {},
  setDropdownVisibility: () => {},
  fetchUnreadListWithTimerReset: () => {}
});

function Provider({ children = null }) {
  const [unreadNotificationsList, setUnreadNotificationsList] = useState([]);
  const [allNotificationsList, setAllNotificationsList] = useState([]);
  const [isRunning, setIsRunning] = useState(false);

  const loadedAllNotifications = useRef(false);
  const currPageRef = useRef(PAGE_START);
  const fetchMoreRef = useRef(true);
  const ifPollingRef = useRef(false);
  const markAsReadTypeRef = useRef(null);
  const setDropdownVisibilityRef = useRef(() => {});

  const isUserIdle = useIdle();

  const handleStart = useCallback(() => {
    setIsRunning(true);
  }, []);

  const handleReset = useCallback(() => {
    setIsRunning(false);
    setTimeout(() => {
      setIsRunning(true);
    }, 0);
  }, []);

  const handlePause = useCallback(() => {
    setIsRunning(false);
  }, []);

  useEffect(() => {
    handleStart();
  }, [handleStart]);

  useEffect(() => {
    isUserIdle ? handlePause() : handleStart();
  }, [isUserIdle, handlePause, handleStart]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        handleStart();
      } else {
        handlePause();
      }
    });
  }, [handleStart, handlePause]);

  const {
    data: unreadNotificationsData,
    isLoading: isFetchingUnreadNotifications,
    isError: unreadNotificationsError,
    fetchData: fetchUnreadNotifications
  } = useFetch('retailerNotifications', {
    initialData: [],
    axiosConfig: {
      params: {
        page: PAGE_START,
        page_size: UNREAD_PAGE_SIZE,
        min_created: MIN_CREATED,
        is_unread_unresolved: true
      }
    },
    onTransform: resp => {
      const notificationsList = _get(resp, 'results', []);
      return notificationsList;
    },
    onError: _ => {
      handlePause();
    },
    errorMessage: ''
  });

  const {
    data: allNotificationsData,
    isLoading: isFetchingAllNotifications,
    isError: allNotificationsError,
    fetchData: fetchAllNotifications
  } = useFetch('retailerNotifications', {
    initialData: [],
    errorMessage: '',
    loadOnMount: false,
    payload: {
      page: currPageRef.current,
      page_size: ALL_PAGE_SIZE
    },
    onTransform: resp => {
      const notificationsList = _get(resp, 'results', []);
      const page = _get(resp, 'page');
      const page_size = _get(resp, 'page_size');
      const total_records = _get(resp, 'total_records');
      const fetchMoreResp = total_records - page * page_size > 0;

      currPageRef.current = page + 1;
      fetchMoreRef.current = fetchMoreResp;
      if (!ifPollingRef.current) handleReset();

      return notificationsList;
    },
    onSuccess: () => {
      if (!loadedAllNotifications.current) {
        loadedAllNotifications.current = true;
      }
    },
    onError: () => {
      if (!loadedAllNotifications.current) {
        loadedAllNotifications.current = true;
      }
      if (ifPollingRef.current) {
        ifPollingRef.current = false;
      }
      handlePause();
    }
  });

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        fetchUnreadNotifications();

        if (loadedAllNotifications.current && !isFetchingAllNotifications) {
          currPageRef.current = PAGE_START;
          ifPollingRef.current = true;
          fetchMoreRef.current = true;
          setTimeout(() => {
            fetchAllNotifications({
              page: currPageRef.current
            });
          }, ALL_NOTIF_LOADER_DELAY);
        }
      }, ALL_NOTIF_POLL_PERIOD);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [
    isRunning,
    fetchAllNotifications,
    fetchUnreadNotifications,
    isFetchingAllNotifications
  ]);

  useEffect(() => {
    setUnreadNotificationsList(unreadNotificationsData);
  }, [unreadNotificationsData]);

  useEffect(() => {
    if (ifPollingRef.current) {
      ifPollingRef.current = false;
      setAllNotificationsList([...allNotificationsData]);
    } else {
      setAllNotificationsList(prevState => [
        ...prevState,
        ...allNotificationsData
      ]);
    }
  }, [allNotificationsData]);

  const {
    mutate: onReadNotification,
    isProcessing: isReadingNotification
  } = useMutation('retailerNotifications', 'PATCH', {
    onSuccess: onReadSuccess,
    onError: onReadError,
    onFinally: onReadFinally
  });

  function onReadSuccess() {}

  function optimisicReadNotification(notificationIds = []) {
    switch (markAsReadTypeRef.current) {
      case 'all': {
        markListAsRead(notificationIds);
        break;
      }
      case 'unread': {
        markListAsRead(notificationIds);
        break;
      }
      case 'banner': {
        removeFromUnreadList(notificationIds);
        break;
      }
      default: {
      }
    }
    onReadNotification({ notification_ids: notificationIds });
    markAsReadTypeRef.current = null;
  }

  function onReadError(errResp) {}

  function onReadFinally() {}

  const markIdsAsReadCb = (prevState, ids) => {
    const newState = _cloneDeep(prevState).map(n => {
      if (ids.includes(n.id)) {
        return {
          ...n,
          is_read: true
        };
      }
      return n;
    });
    return [...newState];
  };

  const markListAsRead = ids => {
    setAllNotificationsList(prev => markIdsAsReadCb(prev, ids));
    setUnreadNotificationsList(prev => markIdsAsReadCb(prev, ids));
  };

  const onMarkNotificationList = (id = '', listType = '') => {
    if (!id && !listType) return;
    markAsReadTypeRef.current = listType;
    optimisicReadNotification([id]);
  };

  const removeFromUnreadList = ids => {
    setUnreadNotificationsList(prev => prev.filter(n => !ids.includes(n.id)));
    setAllNotificationsList(prev => markIdsAsReadCb(prev, ids));
  };

  const markAllAsRead = () => {
    const listOfUnreadNotifications = unreadNotificationsList
      .filter(n => !n.is_read)
      .map(n => n.id);
    if (listOfUnreadNotifications.length === 0) return;
    markAsReadTypeRef.current = 'unread';
    optimisicReadNotification(listOfUnreadNotifications);
  };

  const closeDropdownCb = (setDropdownCb = () => {}) => {
    setDropdownVisibilityRef.current = setDropdownCb;
  };

  const fetchUnreadListWithTimerReset = () => {
    fetchUnreadNotifications();
    handleReset();
  };

  return (
    <NotificationsContext.Provider
      value={{
        unreadNotificationsList,
        isFetchingUnreadNotifications,
        fetchUnreadNotifications,
        isReadingNotification,
        allNotificationsList,
        isFetchingAllNotifications,
        fetchAllNotifications,
        markAllAsRead,
        loadedAllNotifications: loadedAllNotifications.current,
        hasMore: fetchMoreRef.current,
        ifPolling: ifPollingRef.current,
        onMarkNotificationList,
        allNotificationsError,
        unreadNotificationsError,
        closeDropdownCb,
        setDropdownVisibility: setDropdownVisibilityRef.current,
        fetchUnreadListWithTimerReset
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export function NotificationsProvider({ children = null }) {
  const { appType } = useContext(AuthContext);

  return (
    <If test={appType.retex} otherwise={children}>
      <Provider>{children}</Provider>
    </If>
  );
}
