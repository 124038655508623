import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import qs from 'qs';
import styles from './index.module.scss';

import { FreeTrialBanner, UiKit } from 'components';
import { Shopify, Woocommerce, Connected } from './pages';
import { ContainerCard, StoreTiles, Title } from './components';
import { ConnectStoreProvider } from './context/ConnectStoreContext';
import { ROUTES } from './stubs';

function ConnectStore() {
  const { search } = useLocation();

  const { referrer, free_trial: trialPeriod } = qs.parse(search.slice(1));

  return (
    <UiKit.GridRow templateColumns="1fr" className={styles.container}>
      <Title className={styles.card} />
      <FreeTrialBanner
        referrer={referrer}
        trialPeriod={trialPeriod}
        className={styles.banner}
      />
      <div className={styles.content}>
        <Switch>
          <Route exact path={ROUTES.SHOPIFY_CONNECT}>
            <ConnectStoreProvider store="SHOPIFY">
              <Shopify />
            </ConnectStoreProvider>
          </Route>
          <Route exact path={ROUTES.WOO_CONNECT}>
            <ConnectStoreProvider store="WOOCOMMERCE">
              <Woocommerce />
            </ConnectStoreProvider>
          </Route>
          <Route exact path={ROUTES.ROOT}>
            <ContainerCard title="Select your platform">
              <StoreTiles />
            </ContainerCard>
          </Route>
          <Redirect to={ROUTES.LOGIN} />
        </Switch>
      </div>
    </UiKit.GridRow>
  );
}

export { ConnectStore, Connected };
