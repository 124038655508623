import React from 'react';
import cn from 'classnames';

import { If } from 'utils';

const DEFAULT_HELP_COMPONENT = () => null;

export default function InteractConfigWrapper({
  title = '',
  helptext = '',
  customClass = '',
  helptextClassName = '',
  contentClassName = '',
  columnClassName = '',
  cardClassName = '',
  configCard = false,
  helpComponent: HelpComponent = DEFAULT_HELP_COMPONENT,
  footer,
  children
}) {
  const wrapperClassName = cn('interactConfigWrapper', {
    [customClass]: !!customClass
  });
  const contentClass = cn('interactConfigWrapper-content', {
    [contentClassName]: !!contentClassName
  });
  const leftColumnClass = cn(
    'interactConfigWrapper-content--column',
    'interactConfigWrapper-content__config-panel',
    { [columnClassName]: !!columnClassName }
  );
  const rigthColumnClass = cn(
    'interactConfigWrapper-content--column',
    'interactConfigWrapper-content__help-guide',
    { [columnClassName]: !!columnClassName }
  );
  const cardClass = cn('interactConfigWrapper-content__config-panel--card', {
    [cardClassName]: !!cardClassName
  });
  const helptextClass = cn('helptext', {
    [helptextClassName]: !!helptextClassName
  });

  return (
    <section className={wrapperClassName}>
      <If test={title}>
        <h5 className="interactConfigWrapper-title">{title}</h5>
      </If>
      <div className={contentClass}>
        <div className={leftColumnClass}>
          <If test={children}>
            <If test={configCard}>
              <div className={cardClass}>{children}</div>
            </If>
            <If test={!configCard}>{children}</If>
          </If>
        </div>
        <div className={rigthColumnClass}>
          <If test={helptext}>
            <div className={helptextClass}>{helptext}</div>
          </If>
          <If test={HelpComponent && HelpComponent !== DEFAULT_HELP_COMPONENT}>
            <HelpComponent />
          </If>
        </div>
      </div>
      <If test={footer}>
        <div className="interactConfigWrapper-footer">{footer}</div>
      </If>
    </section>
  );
}
