const { createContext } = require('react');

const ShopperGroupSelectorContext = createContext({
  optionsList: [],
  isFetching: false,
  selectedOption: null,
  hasAccess: false,
  isConfigurable: false,
  setSelectedOption: () => {},
  onChange: () => {},
  onSubmitNew: () => {}
});

export { ShopperGroupSelectorContext };
