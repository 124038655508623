import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';

import { useAccess } from 'utils/hooks';
import { UiKit } from 'components';
import ModalTrigger from './ModalTrigger';
import ModalContent from './ModalContent';
import ROUTES from 'config/nav/admin/routes';

/**
 * @param {object} props;
 * @param {(import('dictionary').FEATURE_IDS)} props.featureId Feature ID to check if user has access to the feature
 * @param {(import('dictionary').FEATURE_SLUGS)} props.featureSlug Feature Slug to check if user has access to the feature
 * @param {string} props.closeRedirectRoute Route to redirect when the modal is closed
 */
export default function FeatureUpsellModal({
  featureId = '',
  featureSlug = '',
  closeRedirectRoute = ROUTES.CONFIGURE_RR.ROOT
}) {
  if (!featureId && !featureSlug) {
    throw new Error(
      '"featureId" or "featureSlug" must be provided in prop to <FeatureUpsellModal /> component'
    );
  }

  const { push } = useHistory();
  const { hasFeatureAccess, hasSubFeatureAccess } = useAccess();

  const hasAccess = useRef(
    hasFeatureAccess(featureId) || hasSubFeatureAccess(featureSlug)
  ).current;

  return (
    <UiKit.Modal
      centered
      size="lg"
      isOpen={!hasAccess}
      contentClassName={styles.modal}
      onClose={() => push(closeRedirectRoute)}
    >
      <ModalContent featureId={featureId} featureSlug={featureSlug} />
    </UiKit.Modal>
  );
}

FeatureUpsellModal.Trigger = ModalTrigger;
