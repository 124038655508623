import ADMIN_ROUTES from 'config/nav/admin/routes';

const BASE_URL = ADMIN_ROUTES.OVERVIEW.ROOT;

export default {
  HOME: BASE_URL,

  PENDING_IN_WAREHOUSE: `${BASE_URL}/pending-in-warehouse`,
  PENDING_IN_STORE: `${BASE_URL}/pending-in-store`,

  SELF_SHIP_REQUESTS: `${BASE_URL}/self-ship-requests`,
  SELF_SHIP_REQUESTS_RETEX_MODAL_BASE_URL: `${BASE_URL}/self-ship-requests/request`,
  SELF_SHIP_REQUESTS_RETEX_MODAL: `${BASE_URL}/self-ship-requests/request/:serviceID/item/:id`,

  AWAITING_QUALIFICATION: `${BASE_URL}/qualification-requests`,
  AWAITING_QUALIFICATION_RETEX_MODAL_BASE_URL: `${BASE_URL}/qualification-requests/request`,
  AWAITING_QUALIFICATION_RETEX_MODAL: `${BASE_URL}/qualification-requests/request/:serviceID/item/:id`,

  ADMIN_APPROVAL: `${BASE_URL}/admin-manual-approval`,
  ADMIN_APPROVAL_RETEX_MODAL_BASE_URL: `${BASE_URL}/admin-manual-approval/request`,
  ADMIN_APPROVAL_RETEX_MODAL: `${BASE_URL}/admin-manual-approval/request/:serviceID/item/:id`,

  LOCKED_REQUESTS: `${BASE_URL}/locked-requests`,
  LOCKED_REQUEST_RETEX_MODAL_BASE_URL: `${BASE_URL}/locked-requests/request`,
  LOCKED_REQUEST_RETEX_MODAL: `${BASE_URL}/locked-requests/request/:serviceID/item/:id`,

  AGED_REQUESTS: `${BASE_URL}/aged-requests`,
  AGED_REQUESTS_RETEX_MODAL_BASE_URL: `${BASE_URL}/aged-requests/request`,
  AGED_REQUESTS_RETEX_MODAL: `${BASE_URL}/aged-requests/request/:serviceID/item/:id`
};
