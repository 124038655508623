import qs from 'qs';

import { get } from 'utils';
// import getData from './__DEPRECATED__getData';
import getFacets from './getFacets';
import getTableData from './getTableData';

const createURL = state => `?${qs.stringify(state)}`;

const searchStateToUrl = (location, searchState) => {
  return searchState ? `${location.pathname}${createURL(searchState)}` : '';
};

const urlToSearchStateOptions = location => {
  const obj = qs.parse(location.search.slice(1));
  const x = Object.keys(obj).reduce((acc, key) => {
    if (key.indexOf('options__') > -1) {
      acc[key.replace('options__', '')] = obj[key];
    }
    return acc;
  }, {});
  if (Object.keys(x).length < 1) return null;
  return x;
};

const urlToSearchState = (location = {}, rejectKeys = []) => {
  const obj = qs.parse(location.search.slice(1));
  const x = Object.keys(obj).reduce((acc, key) => {
    if (key.indexOf('options__') > -1) return acc; //
    if (rejectKeys.includes(key)) return acc;
    if (key === 'search' || key === 'ordering') {
      acc[key] = obj[key];
      return acc;
    }
    if (key.indexOf('min') > -1 || key.indexOf('max') > -1) {
      const _key = key.replace('min_', '').replace('max_', '');
      if (!acc[_key])
        acc[_key] = {
          values: {},
          type: 'range'
        };
      if (key.indexOf('min') > -1) {
        acc[_key].values.min = Number(obj[key]);
        //assuming numeric range
      } else {
        acc[_key].values.max = Number(obj[key]);
      }
      return acc;
    }
    if (!isNaN(obj[key])) {
      acc[key] = Number(obj[key]);
      return acc;
    }

    acc[key] = {
      type: 'list',
      values: obj[key].split(',').reduce((acc, val) => {
        acc[val] = true;
        return acc;
      }, {})
    };
    return acc;
  }, {});
  if (Object.keys(x).length < 1) return null;
  return x;
};

const getRefinements = (refinements, method) => {
  const reloadParams = Object.keys(refinements).reduce((acc, key) => {
    const refinedVal = refinements[key];
    if (refinedVal) {
      if (
        typeof refinedVal === 'string' ||
        typeof refinedVal === 'number' ||
        typeof refinedVal === 'boolean'
      ) {
        acc[key] = refinedVal;
      } else if (Array.isArray(refinedVal)) {
        acc[key] = method === get ? refinedVal.join(',') : refinedVal;
      } else if (refinedVal.type === 'range') {
        acc['min_' + key] = refinedVal.values.min;
        acc['max_' + key] = refinedVal.values.max;
      } else {
        acc[key] = Object.keys(refinedVal.values).join(',');
      }
    }
    return acc;
  }, {});
  return reloadParams;
};

const refine = ({ attribute, type, value, _refinements }) => {
  if (type === 'list') {
    const attributeObject = _refinements[attribute]
      ? { ..._refinements[attribute] }
      : { values: {}, type: 'list' };
    const attributeValues = { ...attributeObject.values };

    if (attributeValues[value]) {
      delete attributeValues[value];
    } else {
      attributeValues[value] = true;
    }

    if (Object.keys(attributeValues).length < 1) {
      delete _refinements[attribute];
    } else {
      attributeObject.values = attributeValues;
      _refinements[attribute] = attributeObject;
    }
  } else if (type === 'search') {
    // _refinements[attribute] = value;
    _refinements = { [attribute]: value };
  } else if (type === 'range') {
    _refinements[attribute] = {
      values: value,
      type: 'range'
    };
  } else {
    _refinements[attribute] = value;
  }
  return _refinements;
};

export {
  // getData,
  searchStateToUrl,
  urlToSearchState,
  urlToSearchStateOptions,
  getRefinements,
  refine,
  getFacets,
  getTableData
};
