import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { validate, createEventData } from '../methods';

export default function useGoogleAnalytics() {
  const { pathname } = useLocation();
  const isActiveRef = useRef(false);

  useEffect(() => {
    const id = process.env.REACT_APP_GOOGLE_ANALYTICS_UNIVERSAL_ID;
    if (id) {
      isActiveRef.current = true;
      ReactGA.initialize(id);
    }
  }, []);

  useEffect(() => {
    if (isActiveRef.current) {
      ReactGA.pageview(pathname);
    }
  }, [pathname]);

  const recordEvent = (
    category = '',
    action = '',
    extraOptions = {
      label: null, // String
      value: null, // Integer
      nonInteraction: null, // Boolean
      transport: null // String (beacon|xhr|image)
    }
  ) => {
    if (!isActiveRef.current) return;

    const isValid = validate.isValidEventData(category, action);

    if (!isValid) {
      throw new Error(
        'Google Analytics: Event recording required arguments are invalid'
      );
    }

    const eventData = createEventData(category, action, extraOptions);
    ReactGA.event(eventData);
  };

  return { recordEvent };
}
