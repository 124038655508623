import React from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { isDateBetween } from 'utils/methods';

const CONTAINER_ID = 'announcement-container';

export default function Announcement({
  showFrom = '', // Date | Moment
  showTill = '', // Date | Moment
  buttonText = '',
  onButtonClick = () => {},
  className = '',
  buttonClassName = '',
  contentClassName = '',
  children = null
}) {
  const show = isDateBetween(showFrom || new Date(), showTill);

  if (!show) return null;

  return (
    <>
      {createPortal(
        <UiKit.GridRow
          templateColumns="1fr 160px"
          gap="24"
          className={cn(styles.container, className)}
        >
          <div className={cn(styles.content, contentClassName)}>{children}</div>
          {buttonText && onButtonClick && (
            <button
              onClick={onButtonClick}
              className={cn(styles.action, buttonClassName)}
            >
              {buttonText}
            </button>
          )}
        </UiKit.GridRow>,
        document.getElementById(CONTAINER_ID)
      )}
    </>
  );
}

Announcement.Container = function AnnouncementContainer() {
  return <div id={CONTAINER_ID} />;
};
