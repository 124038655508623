import React from 'react';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'reactstrap';
import cn from 'classnames';
import _isNumber from 'lodash/isNumber';
import styles from './index.module.scss';

export default function Paginate({
  page = 0,
  totalPages = 0,
  className = '',
  isDisabled = false,
  setPage = () => {}
}) {
  if (!_isNumber(totalPages) || totalPages <= 1) return null;

  const onPageChange = ({ selected }) => {
    if (selected === page - 1) return;
    setPage(selected + 1);
  };

  const containerClassName = cn(styles.container, className, {
    [styles.disabled]: isDisabled
  });

  return (
    <Row className={containerClassName}>
      <Col>
        <ReactPaginate
          initialPage={page - 1}
          forcePage={page - 1}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={onPageChange}
          containerClassName={styles.paginate}
          activeClassName={styles.active}
          breakClassName={styles.page}
          disabledClassName={styles.disabledPage}
          breakLabel={<span className={cn(styles.link, styles.mute)}>...</span>}
          pageClassName={styles.page}
          previousClassName={styles.page}
          nextClassName={styles.page}
          pageLinkClassName={styles.link}
          previousLinkClassName={styles.link}
          nextLinkClassName={styles.link}
        />
      </Col>
    </Row>
  );
}
