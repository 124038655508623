import React from 'react';

export default function Carrot() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.27 39.27">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            style={{ fill: 'var(--icon-color)' }}
            d="M30.5,17.93c1.71.5,4.27.77,6-1.2,1.5-1.75,1.94-3.36,1.22-4.54a2.31,2.31,0,0,0-.84-.83,5.51,5.51,0,0,0,2.2-2.23,2.34,2.34,0,0,0,0-2A3.82,3.82,0,0,0,36.8,5a4.58,4.58,0,0,0-2.49,0,4.45,4.45,0,0,0,0-2.48A3.86,3.86,0,0,0,32.16.24a2.37,2.37,0,0,0-2,0,5.56,5.56,0,0,0-2.22,2.2,2.31,2.31,0,0,0-.83-.84C25.9.86,24.28,1.3,22.54,2.8c-2,1.7-1.7,4.26-1.2,6A8.48,8.48,0,0,0,15.9,11.4C10.42,16.66.68,31.5,0,38a1.12,1.12,0,0,0,1.23,1.23c6.52-.68,21.37-10.41,26.63-15.89A8.48,8.48,0,0,0,30.5,17.93ZM24,4.5c1.18-1,1.83-1,1.91-1s.57.62-.1,3.54a1.13,1.13,0,0,0,.8,1.34A1.12,1.12,0,0,0,28,7.64c.61-1.9,2-4.91,3-5.36.1,0,.13,0,.17,0,.78.37.92.78,1,.91.22.67-.06,1.74-.88,3.29A1.13,1.13,0,0,0,32.8,8c1.55-.82,2.62-1.11,3.28-.88.14,0,.55.18.92,1,0,0,0,.07,0,.17-.45,1-3.46,2.43-5.36,3a1.13,1.13,0,0,0,.59,2.17c2.94-.67,3.54-.09,3.54-.1s0,.73-1,1.91c-1.2,1.39-3.59.6-4.57.19a10,10,0,0,0-2.33-3.92l-.07-.06-.07-.07a9.68,9.68,0,0,0-3.91-2.33C23.41,8.09,22.62,5.7,24,4.5ZM2.56,36.71c.88-3.18,3.39-8,6.31-12.53a6.16,6.16,0,0,1,2.27,2.42,1.13,1.13,0,0,0,2-1,8.5,8.5,0,0,0-3.06-3.31,73.1,73.1,0,0,1,5.62-7.43,7.47,7.47,0,0,1,3.47,3.29,1.12,1.12,0,0,0,1.52.47,1.11,1.11,0,0,0,.47-1.51,9.61,9.61,0,0,0-3.86-3.95l.14-.14c4.23-4.06,8-.74,8.72,0h0l.1.09a5.84,5.84,0,0,1,0,8.69,44.35,44.35,0,0,1-4.72,4.11,15.42,15.42,0,0,0-4.11-4.36,1.12,1.12,0,0,0-1.27,1.85,13.39,13.39,0,0,1,3.56,3.86A92.87,92.87,0,0,1,10.83,33a17.4,17.4,0,0,0-2.14-2.42,1.12,1.12,0,0,0-1.55,1.63A17.22,17.22,0,0,1,8.84,34,34.62,34.62,0,0,1,2.56,36.71Z"
          />
        </g>
      </g>
    </svg>
  );
}
