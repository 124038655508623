import React, { useState } from 'react';
import cn from 'classnames';
import { ChromePicker } from 'react-color';

import { If } from 'utils';
import Label from '../Label';
import styles from './index.module.scss';

export default function ColorPicker({
  label = '',
  color = '',
  onChange = () => {},
  error = '',
  disabled = false,
  wrapClassName = '',
  inputContainerClassName = '',
  colorPreviewClassName = '',
  inputClassName = '',
  labelClassName = '',
  pickerClassName = '',
  backdropClassName = ''
}) {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const wrapperClass = cn(styles.colorPickerWrapper, {
    [wrapClassName]: !!wrapClassName
  });
  const inputContainerClass = cn(styles.inputContainer, {
    [inputContainerClassName]: !!inputContainerClassName
  });
  const inputClass = cn(
    styles.input,
    { [styles.invalid]: !!error },
    inputClassName
  );
  const labelClass = cn(styles.inputLabel, labelClassName);
  const pickerClass = cn(styles.colorPicker, pickerClassName);
  const colorPreviewClass = cn(styles.selectedColor, colorPreviewClassName);
  const backDropClass = cn(styles.pickerBackdrop, backdropClassName);

  return (
    <div className={wrapperClass}>
      {label && <Label text={label} className={labelClass} />}
      <div className={inputContainerClass}>
        <input
          readOnly
          value={color}
          className={inputClass}
          disabled={disabled}
          // onChange={(e) => onChange(e.target.value)}
          onClick={() => !disabled && setIsColorPickerOpen(true)}
        />
        <div className={colorPreviewClass} style={{ backgroundColor: color }} />
        <If test={isColorPickerOpen}>
          <div
            className={backDropClass}
            onClick={() => setIsColorPickerOpen(false)}
          ></div>
          <ChromePicker
            disableAlpha
            color={color}
            onChange={({ hex }) => onChange(hex)}
            className={pickerClass}
          />
        </If>
      </div>
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
}
