import React from 'react';
import { Button } from 'reactstrap';
import { If } from 'utils';
const BooleanButton = ({
  disabled,
  onClick,
  processing,
  text,
  selected,
  dataKey
}) => {
  return (
    <Button
      className="BooleanButtons__button"
      disabled={disabled}
      onClick={onClick}
      data-selected={selected}
      data-ref={dataKey}
    >
      <If test={processing}>
        <i className="fas fa-spin fa-spinner" />
      </If>
      <If test={!processing}>{text}</If>
    </Button>
  );
};
export default BooleanButton;
