import ROUTES from 'views/Configure/stubs/routes';

const BASE_URL = ROUTES.AUTOMATION_RULES;

export default {
  CONFIGURE: ROUTES.HOME,
  RETURN_MANAGEMENT: ROUTES.RETURN_MANAGEMENT,
  HOME: BASE_URL,
  ADD_RULE: `${BASE_URL}/add-rule`,
  EDIT_RULE: `${BASE_URL}/:id`
};
