import React from 'react';
import _get from 'lodash/get';
import NotificationIcon from '../NotificationIcon';
import styles from './index.module.scss';
import { ReactComponent as CloseIcon } from 'assets/img/icons/new/banner-close.svg';
import { COLOR_CODES, ICON_COLOR } from '../../stubs';
import { If } from 'utils';
import { actionIdMap, doActionTemplateId } from '../../methods';
import { useHistory } from 'react-router-dom';

const NotificationBanner = ({ details = {}, onDismissCb = () => {} }) => {
  const { push: redirectFn } = useHistory();

  const id = _get(details, 'id', '');
  const type = _get(details, 'category', '');
  const title = _get(details, 'title', '');
  const subtitle = _get(details, 'description', '');
  const isDismissable = _get(details, 'is_dismissable', true);
  const actionLabel = _get(details, 'action_label', '');
  const actionId = _get(details, 'action_id', '');

  const showActionButton = actionLabel && actionId && actionIdMap[actionId];

  const takeAction = () => {
    if (!actionId) return;
    doActionTemplateId(actionId, { redirectFn });
  };

  const notificationStyle = {
    backgroundColor: COLOR_CODES[type],
    borderColor: COLOR_CODES[type]
  };
  const buttonStyle = {
    backgroundColor: ICON_COLOR[type]
  };

  const onDismiss = () => {
    if (!id) return;
    onDismissCb(id);
  };

  return (
    <div className={styles.notificationBody} style={notificationStyle}>
      <div className="d-flex align-items-center">
        <NotificationIcon type={type} />
        <div className="ml-3">
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
      </div>
      <div className={styles.actionCol}>
        <If test={showActionButton}>
          <button
            onClick={takeAction}
            className={styles.actionBtn}
            style={buttonStyle}
          >
            {actionLabel}
          </button>
        </If>
        <If test={isDismissable}>
          <CloseIcon onClick={onDismiss} />
        </If>
      </div>
    </div>
  );
};

export default NotificationBanner;
