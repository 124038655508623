import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import { UiKit } from 'components';
import { If } from 'utils';

const ReasonBody = ({ state, dispatch, data }) => {
  const otherReasonRef = useRef(null);
  let { reason, otherReason, otherReasonInvalid, options } = state;

  useEffect(() => {
    if (reason.value === 'other') {
      otherReasonRef.current.focus();
    }
  }, [reason, otherReasonInvalid]);

  let isSelectActive =
    data.type === 'cancel' ||
    (data.type === 'hold' && data.extra && data.extra.holdVal);
  const otherReasonPromptClassName = classnames(
    'confirmCancel__otherReasonText',
    { confirmCancel__error: otherReasonInvalid }
  );

  const onReasonChange = reason => {
    dispatch({ type: 'handleReasonChange', val: reason });
  };

  const onOtherReasonTextChange = e => {
    dispatch({ type: 'handleInputChange', val: e.target.value });
  };

  return (
    <div className="mt-3">
      <FormGroup>
        <If test={isSelectActive}>
          <UiKit.Select
            label="Select Reason"
            name="reason"
            options={options}
            onChange={onReasonChange}
            value={reason}
            clearable={false}
            wrapperClassName="confirmCancel__interact text-left mb-3"
          />
        </If>
        <If test={reason.value === 'other' || !isSelectActive}>
          <UiKit.Input
            label="Tell us more about it"
            id="otherOption"
            name="otherReason"
            value={otherReason}
            onChange={onOtherReasonTextChange}
            error={otherReasonInvalid}
            className="confirmCancel__interact"
            errorClass="confirmCancel__interactError"
            inputRef={otherReasonRef}
          />
          <div className={otherReasonPromptClassName}>
            Reason must be atleast 10 characters in length.
          </div>
        </If>
      </FormGroup>
    </div>
  );
};

export default ReasonBody;
