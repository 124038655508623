import React from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

export default function LineChartTooltip({
  slice,
  className = '',
  formatTitle = v => v,
  formatValue = v => v
}) {
  const points = _get(slice, 'points', []);
  const xAxisValue = _get(points, '[0].data.x');
  const title = formatTitle(xAxisValue);

  const wrapperClassName = cn(styles.tooltip, { [className]: !!className });

  const items = points.map(({ id, serieId, color, data }) => {
    const yAxisValue = _get(data, 'y', '');
    const value = formatValue(yAxisValue, serieId);
    return (
      <li key={id}>
        <div style={{ backgroundColor: color }} />
        <div>
          {serieId}: {value}
        </div>
      </li>
    );
  });

  return (
    <div className={wrapperClassName}>
      <p>{title}</p>
      <ul>{items}</ul>
    </div>
  );
}
