function hasAddressInformation(address) {
  if (!address) {
    return null;
  }
  const addressFields = [
    'address1',
    'address2',
    'city',
    'province',
    'country',
    'zip',
    'phone'
  ];
  const hasAnyInfo = addressFields.reduce((bool, field) => {
    return address[field] || bool;
  }, false);
  return hasAnyInfo;
}

export default hasAddressInformation;
