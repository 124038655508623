import React from 'react';
import { toast } from 'react-toastify';

import ToastMsg from './msg';

const toastPosition = position => {
  let returnPostion;
  switch (position) {
    case 'top-left':
      returnPostion = toast.POSITION.TOP_LEFT;
      break;
    case 'top-center':
      returnPostion = toast.POSITION.TOP_CENTER;
      break;
    case 'top-right':
      returnPostion = toast.POSITION.TOP_RIGHT;
      break;
    case 'bottom-left':
      returnPostion = toast.POSITION.BOTTOM_LEFT;
      break;
    case 'bottom-center':
      returnPostion = toast.POSITION.BOTTOM_CENTER;
      break;
    default:
      returnPostion = toast.POSITION.TOP_RIGHT;
  }
  return returnPostion;
};

/**
 * Represents a Custom Toast Component.
 * @param {Object} props Props Object.
 * @param {string} props.msg - Message which you want to display on text.
 * @param {"success" | "error" | "warn" | "info"} props.type - toast type
 * @param {boolean} [props.isNotified] - Set to True if toast should not be rendered
 * @param {string} [props.position] - Postion where you want to be toast placed.
 * @param {boolean} [props.persist] - Value for autoClose for the toast.
 */
const CustomToast = ({ isNotified = false, persist = false, ...props }) => {
  if (isNotified) return null;

  return toast(<ToastMsg {...props} />, {
    position: toastPosition(props.position),
    autoClose: !persist,
    className: 'customToast',
    progressClassName: 'customToast__progress-bar',
    bodyClassName: 'customToast__body'
  });
};

const customToast = {
  show: CustomToast,
  hide: toast.dismiss
};

export { CustomToast as default, customToast };
