import React from 'react';
import styles from './index.module.scss';

import { InfoBanner, OverflowText } from 'components';

export default function LockedReason({ reason = '' }) {
  const msg = !reason ? null : (
    <>
      <strong>Locked Reason</strong>:{' '}
      <OverflowText
        id="os-lock-reason"
        text={reason}
        trimLength={150}
        className={styles.text}
      />
    </>
  );

  return (
    <InfoBanner
      isInvalid
      gap="15"
      iconSize="24"
      className={styles.container}
      iconClassName={styles.icon}
      messageClassName={styles.message}
    >
      {msg}
    </InfoBanner>
  );
}
