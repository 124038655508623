import _isArray from 'lodash/isArray';

import { get } from 'utils';

export default function getShopperComments(
  retexId = '',
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  if (!retexId) return onError(new Error('Retex ID must be provided'));

  get({ apiKey: 'shopperComments.get' }, { params: { routeParam: retexId } })
    .then(res => {
      const data = _isArray(res.data) ? res.data : [];
      onSuccess(data);
    })
    .catch(err => onError(err))
    .finally(onFinally);
}
