import { logError, post } from 'utils';

/**
 * @typedef {Object} ApiParams
 * @property {string} file Base64 encoded file data
 * @property {string} folder Cloudinary folder to upload to
 * @property {string} tags Cloudinary tags to apply to the file
 */

/**
 * @param {ApiParams} params
 * @param {Function} onProgress
 * @param {Function} onSuccess
 * @param {Function} onError
 * @param {Function} onFinally
 */

export default function uploadFile(
  params,
  onProgress = () => {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  post(
    { apiKey: 'fileUpload' },
    { params, config: { onUploadProgress: onProgress, timeout: 60000 } }
  )
    .then(({ data }) => onSuccess(data))
    .catch(err => {
      err.displayMessage = 'Failed to upload file';
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
