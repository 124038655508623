import React, { useRef } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { useAccess } from 'utils/hooks';
import { UiKit } from 'components';
import Action from '../../Action';
import FeatureInfo from '../../FeatureInfo';

export default function ModalContent({ featureId = '', featureSlug = '' }) {
  const { getPlanInfoToAccess } = useAccess();

  const { requiredPlan, newFeaturesCount } = useRef(
    getPlanInfoToAccess({
      id: featureId,
      slugs: [featureSlug]
    })
  ).current;

  const planName = _get(requiredPlan, 'name', '').replace(/Plan/g, '');
  const extraFeaturesCount = newFeaturesCount - 1;

  return (
    <UiKit.GridRow gap="100" className="align-items-stretch">
      <FeatureInfo featureId={featureId} featureSlug={featureSlug} />
      <div className={styles.info}>
        <h2 className={styles.title}>
          Unlock more with
          <br />
          <strong>{planName} Plan</strong>
        </h2>
        <p className={styles.description}>
          This feature is available on our {planName} plan. Upgrade now to
          unlock this and {extraFeaturesCount} other premium features.
        </p>
        <Action planData={requiredPlan} className="mt-3" />
      </div>
    </UiKit.GridRow>
  );
}
