import { useCallback, useEffect, useState } from 'react';
import _get from 'lodash/get';
// import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { getConfiguredShopperList } from '../services';

const GLOBAL_OPTION = { label: 'Global Settings', value: null };

export default function useShopperGroupsList(
  entity = '',
  selectedId = '',
  loadData = false
) {
  const [optionsList, setOptionsList] = useState([GLOBAL_OPTION]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isFetching, setIsFetching] = useState(loadData ? true : false);
  const [isFetchingError, setIsFetchingError] = useState(false);

  const fetchData = useCallback(() => {
    if (!entity || !loadData) return;

    setIsFetching(true);
    setIsFetchingError(false);

    getConfiguredShopperList(
      { routeParam: entity },
      resp => {
        const list = _get(resp, 'data', []);
        filterShopperGroupList(list);
      },
      _ => {
        setIsFetchingError(true);
      },
      () => {
        setIsFetching(false);
      }
    );
  }, [entity, loadData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedId === null) {
      const globalSettingsValue = _get(optionsList, '[0]', null);
      setSelectedOption(globalSettingsValue);
    } else if (!isFetching) {
      const configuredShopperGroups = _get(optionsList, '[1].options', []);
      const availableShopperGroups = _get(optionsList, '[2].options', []);
      const combinedList = [
        ...configuredShopperGroups,
        ...availableShopperGroups
      ];

      const selected = combinedList.find(({ value }) => selectedId === value);
      setSelectedOption(selected || _get(optionsList, '[0]', null));
    }
  }, [selectedId, optionsList, isFetching]);

  const filterShopperGroupList = (list = []) => {
    if (!Array.isArray(list) || !list.length) return;
    const availableList = [];
    const configuredList = [];
    list.forEach(shopperGroup => {
      if (!shopperGroup.is_configured) {
        availableList.push({
          label: shopperGroup.name,
          value: shopperGroup.id,
          is_configured: shopperGroup.is_configured
        });
      }

      if (shopperGroup.is_configured) {
        configuredList.push({
          label: shopperGroup.name,
          value: shopperGroup.id,
          is_configured: shopperGroup.is_configured
        });
      }
    });

    setOptionsList(prevData => {
      _set(prevData, '[1]', {
        label: 'Configured Shopper Groups',
        options: configuredList
      });
      _set(prevData, '[2]', {
        label: 'Available Shopper Groups to configure',
        options: availableList
      });
      return prevData;
    });
  };

  const onReload = useCallback(() => fetchData(), [fetchData]);

  return {
    optionsList,
    selectedOption,
    isFetching,
    isFetchingError,
    setSelectedOption,
    onReload
  };
}
