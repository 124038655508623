import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './TabsFilter.module.scss';

const propTypes = {
  tabList: PropTypes.array.isRequired,
  theme: PropTypes.oneOf(['dark', 'light']),
  onChange: PropTypes.func
};

const defaultProps = {
  theme: 'light',
  onChange: () => {}
};

const TabsFilter = ({ tabsList, theme, onChange }) => {
  const [selected, setSelected] = useState(tabsList[0]);
  const onClick = element => {
    setSelected(element);
    onChange();
  };
  return (
    <div className={[style.root, style[theme]].join(' ')}>
      {tabsList.map(element => (
        <span
          className={[
            style.tab,
            theme === 'dark' ? style.darkTab : '',
            selected === element ? style.selectedTab : ''
          ].join(' ')}
          key={element}
          onClick={() => onClick(element)}
        >
          {element}
        </span>
      ))}
    </div>
  );
};

TabsFilter.propTypes = propTypes;
TabsFilter.defaultProps = defaultProps;
export default TabsFilter;
