import _get from 'lodash/get';

export default function getEstimatedRefund(retex = null) {
  if (!retex) return 0;

  const quantity = _get(retex, 'quantity', 0);
  const purchasedProductPrice = _get(retex, 'purchased_variant.price', 0);
  // const exchangedProductPrice = _get(retex, 'exchange_variant.price', 0);
  const tax = _get(retex, 'amount_breakdown.tax', 0);
  const discount = _get(retex, 'amount_breakdown.discount', 0);
  const restockFee = _get(retex, 'amount_breakdown.restocking_fee', 0);
  const incentive = _get(retex, 'amount_breakdown.exchange_incentive', 0);

  const purchasePrice = purchasedProductPrice * quantity;
  // const exchangePrice = exchangedProductPrice * quantity;
  const totalTax = tax * quantity;

  return purchasePrice - totalTax - restockFee + discount + incentive;
}
