export default function findOptionsDifference(variant1, variant2) {
  if (!variant1 || !variant2) {
    return;
  }
  let ret = {};
  Object.keys(variant1).forEach(key => {
    if (variant1[key] !== variant2[key]) {
      ret[key] = [variant1[key], variant2[key]];
    }
  });
  return ret;
}
