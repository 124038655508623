export default [
  {
    label: 'Product not as described in request',
    value: 'Product not as described in request'
  },
  {
    label: 'Original product and price tags are absent',
    value: 'Original product and price tags are absent'
  },
  {
    label: 'Rough use observed',
    value: 'Rough use observed'
  },
  {
    label: 'Mismatch between damage images and product',
    value: 'Mismatch between damage images and product'
  },
  {
    label: 'Incomplete SKU',
    value: 'Incomplete SKU'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];
