import React from 'react';
import PropTypes from 'prop-types';

/**
 * Address Component to render address consistently throughout the website
 * If you need to add any styles to the address text, create a wrapper <div>
 * set the styles on it and put this component inside it
 * @param {object} props.address
 */
export default function Address({ address }) {
  if (!address) {
    return null;
  }
  let name = address.name;
  if ((!name || !name.length) && (address.first_name || address.last_name)) {
    name = `${address.first_name} ${address.last_name}`.trim();
  } else if (
    (!name || !name.length) &&
    (address.firstName || address.lastName)
  ) {
    name = `${address.firstName} ${address.lastName}`.trim();
  }
  // We need to make sure that <br /> tags only come when that line isn't empty.
  // Otherwise our formatted data will look very weird
  return (
    <React.Fragment>
      {name && (
        <React.Fragment>
          <span className="Address__name">{name}</span>
          <br />
        </React.Fragment>
      )}
      {address.company && (
        <React.Fragment>
          {address.company}
          <br />
        </React.Fragment>
      )}
      {address.address1 && (
        <React.Fragment>
          {address.address1}
          <br />
        </React.Fragment>
      )}
      {address.address2 && (
        <React.Fragment>
          {address.address2}
          <br />
        </React.Fragment>
      )}
      {address.city && <React.Fragment>{address.city}</React.Fragment>}
      {address.city && address.province && <React.Fragment>, </React.Fragment>}
      {address.city && !address.province && <React.Fragment> </React.Fragment>}
      {address.province && <React.Fragment>{address.province} </React.Fragment>}
      {address.zip && (
        <React.Fragment>
          - {address.zip}
          <br />
        </React.Fragment>
      )}
      {address.country && (
        <React.Fragment>
          {address.country}
          <br />
        </React.Fragment>
      )}
      {address.phone && (
        <React.Fragment>
          Phone: {address.phone}
          <br />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

Address.propTypes = {
  address: PropTypes.object
};
