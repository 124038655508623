import axios from 'axios';

import { get } from 'utils';

const CancelToken = axios.CancelToken;

const getFacets = ({ facetsEndpoint, params }, callback) => {
  const source = CancelToken.source();
  get({ apiKey: facetsEndpoint }, { params, cancelToken: source.token })
    .then(res => callback(null, res))
    .catch(err => callback(err, {}));
  return source;
};

export default getFacets;
