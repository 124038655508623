import React, { useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _toLower from 'lodash/toLower';
import styles from './index.module.scss';

export default function BarChartDataTooltip({
  keys = [],
  data = {},
  title = '',
  indexValue = '',
  formatLabel = v => v, // value
  formatValue = (d, k) => _get(d, k), // data, key, fallback
  className = ''
}) {
  const tooltipTitle = useMemo(() => {
    return _toLower(title || indexValue);
  }, [title, indexValue]);

  const wrapperClassName = useMemo(() => {
    return cn(styles.tooltip, { [className]: !!className });
  }, [className]);

  const items = useMemo(() => {
    if (_isArray(keys)) {
      return keys.map(key => {
        const color = _get(data, `${key}Color`, 'transparent');
        const label = formatLabel(key);
        const value = formatValue(data, key);
        return (
          <li key={key}>
            <div
              style={{ backgroundColor: color }}
              className={styles.colorBlock}
            />
            <div>
              {label}: {value}
            </div>
          </li>
        );
      });
    }
  }, [data, keys, formatLabel, formatValue]);

  if (_isEmpty(keys)) return null;

  return (
    <div className={wrapperClassName}>
      <strong>{tooltipTitle}</strong>
      <ul>{items}</ul>
    </div>
  );
}
