import React, { useContext } from 'react';
import _get from 'lodash/get';

import styles from './index.module.scss';

import { useEcomPlatform } from 'utils/hooks';
import { EcommPlatfrom, UiKit } from 'components';
import { AuthContext } from 'context/AuthContext';
import Card from '../Card';
import { ReactComponent as ExternalLinkIcon } from 'assets/img/sidebar/customer-portal.svg';

export default function StoreDetails() {
  const { storeProperties } = useContext(AuthContext);
  const { ecommPlatformName, ecommPlatformPlanName } = useEcomPlatform();

  const storeName = _get(storeProperties, 'store_name', '');
  const storeURL = _get(storeProperties, 'store_website', '');

  return (
    <UiKit.GridRow templateColumns="1fr 1fr 1fr" gap="16">
      <Card>
        <UiKit.GridRow
          templateColumns="auto 1fr"
          gap="16"
          className="align-items-center"
        >
          <EcommPlatfrom.Icon />
          <UiKit.GridRow templateColumns="1fr">
            <Card.Title>Store Name</Card.Title>
            <Card.Value bold>{storeName}</Card.Value>
          </UiKit.GridRow>
        </UiKit.GridRow>
      </Card>
      <Card>
        <Card.Title>Store URL</Card.Title>
        <Card.Value bold>
          <a
            href={storeURL}
            rel="noopener noreferrer"
            target="_blank"
            className={styles.link}
          >
            <span>{storeURL}</span>
            <ExternalLinkIcon />
          </a>
        </Card.Value>
      </Card>
      <Card>
        <Card.Title>Current {ecommPlatformName} Plan</Card.Title>
        <Card.Value className="text-capitalize">
          {ecommPlatformPlanName}
        </Card.Value>
      </Card>
    </UiKit.GridRow>
  );
}
