import _get from 'lodash/get';

import { localStore } from 'utils';

export default function saveConfigurationInStorage(configuration) {
  const json = (key, defaultValue = null) => {
    const value = _get(configuration, key) || defaultValue;
    return JSON.stringify(value);
  };

  const isExternalUser = _get(configuration, 'is_externaluser', false);

  localStore.put('customerPortalDomains', json('customer_portal_domains', []));
  localStore.put('emailSettingsDefaults', json('email_settings_defaults'));
  localStore.put(
    'userAccess',
    isExternalUser ? json('externaluser_access') : JSON.stringify('WRITE')
  );
}
