import React, { useState, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Col, Container, Row, UncontrolledAlert } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import cn from 'classnames';

import { AuthContext } from 'context/AuthContext';
import routes from '../../config/routes/auth';

export default function DefaultLayout(props) {
  const search = new URLSearchParams(window.location.search);
  const changePassword = search.get('fromChangePassword');
  const [changePasswordAlert, closeModal] = useState(changePassword === 'true');
  const { appType } = useContext(AuthContext);

  const layoutClassName = cn('main', {
    retexApp: appType.retex,
    trackingApp: appType.shipmentTracking
  });

  return (
    <div className="app">
      <div className="app-body">
        <main className={layoutClassName} id="login-layout">
          <ToastContainer />
          <Container fluid>
            <UncontrolledAlert
              className="login-layout__passwordChangedAlert"
              color="info"
              isOpen={changePasswordAlert}
              toggle={() => closeModal(false)}
            >
              <strong>Success!</strong> Your Password has been changed. Please
              login with your Email and New Password.
            </UncontrolledAlert>
            <Row>
              <Col lg="7" className="left-col" />
              <Col lg={{ size: '5', offset: 7 }} className="right-col">
                <Switch>
                  {routes.map((route, idx) => {
                    const Component = route.component;
                    return Component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={routeProps => (
                          <Component {...routeProps} {...props} />
                        )}
                      />
                    ) : null;
                  })}
                </Switch>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}
