import moment from 'moment';
import _isString from 'lodash/isString';

export default function getFormattedHomePickupDate(date = '') {
  if (!date || !_isString(date)) return 'N/A';

  const pickupDate = date.replace('T00:00:00Z', '');

  return moment(pickupDate).format('ddd, MMM D');
}
