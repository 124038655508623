import React from 'react';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { If } from 'utils';
import ErrorMessage from './ErrorMessage';
import { ReactComponent as CheckIcon } from 'assets/img/icons/select-check.svg';

export default function Radio({
  label = '',
  inline = false,
  name = 'radio-list',
  options = [],
  selectedOption = null,
  error = '',
  disable = false,
  onChange = () => {},
  className = '',
  wrapperClassName = '',
  textClassName = '',
  labelClassName = '',
  errorClassName = ''
}) {
  const radioOptions = options.map(({ label, value, disabled }) => {
    const uniqueId = uuidv4();
    return (
      <RadioOption
        key={uniqueId}
        name={name}
        label={label}
        checked={value === selectedOption}
        onChange={e => onChange(value, e)}
        disabled={disable || disabled}
        className={className}
        textClassName={textClassName}
      />
    );
  });
  const listClassName = cn('uiKit__radioList', {
    spread: inline,
    stack: !inline,
    [wrapperClassName]: !!wrapperClassName
  });

  return (
    <div className="uiKit__element">
      <If test={label}>
        <div className={cn('uiKit__element--labelWrapper', labelClassName)}>
          <label>{label}</label>
        </div>
      </If>
      <ul className={listClassName}>{radioOptions}</ul>
      <ErrorMessage msg={error} className={errorClassName} hide={disable} />
    </div>
  );
}

function RadioOption({
  name,
  checked,
  label,
  onChange,
  disabled,
  className,
  textClassName
}) {
  const uniqueId = uuidv4();
  const classname = cn('uiKit__radio', {
    [className]: !!className
  });
  const labelClass = cn('selection-title', {
    checked,
    [`${className}__label`]: !!className,
    textClassName
  });
  return (
    <li className={classname}>
      <input
        id={uniqueId}
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={uniqueId}>{checked && <CheckIcon />}</label>
      <label htmlFor={uniqueId} className={labelClass}>
        {label}
      </label>
    </li>
  );
}
