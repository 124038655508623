import React, { useState, useContext, useEffect } from 'react';
import _get from 'lodash/get';

import { UiKit } from 'components';
import { ReactTablesContext } from '../../index';
import reactSelectStyles from './reactSelectStyles';

export default function SortDropdown({
  options = [],
  label = 'Sort By:',
  defaultOption = null,
  refinementAttribute = 'ordering'
}) {
  const [option, setOption] = useState(defaultOption);
  const {
    refinements,
    refine,
    clearRefinement,
    searchState: { isFetchingData }
  } = useContext(ReactTablesContext);

  useEffect(() => {
    if (refinementAttribute in refinements) {
      const refinementValue = _get(refinements, refinementAttribute, null);
      const selectedOption = options.find(({ value }) => {
        return value === refinementValue;
      });
      setOption(selectedOption);
    } else {
      setOption(defaultOption);
    }
  }, [refinements, refinementAttribute, options, defaultOption]);

  const onChangeHandler = option => {
    setOption(option);
    const orderingValue = option.value;
    const defaultValue = _get(defaultOption, 'value', null);
    if (orderingValue === defaultValue) {
      clearRefinement({ attribute: refinementAttribute });
    } else {
      refine({ attribute: refinementAttribute, value: orderingValue });
    }
  };

  return (
    <UiKit.Select
      label={label}
      value={option}
      options={options}
      isLoading={isFetchingData}
      isDisabled={isFetchingData}
      onChange={onChangeHandler}
      isSearchable={false}
      overridingStyles={reactSelectStyles}
      wrapperClassName="table-sort"
      labelClassName="table-sort__labelWrapper"
      inputWrapperClassName="table-sort__inputWrapperClassName"
    />
  );
}
