import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default function StepText({
  className = '',
  callout = true,
  children = null
}) {
  const containerClassName = cn(styles.container, className, {
    [styles.callout]: callout
  });

  return <div className={containerClassName}>{children}</div>;
}
