import React from 'react';
import cn from 'classnames';

import { If } from 'utils';
import CustomTableHead from './Head';
import CustomTableRow from './Row';
import CustomTableRows from './Rows';
import CustomTableTabs from './Tabs';
import CustomTableFoot from './Foot';
import CustomTableAddButton from './AddButton';
import Actions from './Actions';
import OverflowMenu from './OverflowMenu';
import InfoLink from './InfoLink';

const CustomTable = ({ children, className = '', isCardType = false }) => {
  const customClassName = cn('CustomTable-section--table', {
    [className]: !!className,
    'card-rows': isCardType
  });
  return <div className={customClassName}>{children}</div>;
};

const CustomTableSection = ({
  id = '',
  title,
  children,
  style,
  customClass = ''
}) => {
  const className = cn('CustomTable-section', {
    [customClass]: !!customClass
  });
  return (
    <div id={id} className={className} style={style}>
      <If test={title}>
        <h5 className="CustomTable-section--heading">{title}</h5>
      </If>
      {children}
    </div>
  );
};

const CustomTableSectionHeading = ({
  title,
  children,
  CustomComponent,
  customClass,
  helpText,
  documentationUrl,
  titleClassName,
  extraContentClass
}) => {
  const className = cn('CustomTable-section--heading', {
    [customClass]: !!customClass
  });
  if (CustomComponent) {
    return (
      <div className={className}>
        <CustomComponent />
      </div>
    );
  }
  // else if (children) {
  const wrapperClassName = cn(className, 'clearfix', 'grid-view');
  const extraContentClassName = cn('extra-content', extraContentClass);
  return (
    <div className={wrapperClassName}>
      <div className="head-section">
        <div className="head-section__title">
          <div className={cn('main-title', titleClassName)}>{title}</div>
          <InfoLink
            placement="right"
            className="info-icon"
            url={documentationUrl}
          />
        </div>
        <If test={helpText}>
          <div className="help-text">{helpText}</div>
        </If>
      </div>
      <If test={children}>
        <div className={extraContentClassName}>{children}</div>
      </If>
    </div>
  );
  // } else {
  //   return (
  //     <div className={className}>
  //       <div className={cn('main-title', titleClassName)}>{title}</div>
  //       {helpText ? <div className="help-text">{helpText}</div> : null}
  //     </div>
  //   );
  // }
};

export {
  CustomTable,
  CustomTableRow,
  CustomTableHead,
  CustomTableSection,
  CustomTableSectionHeading,
  CustomTableRows,
  CustomTableTabs,
  CustomTableFoot,
  CustomTableAddButton,
  Actions,
  OverflowMenu,
  InfoLink
};
