import React from 'react';
import cn from 'classnames';
import { Field, Form } from 'formik';
import LaddaButton, { SLIDE_UP } from 'react-ladda';

export default function MyForm({ touched, errors, loading, handleChange }) {
  const wrapClass = cn('form-control', 'login-input', {
    'is-invalid': errors.email && touched.email
  });
  return (
    <Form>
      <div className="form-group">
        <Field
          autoFocus
          className={wrapClass}
          disabled={loading}
          onChange={handleChange}
          name="email"
          id="exampleInputEmail1"
          type="email"
          aria-describedby="emailHelp"
          placeholder="Email"
        />
        <div className="invalid-feedback">{errors.email}</div>
      </div>
      <LaddaButton
        type="submit"
        className="btn btn-block btn-primary login-button"
        loading={loading}
        data-style={SLIDE_UP}
        data-spinner-size={30}
        data-spinner-color="#ddd"
        data-spinner-lines={12}
      >
        Start
      </LaddaButton>
    </Form>
  );
}
