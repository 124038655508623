import React from 'react';
import BooleanButton from './BooleanButton';
const BooleanButtons = ({
  disabled,
  onClick,
  processing,
  value,
  positiveText = 'Yes',
  negativeText = 'No'
}) => {
  return (
    <div className="BooleanButtons">
      <BooleanButton
        dataKey={true}
        onClick={() => onClick(true)}
        selected={value === true}
        disabled={disabled || processing !== null}
        text={positiveText}
        processing={processing === true}
      />
      <BooleanButton
        dataKey={false}
        onClick={() => onClick(false)}
        selected={value === false}
        disabled={disabled || processing !== null}
        text={negativeText}
        processing={processing === false}
      />
    </div>
  );
};
export default BooleanButtons;
