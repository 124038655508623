import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { If } from 'utils';
import { ReactComponent as CheckIcon } from 'assets/img/icons/select-check.svg';
import { ReactComponent as CheckIndeterminateIcon } from 'assets/img/icons/checkbox-indeterminate.svg';

export default function Checkbox({
  title = '',
  checked = false,
  toggle = () => {},
  disabled = false,
  className = '',
  wrapperClassName = '',
  contentClassName = '',
  textClassName = '',
  children = null,
  indeterminate = false
}) {
  const uniqueId = uuidv4();
  const wrapClass = cn(
    'uiKit__element',
    'uiKit__checkboxWrapper',
    wrapperClassName
  );
  const textClass = cn('selection-title', textClassName);
  const contentClass = cn('uiKit__checkbox', contentClassName);

  const cRef = useRef();

  useEffect(() => {
    if (cRef.current) {
      cRef.current.indeterminate = indeterminate;
    }
  }, [cRef, indeterminate]);

  return (
    <div className={wrapClass}>
      <div className={contentClass}>
        <input
          ref={cRef}
          id={uniqueId}
          type="checkbox"
          checked={checked}
          onChange={e => toggle(!checked, e)}
          disabled={disabled}
        />
        <label htmlFor={uniqueId} className={className}>
          {indeterminate && <CheckIndeterminateIcon />}
          {checked && !indeterminate && <CheckIcon />}
        </label>
        <If test={title && typeof title === 'string'}>
          <label htmlFor={uniqueId} className={textClass}>
            {title}
          </label>
        </If>
      </div>
      <If test={children}>{children}</If>
    </div>
  );
}
