import React, { Fragment, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _startsWith from 'lodash/startsWith';
import cn from 'classnames';
import styles from './index.module.scss';

import Link from './Link';
import Icon from '../Icon';
import { AuthContext } from 'context/AuthContext';

export default function NavLinks({ features = [], className = '' }) {
  const { pathname } = useLocation();
  const { configuration } = useContext(AuthContext);

  const renderFeatures = useMemo(() => {
    const featuresList = _isArray(features) ? features : [];
    const { enabledFeatures, lockedFeatures } = featuresList.reduce(
      (acc, data) => {
        const id = _get(data, 'id', '');
        const url = _get(data, 'url', '');
        const tag = _get(data, 'tag', '');
        const icon = _get(data, 'icon', '');
        const name = _get(data, 'name', '');
        const shepherdId = _get(data, 'shepherdId', '');
        const target = _get(data, 'target', '');
        const isExternal = _get(data, 'htmlAnchor', false);
        const children = _get(data, 'children', null);
        const isEnabled = _get(configuration, id, false);
        const isOpen = _startsWith(pathname, url);
        const isLocked = !isEnabled && !isExternal;
        const hasChildren = _isArray(children) && !_isEmpty(children);

        const list = isLocked ? acc.lockedFeatures : acc.enabledFeatures;
        list.push(
          <Fragment key={id}>
            <Link
              to={url}
              target={target}
              isLocked={isLocked}
              isExternal={isExternal}
              shepherdId={shepherdId}
            >
              <div className={styles.content}>
                <Icon type={icon} />
                <span className={styles.title}>{name}</span>
                {isLocked ? (
                  <Icon type="lock" className={styles.lockIcon} />
                ) : tag ? (
                  <span className={styles.tag}>{tag}</span>
                ) : null}
              </div>
              {hasChildren && !isLocked && (
                <Icon type="arrow" className={styles.arrowIcon} />
              )}
            </Link>
            {hasChildren && (
              <Collapse isOpen={isOpen}>
                <NavLinks features={children} className={styles.subContainer} />
              </Collapse>
            )}
          </Fragment>
        );
        return acc;
      },
      { enabledFeatures: [], lockedFeatures: [] }
    );

    return [...enabledFeatures, ...lockedFeatures];
  }, [features, pathname, configuration]);

  const containerClassName = cn(styles.container, className);

  return <div className={containerClassName}>{renderFeatures}</div>;
}
