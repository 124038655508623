import React, { useState, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import _startsWith from 'lodash/startsWith';
import cn from 'classnames';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';
import { ReactComponent as LockIcon } from 'assets/img/icons/lock-fill.svg';

export default function RoleSwitch({ menuClassName = '', itemClassName = '' }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { ROLES, appType, isWarehouseEnabled } = useContext(AuthContext);

  const activeRole = useMemo(() => {
    if (pathname === '/w' || _startsWith(pathname, '/w/')) return 'warehouse';
    if (pathname === '/s' || _startsWith(pathname, '/s/')) return 'store';
    return 'admin';
  }, [pathname]);

  const toggle = () => setIsOpen(isOpen => !isOpen);

  const dropdownItems = useMemo(() => {
    const admin = { key: 'admin', label: 'Admin', route: '/' };
    const warehouse = { key: 'warehouse', label: 'Warehouse', route: '/w' };
    const store = { key: 'store', label: 'Store', route: '/s' };

    const groups = isWarehouseEnabled
      ? [admin, warehouse, store]
      : [admin, store];

    const { active, locked } = groups.reduce(
      (acc, { key, label, route }) => {
        const isLocked = !_get(ROLES, key, false);
        const list = isLocked ? acc.locked : acc.active;
        list.push(
          <DropdownItem
            key={key}
            disabled={isLocked}
            onClick={() => history.push(route)}
            className={cn(styles.item, itemClassName)}
          >
            <span>{label}</span>
            {isLocked && <LockIcon />}
          </DropdownItem>
        );
        return acc;
      },
      { active: [], locked: [] }
    );

    return [...active, ...locked];
  }, [ROLES, history, isWarehouseEnabled, itemClassName]);

  if (!appType.retex) return null;

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className={styles.dropdown}>
      <DropdownToggle nav className={styles.toggleBox}>
        {activeRole} <i className="fas fa-chevron-down" />
      </DropdownToggle>
      <DropdownMenu className={cn(styles.menu, menuClassName)}>
        {dropdownItems}
      </DropdownMenu>
    </Dropdown>
  );
}
