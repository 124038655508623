import React, { useContext } from 'react';
import _get from 'lodash/get';

import { UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';
import Variant from './Variant';

export default function ProductVariants() {
  const { retex } = useContext(RetexContext);

  const quantity = _get(retex, 'quantity', 0);
  const entityType = _get(retex, 'entity_type', '');
  const pruchasedVariant = _get(retex, 'purchased_variant', {});
  const exchangeVariant = _get(retex, 'exchange_variant', {});

  const isExchange = entityType === 'EXC';
  const formattedEntityType = isExchange ? 'EXCHANGE' : 'RETURN';

  return (
    <UiKit.GridRow templateColumns="1fr" gap="15">
      <Variant
        data={pruchasedVariant}
        subTitle={formattedEntityType}
        quantity={quantity}
      />
      {isExchange && (
        <Variant showIcon data={exchangeVariant} quantity={quantity} />
      )}
    </UiKit.GridRow>
  );
}
