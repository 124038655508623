import React from 'react';
import cn from 'classnames';

import { If } from 'utils';

const PROVISIONAL_TYPES = ['inline', 'adder', 'circle'];

export default function Button({
  children,
  innerRef = null,
  onClick = () => {},
  customType = 'primary', // primary | success | danger | cancel | outline | inline | adder | circle
  disabled,
  processing,
  processingText = 'Saving',
  wrapperStyle = {},
  wrapperClassName = '',
  className = '',
  wrapperId = '',
  shepherdId = '',
  ...htmlProps
}) {
  const isDisabled = disabled || processing;
  const isProvisionalButton = PROVISIONAL_TYPES.includes(customType);

  const wrapClassName = cn('uiKit__element', 'd-flex', {
    [wrapperClassName]: !!wrapperClassName
  });
  const buttonClassName = cn('uiKit__button', {
    [customType]: !!customType,
    [className]: !!className,
    processing: !!processing
  });

  return (
    <div id={wrapperId} className={wrapClassName} style={wrapperStyle}>
      <button
        {...htmlProps}
        ref={innerRef}
        onClick={onClick}
        disabled={isDisabled}
        data-shepherd={shepherdId}
        className={buttonClassName}
      >
        <If test={isProvisionalButton}>{children}</If>
        <If test={!isProvisionalButton}>
          <div className="uiKit__button--text">{children}</div>
          <div className="uiKit__button--processing">{processingText}</div>
        </If>
      </button>
    </div>
  );
}
