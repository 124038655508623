import React, { useMemo } from 'react';
import classnames from 'classnames';

import { ReactComponent as CrossIcon } from 'assets/img/icons/close.svg';

export default function EmailBadges({
  emails = [],
  disabled = true,
  onDelete = () => {},
  wrapperClassName = '',
  emptyBlockMsg = 'No recipients added.'
}) {
  const emailsAvailable = emails && emails.length > 0;
  const emailItems = emailsAvailable ? (
    emails.map(mail => {
      const className = classnames('mailingList__emailAddressesListItem', {
        disabled
      });
      const iconClass = classnames('mailingList__crossIcon', {
        disabled
      });
      return (
        <li key={mail} className={className}>
          <span className="mailingList__emailAddress">{mail}</span>
          <span className={iconClass}>
            <CrossIcon onClick={() => onDelete(mail)} />
          </span>
        </li>
      );
    })
  ) : (
    <li className="mailingList__noEmailAddresses">
      <span>{emptyBlockMsg}</span>
    </li>
  );

  const wrapperClass = useMemo(() => {
    return classnames('mailingList__emailAddressesList', {
      [wrapperClassName]: !!wrapperClassName
    });
  }, [wrapperClassName]);

  return <ul className={wrapperClass}>{emailItems}</ul>;
}
