import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { post } from 'utils';
import { UiKit, MailingList, CustomToast } from 'components';
import { UncontrolledTooltip } from 'reactstrap';

export const RESEND_EMAIL_TYPES = {
  OS_CONFIRMATION: 'OS_CONFIRMATION',
  PAYMENT_REQUIRED: 'PAYMENT_REQUIRED'
};

export default function ResendOsEmail({
  osId = '',
  type = '',
  customerEmail = '',
  isDisabled = false,
  tooltipMessage = null,
  buttonClassName = '',
  buttonWrapperClassName = ''
}) {
  const [emails, setEmails] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const id = useRef(`email-trigger-${uuidv4()}`).current;

  const { buttonText, modalTitle, modalHelptext } = getCopies(type);
  const hasNoEmailRecipients = _isEmpty(emails);

  useEffect(() => {
    if (isOpen && customerEmail) {
      setEmails(prevEmails => ({ ...prevEmails, [customerEmail]: true }));
    }
  }, [isOpen, customerEmail]);

  const onOpenModal = () => setIsOpen(true);

  const onCloseModal = () => setIsOpen(false);

  const onSendEmail = () => {
    if (!osId) return;
    setIsProcessing(true);
    const params = { routeParam: osId, type, emails: Object.keys(emails) };
    post({ apiKey: 'requests.trigger_emails' }, { params })
      .then(() => {
        const msg = 'Email sent successfully';
        CustomToast({ type: 'success', msg });
      })
      .catch(err => {
        const msg =
          'Unable to send the email at the moment. Please try again later!';
        CustomToast({ isNotified: err.notified, type: 'error', msg });
      })
      .finally(() => {
        onCloseModal();
        setIsProcessing(false);
      });
  };

  const buttonClass = cn(styles.button, buttonClassName);
  const buttonWrapperClass = cn(styles.buttonWrapper, buttonWrapperClassName);

  if (!RESEND_EMAIL_TYPES[type]) {
    throw new Error('Please provide a valid email type');
  }

  return (
    <div id={id}>
      <UiKit.Button
        onClick={onOpenModal}
        disabled={isDisabled}
        className={buttonClass}
        wrapperClassName={buttonWrapperClass}
      >
        {buttonText}
      </UiKit.Button>
      {tooltipMessage && (
        <UncontrolledTooltip target={id}>{tooltipMessage}</UncontrolledTooltip>
      )}
      <UiKit.Modal
        isOpen={isOpen}
        title={modalTitle}
        subTitle={modalHelptext}
        onClose={onCloseModal}
        onConfirm={onSendEmail}
        isProcessing={isProcessing}
        confirmBtnText="Send e-mail"
        isConfirmDisabled={hasNoEmailRecipients}
      >
        <MailingList
          emails={emails}
          onUpdate={setEmails}
          disabled={isProcessing}
          label="Enter email addresses to send email to"
        />
      </UiKit.Modal>
    </div>
  );
}

function getCopies(type = '') {
  switch (type) {
    case RESEND_EMAIL_TYPES.OS_CONFIRMATION:
      return {
        modalTitle: 'Request confirmation email',
        modalHelptext:
          'You can re-send the return request confirmation email to your shoppers.',
        buttonText: 'Resend shipping email'
      };
    case RESEND_EMAIL_TYPES.PAYMENT_REQUIRED:
      return {
        modalTitle: 'Payment reminder email',
        modalHelptext:
          'You can re-send the email your shoppers reminding them of the payment to confirm the exchange order.',
        buttonText: 'Send payment reminder email'
      };
    default:
      return {};
  }
}
