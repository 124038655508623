import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  createContext,
  useMemo,
  useCallback
} from 'react';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';

import Highlight from '../Highlight';
import { Context } from '../../context';

export const SectionContext = createContext();

export default function Section({
  hashId = '',
  className = '',
  shepherdId = '',
  highlighClassName = '',
  disableHighlight = false,
  highlightAnimationDelay = 500,
  children = null
}) {
  const { hash } = useLocation();
  const sectionRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const { scrollTo } = useContext(Context);

  const pageHash = useMemo(() => {
    const hasHash = _get(hashId, '0') === '#';
    return hasHash ? hashId : `#${hashId}`;
  }, [hashId]);

  useEffect(() => {
    const hashMatches = hash === pageHash;
    setIsFocused(hashMatches);
    if (hashMatches) {
      scrollTo(sectionRef);
    }
  }, [hash, pageHash, scrollTo]);

  const onClearFocus = useCallback(() => {
    if (!isFocused) return;
    setIsFocused(false);
  }, [isFocused]);

  return (
    <SectionContext.Provider value={{ isFocused, onClearFocus }}>
      <section
        ref={sectionRef}
        className={className}
        data-shepherd={shepherdId}
      >
        <Highlight
          disabled={disableHighlight}
          delay={highlightAnimationDelay}
          className={highlighClassName}
        >
          {children}
        </Highlight>
      </section>
    </SectionContext.Provider>
  );
}
