import React from 'react';
import lockIcon from 'assets/img/icons/lock-fill.svg';
import styles from './index.module.scss';
import classNames from 'classnames';

const LockContainer = ({
  locked = false,
  children = null,
  onHoverText = (
    <>
      This is a global-level configuration, please select <b>Global Settings</b>{' '}
      from the dropdown above.
    </>
  )
}) => {
  const mainContainerClassname = classNames({
    [styles.mainContainer]: locked
  });

  const hoverTextClassname = classNames(styles.hoverText);

  const showLockIcon = locked ? (
    <img src={lockIcon} alt="" className={styles.lockIcon} />
  ) : null;

  const showHoverText = locked ? (
    <div className={hoverTextClassname}>{onHoverText}</div>
  ) : null;

  return (
    <div className={styles.outerContainer}>
      {showHoverText}
      <div className={mainContainerClassname}>
        {showLockIcon}

        {children}
      </div>
    </div>
  );
};

export default LockContainer;
