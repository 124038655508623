import React, { Component } from 'react';
import { UncontrolledAlert } from 'reactstrap';
import { Formik } from 'formik';

import { getUrlParameter } from 'utils/methods';
import { isValidEmail } from 'utils/validators';
import { fetchRetailers } from 'utils/services';
import StepOneForm from './StepOneForm.js';

let defaultValues;

class FormWrapper extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeError = this.closeError.bind(this);
    this.state = {
      error: false,
      loading: false,
      step: 1
    };
    this.errorMessage = '';
    const email = getUrlParameter(null, 'email');
    defaultValues = {
      email: email || ''
    };
  }
  handleSubmit(values) {
    this.setState({
      loading: true
    });
    const formattedValues = Object.assign({}, values);
    formattedValues.email = formattedValues.email.toLowerCase();
    // this.setState({
    //   email:values.email,
    //   list:['Yummie','Zenwear'].map((entry)=>{ return {value:entry,label:entry} }),
    //   step:2
    // })
    this.fetchRetailerList(formattedValues);
  }

  fetchRetailerList = values => {
    fetchRetailers(
      values,
      payload => {
        this.props.proceed(Object.assign({}, payload, { email: values.email }));
      },
      () => {
        this.setState({ loading: false });
        this.setError('Unable to fetch the user information at the moment.');
      }
    );
  };
  setError(msg) {
    this.errorMessage = msg;
    this.setState({ error: true });
  }
  handleChange(values) {
    this.setState({
      error: false,
      isPasswordChange: false
    });
  }
  closeError() {
    this.setState({
      error: false,
      isPasswordChange: false
    });
  }

  validate(values) {
    let errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  }

  render() {
    // const { step, list, email } = this.state;
    return (
      <div>
        <UncontrolledAlert
          className="text-left"
          color="danger"
          isOpen={this.state.error}
          toggle={this.closeError}
        >
          <strong>Error!</strong> {this.errorMessage}
        </UncontrolledAlert>
        <Formik
          validateOnChange={false}
          validate={this.validate}
          initialValues={defaultValues}
          onSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          render={formikProps => (
            <StepOneForm {...formikProps} loading={this.state.loading} />
          )}
        />
      </div>
    );
  }
}
export default FormWrapper;
