import React, { useContext } from 'react';
import _get from 'lodash/get';

import { ReactTablesContext } from '../index';
import { TableHighlight } from 'components/Tables';

export default function Highlight({
  data = {},
  attribute = '',
  attributes = null,
  onClick = () => {},
  className = ''
}) {
  const { refinements } = useContext(ReactTablesContext);
  const searchText = _get(refinements, 'search', '').trim();

  return (
    <TableHighlight
      text={searchText}
      data={data}
      attribute={attribute}
      attributes={attributes}
      onClick={onClick}
      className={className}
    />
  );
}
