import React, {
  useContext,
  useState,
  useRef,
  useMemo,
  useCallback
} from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { orderServiceInprogressStatuses } from 'utils/DICTIONARY';
import { OS_STATUS } from 'dictionary';
import { StickyButtons, CancelHoldRequest } from 'components';
import { OverflowMenu } from 'components/Tables/CustomTable';
import { RequestAction } from 'views/Requests/components';
import { OrderServiceContext } from 'components/OrderServiceDetails';

export default function Actions({ className = '' }) {
  const {
    orderService,
    retexes,
    isDisabled,
    showAction,
    onLoadData,
    onUpdateRetex,
    setIsDisabled,
    setProcessingId
  } = useContext(OrderServiceContext);
  const [isUnlockModalOpen, setIsUnlockModalOpen] = useState(false);
  const modalDataRef = useRef(null);
  const IN_PROGESS_STATUSES = useRef(orderServiceInprogressStatuses()).current;

  const osId = _get(orderService, 'id', null);
  const status = _get(orderService, 'status', '');
  const isLocked = _get(orderService, 'on_hold', false);
  const isInProgress = IN_PROGESS_STATUSES.includes(status);
  const isPaymentPending = status === OS_STATUS.PENDING_PAYMENT_COMPLETION;
  const isAdminApprovalNeeded =
    status === OS_STATUS.PENDING_AT_ADMIN ||
    status === OS_STATUS.PENDING_QUALIFICATION;
  const showRejectButton = isAdminApprovalNeeded && !isLocked;

  const onToggleModal = useCallback((modalData = null) => {
    modalDataRef.current = modalData;
    setIsUnlockModalOpen(!!modalData);
  }, []);

  const onPostUnlock = useCallback(() => {
    onLoadData();
    onToggleModal();
  }, [onLoadData, onToggleModal]);

  const onBeforeProcessing = useCallback(
    (data, type) => {
      const id = _get(data, 'id', null);
      const status = _get(data, 'status', '');
      const isOnHold = _get(data, 'on_hold', false);
      const isUnlocking = type === 'unlock';
      const isCancelling = type === 'cancel';

      if (isUnlocking) {
        onToggleModal({
          type: 'hold',
          orderServiceID: id,
          extra: { holdVal: !isOnHold }
        });
      } else if (isCancelling) {
        onToggleModal({
          type: 'cancel',
          orderServiceID: id,
          orderServiceStatus: status
        });
      } else {
        return setIsDisabled(true);
      }
    },
    [onToggleModal, setIsDisabled]
  );

  const onAfterProcessing = useCallback(() => {
    setIsDisabled(false);
    setProcessingId(null);
    onLoadData();
  }, [setIsDisabled, setProcessingId, onLoadData]);

  const overflowMenuItems = useMemo(() => {
    if (!isInProgress) return [];
    const menuItems = [];
    if (!isLocked) {
      menuItems.push({
        title: 'Lock Request',
        onClick: () => {
          onToggleModal({
            type: 'hold',
            orderServiceID: osId,
            extra: { holdVal: true }
          });
        }
      });
    }
    menuItems.push({
      title: 'Cancel Request',
      onClick: () => {
        onToggleModal({
          type: 'cancel',
          orderServiceID: osId
        });
      }
    });
    return menuItems;
  }, [osId, isInProgress, isLocked, onToggleModal]);

  const actionProps = {
    data: orderService,
    retexes,
    isDisabled,
    onSetProcessingId: setProcessingId,
    onRetexUpdate: onUpdateRetex,
    onBeforeProcessing,
    onAfterProcessing
  };
  const containerClassName = cn(styles.container, className);

  if (!showAction) return null;

  return (
    <StickyButtons
      containerClassName={containerClassName}
      contentClassName={styles.content}
    >
      <If test={!isPaymentPending}>
        <OverflowMenu
          alignToRight
          direction="up"
          items={overflowMenuItems}
          isDisabled={!isInProgress || isDisabled}
          className={styles.overflowMenu}
        />
      </If>
      <If test={showRejectButton}>
        <RequestAction isApproving={false} {...actionProps} />
      </If>
      <RequestAction isApproving {...actionProps} />
      {modalDataRef.current && (
        <CancelHoldRequest
          isOpen={isUnlockModalOpen}
          data={modalDataRef.current}
          toggle={onToggleModal}
          done={onPostUnlock}
        />
      )}
    </StickyButtons>
  );
}
