import React from 'react';
import { renderToString } from 'react-dom/server';

import { ProgressIndicator, StepText } from 'containers/TourGuides/components';
import {
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import ADMIN_ROUTES from 'config/nav/admin/routes';

const SHOPPER_PORTAL_PATH = ADMIN_ROUTES.CONFIGURE_RR.SHOPPER_PORTAL;

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getCustomerCareSteps({
  push = () => {},
  onShow = () => {},
  onHide = () => {}
}) {
  return [
    {
      id: 'customer-care-intro',
      title: 'Customer Care',
      text: renderToString(
        <>
          <StepText>
            Let's see where you can configure your Customer Care details.
          </StepText>
          <ProgressIndicator current={1} total={3} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({ push, path: SHOPPER_PORTAL_PATH });
      },
      buttons: [{ text: "Let's go", type: 'next' }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'customer-care-card',
      attachTo: {
        element: getShepherdSelector('card-customer-care'),
        on: 'left'
      },
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText className="mb-1">
            Your customer care details will be shown to your shoppers both on
            the returns portal and also as a part of all email communications
            that Return Rabbit sends on your behalf.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on <code>Manage</code> button to continue.
          </StepText>
          <ProgressIndicator current={2} total={3} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector('card-customer-care')} a`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'customer-care-details',
      attachTo: {
        element: getShepherdSelector('customer-care-details'),
        on: 'right'
      },
      canClickTarget: false,
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      text: renderToString(
        <>
          <StepText>
            Here you can setup the customer care phone number and email id,
            which will be shown to shoppers.
            <br />
            <br />
            You can also leave the phone number blank in case you don't want to
            show a phone number.
          </StepText>
          <ProgressIndicator current={3} total={3} />
        </>
      ),
      buttons: [{ text: 'Done', type: 'next' }],
      when: { show: onShow, hide: onHide }
    }
  ];
}
