import React from 'react';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'reactstrap';

export default function Pagination({
  data,
  totalPage,
  fetchMore,
  isDataFetched
}) {
  if (data.length === 0 && isDataFetched) {
    return (
      <Row className="no-record no-gutters">
        <Col>No records found</Col>
      </Row>
    );
  }
  if (totalPage <= 1) return null;

  const fetchMorePage = e => fetchMore(e.selected + 1);

  return (
    <Row className="py-4 mt-3 no-gutters">
      <Col>
        <ReactPaginate
          previousLabel={''}
          nextLabel={''}
          pageCount={totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={fetchMorePage}
          containerClassName={'pagination pagination-l'}
          activeClassName={'active'}
          breakClassName="page-item"
          breakLabel={<span className="page-link">...</span>}
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
        />
      </Col>
    </Row>
  );
}
