import React from 'react';
import loadable from '@loadable/component';
import _isFunction from 'lodash/isFunction';

export default function CustomLazyLoad(
  loadFunc = () => {},
  fallback = <div>Loading...</div>
) {
  if (!_isFunction(loadFunc)) return () => null;

  return loadable(loadFunc, { fallback, ssr: false });
}
