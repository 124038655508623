import _get from 'lodash/get';
import _set from 'lodash/set';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import { localStore } from 'utils';
import { logout } from 'utils/services';
import { CustomToast } from 'components';
import { API_ENDPOINTS } from 'config/env';
import { axiosPublicInstance, axiosPrivateInstance } from './axios';

const PREVENT_LOGOUT_FOR_URL = {
  [API_ENDPOINTS.login]: true
};

let IS_LOGGINIG_OUT = false;

export default function setup() {
  axiosPublicInstance.defaults.timeout = 30000; // 30 seconds
  axiosPrivateInstance.defaults.timeout = 30000; // 30 seconds

  axiosPublicInstance.interceptors.request.use(requestInterceptor);
  axiosPrivateInstance.interceptors.request.use(requestInterceptor);

  axiosPublicInstance.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
  );
  axiosPrivateInstance.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
  );
}

function requestInterceptor(config) {
  const store = localStore.get('rrloggedInStoreName');
  const appType = _toLower(_get(window, 'config.APP_TYPE', ''));
  _set(config, 'headers.app_type', appType);
  if (store) {
    _set(config, 'headers.X-Cookie-Authentication', true);
    _set(config, 'headers.X-Store', store);
  }
  return config;
}

function responseSuccessInterceptor(response) {
  const res = response.data;
  const url = response.config.url;
  res.statusCode = response.status;
  res.notified = false;
  notify(res);
  if (!res.data || res.data === null) {
    const error = new Error(`Api Error: - ${url}`);
    error.displayMessage = 'Error fetching data. Please try again later';
    error.responseReceived = res;
    return Promise.reject(error);
  }
  return res;
}

function responseErrorInterceptor(error) {
  const url = _get(error, 'config.url');
  const baseURL = _get(error, 'config.baseURL');
  const method = (_get(error, 'config.method') || '').toUpperCase();
  const status =
    _get(error, 'response.status') !== null
      ? _get(error, 'response.status')
      : -1;
  error.message = `${method} ${status} ${url}`;

  const endpoint = _replace(url, baseURL, '');
  const responseData = _get(error, 'response.data');
  error.method = _get(error, 'config.method');
  error.statusCode = status;
  error.url = url;
  error.notified = notify(responseData);
  error.displayMessage = _get(responseData, 'displayMessage');

  if (
    (status === 401 || status === 403) &&
    !PREVENT_LOGOUT_FOR_URL[endpoint] &&
    !IS_LOGGINIG_OUT
  ) {
    IS_LOGGINIG_OUT = true;
    window.location = '/';
    logout(() => {
      IS_LOGGINIG_OUT = false;
    });
  }

  return Promise.reject(error);
}

function notify(res) {
  const show = _get(res, 'displayMessage.showToUser', false);
  if (show) {
    const { message, level } = _get(res, 'displayMessage', {});
    CustomToast({ msg: message, type: level });
    res.notified = true;
  }
  return show;
}
