import React, { useContext } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { formatPrice } from 'utils/formatters';
import { UiKit, ProductVariantAttributes, DispositionMode } from 'components';
import { OrderServiceContext } from 'components/OrderServiceDetails';
import { ReactComponent as LockIcon } from 'assets/img/icons/lock-fill.svg';
import { ReactComponent as ReturnIcon } from 'assets/img/icons/return-arrow.svg';
import { ReactComponent as ExchangeIcon } from 'assets/img/icons/exchange-arrow.svg';

export default function RetexInfo({ data = {}, canSelect = true }) {
  const {
    orderService = {},
    retexStatuses = {},
    isDisabled = false,
    processingId = null,
    terminalOsStatuses = [],
    rejectedRetexStatuses = [],
    approvedRetexStatuses = [],
    onRetexClick = () => {},
    onUpdateRetex = () => {}
  } = useContext(OrderServiceContext);

  const id = _get(data, 'id', '');
  const name = _get(data, 'name', '');
  const status = _get(data, 'status', '');
  const amount = _get(data, 'amount', '');
  const currency = _get(data, 'currency', '');
  const quantity = _get(data, 'quantity', '');
  const entityType = _get(data, 'entity_type', '');
  const dispositionMode = _get(data, 'disposition.mode', '');
  const purchasedVariant = _get(data, 'purchased_variant', {});
  const exchangeVariant = _get(data, 'exchange_variant', null);
  const isActionable = _get(data, 'is_actionable', false);
  const isChecked = _get(data, 'is_checked', false);
  const showRestock = _get(data, 'show_restock', false);
  const willRestock = _get(data, 'will_restock', false);
  const isOnHold = _get(orderService, 'on_hold', false);
  const osStatus = _get(orderService, 'status', false);

  const productName = _get(purchasedVariant, 'title', '').toLowerCase();
  const productImageUrl = _get(purchasedVariant, 'image_url', '');
  const optionValues = _get(purchasedVariant, 'option_values', {});
  const formattedStatus = _get(retexStatuses, status, '');

  const exchangedProductName = _get(exchangeVariant, 'title', '').toLowerCase();
  const exchangedProductImageUrl = _get(exchangeVariant, 'image_url', '');
  const exchangedOptionValues = _get(exchangeVariant, 'option_values', {});

  const isReturn = entityType === 'RET';
  const isProcessing = id === processingId;
  const isRejected = rejectedRetexStatuses.includes(status);
  const isApproved = approvedRetexStatuses.includes(status);
  const isTerminatedOS = terminalOsStatuses.includes(osStatus);

  const pricePrefix = amount < 0 ? '-' : '';
  const disableActions = isDisabled || isOnHold;
  const retexType = isReturn ? 'Return' : 'Exchange';
  const formattedPrice = `${pricePrefix} ${formatPrice(amount, currency)}`;
  const restockCheckboxLabel = `Restock product${quantity > 1 ? 's' : ''}`;

  const productTitleClassName = cn(styles.button, styles.strongText);
  const restockCheckboxClassname = cn(styles.restockLabel, styles.lightText);
  const containerClassName = cn(styles.container, {
    [styles.danger]: isOnHold && !isTerminatedOS
  });
  const statusClassName = cn(styles.lightText, styles.status, {
    [styles.danger]: isRejected || isOnHold,
    [styles.success]: isApproved
  });
  const priceClassName = cn(styles.strongText, {
    [styles.discarded]: isRejected
  });

  const onLinkClick = () => onRetexClick(id, name);

  const onToggleRetex = () => {
    if (!isActionable || disableActions) return;
    onUpdateRetex(id, { is_checked: !isChecked });
  };

  const onToggleDisposition = () => {
    if (!showRestock || !isActionable || disableActions) return;
    onUpdateRetex(id, { will_restock: !willRestock });
  };

  return (
    <div className={containerClassName}>
      {canSelect && isActionable && (
        <UiKit.Checkbox
          checked={isChecked}
          toggle={onToggleRetex}
          disabled={disableActions}
          wrapperClassName={styles.checkbox}
        />
      )}
      <UiKit.GridRow templateColumns="1fr" gap={14} className="flex-grow-1">
        <UiKit.GridRow templateColumns="70px 1fr" gap={14}>
          <div className={styles.imageWrapper}>
            <img src={productImageUrl} alt={productName} />
            <span>{quantity}</span>
          </div>
          <div className={styles.content}>
            <UiKit.GridRow
              templateColumns="1fr auto"
              gap="30"
              className="align-items-center"
            >
              <div className={styles.productTitleWrapper}>
                <span
                  role="button"
                  onClick={onLinkClick}
                  className={productTitleClassName}
                >
                  {productName}
                </span>
              </div>
              <div className={styles.entityType}>
                {isReturn ? <ReturnIcon /> : <ExchangeIcon />}
                <span>{retexType}</span>
              </div>
            </UiKit.GridRow>
            <UiKit.GridRow className="align-items-center" gap="20">
              <span className={statusClassName}>
                {isOnHold && !isTerminatedOS ? (
                  <LockIcon className={styles.lockIcon} />
                ) : null}
                {formattedStatus}
                {isProcessing && '...'}
              </span>
              {showRestock ? (
                <UiKit.Checkbox
                  checked={willRestock}
                  title={restockCheckboxLabel}
                  toggle={onToggleDisposition}
                  disabled={!isActionable || disableActions}
                  textClassName={restockCheckboxClassname}
                />
              ) : isApproved || isRejected ? (
                <DispositionMode
                  mode={dispositionMode}
                  className={styles.dispositionMode}
                />
              ) : null}
            </UiKit.GridRow>
            <div className={styles.row}>
              <ProductVariantAttributes
                optionValues={optionValues}
                className={styles.lightText}
              />
              <span className={priceClassName}>{formattedPrice}</span>
            </div>
          </div>
        </UiKit.GridRow>
        {!isReturn && exchangeVariant && (
          <div className={styles.exchangeItemContainer}>
            <p className={cn(styles.lightText, 'mb-2')}>Exchange with:</p>
            <UiKit.GridRow templateColumns="auto 1fr" gap={14}>
              <div className="text-right">
                <img src={exchangedProductImageUrl} alt="product thumbnail" />
              </div>
              <div className={styles.info}>
                <strong>{exchangedProductName}</strong>
                <ProductVariantAttributes
                  showCount={2}
                  optionValues={exchangedOptionValues}
                  className={styles.lightText}
                />
              </div>
            </UiKit.GridRow>
          </div>
        )}
      </UiKit.GridRow>
    </div>
  );
}
