import * as Yup from 'yup';

const INITIAL_VALUES = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
};

const formValidatorSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Password is required'),
  newPassword: Yup.string().required('Password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required')
});

export { INITIAL_VALUES, formValidatorSchema };
