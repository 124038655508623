import moment from 'moment';
import _isNumber from 'lodash/isNumber';

export default function getDeafaultMinDateFilter(months = null) {
  const monthOffset = _isNumber(months) ? months : 1;
  return moment()
    .utc()
    .startOf('day')
    .subtract(monthOffset, 'month')
    .unix();
}
