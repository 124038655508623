import React from 'react';

export default function Delivery() {
  return (
    <svg viewBox="0 0 53.16 42.33">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            style={{ fill: 'var(--icon-color)' }}
            d="M49.16,38.3v-7h4V29.37a8.84,8.84,0,0,1-1.54,0h-.33a5.34,5.34,0,0,1-.69-.05L50,29.28v0H43.14v2H37.25a4.14,4.14,0,0,1-.68-.1A4.31,4.31,0,0,1,36,31l-.15-.08-.18-.14v0l-1.94-1.29-8.43-3a3.09,3.09,0,0,0-2.42.16,3.09,3.09,0,0,0-.05,5.49L25,33.27H21.81L20.51,30a2.76,2.76,0,0,0-2.58-1.75,2.81,2.81,0,0,0-2.26,1.16A2.77,2.77,0,0,0,15.3,32l.3.9-2.19-1.2a2.91,2.91,0,0,0-2.29-.2A2.75,2.75,0,0,0,9.6,32.7c0,.07,0,.14,0,.21-.08.22-.18.43-.27.64a.36.36,0,0,0,0,.11v0a.64.64,0,0,0,0,.07,7.6,7.6,0,0,0,0,1c0,.15.07.3.11.45l.27.51c.46.43.94.83,1.41,1.25a1.38,1.38,0,0,1,.41.19v0l4.25,2.1,5.22,1.06a2.14,2.14,0,0,1,.43.07l.34.14.22,0a11.33,11.33,0,0,1,1.26.24h0l2.78.56h4.29c.38,0,.77,0,1.16,0a11.78,11.78,0,0,1,2.06,0h5.79l3.9-.78v.79l-.09.08a1.9,1.9,0,0,1,.58.74,13,13,0,0,1,1.87.18h7.66v-2h-8v-9h2v7ZM17.3,30.62a.81.81,0,0,1,1.36.16l1,2.5H17.85l-.66-2A.76.76,0,0,1,17.3,30.62Zm25.84,7.85h0l-4.1.82H26.24l-9.78-1.95L11.64,35a.86.86,0,0,1-.4-1.14.88.88,0,0,1,.51-.47.9.9,0,0,1,.7.07l3.43,1.87h9.49l3.95-2-5.59-3a1.09,1.09,0,0,1-.53-1.28,1.1,1.1,0,0,1,1.4-.71l8.09,2.83,3.15,2.1h7.3Z"
          />
          <path
            style={{ fill: 'var(--icon-color)' }}
            d="M44,20.58A8.38,8.38,0,0,1,44.16,19V3a2.48,2.48,0,0,1,0-.27,3.88,3.88,0,0,1,0-1c0-.44,0-.88.08-1.31A15,15,0,0,1,42.06.3h-9.2a5.15,5.15,0,0,1-2,.32C25,.44,19.24.42,13.44.42A5.48,5.48,0,0,1,12.26.3H3.87C3,.36,2.14.38,1.27.39A4,4,0,0,1,.29.3,14.34,14.34,0,0,1,.16,3.77v16c0,.25.06.51.08.79,0,.77,0,1.53-.06,2.29,0,.08,0,.15,0,.22V26.3H5.33a1.24,1.24,0,0,1,.27,0h.05a4,4,0,0,1,.6,0c.53,0,1.07,0,1.6,0H36.34a1.58,1.58,0,0,1,.38,0c.44,0,.88,0,1.32,0,.26,0,.51,0,.77.05h5.35v-4h0a5,5,0,0,1-.09-1.16C44.05,21,44,20.76,44,20.58ZM33.16,2.3h3v7h-3Zm-8,22h-23V2.3h23Zm17,0h-15V2.3h4v9h7v-9h4Z"
          />
          <rect
            style={{ fill: 'var(--icon-color)' }}
            x="0.16"
            y="0.01"
            width="44.01"
            height="1"
          />
          <rect
            style={{ fill: 'var(--icon-color)' }}
            x="43.17"
            y="0.08"
            width="1"
            height="26.21"
          />
          <rect
            style={{ fill: 'var(--icon-color)' }}
            width="1"
            height="26.21"
          />
          <line
            style={{
              fill: 'none',
              strokeMiterlimit: 10,
              stroke: 'var(--icon-color)'
            }}
            x1="43.14"
            y1="29.77"
            x2="53.16"
            y2="29.77"
          />
          <line
            style={{
              fill: 'none',
              strokeMiterlimit: 10,
              stroke: 'var(--icon-color)'
            }}
            x1="43.14"
            y1="41.83"
            x2="53.16"
            y2="41.83"
          />
        </g>
      </g>
    </svg>
  );
}
