import React, { useContext, useRef } from 'react';
import cn from 'classnames';

import { AuthContext } from 'context/AuthContext';
import rrLogo from 'assets/img/brand/rr-logo.svg';
import trackingRabbitLogo from 'assets/img/brand/tracking-rabbit-logo.png';

export default function Title({ className = '' }) {
  const { appType } = useContext(AuthContext);

  const logo = useRef(appType.shipmentTracking ? trackingRabbitLogo : rrLogo)
    .current;

  return (
    <div className={cn('text-center', className)}>
      <div className="brand-logo">
        <img alt="logo" src={logo} width="215" />
      </div>
      <small>Enabling Seamless Customer Experience</small>
    </div>
  );
}
