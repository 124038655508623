import { useState, useCallback } from 'react';
import _isBoolean from 'lodash/isBoolean';

export default function useToggleMap(
  initialValue = {},
  { allowMultiActive = true } = {}
) {
  const [active, setActive] = useState(initialValue);

  const setMultiActive = useCallback((id, enforceValue = null) => {
    const forceUpdate = _isBoolean(enforceValue);

    if (forceUpdate) {
      setActive(currActive => {
        const active = { ...currActive };
        delete active[id];
        if (enforceValue) {
          active[id] = true;
        }
        return active;
      });
    } else {
      setActive(currActive => {
        const active = { ...currActive };
        if (id in active) {
          delete active[id];
        } else {
          active[id] = true;
        }
        return active;
      });
    }
  }, []);

  const setSingleActive = useCallback((id, enforceValue = null) => {
    const forceUpdate = _isBoolean(enforceValue);

    if (forceUpdate) {
      setActive(() => {
        const active = {};
        if (enforceValue) {
          active[id] = true;
        }
        return active;
      });
    } else {
      setActive(currActive => {
        const active = {};
        if (forceUpdate) {
          active[id] = enforceValue;
        } else if (!(id in currActive)) {
          active[id] = true;
        }
        return active;
      });
    }
  }, []);

  const onToggle = useCallback(
    (id, enforceValue = null) => {
      if (allowMultiActive) {
        setMultiActive(id, enforceValue);
      } else {
        setSingleActive(id, enforceValue);
      }
    },
    [allowMultiActive, setMultiActive, setSingleActive]
  );

  const onClear = useCallback(() => setActive({}), []);

  return {
    active,
    setActive,
    onToggle,
    onClear
  };
}
