import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import qs from 'qs';
import styles from './index.module.scss';

import { CustomToast, Loader } from 'components';
import { AuthContext } from 'context/AuthContext';
import { externalUserLogin } from 'utils/services';

export default function ExternalSignIn() {
  const { search } = useLocation();
  const { replace } = useHistory();
  const { login } = useContext(AuthContext);

  const { token, store } = qs.parse(search.slice(1));

  useEffect(() => {
    if (token) {
      externalUserLogin(
        { token },
        data => {
          login(data);
          replace('/');
        },
        err => {
          replace('/login');
          CustomToast({
            isNotified: err.notified,
            type: 'error',
            msg: "We couldn't log you in. Please try again."
          });
        }
      );
    }
  }, [token, login, replace]);

  if (!token) return <Redirect to="/" />;

  return (
    <div className={styles.container}>
      <Loader loading spinnerClassName={styles.spinner} />
      <span className={styles.content}>
        Provisioning access to <strong>"{store || 'the store'}"</strong>...
      </span>
    </div>
  );
}
