import { OS_STATUS } from 'dictionary';

export function isOsAwaitingAtAdmin(status = '') {
  return (
    status === OS_STATUS.PENDING_QUALIFICATION ||
    status === OS_STATUS.PENDING_AT_ADMIN
  );
}

export function isOsPendingPayment(status = '') {
  return status === OS_STATUS.PENDING_PAYMENT_COMPLETION;
}
