import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { formatNumber } from 'utils';
import {
  PARAMETER_TYPE,
  PARAMETER_LABEL,
  INFINITE_VALUE
} from '../../../stubs';

export default function getFormattedRange(range = []) {
  if (!_isArray(range)) return { label: 'N/A', format: 'N/A' };

  const min = _get(range, '[0]', 0);
  const max = _get(range, '[1]', 0);
  const isForAllRange = min === 0 && max === INFINITE_VALUE;

  const format = {
    label: '',
    value: PARAMETER_LABEL[PARAMETER_TYPE.ALL]
  };

  if (!isForAllRange) {
    const minFormatted = formatNumber(min, { mantissa: 0 });
    const maxFormatted = formatNumber(max, { mantissa: 0 });
    format.label = 'Range:';
    format.value = `$${minFormatted} to $${maxFormatted}`;
  }

  return format;
}
