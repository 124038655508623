import { useContext } from 'react';
import _get from 'lodash/get';

import { AuthContext } from 'context/AuthContext';

export default function Can({ _key, children }) {
  const { configuration } = useContext(AuthContext);
  const displayConfig = configuration || null;

  if (_key === true) return children;
  if (!displayConfig) return null;

  const isEnabled = _get(displayConfig, _key, false);

  return isEnabled ? children : null;
}
