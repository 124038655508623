import { useState, useRef, useCallback, useEffect } from 'react';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _isObject from 'lodash/isPlainObject';

import { findAllValues } from 'utils/DICTIONARY';
import { RETEX_STATUS } from 'dictionary';
import { loadData } from '../services';
import {
  isRetexApproved,
  isRetexRejected,
  isRetexCancelled,
  isRetexAwatingAtAdmin
} from 'utils/methods';

export default function useRetex(osId = '', retexId = '', onLoaded = () => {}) {
  const [retex, setRetex] = useState({});
  const [orderService, setOrderService] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const retexStatuses = useRef(findAllValues('retexStatusChoices')).current;
  const approvalTypes = useRef(findAllValues('request_approval_type')).current;
  const automatedRuleTypes = useRef(
    findAllValues('automationRuleActionChoices')
  ).current;

  const status = _get(retex, 'status', '');
  const isApproved = isRetexApproved(status);
  const isRejected = isRetexRejected(status);
  const isCancelled = isRetexCancelled(status);
  const isAwatitingAtAdmin = isRetexAwatingAtAdmin(status);
  const isAtStore = status === RETEX_STATUS.AWAITING_STORE_AUTHORIZATION;
  const isAtWarehouse = status === RETEX_STATUS.AWAITING_WH_AUTHORIZATION;
  const showAction = isAwatitingAtAdmin || isAtStore || isAtWarehouse;

  const onLoadData = useCallback(() => {
    setIsError(false);
    setIsLoading(true);
    loadData(
      { osId, retexId },
      (orderService = {}, retex = {}) => {
        setRetex(retex);
        setOrderService(orderService);
        onLoaded(null, { retex, orderService });
        setIsLoading(false);
      },
      err => {
        setIsError(true);
        onLoaded(err, null);
        setIsLoading(false);
      }
    );
  }, [osId, retexId, onLoaded]);

  useEffect(() => {
    onLoadData();
  }, [onLoadData]);

  const onUpdateRetex = useCallback((updates = {}) => {
    if (!_isObject(updates)) return;
    setRetex(currData => _merge({}, currData, updates));
  }, []);

  const onUpdateOrderService = useCallback((updates = {}) => {
    if (!_isObject(updates)) return;
    setOrderService(currData => _merge({}, currData, updates));
  }, []);

  return {
    isError,
    isLoading,
    retex,
    orderService,
    retexStatuses,
    approvalTypes,
    automatedRuleTypes,
    isApproved,
    isRejected,
    isCancelled,
    isAwatitingAtAdmin,
    isDisabled,
    showAction,
    onLoadData,
    onUpdateRetex,
    setIsDisabled,
    onUpdateOrderService
  };
}
