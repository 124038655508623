import React from 'react';
import { createPortal } from 'react-dom';

export const NOTIFICATION_BANNER_CONTAINER_ID =
  'notifications-banner-container';

export default function NotificationsBanner({ children = null }) {
  return (
    <>
      {createPortal(
        <>{children}</>,
        document.getElementById(NOTIFICATION_BANNER_CONTAINER_ID)
      )}
    </>
  );
}

NotificationsBanner.Container = function NotificationsBannerContainer() {
  return <div id={NOTIFICATION_BANNER_CONTAINER_ID} />;
};
