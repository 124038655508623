const URL_PREFIX = '/w';

const ROOT_PATHS = {
  OVERVIEW: `${URL_PREFIX}/overview`
};

export default {
  OVERVIEW: {
    ROOT: ROOT_PATHS.OVERVIEW
  }
};

export { URL_PREFIX };
