const ROOT_PATHS = {
  GETTING_STARTED: '/getting-started',
  OVERVIEW: '/overview',
  ORDERS: '/orders',
  REQUESTS: '/requests',
  ANALYTICS: '/analytics',
  CONFIGURE_RR: '/configure',
  SETTINGS: '/settings',
  HELP_AND_SUPPORT: '/help-and-support'
};

export default {
  ACCOUNT_SETTINGS: {
    ROOT: '/account-settings'
  },
  GETTING_STARTED: {
    ROOT: ROOT_PATHS.GETTING_STARTED
  },
  OVERVIEW: {
    ROOT: ROOT_PATHS.OVERVIEW
  },
  ORDERS: {
    ROOT: ROOT_PATHS.ORDERS
  },
  REQUESTS: {
    ROOT: ROOT_PATHS.REQUESTS
  },
  ANALYTICS: {
    ROOT: ROOT_PATHS.ANALYTICS,
    DASHBOARD_LEGACY: `${ROOT_PATHS.ANALYTICS}/dashboard-analytics`,
    DASHBOARD: `${ROOT_PATHS.ANALYTICS}/dashboard`,
    INSIGHTS: `${ROOT_PATHS.ANALYTICS}/insights`,
    USER_REPORTS: `${ROOT_PATHS.ANALYTICS}/user-reports`
  },
  CONFIGURE_RR: {
    ROOT: ROOT_PATHS.CONFIGURE_RR,
    RETURN_MANAGEMENT: `${ROOT_PATHS.CONFIGURE_RR}/return-management`,
    SHIPPING: `${ROOT_PATHS.CONFIGURE_RR}/shipping`,
    SHOPPER_PORTAL: `${ROOT_PATHS.CONFIGURE_RR}/shopper-portal`
  },
  SETTINGS: {
    ROOT: ROOT_PATHS.SETTINGS,
    MANAGE_SUBSCRIPTION: `${ROOT_PATHS.SETTINGS}/manage-subscription`,
    USER_MANAGEMENT: `${ROOT_PATHS.SETTINGS}/manage-users`,
    MANAGE_APIS: `${ROOT_PATHS.SETTINGS}/manage-apis`,
    ACCOUNT: `${ROOT_PATHS.SETTINGS}/account`
  },
  HELP_AND_SUPPORT: {
    ROOT: ROOT_PATHS.HELP_AND_SUPPORT,
    KNOWLEDGE_BANK: `${ROOT_PATHS.HELP_AND_SUPPORT}/knowledge-bank`,
    WATCH_TUTORIAL: `${ROOT_PATHS.HELP_AND_SUPPORT}/watch-tutorial`
  }
};
