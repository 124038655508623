import { get, logError } from 'utils';

export default function getSubscription(
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  get({ apiKey: 'subscription' })
    .then(({ data }) => onSuccess(data))
    .catch(err => {
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
