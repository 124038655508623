import React from 'react';

import { UiKit } from 'components';

export default function Footer({
  processing = false,
  onCancel = () => {},
  onConfirm = () => {},
  isPasswordStrong = false
}) {
  return (
    <div className="ChangePassword__footer">
      <UiKit.Button
        customType="inline"
        disabled={processing}
        onClick={onCancel}
      >
        Cancel
      </UiKit.Button>
      <UiKit.Button
        customType="success"
        disabled={processing || !isPasswordStrong}
        onClick={onConfirm}
        wrapperClassName="ml-3"
      >
        Confirm
      </UiKit.Button>
    </div>
  );
}
