import React, { useState } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { PLAN_TERM } from 'dictionary';
import { useSubscription, useBilling } from 'utils/hooks';
import { Toggle, UiKit } from 'components';
import Plan from './Plan';

export default function ShopifySubscriptionModal() {
  const [paymentMode, setPaymentMode] = useState(PLAN_TERM.YEARLY);
  const {
    source,
    isQuotaExhausted,
    showSubscriptionModal,
    onToggleSubscriptionModal
  } = useSubscription();
  const {
    plans,
    processingPlan,
    isNotifiedForEnterprise,
    onSubscribe
  } = useBilling({
    loadOnMount: isQuotaExhausted && source === 'SHOPIFY',
    payload: { redirect_url: '/' }
  });

  const upgradablePlansList = plans.reduce((acc, data, index) => {
    const id = _get(data, 'id', index);
    const slug = _get(data, 'slug', '');
    const isEnterprisePlan = slug === 'enterprise';
    const planPrice = _get(data, `prices.${paymentMode}.price`, 0);

    const onPlanSelect = () => {
      onSubscribe({
        slug,
        payload: {
          plan_slug: slug,
          payment_mode: paymentMode
        }
      });
    };

    if (planPrice > 0 || isEnterprisePlan) {
      acc.push(
        <Plan
          key={id}
          data={data}
          term={paymentMode}
          processingPlan={processingPlan}
          isNotifiedForEnterprise={isNotifiedForEnterprise}
          onSubscribe={onPlanSelect}
        />
      );
    }

    return acc;
  }, []);

  const onToggleTerm = () => {
    setPaymentMode(
      paymentMode === PLAN_TERM.MONTHLY ? PLAN_TERM.YEARLY : PLAN_TERM.MONTHLY
    );
  };

  return (
    <UiKit.Modal
      centered
      isOpen={showSubscriptionModal}
      title="Oh No! You’ve reached the end of your return limit"
      onClose={onToggleSubscriptionModal}
      className={styles.container}
      contentClassName={styles.content}
      titleClassName={styles.title}
      headerClassName={styles.header}
    >
      <p className={styles.description}>
        Please upgrade your service tier to continue processing returns.
      </p>
      <div className={styles.termToggle}>
        <Toggle
          size="lg"
          isActive={paymentMode === 'yearly'}
          onClick={onToggleTerm}
        />
        <span>Show Annual Plans</span>
      </div>
      <h6 className={styles.optionsHeading}>Upgrade Options:</h6>
      <UiKit.GridRow gap="20" className={styles.plansList}>
        {upgradablePlansList}
      </UiKit.GridRow>
      <strong className={styles.note}>
        Note: No new service requests will be created until the end of this
        calendar month until a plan is subscribed.
      </strong>
    </UiKit.Modal>
  );
}
