import React from 'react';
import Loader from './Loader';

export default function Loading({
  loading = true,
  className = '',
  children = null
}) {
  return loading ? <Loader loading={true} customClass={className} /> : children;
}
