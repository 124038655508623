import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { useEcomPlatform } from 'utils/hooks';
import { ReactComponent as ShopifyIcon } from 'assets/img/ecomm-platforms/shopify.svg';
import { ReactComponent as WooIcon } from 'assets/img/ecomm-platforms/woocommerce.svg';

const LOGO_MAP = {
  SHOPIFY: ShopifyIcon,
  WOOCOMMERCE: WooIcon
};

/**
 * @param {Object} props
 * @param {import("utils/types").EcommPlatformSource} props.source
 * @param {string} props.className
 */
export default function Icon({ source = '', className = '' }) {
  const { ecommPlatformSource } = useEcomPlatform();

  const Logo = LOGO_MAP[source || ecommPlatformSource];

  if (!Logo) return null;

  return <Logo className={cn(styles.icon, className)} />;
}
