import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

export default function ProgressIndicator({
  current = 0,
  total = 1,
  hideStepCount = false
}) {
  const completionPercent = (current * 100) / total;

  const progressBarClassName = classNames(
    styles.progressBar,
    'shepherd-progress-bar'
  );
  return (
    <>
      <div
        className={progressBarClassName}
        style={{ '--width': `${completionPercent}%` }}
      ></div>
      {!hideStepCount && (
        <span className={styles.stepCount}>
          Step {current}/{total}
        </span>
      )}
    </>
  );
}
