import React, { useContext } from 'react';
import { DropdownToggle, Nav, UncontrolledButtonDropdown } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _startCase from 'lodash/startCase';
import styles from './index.module.scss';

import RoleSwitch from '../RoleSwitch';
import StoreSwitch from '../StoreSwitch';
import UserDropdownMenu from '../UserDropdownMenu';
import { AuthContext } from 'context/AuthContext';
import { Notifications } from './components';
import { If } from 'utils';

export default function DefaultHeader() {
  const { userData, storeProperties, permittedStores, appType } = useContext(
    AuthContext
  );

  const storeName = _get(storeProperties, 'store_unique_name', '');
  const firstName = _get(userData, 'firstName', '');
  const lastName = _get(userData, 'lastName', '');
  const avatarPicUrl = _get(userData, 'avatarPicUrl', '');

  const hasMultipleStoreAccess = _size(permittedStores) > 1;
  const avatar = avatarPicUrl
    ? avatarPicUrl
    : `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=fbf0ea`;

  const storeNameSelectorClassName = cn(styles.userInfo, {
    [styles.empty]: !hasMultipleStoreAccess
  });

  return (
    <div className={styles.container}>
      <RoleSwitch menuClassName={styles.menu} itemClassName={styles.item} />
      <div className={styles.content}>
        <If test={appType.retex}>
          <Notifications />
        </If>
        <Nav navbar className={styles.userNav}>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              nav
              className={storeNameSelectorClassName}
              caret={hasMultipleStoreAccess}
            >
              <span>{storeName}</span>
            </DropdownToggle>
            {hasMultipleStoreAccess && (
              <StoreSwitch
                className={styles.menu}
                itemClassName={styles.item}
              />
            )}
          </UncontrolledButtonDropdown>
        </Nav>
        <Nav navbar className={styles.userNav}>
          <UncontrolledButtonDropdown>
            <DropdownToggle nav className={styles.userInfo} caret>
              <img src={avatar} alt="user" />
              <span>
                {_startCase(firstName)}
                {lastName && ' ' + _startCase(lastName)}
              </span>
            </DropdownToggle>
            <UserDropdownMenu
              className={styles.menu}
              itemClassName={styles.item}
            />
          </UncontrolledButtonDropdown>
        </Nav>
      </div>
    </div>
  );
}
