/**
 * format Price in standard format of $40.00 (two decimal places)
 * @param  {string} price
 * @param  {string} currency
 * @return {string}
 */
import logrocket from 'logrocket';
import { findCurrency } from 'utils/DICTIONARY';
import formatNumber from 'utils/formatNumber';

export default function formatPrice(price, currency) {
  let formattedCurrency = findCurrency(currency) || '';
  if (!price) {
    return `${formattedCurrency}0.00`;
  }

  const roundedPrice = Math.round(Math.abs(price * 100)) / 100;
  const amount = formatNumber(roundedPrice, {}, false);

  if (currency) {
    return `${formattedCurrency}${amount}`;
  } else {
    logrocket.captureException(new Error('Currency is not in valid format'), {
      tags: { subscription: 'Currency' },
      extra: { msgReason: 'Currency comes either empty,null or undefined' }
    });
    return `${amount}`;
  }
}
