import { put, logError } from 'utils';

export default function updateManualDeduction(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  put({ apiKey: 'retexes.manualDeduction' }, { params })
    .then(({ data }) => onSuccess(data))
    .catch(err => {
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
