import React, { useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { Skeleton } from 'components';
import { TableRow } from 'components/Tables';

export default function TableLoader({
  isLoading = false,
  rowsCount = 3,
  headers = [],
  rowClassName = {},
  children = null
}) {
  const rowCells = useMemo(() => {
    return headers.map(cell => {
      const size = _get(cell, 'lg');
      return {
        lg: size,
        CustomComponent: <Skeleton className="m-0 pr-5" width="60%" />
      };
    });
  }, [headers]);

  if (!isLoading) return children;

  return Array(rowsCount)
    .fill(null)
    .map((_, index) => (
      <TableRow
        key={index}
        values={rowCells}
        customTableRowClass={cn(styles.row, rowClassName)}
      />
    ));
}
