import STATES from './US_STATES';
import { logError, localStore } from 'utils';

const loggedKeyErrorList = [];

function logKeyError(key) {
  try {
    if (loggedKeyErrorList.indexOf(key) === -1) {
      logError(`Key not found in master data - ${key}`);
      loggedKeyErrorList.push(key);
    }
  } catch (e) {
    logError(e);
  }
}

function findValue(arr, obj) {
  try {
    if (arr.length === 1) {
      if (arr[0] === 'null') {
        return '-';
      }
      return obj[arr[0]];
    }
    var key = arr.splice(0, 1)[0];
    return findValue(arr, obj[key]);
  } catch (e) {
    return 'undefined';
  }
}

const DICTIONARY = () => {
  const obj = JSON.parse(localStore.get('dictionary')) || {};
  return obj;
};

const find = (group, key) => {
  try {
    const STORE = DICTIONARY()[group];
    if (STORE[key]) {
      return STORE[key];
    } else {
      logKeyError(key);
      return key;
    }
  } catch (e) {
    logError(e);
    return key;
  }
};

const Shipping_Method = key => find('retailerShippingMethodChoices', key);

const Refund_Method = key => find('retailerPaymentMethodChoices', key);

const findEcommPlatform = key => find('ecommerce_platforms', key);

const findCurrency = key => {
  if (!key) return null;
  return find('currencySymbolChoices', key);
};

const getEcomPlatformMap = () => {
  return DICTIONARY()['ecommerce_platforms'] || {};
};

const activeServiceRequestChoices = () => {
  return DICTIONARY()['inprogress_request_status'] || [];
};

const rejectedServiceRequestChoices = () => {
  return DICTIONARY()['rejected_service_request_status'] || [];
};

const approvedServiceRequestChoices = () => {
  return DICTIONARY()['successful_request_status'] || [];
};

const pickupSlotChoices = () => {
  return DICTIONARY()['pickup_slot_choices'] || [];
};

const orderServiceStatuses = () => {
  return DICTIONARY()['order_service_statuses'] || [];
};

const orderServiceTerminalStatuses = () => {
  return DICTIONARY()['terminal_order_service_statuses'] || [];
};

const orderServiceInprogressStatuses = () => {
  return DICTIONARY()['inprogress_order_service_statuses'] || [];
};

const Warehouse_Disposition_Method = key => {
  return find('dispositionMethodChoices', key);
};

// Alias to a more general name
const paymentMethod = Refund_Method;

const Ret_Exc_Status = key => find('retexStatusChoices', key);

const deliveryStatusChoices = key => find('deliveryStatusChoices', key);

const entityTypeChoices = key => find('entityTypeChoices', key);

const financialStatus = key => find('order_financial_statuses', key);

const pickupSlotChoice = key => {
  return find('pickup_slot_choices', key);
};

const paymentWaiveOffHelpText = () => find('paymentWaiveOff', 'infoText');

const tagRuleTypes = key => find('tag_rule_types', key);

const tagRuleActions = key => find('tag_rule_actions', key);

const UNITS = {
  dimension: [
    {
      value: 'in',
      label: 'in'
    },
    {
      value: 'cm',
      label: 'cm'
    },
    {
      value: 'm',
      label: 'm'
    }
  ],
  weight: [
    { value: 'g', label: 'gm' },
    { value: 'kg', label: 'kg' },
    { value: 'oz', label: 'oz' },
    { value: 'lb', label: 'lb' }
  ]
};

function index(key) {
  const keyArray = key.split('.');
  const obj = JSON.parse(localStore.get('dictionary'));
  var value = findValue(keyArray, obj);
  if (typeof value === 'undefined' || value === 'undefined' || value === null) {
    logKeyError(key);
    value = keyArray[keyArray.length - 1];
  }
  return value;
}

function findAllValues(key) {
  const obj = JSON.parse(localStore.get('dictionary'));
  return obj[key];
}

export {
  Shipping_Method,
  Refund_Method,
  Ret_Exc_Status,
  STATES,
  paymentMethod,
  findEcommPlatform,
  getEcomPlatformMap,
  deliveryStatusChoices,
  entityTypeChoices,
  pickupSlotChoices,
  financialStatus,
  pickupSlotChoice,
  orderServiceStatuses,
  orderServiceTerminalStatuses,
  orderServiceInprogressStatuses,
  activeServiceRequestChoices,
  rejectedServiceRequestChoices,
  approvedServiceRequestChoices,
  findAllValues,
  Warehouse_Disposition_Method,
  findCurrency,
  paymentWaiveOffHelpText,
  UNITS,
  tagRuleTypes,
  tagRuleActions
};

export default index;
