import React from 'react';
import { Formik } from 'formik';
import Select from 'react-select';
import { Button, Input } from 'reactstrap';

import { If } from 'utils';
import { isValidEmail } from 'utils/validators';

const StepTwo = ({ requestLink, processing, success, email, list }) => {
  return (
    <Formik
      initialValues={{ email }}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!isValidEmail(values.email)) {
          errors.email = 'Invalid email address';
        }
        if (!values.retailer_name) {
          errors.retailer_name = 'Please select a retailer';
        }
        return errors;
      }}
      onSubmit={values => {
        const formattedValues = Object.assign({}, values);
        formattedValues.email = formattedValues.email.toLowerCase();
        requestLink(formattedValues);
      }}
    >
      {({ values, errors, handleChange, handleSubmit, setValues }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Input
              type="email"
              name="email"
              onChange={handleChange}
              className="form-control"
              aria-describedby="emailHelp"
              placeholder="Email"
              value={values.email}
              invalid={errors && errors['email']}
              disabled={success !== null || processing}
            />
          </div>
          <If test={list && list.length > 0}>
            <div className="form-group retailer-select">
              <Select
                onChange={e =>
                  setValues(
                    Object.assign({}, values, {
                      retailer_name: e ? e.value : null
                    })
                  )
                }
                options={list}
                name="retailer"
                placeholder="Select retailer"
                // className={`confirmCancel__select cancel-height mb-3`}
                className="react-select-container cancel-height mb-3"
                classNamePrefix="react-select"
                id="cancel-reason"
                value={
                  list.find(el => el.value === values.retailer_name) || null
                }
                // clearable={false}
              />
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {errors.retailer_name}
              </div>
            </div>
          </If>
          <div className="form-group">
            <Button
              className="login-button"
              type="submit"
              disabled={
                processing ||
                success !== null ||
                (errors && (errors['email'] || errors['retailer_name']))
              }
            >
              {processing
                ? 'Processing...'
                : success !== null
                ? 'Sent'
                : 'Get Reset Link'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default StepTwo;
