import React, { useContext, useEffect } from 'react';
import styles from './index.module.scss';

import Alert from '../Alert';
import { OrderServiceContext } from 'components/OrderServiceDetails';

export default function GeneratingLabel() {
  const { onReSync } = useContext(OrderServiceContext);

  useEffect(() => {
    onReSync({ fields: 'shipping_info' });
    const timer = setInterval(
      () => onReSync({ fields: 'shipping_info' }),
      5000
    );
    return () => clearInterval(timer);
  }, [onReSync]);

  return (
    <Alert iconClassName={styles.icon}>
      We’re working on generating a shipment label for you
    </Alert>
  );
}
