import React from 'react';
import cn from 'classnames';

export default function Loader({
  className = '',
  loading = false,
  children = null
}) {
  if (loading) {
    return (
      <div className={cn({ [className]: !!className })}>
        <div className="spinner" />
      </div>
    );
  }

  if (children) {
    return children;
  }

  return null;
}
