import React, { useState } from 'react';
import styles from './index.module.scss';

import { UiKit } from 'components';
import Card from '../Card';
import CustomerInfo from './CustomerInfo';
import ShippingInfo from './ShippingInfo';
import PaymentInfo from './PaymentInfo';

const TABS = ['CUSTOMER', 'SHIPPING', 'PAYMENT'];

export default function InfoCard() {
  const [tab, setTab] = useState(TABS[0]);

  return (
    <Card className={styles.container}>
      <UiKit.PillSwitch
        value={tab}
        onChange={setTab}
        className={styles.tabsContainer}
        tabClassName={styles.tab}
        activeTabClassName={styles.activeTab}
        floatingPillClassName={styles.floatingPill}
      >
        <UiKit.PillSwitch.Pill label="Customer" value={TABS[0]} />
        <UiKit.PillSwitch.Pill label="Shipping" value={TABS[1]} />
        <UiKit.PillSwitch.Pill label="Payment" value={TABS[2]} />
      </UiKit.PillSwitch>
      <div className={styles.content}>
        {tab === TABS[0] && <CustomerInfo />}
        {tab === TABS[1] && <ShippingInfo />}
        {tab === TABS[2] && <PaymentInfo />}
      </div>
    </Card>
  );
}
