import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import cn from 'classnames';
import _isArray from 'lodash/isArray';

export default function Foot({
  values = [],
  className = '',
  bottomOffset = 0
}) {
  const data = useMemo(() => {
    if (!_isArray(values)) return null;
    return values.map((info, index) => {
      if (!info) return null;
      return (
        <Col
          lg={info.lg || null}
          key={index}
          className={info.customClass || ''}
        >
          {info.title}
        </Col>
      );
    });
  }, [values]);

  const attachClass = useMemo(() => {
    return cn('no-gutters', 'table-foot', { [className]: !!className });
  }, [className]);

  if (!values || values.length < 1) return null;

  return (
    <Row className={attachClass} style={{ bottom: `${bottomOffset}px` }}>
      {data}
    </Row>
  );
}
