import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';

import { If } from 'utils';
import { Skeleton } from 'components';
import { ShopperGroupSelectorContext } from '../context';
import { ROUTES } from 'views/Configure/ReturnManagement/ShopperGroups/stubs';
import portalIcon from 'assets/img/sidebar/customer-portal.svg';

export default function Helptext() {
  const { selectedOption, isFetching, hasAccess } = useContext(
    ShopperGroupSelectorContext
  );

  const selectedId = _get(selectedOption, 'value', null);
  const redirectLink = selectedId
    ? ROUTES.SHOPPER_GROUPS_DETAILS_EDIT.replace(':id', selectedId)
    : ROUTES.HOME;

  return (
    <If test={hasAccess}>
      <If
        test={!isFetching}
        otherwise={<Skeleton width="100%" height="16px" className="mt-1" />}
      >
        <If
          test={!!selectedId}
          otherwise={`You can choose from your list of active shopper groups here. If you want to modify global configuration please select global settings.`}
        >
          You can edit the currently configuring shopper group criterias{' '}
          <Link to={redirectLink} className="d-inline-block">
            <b>here</b>
            <img src={portalIcon} className="ml-1" alt="" />
          </Link>
        </If>
      </If>
    </If>
  );
}
