/**
 * Utility function to check if an array is filled
 * Why is this needed?
 * Because empty array [] is truthy
 * @param  {any}  arr
 * @return {Boolean}
 */
export default function isFilledArray(arr) {
  return Array.isArray(arr) && arr.length;
}
