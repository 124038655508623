import { useHistory } from 'react-router-dom';

const useResetHistory = (callback = () => {}) => {
  const history = useHistory();

  // This didnt work as expected
  // const onReset = (e)=>{
  //   history.block(() => {});
  //   return callback(e)
  // }

  history.block(() => true);

  return callback;
};

export default useResetHistory;
