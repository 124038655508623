import React from 'react';
import styles from './index.module.scss';
import Skeleton, { SkeletonTheme } from 'components/Skeleton';

export default function Loader({ isLoading, children }) {
  if (!isLoading) return children;

  return (
    <div className={styles.container}>
      <SkeletonTheme className={styles.loader}>
        <Skeleton height={16} width="32px" rounded />
        <Skeleton height={20} width="58px" />
      </SkeletonTheme>
    </div>
  );
}
