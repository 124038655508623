import React, { useContext } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';

export default function Header() {
  const {
    retex,
    isApproved,
    isRejected,
    retexStatuses,
    approvalTypes
  } = useContext(RetexContext);

  const name = _get(retex, 'name', '');
  const status = _get(retex, 'status', '');
  const approvalType = _get(retex, 'approval_type', '');
  const formattedStatus = _get(retexStatuses, status, '');
  const formattedApprovalType = _get(approvalTypes, approvalType, '');

  const statusClassName = cn({
    [styles.danger]: isRejected,
    [styles.success]: isApproved
  });

  return (
    <UiKit.GridRow templateColumns="1fr" gap="4">
      <h3 className={styles.title}>{name}</h3>
      <div className={styles.row}>
        <strong className={statusClassName}>{formattedStatus}</strong>
        <p>
          Approval Type: <strong>{formattedApprovalType}</strong>
        </p>
      </div>
    </UiKit.GridRow>
  );
}
