import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

import { If } from 'utils';
import { ReactComponent as VerticalEllipsis } from 'assets/img/icons/dots-menu.svg';

export default function OverflowMenu({
  items = [],
  className = '',
  onClick = () => {},
  isDisabled = false,
  alignToRight = false,
  direction = 'down' // 'up' | 'down' | 'left' | 'right'
}) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonClass = className ? `${className}__button` : '';
  const dropdownClass = className ? `${className}__dropdown` : '';
  const dropdownItemClass = className ? `${className}__dropdown-item` : '';

  const toggle = () => setIsOpen(!isOpen);

  const Items = items.map((item, index) => {
    return (
      <DropdownItem
        disabled={item.disabled}
        className={`CustomTable__OverflowMenu-dropdownItem ${dropdownItemClass}`.trim()}
        onClick={item.onClick}
        key={index}
      >
        {item.title}
      </DropdownItem>
    );
  });

  return (
    <Dropdown
      // id="role-dropdown"
      isOpen={isOpen}
      toggle={toggle}
      onClick={onClick}
      disabled={isDisabled}
      direction={direction}
      className={`CustomTable__OverflowMenu ${className}`.trim()}
    >
      <DropdownToggle
        disabled={isDisabled}
        className={`CustomTable__OverflowMenu-dropdownToggle ${buttonClass}`.trim()}
      >
        <VerticalEllipsis />
      </DropdownToggle>
      <If test={items.length}>
        <DropdownMenu
          right={alignToRight}
          className={`CustomTable__OverflowMenu-dropdownMenu ${dropdownClass}`.trim()}
        >
          {Items}
        </DropdownMenu>
      </If>
    </Dropdown>
  );
}
