import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { If } from 'utils';
import notFoundImage from 'assets/rr-illustrations/lost.png';

export default function ErrorPage({
  homeButton = false,
  message = 'Something went wrong. Please retry or check your connection.\n If the error persists, please contact support'
}) {
  return (
    <div className="ErrorPage">
      <Helmet>
        <title>Error - Something went wrong</title>
      </Helmet>
      <div className="ErrorPage__image">
        <img src={notFoundImage} alt="not found" />
      </div>
      <div className="ErrorPage__text">{message}</div>
      <If test={homeButton}>
        <Link to="/">
          <button className="ErrorPage__button">Back To Home</button>
        </Link>
      </If>
    </div>
  );
}
