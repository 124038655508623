import { useCallback, useEffect, useRef, useState } from 'react';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

import { get, post, logError } from 'utils';
import { CustomToast } from 'components';

const DEFAULT = {
  method: 'GET',
  loadOnMount: true,
  initialData: null,
  isPublicAPI: false,
  payload: {},
  routeParam: '',
  routeParams: {},
  cancelToken: null,
  axiosConfig: {},
  onTransform: data => data,
  onSuccess: () => {},
  onError: () => {},
  onFinally: () => {},
  errorMessage: 'Unable to fetch data at the moment. Please try again later.'
};

const API_METHODS = {
  GET: () => get,
  POST: () => post
};

/**
 * @callback OnTransform
 * @param {Object} apiResponseData API response.data value
 * @returns {Object} Transformed API response daa
 */

/**
 * @callback OnSuccess
 * @param {Object} transformedData onTransform(response.data) value
 * @param {Object} apiResponseData API response.data value
 */

/**
 * @callback OnError
 * @param {Object} error Error or AxiosError object
 * @param {Object} apiResponseData API Response
 */

/**
 * Configuration of the hook
 * @typedef {Object} Config
 * @property {"GET" | "POST"} [method="GET"]
 * @property {boolean} [loadOnMount] If true, the data fetching will start on mount
 * @property {Object | null} [initialData] Initial Data representing the schema of API response data or null
 * @property {boolean} [isPublicAPI] True to use Public Axios instance | False to use Private Axios instance.
 * @property {Object.<string, *>} [payload] API payload
 * @property {string} [routeParam] API endpoint fragment to be added at the end of the API endpoint, usually dynamic UUID or slug
 * @property {Object.<string, *>} [routeParams] Define the route dynamic parameters to that replaces the placeholder fragments (withing [] brackets) in the API endpoint
 * @property {import('axios').CancelToken} [cancelToken] API Cancel/Abort token
 * @property {import('axios').AxiosRequestConfig} [axiosConfig] Axios Request Config object
 * @property {OnTransform} [onTransform] Method to transform the API response data. Return the desired schema.
 * @property {OnSuccess} [onSuccess] Callback on successful data fetching
 * @property {OnError} [onError] Callback on API request failure
 * @property {string} [errorMessage] Custom toast message in case the API fails to fetch data
 */

/**
 * useFetch arguments
 * @param {string} apiKey API Key map from the API.js [API_ENDPOINTS]
 * @param {Config} [config] Hook configuration
 */

export default function useFetch(apiKey, config) {
  const method = _get(config, 'method', DEFAULT.method);

  if (!apiKey) {
    throw new Error('API Key is required in the useFetch hook');
  } else if (!(method in API_METHODS)) {
    throw new Error(`"${method}" is not valid api method`);
  }

  const initialData = _get(config, 'initialData', DEFAULT.initialData);
  const loadOnMount = _get(config, 'loadOnMount', DEFAULT.loadOnMount);
  const axiosConfig = _get(config, 'axiosConfig', DEFAULT.axiosConfig);
  const payload = _get(config, 'payload', DEFAULT.payload);
  const routeParam = _get(config, 'routeParam', DEFAULT.routeParam);
  const routeParams = _get(config, 'routeParams', DEFAULT.routeParams);
  const cancelToken = _get(config, 'cancelToken', DEFAULT.cancelToken);
  const isPublicAPI = _get(config, 'isPublicAPI', DEFAULT.isPublicAPI);
  const onTransform = _get(config, 'onTransform', DEFAULT.onTransform);
  const onSuccess = _get(config, 'onSuccess', DEFAULT.onSuccess);
  const onFinally = _get(config, 'onFinally', DEFAULT.onFinally);
  const onError = _get(config, 'onError', DEFAULT.onError);
  const errorMessage = _get(config, 'errorMessage', DEFAULT.errorMessage);

  const [data, setData] = useState(initialData);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(loadOnMount);
  const isMountedRef = useRef(false);

  const fetchData = useCallback(
    (payloadOverrides = {}, axiosConfigOverrides = {}) => {
      setIsError(false);
      setIsLoading(true);
      const methodGen = _get(API_METHODS, method);
      const request = methodGen();
      const params = {
        routeParam,
        routeParams,
        ...payload,
        ...payloadOverrides
      };
      request(
        { apiKey, noTokenRequired: isPublicAPI },
        {
          params,
          cancelToken,
          config: _merge({}, axiosConfig, axiosConfigOverrides)
        }
      )
        .then(res => {
          const data = onTransform(_cloneDeep(res.data));
          setData(data);
          onSuccess(data, res.data);
        })
        .catch(error => {
          setIsError(true);
          onError(error, _get(error, 'response.data.data', null));
          logError(
            errorMessage === DEFAULT.errorMessage ? error : errorMessage
          );
          if (errorMessage) {
            CustomToast({
              isNotified: _get(error, 'notified', false),
              msg: errorMessage,
              type: 'error'
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
          onFinally();
        });
    },
    [
      apiKey,
      method,
      isPublicAPI,
      payload,
      routeParam,
      routeParams,
      cancelToken,
      axiosConfig,
      errorMessage,
      onTransform,
      onSuccess,
      onError,
      onFinally
    ]
  );

  useEffect(() => {
    if (!isMountedRef.current && loadOnMount) {
      isMountedRef.current = true;
      fetchData();
    }
  }, [fetchData, loadOnMount]);

  return { data, isLoading, isError, fetchData };
}
