import React from 'react';

export default function Cover() {
  return (
    <svg width="17px" height="15px" viewBox="0 0 17 15" version="1.1">
      <title>noun-webpage-4646022</title>
      <g
        id="Version-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Components"
          fillRule="nonzero"
          transform="translate(-222.000000, -307.000000)"
          style={{ fill: 'var(--icon-color)' }}
        >
          <g
            id="noun-webpage-4646022"
            transform="translate(223.000000, 308.000000)"
          >
            <path
              d="M14.6877472,0 L0.312456818,0 C0.139681456,0 0,0.139945967 0,0.312456818 L0,13.4375182 C0,13.610029 0.139684805,13.749975 0.312456818,13.749975 L14.6874124,13.749975 C14.8601878,13.749975 15,13.610029 15,13.4375182 L15,0.312456818 C15,0.139945967 14.8603152,0 14.6875432,0 L14.6877472,0 Z M14.3751598,0.624913635 L14.3751598,1.87487483 L5.62523053,1.87500565 C5.54204711,1.87500565 5.46278781,1.90783404 5.40432437,1.96655965 L4.55836059,2.81252343 L0.625194887,2.81252343 L0.625194887,0.625057843 L14.3751598,0.624913635 Z M0.625318771,13.1251953 L0.625318771,3.43742678 L4.68772615,3.43742678 C4.77090958,3.43742678 4.85016888,3.40459839 4.90863232,3.34587278 L5.75459609,2.49990901 L14.3752837,2.49990901 L14.3752837,13.1248471 L0.625318771,13.1251953 Z"
              id="Shape"
              style={{ stroke: 'var(--icon-color)' }}
              strokeWidth="0.1"
            />
            <path
              d="M3.90644683,2.18746558 L4.21890365,2.18746558 C4.39167901,2.18746558 4.53136047,2.04751961 4.53136047,1.87500876 C4.53136047,1.70249791 4.39167566,1.56255195 4.21890365,1.56255195 L3.90644683,1.56255195 C3.73367147,1.56255195 3.59398992,1.70249791 3.59398992,1.87500876 C3.5938592,2.04751961 3.73354424,2.18746558 3.90644683,2.18746558 L3.90644683,2.18746558 Z"
              id="Path"
            />
            <path
              d="M2.65621777,2.18746558 L2.96867459,2.18746558 C3.14144995,2.18746558 3.28113141,2.04751961 3.28113141,1.87500876 C3.28113141,1.70249791 3.1414466,1.56255195 2.96867459,1.56255195 L2.65621777,1.56255195 C2.48344241,1.56255195 2.34376095,1.70249791 2.34376095,1.87500876 C2.34376095,2.04751961 2.48344576,2.18746558 2.65621777,2.18746558 Z"
              id="Path"
            />
            <path
              d="M1.40632354,2.18746558 L1.71878036,2.18746558 C1.89155572,2.18746558 2.03123717,2.04751961 2.03123717,1.87500876 C2.03123717,1.70249791 1.89155237,1.56255195 1.71878036,1.56255195 L1.40632354,1.56255195 C1.23354818,1.56255195 1.09386672,1.70249791 1.09386672,1.87500876 C1.09386672,2.04751961 1.23355152,2.18746558 1.40632354,2.18746558 Z"
              id="Path"
            />
            <path
              d="M5.62509326,4.3748642 L1.87507573,4.3748642 C1.70230037,4.3748642 1.56261891,4.51481017 1.56261891,4.68732102 L1.56261891,5.93728222 C1.56261891,6.10979307 1.70230372,6.24973903 1.87507573,6.24973903 L5.62509326,6.24973903 C5.79786862,6.24973903 5.93755007,6.10979307 5.93755007,5.93728222 L5.93755007,4.68732102 C5.93755007,4.51494075 5.79786527,4.3748642 5.62509326,4.3748642 L5.62509326,4.3748642 Z M5.31263644,5.62495933 L2.18753255,5.62495933 L2.18753255,4.99991176 L5.31250251,4.99991176 L5.31263644,5.62495933 Z"
              id="Shape"
            />
            <path
              d="M13.1251283,9.09620323 L8.12521655,9.09620323 C7.95244119,9.09620323 7.81275973,9.2361492 7.81275973,9.40866005 L7.81275973,10.6586212 C7.81275973,10.8311321 7.95244454,10.9710781 8.12521655,10.9710781 L13.1251283,10.9710781 C13.2979037,10.9710781 13.4375851,10.8311321 13.4375851,10.6586212 L13.4375851,9.40866005 C13.4375851,9.23627978 13.2979003,9.09620323 13.1251283,9.09620323 Z M12.8126715,10.3462984 L8.43753944,10.3462984 L8.43753944,9.7212508 L12.8126715,9.7212508 L12.8126715,10.3462984 Z"
              id="Shape"
            />
            <path
              d="M5.62509326,7.50010202 L1.87507573,7.50010202 C1.70230037,7.50010202 1.56261891,7.64004799 1.56261891,7.81255884 C1.56261891,7.98506969 1.70230372,8.12501566 1.87507573,8.12501566 L5.62509326,8.12501566 C5.79786862,8.12501566 5.93755007,7.98506969 5.93755007,7.81255884 C5.93755007,7.64004799 5.79786527,7.50010202 5.62509326,7.50010202 Z"
              id="Path"
            />
            <path
              d="M5.62509326,9.06238611 L1.87507573,9.06238611 C1.70230037,9.06238611 1.56261891,9.20233208 1.56261891,9.37484293 C1.56261891,9.54735378 1.70230372,9.68729974 1.87507573,9.68729974 L5.62509326,9.68729974 C5.79786862,9.68729974 5.93755007,9.54735378 5.93755007,9.37484293 C5.93755007,9.20246266 5.79786527,9.06238611 5.62509326,9.06238611 Z"
              id="Path"
            />
            <path
              d="M5.62509326,10.625005 L1.87507573,10.625005 C1.70230037,10.625005 1.56261891,10.764951 1.56261891,10.9374618 C1.56261891,11.1099727 1.70230372,11.2499187 1.87507573,11.2499187 L5.62509326,11.2499187 C5.79786862,11.2499187 5.93755007,11.1099727 5.93755007,10.9374618 C5.93755007,10.764951 5.79786527,10.625005 5.62509326,10.625005 Z"
              id="Path"
            />
            <path
              d="M13.1251283,4.3748642 L8.12521655,4.3748642 C7.95244119,4.3748642 7.81275973,4.51481017 7.81275973,4.68732102 L7.81275973,7.81229098 C7.81275973,7.98480183 7.95244454,8.1247478 8.12521655,8.1247478 L13.1251283,8.1247478 C13.2979037,8.1247478 13.4375851,7.98480183 13.4375851,7.81229098 L13.4375851,4.68745495 C13.4375851,4.51494409 13.2979003,4.3748642 13.1251283,4.3748642 L13.1251283,4.3748642 Z M12.8126715,7.49996809 L8.43753944,7.49996809 L8.43753944,4.99991176 L12.8126715,4.99991176 L12.8126715,7.49996809 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
