import React, { useEffect, useState, useMemo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { CHECK_CODES, CHECK_LIST_TEXT } from './stubs';
import { ReactComponent as CheckIcon } from 'assets/img/icons/green-check.svg';
import { ReactComponent as CrossIcon } from 'assets/img/icons/red-cross.svg';

export default function PasswordStrength({
  password = '',
  onChange = () => {}
}) {
  const [checks, setChecks] = useState(() => {
    return Object.keys(CHECK_CODES).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
  });

  useEffect(() => {
    setChecks({
      [CHECK_CODES.CHAR12]: password.length >= 12,
      [CHECK_CODES.UPPR]: !!password.match(/[A-Z]+/),
      [CHECK_CODES.LWR]: !!password.match(/[a-z]+/),
      [CHECK_CODES.NUM]: !!password.match(/[0-9]+/),
      [CHECK_CODES.SPECHAR]: !!password.match(/[!@#$%^&*]+/)
    });
  }, [password]);

  useEffect(() => {
    const isValid = Object.values(checks).every(check => check);
    onChange(isValid);
  }, [checks, onChange]);

  const checksPoints = useMemo(() => {
    return Object.keys(checks).map(check => {
      const isChecked = checks[check];
      const className = cn(styles.checkListItem, {
        [styles.red]: !isChecked,
        [styles.green]: isChecked
      });
      return (
        <span key={check} className={className}>
          {isChecked ? <CheckIcon /> : <CrossIcon />}
          <span className="ml-1">{CHECK_LIST_TEXT[check]}</span>
        </span>
      );
    });
  }, [checks]);

  return (
    <div className={cn(styles.container, 'mt-4')}>
      <div className={styles.label}>Password must contain:</div>
      <UiKit.GridRow
        gap="12"
        templateColumns="1fr 1fr"
        className={styles.checkList}
      >
        {checksPoints}
      </UiKit.GridRow>
    </div>
  );
}
