export const OS_STATUS = {
  PENDING_COMPLETION: 'PENDING_COMPLETION',
  PENDING_QUALIFICATION: 'PENDING_QUALIFICATION',
  PENDING_DELIVERY: 'PENDING_DELIVERY',
  PENDING_AT_ADMIN: 'PENDING_AT_ADMIN',
  PENDING_AT_WAREHOUSE: 'PENDING_AT_WAREHOUSE',
  PENDING_AT_STORE: 'PENDING_AT_STORE',
  PENDING_PAYMENT_COMPLETION: 'PENDING_PAYMENT_COMPLETION',
  PROCESSED: 'PROCESSED',
  CANCELLED: 'CANCELLED'
};

export const RETEX_STATUS = {
  AWAITING_COMPLETION: 'AWAITING_COMPLETION',
  AWAITING_DELIVERY: 'AWAITING_DELIVERY',
  AWAITING_QUALIFICATION_APPROVAL: 'AWAITING_QUALIFICATION_APPROVAL',
  AWAITING_EXCHANGE_APPROVAL: 'AWAITING_EXCHANGE_APPROVAL',
  AWAITING_REFUND_APPROVAL: 'AWAITING_REFUND_APPROVAL',
  AWAITING_WH_AUTHORIZATION: 'AWAITING_WH_AUTHORIZATION',
  AWAITING_STORE_AUTHORIZATION: 'AWAITING_STORE_AUTHORIZATION',
  AWAITING_PAYMENT_COMPLETION: 'AWAITING_PAYMENT_COMPLETION',
  AWAITING_PAYMENT_RESOLUTION: 'AWAITING_PAYMENT_RESOLUTION',
  QUALIFICATION_APPROVAL_REJECTED: 'QUALIFICATION_APPROVAL_REJECTED',
  STORE_AUTHORIZATION_REJECTED: 'STORE_AUTHORIZATION_REJECTED',
  WH_AUTHORIZATION_REJECTED: 'WH_AUTHORIZATION_REJECTED',
  EXCHANGE_REJECTED: 'EXCHANGE_REJECTED',
  REFUND_REJECTED: 'REFUND_REJECTED',
  EXCHANGE_SUCCESS: 'EXCHANGE_SUCCESS',
  REFUND_SUCCESS: 'REFUND_SUCCESS',
  CANCELLED: 'CANCELLED'
};

export const OS_RETEX_STATUS_MAP = {
  [OS_STATUS.PENDING_COMPLETION]: [RETEX_STATUS.AWAITING_COMPLETION],
  [OS_STATUS.PENDING_DELIVERY]: [RETEX_STATUS.AWAITING_DELIVERY],
  [OS_STATUS.PENDING_QUALIFICATION]: [
    RETEX_STATUS.AWAITING_QUALIFICATION_APPROVAL
  ],
  [OS_STATUS.PENDING_AT_ADMIN]: [
    RETEX_STATUS.AWAITING_EXCHANGE_APPROVAL,
    RETEX_STATUS.AWAITING_REFUND_APPROVAL
  ],
  [OS_STATUS.PENDING_AT_WAREHOUSE]: [RETEX_STATUS.AWAITING_WH_AUTHORIZATION],
  [OS_STATUS.PENDING_AT_STORE]: [RETEX_STATUS.AWAITING_STORE_AUTHORIZATION],
  [OS_STATUS.PENDING_PAYMENT_COMPLETION]: [
    RETEX_STATUS.AWAITING_PAYMENT_COMPLETION
  ],
  [OS_STATUS.PROCESSED]: [
    RETEX_STATUS.QUALIFICATION_APPROVAL_REJECTED,
    RETEX_STATUS.STORE_AUTHORIZATION_REJECTED,
    RETEX_STATUS.WH_AUTHORIZATION_REJECTED,
    RETEX_STATUS.EXCHANGE_REJECTED,
    RETEX_STATUS.REFUND_REJECTED,
    RETEX_STATUS.EXCHANGE_SUCCESS,
    RETEX_STATUS.REFUND_SUCCESS
  ],
  [OS_STATUS.CANCELLED]: [RETEX_STATUS.CANCELLED]
  //Retex Status: AWAITING_PAYMENT_RESOLUTION
};
