import React from 'react';
import _get from 'lodash/get';

import { useSubscription } from 'utils/hooks';
import UsageAlert from './UsageAlert';
import UsageTracker from './UsageTracker';

export default function QuotaUsage() {
  const { subscription, isQuotaExhausted } = useSubscription();

  const showMetrics = _get(subscription, 'show_usage_metrics', false);
  const maxRequests = _get(subscription, 'allowed_requests_count', 0);
  const processedCount = _get(subscription, 'requests_processed_count', 0);

  const show = showMetrics && !isQuotaExhausted;
  const remainingCount = maxRequests - processedCount;
  const showAlert = remainingCount < maxRequests / 2;

  if (!show) return null;

  if (showAlert) return <UsageAlert remaining={remainingCount} />;

  return <UsageTracker total={maxRequests} used={processedCount} />;
}
