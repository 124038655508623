import _isFunction from 'lodash/isFunction';

import { ROUTES } from '../../../stubs';
import { PAGE_HEADER } from '../stubs';

export default function getBreadcrumbItems(redirectTo = () => {}) {
  if (!_isFunction(redirectTo)) return [];

  return [
    {
      label: 'Configure',
      onClick: () => redirectTo(ROUTES.CONFIGURE)
    },
    {
      label: 'Return Management',
      onClick: () => redirectTo(ROUTES.RETURN_MANAGEMENT)
    },
    { label: PAGE_HEADER.TITLE }
  ];
}
