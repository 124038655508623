import { useRef, useCallback, useEffect } from 'react';
import _get from 'lodash/get';

import { logError } from 'utils';
import { getPublishedDeploy } from 'utils/services/netlify';

export default function useNetlifySync(run = true) {
  const timer = useRef(null);
  const netlifySyncFailureCount = useRef(0);
  const isProd = process.env.REACT_APP_MODE === 'PRODUCTION';

  const cancelValidation = useCallback(() => clearInterval(timer.current), []);

  const validateBuild = useCallback(() => {
    if (!isProd) return;

    const checkWithNetlify = () => {
      getPublishedDeploy(
        deploy => {
          netlifySyncFailureCount.current = 0;
          const publishedBuildId = _get(deploy, 'build_id', '');
          const currentBuildId = process.env.REACT_APP_BUILD_ID;
          if (currentBuildId !== publishedBuildId) {
            cancelValidation();
            window.location.reload();
          }
        },
        err => {
          netlifySyncFailureCount.current++;
          if (netlifySyncFailureCount.current >= 3) {
            cancelValidation();
            const syncError = new Error(err);
            syncError.message =
              'Aborting Netlify sync | Failed to fetch data 3 times in a row';
            logError(syncError);
          }
        }
      );
    };

    checkWithNetlify();
    timer.current = setInterval(checkWithNetlify, 10000);
  }, [isProd, cancelValidation]);

  useEffect(() => {
    if (isProd && run) {
      window.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') validateBuild();
        else cancelValidation();
      });
      validateBuild();
    } else {
      cancelValidation();
    }
    return cancelValidation;
  }, [isProd, run, validateBuild, cancelValidation]);
}
