import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { If } from 'utils';
import { useAccess } from 'utils/hooks';
import FeatureLink from '../FeatureLink';
import SubFeatures from '../SubFeatures';
import { ReactComponent as LockIcon } from 'assets/img/icons/lock-fill.svg';
import { FeatureUpsell } from 'components';

/**
 * @param {Object} props
 * @param {string} props.tag
 * @param {string} props.title
 * @param {string} props.description
 * @param {string} props.icon
 * @param {import("utils/types").FeatureCardLink} props.link
 * @param {object} props.config
 * @param {import("utils/types").FeatureCardSubFeatures[]} props.features
 * @param {boolean} props.locked
 * @param {boolean} props.disabled
 * @param {string} props.shepherdId
 * @param {import("dictionary").FEATURE_IDS} props.featureId
 * @param {import("dictionary").FEATURE_SLUGS} props.featureSlug
 * @param {function} props.renderAction
 */
export default function FeatureCard({
  tag = '',
  title = '',
  description = '',
  icon = '',
  link = null,
  config = {},
  features = [],
  locked = false,
  disabled = false,
  shepherdId = '',
  featureId = '',
  featureSlug = '',
  renderAction = () => null
}) {
  const [subFeatures, setSubFeatures] = useState(null);

  const { hasFeatureAccess, hasSubFeatureAccess } = useAccess();

  const hasAccess = featureId
    ? hasFeatureAccess(featureId)
    : featureSlug
    ? hasSubFeatureAccess(featureSlug)
    : true;

  const isAccessible = hasAccess && !locked;

  useEffect(() => {
    if (_isArray(features) && !_isEmpty(features)) {
      const subfeatures = features.filter(({ id }) => {
        return !!_get(config, `${id}.enabled`, false);
      });
      setSubFeatures(subfeatures);
    }
  }, [features, config]);

  return (
    <Card className={styles.container} data-shepherd={shepherdId}>
      <FeatureUpsell.Container
        hasAccess={isAccessible}
        className={styles.content}
      >
        <CardBody className={styles.body}>
          {icon && (
            <div className={styles.iconWrapper}>
              <img src={icon} alt={`${title} icon`} className={styles.icon} />
              {locked && <LockIcon className={styles.lockIcon} />}
            </div>
          )}
          <div className={styles.header}>
            <div className={styles.titleWrapper}>
              <h6 className={styles.title}>{title}</h6>
              {tag && <span className={styles.tag}>{tag}</span>}
            </div>
            <If test={!isAccessible}>
              <FeatureUpsell.LockBadge />
            </If>
          </div>
          <p className={styles.description}>{description}</p>
          <If test={!locked}>
            {renderAction() || (
              <>
                <FeatureLink
                  link={link}
                  disabled={disabled}
                  hasAccess={isAccessible}
                />
                <SubFeatures items={subFeatures} disabled={disabled} />
              </>
            )}
          </If>
        </CardBody>
      </FeatureUpsell.Container>
    </Card>
  );
}
