import React, { forwardRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import cn from 'classnames';
import _has from 'lodash/has';

import Label from '../Label';
import * as EDITOR from './stubs';

function HtmlEditor(
  {
    label = '',
    labelClassName = '',
    value = '',
    disabled = false,
    onChange = () => {},
    error = '',
    height = 400,
    // initialValue = '',
    ...restProps
  },
  ref
) {
  const onInit = (_, editorRef) => {
    if (_has(ref, 'current')) {
      ref.current = editorRef;
    }
  };

  const wrapperClassName = cn('html-editor', {
    'html-editor__error': !!error
  });

  return (
    <div className={wrapperClassName}>
      {label && <Label text={label} className={labelClassName} />}
      <Editor
        apiKey={process.env.REACT_APP_TINY_DOCS_API_KEY}
        value={value}
        disabled={disabled}
        init={{
          height,
          plugins: EDITOR.PLUGINS,
          toolbar: EDITOR.TOOLBAR,
          menubar: EDITOR.MENUBAR,
          menu: EDITOR.MENU
        }}
        onInit={onInit}
        onEditorChange={onChange}
        {...restProps}
      />
      {error && <p className="html-editor__error--message">{error}</p>}
    </div>
  );
}

export default forwardRef(HtmlEditor);
