import cn from 'classnames';
import React from 'react';

const SectionContainer = ({
  shepherdId = '',
  title = '',
  className = '',
  disabled = false,
  children
}) => {
  const containerClassName = cn('section-container', className);
  const contentClassName = cn('section', { disabled: disabled });

  return (
    <div data-shepherd={shepherdId} className={containerClassName}>
      <div className={contentClassName}>
        <div>{title}</div>
        <div className="section__children">{children}</div>
      </div>
    </div>
  );
};

export default SectionContainer;
