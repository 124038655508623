import React, { useState, useContext, useCallback, useMemo } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';

const VIEWER_STYLES = {
  zIndex: 2000,
  padding: '40px',
  backgroundColor: 'rgba(0,0,0,0.9)'
};

export default function AttachedImages() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { retex } = useContext(RetexContext);

  const imagesSrc = _get(retex, 'attached_images', []);

  const toggleImageViewer = useCallback((index = 0) => {
    setActiveIndex(index);
    setModalOpen(isOpen => !isOpen);
  }, []);

  const images = useMemo(() => {
    if (!_isArray(imagesSrc)) return null;
    return imagesSrc.map((src, index) => (
      <img
        key={index}
        src={src}
        alt="damaged-product"
        onClick={() => toggleImageViewer(index)}
      />
    ));
  }, [imagesSrc, toggleImageViewer]);

  if (_isEmpty(imagesSrc)) return null;

  return (
    <UiKit.GridRow templateColumns="1fr" gap="10" className={styles.container}>
      <span className={styles.label}>Attached Images</span>
      <div className={styles.images}>{images}</div>
      {isModalOpen && (
        <ImageViewer
          src={imagesSrc}
          disableScroll
          closeOnClickOutside
          currentIndex={activeIndex}
          onClose={toggleImageViewer}
          backgroundStyle={VIEWER_STYLES}
        />
      )}
    </UiKit.GridRow>
  );
}
