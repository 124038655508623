import React from 'react';

export default function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        style={{ fill: 'var(--icon-color)' }}
        fillRule="evenodd"
        d="M7.333 10.39L2.471 5.53a.667.667 0 0 0-.942.942l5.333 5.334c.26.26.682.26.943 0l5.333-5.334a.667.667 0 0 0-.943-.942L7.333 10.39z"
      />
    </svg>
  );
}
