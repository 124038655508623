import ROUTES from 'views/Configure/stubs/routes';

const BASE_URL = ROUTES.SHOPPER_GROUPS;

export default {
  CONFIGURE: ROUTES.HOME,
  RETURN_MANAGEMENT: ROUTES.RETURN_MANAGEMENT,
  HOME: BASE_URL,
  SHOPPER_GROUPS_DETAILS_EDIT: `${BASE_URL}/:id`,
  SHOPPER_GROUPS_DETAILS_CREATE: `${BASE_URL}/create`
  // SHOPPER_GROUPS_DETAILS: `${BASE_URL}/:id`
};
