import _merge from 'lodash/merge';

import scrollToHandler from './scrollToHandler';

/**
 * @param {import('react-shepherd').Tour.TourOptions} Tour Options
 * @returns {import('react-shepherd').Tour.TourOptions} Tour Options
 */
export default function getTourOptions(overrideTourOptions = {}) {
  const DEFALT_OPTIONS = {
    exitOnEsc: true,
    useModalOverlay: true,
    keyboardNavigation: process.env.REACT_APP_MODE !== 'PRODUCTION',
    defaultStepOptions: {
      scrollTo: true,
      scrollToHandler,
      canClickTarget: false,
      modalOverlayOpeningRadius: 6,
      modalOverlayOpeningPadding: 6,
      cancelIcon: { enabled: true },
      highlightClass: 'shepherd-highlight'
    }
  };

  return _merge({}, DEFALT_OPTIONS, overrideTourOptions);
}
