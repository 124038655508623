import React from 'react';
import _get from 'lodash/get';

import { UiKit } from 'components';

export default function Form({
  errors = {},
  values = {},
  isProcessing = false,
  handleBlur = () => {},
  handleChange = () => {},
  handleSubmit = () => {}
}) {
  const storeName = _get(values, 'storeName', '');
  const error = _get(errors, 'storeName', '');

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <UiKit.Label text="Shopify Store Name" />
        <UiKit.GridRow
          templateColumns="1fr auto"
          gap="4"
          className="pr-5 align-items-center"
        >
          <UiKit.Input
            autoFocus
            name="storeName"
            value={storeName}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Enter your store name"
          />
          <strong>.myshopify.com</strong>
        </UiKit.GridRow>
      </div>
      {error && <div id="feedback">{error}</div>}
      <UiKit.Button
        type="submit"
        disabled={!storeName}
        processing={isProcessing}
        processingText="Connecting..."
        wrapperClassName="mt-4"
        className="w-100"
      >
        Install ReturnRabbit App
      </UiKit.Button>
    </form>
  );
}
