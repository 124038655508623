import React from 'react';

// cls-1

export default function Package() {
  return (
    <svg viewBox="0 0 888.84 861.73">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            style={{ fill: 'var(--icon-color)' }}
            d="M454.52,824.42l-42.3-18.6q-50.7-22.35-101.5-44.7-61.05-27-122.2-53.9-53.1-23.4-106.1-46.7c-17.1-7.5-34-16-51.4-22.7a4.88,4.88,0,0,1-.7-.3c3.3,5.8,6.6,11.5,9.9,17.3V245.22c0-18.9.8-38,0-56.9v-.8c-10,5.8-20.1,11.5-30.1,17.3l42.3,18.6q50.7,22.35,101.5,44.7,61.05,27,122.2,53.9,53.1,23.4,106.1,46.7c17.1,7.5,34,16,51.4,22.7a4.88,4.88,0,0,1,.7.3c-3.3-5.8-6.6-11.5-9.9-17.3V784c0,19-.5,38,0,56.9v.8c0,10.5,9.2,20.5,20,20s20-8.8,20-20V432.12c0-19,.5-38,0-56.9v-.8c0-6.4-3.7-14.5-9.9-17.3l-42.3-18.6q-50.7-22.35-101.5-44.7-61.05-27-122.2-53.9-53.1-23.4-106.1-46.7c-17.1-7.6-34.3-15.2-51.4-22.7-.2-.1-.5-.2-.7-.3-6.8-3-13.5-3.9-20.2,0a20.15,20.15,0,0,0-9.9,17.3v409.6c0,19-.5,38,0,56.9v.8c0,6.4,3.7,14.5,9.9,17.3l42.3,18.6q50.7,22.35,101.5,44.7,61.05,27,122.2,53.9,53.1,23.4,106.1,46.7c17.1,7.6,34.3,15.2,51.4,22.7.2.1.5.2.7.3,9.5,4.2,21.9,3.1,27.4-7.2C466.32,843.12,464.72,828.92,454.52,824.42Z"
          />
          <path
            style={{ fill: 'var(--icon-color)' }}
            d="M858.52,637.52l-42.3,18.6q-50.7,22.35-101.5,44.7-61.05,27-122.2,53.9-53.1,23.4-106.1,46.7c-17.1,7.5-34.6,14.5-51.4,22.7-.2.1-.5.2-.7.3,10,5.8,20.1,11.5,30.1,17.3V432.12c0-18.9.8-38,0-56.9v-.8c-3.3,5.8-6.6,11.5-9.9,17.3l42.3-18.6q50.7-22.35,101.5-44.7,61-27,122.2-53.9,53.1-23.4,106.1-46.7c17.1-7.5,34.6-14.5,51.4-22.7.2-.1.5-.2.7-.3-10-5.8-20.1-11.5-30.1-17.3v409.6c0,19-.5,38,0,56.9v.8c0,10.5,9.2,20.5,20,20s20-8.8,20-20V245.22c0-19,.5-38,0-56.9v-.8a20.15,20.15,0,0,0-9.9-17.3c-6.7-3.9-13.4-3-20.2,0l-42.3,18.6q-50.7,22.35-101.5,44.7-61.05,27-122.2,53.9-53.1,23.4-106.1,46.7c-17.1,7.5-34.7,14.3-51.4,22.7-.2.1-.5.2-.7.3-6.2,2.7-9.9,10.8-9.9,17.3V784c0,19-.5,38,0,56.9v.8a20.15,20.15,0,0,0,9.9,17.3c6.7,3.9,13.4,3,20.2,0l42.3-18.6q50.7-22.35,101.5-44.7,61-27,122.2-53.9,53.1-23.4,106.1-46.7c17.1-7.5,34.7-14.3,51.4-22.7.2-.1.5-.2.7-.3,9.6-4.2,12.6-19,7.2-27.4C879.52,634.72,868.82,633,858.52,637.52Z"
          />
          <path
            style={{ fill: 'var(--icon-color)' }}
            d="M858.52,170.22l-42.3,18.6q-50.7,22.35-101.5,44.7-61.05,27-122.2,53.9-53.1,23.4-106.1,46.7c-17.1,7.5-34.6,14.6-51.4,22.7-.2.1-.5.2-.7.3h20.2l-42.3-18.6q-50.7-22.35-101.5-44.7-61.05-27-122.2-53.9-53.1-23.4-106.1-46.7c-17.1-7.5-34-15.8-51.4-22.7a4.88,4.88,0,0,1-.7-.3l-4.8,36.6c14.1-5.6,28.2-11.1,42.3-16.7q50.55-20,101.2-40l122.4-48.3q53.1-21,106.2-41.9c11.6-4.6,23.2-9.1,34.7-13.7,5.5-2.2,11.3-4.1,16.6-6.6.2-.1.5-.2.7-.3H439c14.1,5.6,28.2,11.1,42.3,16.7Q531.87,76,582.52,96l122.4,48.3q53.1,21,106.2,41.9c17.1,6.7,34,14.4,51.4,20.3.2.1.5.2.7.3,9.8,3.9,22.4-4.2,24.6-14,2.6-11.5-3.5-20.5-14-24.6-14.1-5.6-28.2-11.1-42.3-16.7q-50.85-20.1-101.6-40.1-61.35-24.3-122.8-48.5-52.95-20.85-105.9-41.8c-16.9-6.7-33.7-14.3-50.9-20.1-7-2.4-11.7-.1-17.7,2.3-8.8,3.5-17.6,7-26.4,10.4q-47.85,18.9-95.8,37.8-61,24.15-122.2,48.3-55.2,21.75-110.3,43.6c-20.1,7.9-40.2,15.9-60.2,23.8-.9.4-1.8.7-2.8,1.1-7.6,3-13.2,8.1-14.3,16.6-1,7.2,2.4,16.8,9.5,19.9,14.2,6.2,28.4,12.5,42.5,18.7l102,45q61.35,27,122.7,54.1,52.8,23.25,105.7,46.6c16.9,7.4,33.8,15,50.7,22.3,7.7,3.3,13.7,3.3,21.4,0,1.8-.8,3.7-1.6,5.5-2.4,8.8-3.9,17.5-7.7,26.3-11.6q47.85-21.15,95.8-42.2,61-26.85,122.1-53.8,55.35-24.45,110.7-48.8c20.2-8.9,40.3-17.8,60.5-26.6.9-.4,1.8-.8,2.8-1.2,9.6-4.2,12.6-19,7.2-27.4C879.52,167.42,868.82,165.72,858.52,170.22Z"
          />
          <path
            style={{ fill: 'var(--icon-color)' }}
            d="M646.62,275.52v130.3c0,10.5,9.2,20.5,20,20s20-8.8,20-20V275.52c0-10.5-9.2-20.5-20-20s-20,8.7-20,20Z"
          />
          <path
            style={{ fill: 'var(--icon-color)' }}
            d="M237.12,120.22c14.1,5.8,28.1,11.6,42.2,17.4q50.7,20.85,101.3,41.7,61.35,25.2,122.6,50.4,52.95,21.75,106,43.6c17.1,7,34.1,15,51.5,21.2.2.1.5.2.7.3,9.7,4,22.4-4.3,24.6-14,2.7-11.6-3.6-20.3-14-24.6-14.1-5.8-28.1-11.6-42.2-17.4q-50.7-20.85-101.3-41.7-61.35-25.2-122.6-50.4Q353,125,299.92,103.12c-17.1-7-34.1-15-51.5-21.2-.2-.1-.5-.2-.7-.3-9.7-4-22.4,4.3-24.6,14-2.6,11.7,3.6,20.3,14,24.6Z"
          />
        </g>
      </g>
    </svg>
  );
}
