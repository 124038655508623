import storage from 'local-storage-fallback';

function put(key, data) {
  if (!data) data = '';
  storage.setItem(key, data);
}
function get(key) {
  let data = storage.getItem(key);
  if (typeof data === 'undefined') data = null;
  return data;
}
function clear(key) {
  storage.removeItem(key);
}

export default {
  put,
  get,
  clear
};
