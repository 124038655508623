export default [
  {
    label: 'Every exchange/return request',
    value: false
  },
  {
    label: 'Only exchange/return request on last item of an order',
    value: true
  }
];
