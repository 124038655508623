import React from 'react';
import { ReactComponent as CriticalAlertsIcon } from 'assets/img/icons/new/critical_alerts_icon.svg';
import { ReactComponent as AnnouncementIcon } from 'assets/img/icons/new/announcement_icon.svg';
import { ReactComponent as AlertsIcon } from 'assets/img/icons/new/alerts_icon.svg';
import RabbitIcon from 'assets/img/icons/new/rabbit_wander_img.svg';
import { Skeleton } from 'components';

export const COLOR_CODES = {
  critical: '#FEF0EE',
  announcement: '#E9F5F3',
  alert: '#F4F6F8',
  undefined: 'grey'
};

export const HOVER_COLOR_CODES = {
  critical: '#FFE9E6',
  announcement: '#E2F7F3',
  alert: '#EAEDF0',
  undefined: 'grey'
};

export const ICON_COLOR = {
  critical: '#F57762',
  announcement: '#27A18A',
  alert: '#45505B',
  undefined: 'grey'
};

export const ICON_BG_COLOR_CODES = {
  critical: '#f8aca0cc',
  announcement: '#2BA38C1A',
  alert: '#DFE4E8',
  undefined: 'grey'
};

export const NOTIFICATION_ICON = {
  critical: <CriticalAlertsIcon />,
  announcement: <AnnouncementIcon />,
  alert: <AlertsIcon />,
  undefined: <AlertsIcon />
};

export const AllReadComponent = (
  <div
    className="d-flex align-items-center justify-content-center h-75"
    style={{ color: '#637381' }}
  >
    <div className="text-center">
      <img src={RabbitIcon} alt="rabbit-wanderer" />
      <div className="h5 font-weight-bold mt-2">You’re all caught up</div>
      <div>Go to all notifications to see past read notifications</div>
    </div>
  </div>
);

export const NoNotifications = (
  <div
    className="d-flex align-items-center justify-content-center h-75"
    style={{ color: '#637381' }}
  >
    <div className="text-center">
      <img src={RabbitIcon} alt="rabbit-wanderer" />
      <div className="h5 font-weight-bold mt-2">No notifications</div>
    </div>
  </div>
);

export const NotificationError = (
  <div
    className="d-flex align-items-center justify-content-center h-75"
    style={{ color: '#637381' }}
  >
    <div className="text-center">
      <img src={RabbitIcon} alt="rabbit-wanderer" />
      <div className="mt-2 font-weight-bold">
        Oh no! there was an error in fetching notifications
      </div>
    </div>
  </div>
);

export const NotificationSkeleteonLoader = (
  <>
    <Skeleton count={3} height="100px" className="rounded mb-4" />
  </>
);

export const EndOfNotifications = (
  <p className="text-center">
    <b>All caught up! Stay tuned for new updates.</b>
  </p>
);
