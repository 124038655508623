export const VALIDITY_MAP = {
  shipstation: {
    90: true,
    60: true
  }
};

const createMessage = (referrer, trialDays) => {
  return `Congratulations, you are eligible for a Return Rabbit ${trialDays}-day trial, compliments of ${referrer}!`;
};

export const CONTENT_FACTORY = {
  shipstation: trialDays => createMessage('ShipStation', trialDays)
};
