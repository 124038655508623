import { useEffect } from 'react';

import { user } from 'utils';

export default function useRedirect() {
  const isProductionServer = process.env.REACT_APP_MODE === 'PRODUCTION';
  const isAspenCx = window.location.hostname === 'admin.aspen.cx';
  const isRedirecting = isAspenCx && isProductionServer;

  useEffect(() => {
    if (isRedirecting) {
      user.logout();
      const redirectionURL = `https://admin.returnrabbit.com${window.location.pathname}`;
      window.location = redirectionURL;
    }
  }, [isRedirecting]);

  return isRedirecting;
}
