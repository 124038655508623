import { post } from 'utils';

export default function changePassword(
  params,
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  post({ apiKey: 'changePassword' }, { params })
    .then(({ data }) => onSuccess(data))
    .catch(onError)
    .finally(onFinally);
}
