import React, { useContext, useState, useEffect, useCallback } from 'react';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import Form from './Form';
import { NotesContext } from '../../context';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';

export default function CreateNote() {
  const { notes = [], canCreate = true } = useContext(NotesContext);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (canCreate) {
      setIsCreating(creating => creating || _isEmpty(notes));
    }
  }, [notes, canCreate]);

  const onToggleForm = useCallback(() => {
    if (!canCreate) return;
    setIsCreating(create => !create);
  }, [canCreate]);

  if (!canCreate) return null;

  return (
    <div className={styles.container}>
      {isCreating ? (
        <Form onCancel={onToggleForm} />
      ) : (
        <button onClick={onToggleForm} className={styles.createButton}>
          <EditIcon />
          <span>Add a note</span>
        </button>
      )}
    </div>
  );
}
