import React, { useContext } from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';
import { NotesContext } from '../../context';

export default function Note({
  data = {},
  deletingIds = {},
  onDelete = () => {}
}) {
  const { userData } = useContext(AuthContext);
  const { canDelete } = useContext(NotesContext);
  const loggedInUserId = `${_get(userData, 'userId', '')}`;

  const id = _get(data, 'id', '');
  const note = _get(data, 'note', '');
  const createdAt = _get(data, 'created_at', '');
  const createdBy = _get(data, 'created_by_user_name', '');
  const userId = _get(data, 'created_by_user_id', '');
  const isDeleting = _get(deletingIds, id, false);

  const allowDelete = canDelete && userId === loggedInUserId;
  const elapsedCreatedTime = moment(createdAt).fromNow();
  const username = createdBy
    .split(' ')
    .map(name => _upperFirst(name))
    .join(' ');

  const onDeleteClick = () => {
    if (!allowDelete) return;
    onDelete();
  };

  return (
    <li className={styles.note}>
      <p>{note}</p>
      <div className={styles.row}>
        <span>
          {username}, {elapsedCreatedTime}
        </span>
        {allowDelete && (
          <button onClick={onDeleteClick} disabled={isDeleting}>
            {isDeleting ? 'Deleting' : 'Delete'}
          </button>
        )}
      </div>
    </li>
  );
}
