import React from 'react';
import { renderToString } from 'react-dom/server';

import { ProgressIndicator, StepText } from 'containers/TourGuides/components';
import {
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import CONFIGURE_ROUTES from 'views/Configure/stubs/routes';

const AGGREGATOR_PATH = CONFIGURE_ROUTES.AGGREGATORS;

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getAggregatorSteps({
  push = () => {},
  onShow = () => {},
  onHide = () => {}
} = {}) {
  return [
    {
      id: 'intro',
      title: 'Shipping Aggregators',
      text: renderToString(
        <>
          <StepText>
            Let's get you set up with a shipping aggregator. This is the
            platform you will connect to Return Rabbit to issue return shipping
            labels. We support both ShipEngine and ShipStation at this time.
            <br />
            <br />
            If you don't have an account, you will see how to create one.
          </StepText>
          <ProgressIndicator current={1} total={6} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({ push, path: AGGREGATOR_PATH });
      },
      buttons: [{ text: "Let's go", type: 'next' }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'shipengine-card',
      attachTo: {
        element: getShepherdSelector('aggregator-card-shipengine'),
        on: 'right'
      },
      canClickTarget: true,
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: AGGREGATOR_PATH,
          selector: this.attachTo.element
        });
      },
      text: renderToString(
        <>
          <StepText>
            Here you will connect your ShipEngine account with Return Rabbit.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on <code>View Details</code> to start the setup.
          </StepText>
          <ProgressIndicator current={2} total={6} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector('aggregator-card-shipengine')} button`,
        event: 'click'
      },
      buttons: [{ text: 'Back', secondary: true, type: 'back' }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'token-help',
      attachTo: {
        element: `${getShepherdSelector(
          'aggregator-redirect'
        )} ${getShepherdSelector('question-mark')}`,
        on: 'right'
      },
      text: renderToString(
        <>
          <StepText>
            ShipEngine requires api keys to connect with Return Rabbit. You can
            click on <code>?</code> to know how to get that key.
          </StepText>
          <ProgressIndicator current={3} total={6} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({ selector: this.attachTo.element });
      },
      buttons: [
        { text: 'Back', secondary: true, type: 'back' },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'live-token-input',
      attachTo: {
        element: `${getShepherdSelector(
          'aggregator-key-wrapper'
        )}:nth-child(1) input`,
        on: 'right'
      },
      canClickTarget: true,
      title: 'Add or edit your production key',
      text: renderToString(
        <>
          <StepText>
            Copy the production key from ShipEngine, and add it here.
          </StepText>
          <ProgressIndicator current={4} total={6} />
        </>
      ),
      buttons: [
        { text: 'Back', secondary: true, type: 'back' },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'test-token-input',
      attachTo: {
        element: `${getShepherdSelector(
          'aggregator-key-wrapper'
        )}:nth-child(2) input`,
        on: 'right'
      },
      canClickTarget: true,
      title: 'Add or edit your sandbox key',
      text: renderToString(
        <>
          <StepText>
            Copy the sandbox key from ShipEngine and add it here.
          </StepText>
          <ProgressIndicator current={5} total={6} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'edit-token',
      attachTo: {
        element: `${getShepherdSelector('aggregator-save-btn')}`,
        on: 'right'
      },
      title: 'Click here to edit or save your keys',
      text: renderToString(
        <>
          <StepText>
            Once you have added your keys from ShipEngine, click here to edit or
            save those keys.
          </StepText>
          <ProgressIndicator current={6} total={6} />
        </>
      ),
      canClickTarget: false,
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Done', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    }
  ];
}
