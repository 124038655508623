import React, { useMemo, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import _size from 'lodash/size';
import styles from './index.module.scss';

import FetchOptionsKeyValues from 'components/FetchOptionsKeyValues';

export default function ProductVariantAttributes({
  optionValues = {},
  showCount = 2,
  joinWith = <>&nbsp;|&nbsp;</>,
  className = ''
}) {
  const id = useRef(`attributes-${uuidv4()}`).current;

  const { primaryAttributes, restAttributes } = useMemo(() => {
    return Object.keys(optionValues).reduce(
      (acc, key, index) => {
        const obj =
          index < showCount ? acc.primaryAttributes : acc.restAttributes;
        obj[key] = optionValues[key];
        return acc;
      },
      { primaryAttributes: {}, restAttributes: {} }
    );
  }, [optionValues, showCount]);

  const additionalAttributesCount = _size(restAttributes);
  const hasAdditionalAttributes = additionalAttributesCount > 0;
  const containerClassName = cn(styles.container, className);

  return (
    <div className={containerClassName}>
      <FetchOptionsKeyValues options={primaryAttributes} joinWith={joinWith} />
      {hasAdditionalAttributes && (
        <>
          {joinWith}
          <span className={styles.extraInfo} id={id}>
            +{additionalAttributesCount} more...
          </span>
          <UncontrolledTooltip target={id}>
            <FetchOptionsKeyValues options={restAttributes} joinWith={<br />} />
          </UncontrolledTooltip>
        </>
      )}
    </div>
  );
}
