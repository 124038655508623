import React, { useContext, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { formatPrice } from 'utils/formatters';
import { RetexContext } from 'components/RetexDetails';
import ManualDeduction from '../ManualDeduction';
import { ShopperGroupIncentiveIcon } from 'components/OrderServiceDetails/components';
import { ReactComponent as InfoIcon } from 'assets/img/alert-icons/info.svg';
import { AuthContext } from 'context/AuthContext';

export default function AmountBreakdown() {
  const { retex, isRejected } = useContext(RetexContext);

  const { masterData } = useContext(AuthContext);
  const SHOPPER_GROUPS_OVERRIDES = useRef(
    _get(masterData, 'shopper_group_override_entities', [])
  ).current;
  const ENTITY_OVERRIDES = useRef(
    Array.isArray(SHOPPER_GROUPS_OVERRIDES) &&
      SHOPPER_GROUPS_OVERRIDES.length === 5
      ? SHOPPER_GROUPS_OVERRIDES.map(({ value = '' }) => value)
      : ['', '', '', '', '']
  ).current;

  const id = _get(retex, 'id', '');
  const quantity = _get(retex, 'quantity', 0);
  const currency = _get(retex, 'currency', 'USD');
  const entityType = _get(retex, 'entity_type', '');
  const discountCode = _get(retex, 'discount_code', '');
  const purchasedProductPrice = _get(retex, 'purchased_variant.price', 0);
  const exchangedProductPrice = _get(retex, 'exchange_variant.price', 0);
  const amount = _get(retex, 'amount_breakdown.amount', 1);
  const tax = _get(retex, 'amount_breakdown.tax', 1);
  const discount = _get(retex, 'amount_breakdown.discount', 2);
  const restockFee = _get(retex, 'amount_breakdown.restocking_fee', 0.5);
  const incentive = _get(retex, 'amount_breakdown.exchange_incentive', 2);
  const originalOrderAmount = _get(
    retex,
    'amount_breakdown.original_order_amount',
    null
  );
  const shopperOverrides = _get(retex, 'shopper_group_overrides_applied', []);

  const isRefund = amount >= 0;
  const isReturn = entityType === 'RET';
  const isExchange = entityType === 'EXC';
  const tooltipId = `purchase-price-${id}`;

  const purchasePrice = formatPrice(purchasedProductPrice * quantity, currency);
  const originalOrderAmtValue = formatPrice(
    originalOrderAmount * quantity,
    currency
  );
  const exchangePrice = formatPrice(exchangedProductPrice * quantity, currency);
  const totalTax = formatPrice(tax * quantity, currency);
  const totalDiscount = formatPrice(discount, currency);
  const incentiveAmount = formatPrice(incentive, currency);
  const totalRestockingFee = formatPrice(restockFee, currency);
  const totalAmount = formatPrice(amount, currency);

  const showOriginalOrderAmount = originalOrderAmount !== null;
  const originalAmountSign = originalOrderAmount < 0 ? '-' : '';
  const originalExchangeValue = `${originalAmountSign} ${originalOrderAmtValue}`;

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Line Item Breakdown</h3>
      <div className={styles.content}>
        <div className={styles.row}>
          <span className={styles.label}>
            Original Purchase Price{' '}
            <InfoIcon id={tooltipId} className={styles.infoIcon} />
            <UncontrolledTooltip target={tooltipId}>
              Includes taxes and discounts applied
            </UncontrolledTooltip>
          </span>
          <span className={styles.value}>{purchasePrice}</span>
        </div>
        <If test={showOriginalOrderAmount}>
          <div className={styles.row}>
            <span className={styles.label}>
              Original Exchange Payment{' '}
              <InfoIcon id={tooltipId} className={styles.infoIcon} />
              <UncontrolledTooltip target={tooltipId}>
                Includes breakup for previous order amount
              </UncontrolledTooltip>
            </span>
            <span className={styles.value}>{originalExchangeValue}</span>
          </div>
        </If>
        <If test={isExchange}>
          <div className={styles.row}>
            <div className={styles.label}>Price of Exchange Item(s)</div>
            <div className={styles.value}>- {exchangePrice}</div>
          </div>
          <If test={tax}>
            <div className={styles.row}>
              <div className={styles.label}>Tax</div>
              <div className={styles.value}>- {totalTax}</div>
            </div>
          </If>
          <If test={discount}>
            <div className={styles.row}>
              <div className={styles.label}>
                Original Discount{' '}
                <If test={discountCode}>
                  <em className={styles.discountTag}>{discountCode}</em>
                </If>
              </div>
              <div className={styles.value}>+ {totalDiscount}</div>
            </div>
          </If>
          <If test={incentive}>
            <div className={styles.row}>
              <div className={styles.label}>
                Additional Exchange Credit{' '}
                <ShopperGroupIncentiveIcon
                  type={ENTITY_OVERRIDES[2]}
                  incentives={shopperOverrides}
                  iconClassName={styles.infoIcon}
                  message="Shopper group's bonus cash incentive applied"
                />
              </div>
              <div className={styles.value}>+ {incentiveAmount}</div>
            </div>
          </If>
        </If>
        <div className={styles.row}>
          <div className={styles.label}>
            Restocking Fee{' '}
            <ShopperGroupIncentiveIcon
              type={ENTITY_OVERRIDES[4]}
              incentives={shopperOverrides}
              iconClassName={styles.infoIcon}
              message="Shopper group's unit restocking fee applied"
            />
          </div>
          <div className={styles.value}>- {totalRestockingFee}</div>
        </div>
        {isReturn && (
          <ManualDeduction
            containerClassName={styles.row}
            labelClassName={styles.label}
            valueClassName={styles.value}
          />
        )}
        <div className={styles.row}>
          <div className={styles.label}>Line Item Total</div>
          <span>{isRefund ? 'Estimated Refund' : 'Shopper Pays'}</span>
          <div
            className={cn(styles.value, styles.largeText, {
              [styles.striked]: isRejected
            })}
          >
            {totalAmount}
          </div>
        </div>
      </div>
    </div>
  );
}
