import React, { useCallback, useRef, createContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';

import { getScrollFrame } from 'utils/methods';

/**
 * @typedef {Object} ReactRef
 * @property {Object | null} current
 */

export const Context = createContext({
  /**
   * @param {ReactRef} ref - useRef | React.createRef value
   * @param {"smooth" | "auto"} scrollBehavior - Default "smooth"
   */
  scrollTo: (ref, scrollBehavior) => {},
  /**
   * Clears the location.hash while preventing scroll position
   */
  removeUrlHash: () => {}
});

export function Provider({ children }) {
  const timerRef = useRef(null);
  const { replace } = useHistory();
  const { pathname, search, state } = useLocation();

  const scrollTo = useRef((sectionRef = null, scrollBehavior = 'smooth') => {
    const element = _get(sectionRef, 'current', null);
    if (!element) return;

    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      sectionRef.current.scrollIntoView({ behavior: scrollBehavior });
      clearTimeout(timerRef.current);
    }, [200]);
  }).current;

  const removeUrlHash = useCallback(() => {
    const scrollFrame = getScrollFrame();
    const prevYScrollPositon = scrollFrame.scrollTop;
    const prevXScrollPositon = scrollFrame.scrollLeft;
    replace(pathname + search, state);
    scrollFrame.scrollTop = prevYScrollPositon;
    scrollFrame.scrollLeft = prevXScrollPositon;
  }, [pathname, search, state, replace]);

  return (
    <Context.Provider value={{ scrollTo, removeUrlHash }}>
      {children}
    </Context.Provider>
  );
}
