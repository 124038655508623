import React from 'react';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';

function getCustomSelectStyles(data) {
  const error = _get(data, 'error', false);
  const isGrouped = _get(data, 'isGrouped', false);

  return {
    container: (provided, state) => ({
      ...provided,
      borderRadius: '4px',
      backgroundColor: state.isDisabled ? '#f7f7f7' : '#ffffff'
    }),
    control: (provided, state) => {
      return {
        ...provided,
        boxShadow: 'unset',
        // boxShadow:
        //   error || state.isDisabled
        //     ? 'none'
        //     : state.menuIsOpen
        //     ? '0 0 4px 0 rgba(12, 19, 26, 0.3)'
        //     : '0 0 4px 0 rgba(12, 19, 26, 0.2)',
        border:
          error && !state.isDisabled
            ? '1px solid #da4d4db3'
            : state.isDisabled
            ? '1px solid #e4f0fe'
            : '1px solid rgba(12, 19, 26, 0.5)',
        minHeight: '44px',
        borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px',
        backgroundColor: state.isDisabled ? '#fafcff' : '#ffffff',
        cursor: state.isDisabled ? 'not-allowed' : 'unset',
        '&:hover': {
          border: error
            ? '1px solid rgba(255, 121, 121, 0.5)'
            : '1px solid rgba(12, 19, 26, 0.5)',
          boxShadow: 'unset'
          // boxShadow: '0 0 4px 0 rgba(12, 19, 26, 0.3)'
        }
      };
    },
    singleValue: provided => ({
      ...provided,
      color: '#0c131a'
    }),
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        transition: 'all 150ms',
        transform: state.isFocused ? 'rotateX(180deg)' : 'rotateX(0)'
      };
    },
    group: provided => {
      return {
        ...provided,
        padding: '0'
      };
    },
    groupHeading: provided => {
      return {
        ...provided,
        margin: '0',
        padding: '6px 12px',
        backgroundColor: '#f3f3f3',
        position: 'sticky',
        top: '0',
        textTransform: 'unset'
      };
    },
    indicatorsContainer: provided => ({ ...provided, cursor: 'pointer' }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: provided => ({ ...provided, color: '#a6afb8' }),
    menu: provided => ({
      ...provided,
      margin: '0',
      boxShadow: '0 2px 4px 0 rgba(12, 19, 26, 0.3)',
      backgroundColor: '#ffffff',
      borderRadius: '0 0 4px 4px',
      borderTop: error ? 'none' : '1px solid rgba(12, 19, 26, 0.2)',
      zIndex: '100000'
    }),
    menuList: provided => ({
      ...provided,
      borderRadius: '0 0 4px 4px',
      padding: '0',
      position: 'relative'
    }),
    option: (provided, state) => {
      return {
        ...provided,
        fontSize: '14px',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        padding: !isGrouped ? '8px 12px' : '8px 12px 8px 28px',
        fontWeight: state.isSelected ? '500' : '400',
        color: state.isDisabled
          ? 'rgba(0, 0, 0, 0.6)'
          : state.isSelected
          ? '#0c131a'
          : '#0c131ab3',
        backgroundColor: state.isDisabled
          ? 'rgba(0, 0, 0, 0.1)'
          : state.isFocused
          ? '#fbede5'
          : 'transparent',
        '&:active': {
          backgroundColor: state.isDisabled ? 'rgba(0, 0, 0, 0.1)' : '#f2c6ab'
        },
        '&:focus-visible': {
          outlineColor: '#f2c6ab'
        }
      };
    },
    multiValue: provided => ({
      ...provided,
      backgroundColor: '#fbede5',
      borderRadius: '4px'
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: '#607180',
      transform: 'translateY(-1px)'
    }),
    multiValueRemove: provided => ({
      ...provided,
      transform: 'translateY(1px)',
      color: '#607180',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#607180'
      }
    }),
    noOptionsMessage: () => ({ display: 'none' })
  };
}

//--------------------------------------------------------------------------

const formatGroupLabel = ifGrouped => {
  return !ifGrouped
    ? null
    : data => (
        <div
          style={{
            color: '#0c131a',
            fontSize: '12px',
            fontWeight: '800'
          }}
        >
          {_capitalize(data.label)}
        </div>
      );
};

//--------------------------------------------------------------------------

export { getCustomSelectStyles, formatGroupLabel };
