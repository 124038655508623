import URL_ROUTES from './routes';

export default {
  items: [
    {
      name: 'Overview',
      id: 'store.enabled',
      url: URL_ROUTES.OVERVIEW.ROOT,
      icon: 'icon-overview'
    }
  ]
};
