import React, { useContext } from 'react';

import { ReactTablesContext } from '..';
import { TablePaginate } from 'components/Tables';

export default function ReactTablesPagination({
  disabled = false,
  className = ''
}) {
  const { pagination } = useContext(ReactTablesContext);
  const { setPage, totalPages, currentPage } = pagination;

  return (
    <TablePaginate
      page={currentPage}
      totalPages={totalPages}
      className={className}
      setPage={setPage}
      isDisabled={disabled}
    />
  );
}
