import ROUTES from 'views/Configure/stubs/routes';

const HOME = ROUTES.EXCHANGES;

export default {
  HOME,
  CATALOG_EXCHANGE: `${HOME}/catalog-exchange`,
  PRODUCT_RECOMMENDATION: `${HOME}/product-recommendation`,
  EXCHANGE_INCENTIVES: `${HOME}/exchange-incentives`,
  BONUS_CASH_INCENTIVE: `${HOME}/bonus-cash-incentive`
};
