import _get from 'lodash/get';

import { localStore } from 'utils';

const DICT = {
  SELF_SHIP: 'SERVICE_REQUEST_CREATED_SELF_SHIP',
  SEND_LABEL: 'SERVICE_REQUEST_CREATED_SEND_LABEL',
  HOME_PICKUP: 'SERVICE_REQUEST_CREATED_HOME_PICKUP',
  STORE_RETURN: 'SERVICE_REQUEST_CREATED_RETURN_STORE'
};

export default function checkEmailToSend(shippingMethod) {
  const emailSettingToCheck = _get(DICT, shippingMethod);
  const getAllEmailSetting = JSON.parse(
    localStore.get('emailSettingsDefaults')
  );
  const findSelectedEmailSetting = _get(
    getAllEmailSetting,
    emailSettingToCheck
  );
  if (!findSelectedEmailSetting) return null;
  let {
    enabled,
    send_to_customer,
    stc_checkbox_default_value
  } = findSelectedEmailSetting;
  if (enabled) {
    return {
      send_to_customer,
      stc_checkbox_default_value,
      email_setting: true
    };
  }
  return null;
}
