import { useState, useEffect } from 'react';
import _get from 'lodash/get';

import { getRetexesOfOrderService } from 'utils/services';

export default function useRetexes(osId = '') {
  const [retexIds, setRetexIds] = useState([]);
  const [retexesDataMap, setRetexesDataMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (osId) {
      getRetexesOfOrderService(
        { routeParams: { id: osId }, fields: 'id,name,status' },
        data => {
          const { retexIds, retexesDataMap } = data.reduce(
            (acc, retex, index) => {
              const id = _get(retex, 'id', '');
              const name = _get(retex, 'name', '');
              const status = _get(retex, 'status', '');
              if (id) {
                acc.retexIds.push(id);
                acc.retexesDataMap[id] = { id, name, status };
              }
              return acc;
            },
            { retexIds: [], retexesDataMap: {} }
          );
          setRetexIds(retexIds);
          setRetexesDataMap(retexesDataMap);
        },
        () => {},
        () => setIsLoading(false)
      );
    }
  }, [osId]);

  return {
    retexIds,
    retexesDataMap,
    isFetchingRetexes: isLoading
  };
}
