import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { UiKit } from 'components';
import notFoundImage from 'assets/rr-illustrations/lost.png';

export default function NotFound() {
  return (
    <div className="NotFound">
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <div className="NotFound__image">
        <img src={notFoundImage} alt="not found" />
      </div>
      <div className="NotFound__text">
        Sorry. We couldn’t find what you were looking for.
      </div>
      <Link to="/">
        <UiKit.Button>Back To Home</UiKit.Button>
      </Link>
    </div>
  );
}
