import React from 'react';
import { Button } from 'reactstrap';
function ButtonBlock(props) {
  return (
    <Button
      color={props.color || 'primary'}
      disabled={props.processing || props.disabled}
      onClick={props.action}
      className={props.className}
    >
      {props.processing ? 'Processing...' : props.text || 'Continue'}
    </Button>
  );
}
export default ButtonBlock;
