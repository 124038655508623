import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { UiKit, Callout } from 'components';
import { ReactComponent as HourglassIcon } from 'assets/img/icons/hourglass.svg';
import { ReactComponent as InfoIcon } from 'assets/img/icons/info-fill.svg';
import { ReactComponent as WarningIcon } from 'assets/img/icons/warning-red.svg';

const ICON_MAP = {
  info: HourglassIcon,
  error: WarningIcon
};

export default function Alert({
  type = 'info',
  children = null,
  tooltipMessage = '',
  className = '',
  iconClassName: iconClass = ''
}) {
  const containerClassName = cn(styles.container, className, {
    [styles.info]: type === 'info',
    [styles.error]: type === 'error'
  });

  const iconClassName = cn(styles.icon, iconClass);

  const Icon = _get(ICON_MAP, type, () => null);

  return (
    <Callout type={type} className={containerClassName}>
      <Callout.Content className={styles.content}>
        <UiKit.GridRow
          gap="8"
          templateColumns="auto 1fr"
          className={styles.row}
        >
          <div className={iconClassName}>
            <Icon />
          </div>
          <p className={styles.title}>{children}</p>
        </UiKit.GridRow>
        {tooltipMessage && (
          <>
            <div id="label-status-info-icon" className={styles.infoIcon}>
              <InfoIcon />
            </div>
            <UncontrolledTooltip target="label-status-info-icon">
              {tooltipMessage}
            </UncontrolledTooltip>
          </>
        )}
      </Callout.Content>
    </Callout>
  );
}
