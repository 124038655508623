import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { waterfall } from 'async';
import axios from 'axios';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _isFunction from 'lodash/isFunction';

import { get, logError, post } from 'utils';
import { CustomToast } from 'components';

const API_METHODS = {
  GET: () => get,
  POST: () => post
};

const DEFAULT = {
  method: 'GET',
  loadOnMount: true,
  refetchOn: null,
  initialData: null,
  isPublicAPI: false,
  payload: {},
  routeParam: '',
  routeParams: {},
  axiosConfig: {},
  onTransform: data => data,
  onSuccess: () => {},
  onError: () => {},
  ignoreReadModeCheck: false,
  errorMessage: 'Unable to fetch data at the moment. Please try again later.'
};

/**
 * @callback PayloadGenerator
 * @param {Object} aggregateReponseData API response.data value
 * @returns {Object.<string, *>} payload for the api call
 */

/**
 * @callback OnTransform
 * @param {Object} apiResponseData API response.data value
 * @returns {Object} Transformed API response daa
 */

/**
 * @callback OnSuccess
 * @param {Object} transformedData onTransform(aggregateData) value
 * @param {Object} rawAggregateData Raw aggregated response data
 */

/**
 * @callback OnError
 * @param {Object} error Error or AxiosError object
 * @param {Object} apiResponseData API Response
 */

/**
 * @typedef {Object} Apis
 * @property {string} apiKey API Key map from the API.js [API_ENDPOINTS]
 * @property {string} dataKey data key that will be used to create the fnal response data
 * @property {"GET" | "POST"} method API method  to use for the request
 * @property {boolean} [isPublicAPI] True to use Public Axios instance | False to use Private Axios instance.
 * @property {boolean} [ignoreReadModeCheck] To skip the check of read-mode and trigger the API
 * @property {PayloadGenerator | Object<string, *>} [payload] API payload
 * @property {string} [routeParam] API endpoint fragment to be added at the end of the API endpoint, usually dynamic UUID or slug
 * @property {Object.<string, *>} [routeParams] Define the route dynamic parameters to that replaces the placeholder fragments (withing [] brackets) in the API endpoint
 * @property {import('axios').AxiosRequestConfig} [axiosConfig] Axios Request Config object
 * @property {OnTransform} [onTransform] Method to transform the API response data by returning the data back in desired schema.
 */

/**
 * @typedef {Object} Config
 * @property {boolean} [loadOnMount] If true, the data fetching will start on mount
 * @property {any} [refetchOn] Any truthy value, to re-trigger the APIs.
 * @property {Object | null} [initialData] Initial Data representing the schema of API response data or null
 * @property {OnTransform} [onTransform] Method to transform the aggregated data of all API calls
 * @property {OnSuccess} [onSuccess] Callback on successful data fetching
 * @property {OnError} [onError] Callback on API request failure
 * @property {string} [errorMessage] Custom toast message in case the API fails to fetch data
 */

/**
 * Arguments of useSeriesFetch hook
 * @param {Apis[]} apis List of the objects defining the apiKey and the request configuration
 * @param {Config} config Optional parameters to control the behaviour of the hook
 */

export default function useSeriesFetch(apis = [], config = {}) {
  const initialData = _get(config, 'initialData', DEFAULT.initialData);
  const loadOnMount = _get(config, 'loadOnMount', DEFAULT.loadOnMount);
  const refetchOn = _get(config, 'refetchOn', DEFAULT.refetchOn);
  const errorMessage = _get(config, 'errorMessage', DEFAULT.errorMessage);
  const onTransform = _get(config, 'onTransform', DEFAULT.onTransform);
  const onSuccess = _get(config, 'onSuccess', DEFAULT.onSuccess);
  const onError = _get(config, 'onError', DEFAULT.onError);

  const [data, setData] = useState(initialData);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(loadOnMount);
  const isMountedRef = useRef(false);
  const cancelTokenSourceRef = useRef(null);

  const requests = useMemo(() => {
    let isFirst = true;

    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel();
    }

    cancelTokenSourceRef.current = axios.CancelToken.source();

    return apis.reduce((acc, { apiKey, dataKey, ...metadata } = {}) => {
      if (!apiKey || !dataKey) {
        throw new Error(
          'apiKey and the dataKey are required in the useSeriesFetch hook'
        );
      }

      const apiVerb = _get(metadata, 'method', DEFAULT.method);
      const payload = _get(metadata, 'payload', DEFAULT.payload);
      const axiosConfig = _get(metadata, 'axiosConfig', DEFAULT.axiosConfig);
      const routeParam = _get(metadata, 'routeParam', DEFAULT.routeParam);
      const routeParams = _get(metadata, 'routeParams', DEFAULT.routeParams);
      const isPublicAPI = _get(metadata, 'isPublicAPI', DEFAULT.isPublicAPI);
      const onTransform = _get(metadata, 'onTransform', DEFAULT.onTransform);
      const ignoreReadModeCheck = _get(
        metadata,
        'ignoreReadModeCheck',
        DEFAULT.ignoreReadModeCheck
      );

      const methodGen = _get(API_METHODS, apiVerb, API_METHODS.GET);
      const apiMethod = methodGen();

      const apiCall = (
        payload,
        successCallback = () => {},
        errorCallback = () => {}
      ) => {
        const params = { routeParam, routeParams, ...payload };
        apiMethod(
          { apiKey, noTokenRequired: isPublicAPI, ignoreReadModeCheck },
          {
            params,
            config: {
              ...axiosConfig,
              cancelToken: cancelTokenSourceRef.current.token
            }
          }
        )
          .then(({ data }) => {
            successCallback({ [dataKey]: onTransform(data) });
          })
          .catch(err => errorCallback(err));
      };

      if (isFirst) {
        isFirst = false;
        acc.push(function(callback) {
          apiCall(
            payload,
            data => callback(null, data),
            err => callback(err)
          );
        });
      } else {
        acc.push(function(aggregateData, callback) {
          const payloadData = _isFunction(payload)
            ? payload(aggregateData)
            : payload;
          apiCall(
            payloadData,
            data => callback(null, { ...aggregateData, ...data }),
            err => callback(err)
          );
        });
      }

      return acc;
    }, []);
  }, [apis]);

  const fetchData = useCallback(() => {
    setIsError(false);
    setIsLoading(true);
    waterfall(requests, (error, data) => {
      if (axios.isCancel(error)) {
        return;
      }

      setIsLoading(false);

      if (error) {
        setIsError(true);
        onError(error, _get(error, 'response.data.data', null));
        logError(errorMessage === DEFAULT.errorMessage ? error : errorMessage);
        if (errorMessage) {
          CustomToast({
            isNotified: _get(error, 'notified', false),
            msg: errorMessage,
            type: 'error'
          });
        }
      } else {
        const transformedData = onTransform(_cloneDeep(data));
        setData(transformedData);
        onSuccess(transformedData, data);
      }
    });
  }, [requests, onSuccess, onError, onTransform, errorMessage]);

  useEffect(() => {
    if (isMountedRef.current && refetchOn) {
      fetchData();
    }
  }, [fetchData, refetchOn]);

  useEffect(() => {
    if (!isMountedRef.current && loadOnMount) {
      fetchData();
    }
    isMountedRef.current = true;
  }, [fetchData, loadOnMount]);

  return { data, isError, isLoading, fetchData };
}
