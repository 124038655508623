import ADMIN_ROUTES from 'config/nav/admin/routes';

const HOME = ADMIN_ROUTES.REQUESTS.ROOT;

export default {
  HOME,
  REQUEST_DETAILS: `${HOME}/:osId`,
  RETEX_DETAILS: `${HOME}/:osId/retexes/:retexId`,
  CREATE_NEW_REQUEST: ADMIN_ROUTES.ORDERS.ROOT
};
