import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default function DispositionMode({ mode = 'scrap', className = '' }) {
  const isRestocked = mode === 'resale';

  const text = isRestocked ? 'Restocked' : 'Not Restocked';
  const containerClassName = cn(
    styles.container,
    { [styles.positive]: isRestocked },
    className
  );

  return (
    <div className={containerClassName}>
      <span data-ring />
      {text}
    </div>
  );
}
