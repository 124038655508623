const RECHECK_FREQUENCY = 100;

export default function beforeShowPromise({
  path = '',
  push = () => {},
  delay = 200,
  selector = '',
  waitTillSeconds = 15
} = {}) {
  if (path && window.location.pathname !== path) {
    push(path);
  }
  if (!selector) {
    return new Promise(resolve => setTimeout(() => resolve(), delay));
  } else {
    return new Promise(resolve => {
      let triesCount = 0;
      const triesPerSecond = Math.floor(1000 / RECHECK_FREQUENCY);
      const totalTries = triesPerSecond * waitTillSeconds;
      const timer = setInterval(() => {
        triesCount++;
        const isElementFound = !!document.querySelector(selector);
        if (isElementFound || triesCount >= totalTries) {
          clearInterval(timer);
          resolve();
        }
      }, RECHECK_FREQUENCY);
    });
  }
}
