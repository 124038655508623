import React, { useState, useRef, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import styles from './index.module.scss';

import { ReactComponent as CopyIcon } from 'assets/img/icons/copy-clipboard.svg';

export default function ExternalLink({
  href = '',
  text = '',
  fallback = null,
  allowCopy = false,
  containerClassName = '',
  copyButtonClassName = '',
  className = ''
}) {
  const [isCopied, setIsCopied] = useState(false);
  const id = useRef(`link-${uuidv4()}`).current;

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
        clearTimeout(timer);
      }, 600);
    }
  }, [isCopied]);

  const onCopy = () => {
    if (isCopied) return;
    navigator.clipboard.writeText(href);
    setIsCopied(true);
  };

  const wrapperClassName = cn(styles.container, containerClassName);
  const buttonClassName = cn(styles.copyBtn, copyButtonClassName);
  const linkClassName = cn(styles.link, className);

  if (!href) return fallback;

  return (
    <div className={wrapperClassName}>
      {allowCopy && (
        <button id={id} className={buttonClassName} onClick={onCopy}>
          <CopyIcon />
          <UncontrolledTooltip target={id} delay={{ show: 0, hide: 500 }}>
            {isCopied ? 'Copied ✅' : 'Copy Link'}
          </UncontrolledTooltip>
        </button>
      )}
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={linkClassName}
      >
        {text}
      </a>
    </div>
  );
}
