import { deleteRequest } from 'utils';

export default function deleteRetailerNote(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  deleteRequest({ apiKey: 'retailerNotes.delete' }, { params })
    .then(res => onSuccess(res.data))
    .catch(onError)
    .finally(onFinally);
}
