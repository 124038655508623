import _get from 'lodash/get';
import _set from 'lodash/set';
import _isArray from 'lodash/isArray';

import { OS_RETEX_STATUS_MAP, RETEX_STATUS } from 'dictionary';
import { rejectedServiceRequestChoices } from 'utils/DICTIONARY';
import { isOsAwaitingAtAdmin } from 'utils/methods';

const REST_RETEXES_KEY = 'REST';

export default function modifyRetexes(osStatus = '', retexes = []) {
  if (!_isArray(retexes) || !osStatus) return [];

  const needsAdminAction = isOsAwaitingAtAdmin(osStatus);
  const rejectedRetexStatuses = rejectedServiceRequestChoices();
  const retexStatusSequence = _get(OS_RETEX_STATUS_MAP, osStatus) || [];

  const sortedRetexesMap = new Map();
  retexStatusSequence.forEach(key => sortedRetexesMap.set(key, []));
  sortedRetexesMap.set(REST_RETEXES_KEY, []);

  retexes.forEach(retex => {
    const status = _get(retex, 'status', '');
    const dispositionMode = _get(retex, 'disposition.mode', null);

    const matchesOsStatus = sortedRetexesMap.has(status);
    const key = matchesOsStatus ? status : REST_RETEXES_KEY;
    const currentData = sortedRetexesMap.get(key);
    const isRejected = rejectedRetexStatuses.includes(status);
    const isApproved =
      status === RETEX_STATUS.REFUND_SUCCESS ||
      status === RETEX_STATUS.EXCHANGE_SUCCESS;
    const showRestock =
      status === RETEX_STATUS.AWAITING_REFUND_APPROVAL ||
      status === RETEX_STATUS.AWAITING_EXCHANGE_APPROVAL ||
      status === RETEX_STATUS.AWAITING_PAYMENT_RESOLUTION ||
      status === RETEX_STATUS.AWAITING_PAYMENT_COMPLETION;

    const isActionable = matchesOsStatus && needsAdminAction;
    const wasRestocked = dispositionMode === 'resale';

    _set(retex, 'is_active', matchesOsStatus);
    _set(retex, 'is_approved', isApproved);
    _set(retex, 'is_rejected', isRejected);
    _set(retex, 'is_actionable', isActionable);
    _set(retex, 'is_checked', isActionable);
    _set(retex, 'will_restock', wasRestocked);
    _set(retex, 'show_restock', showRestock);
    sortedRetexesMap.set(key, [...currentData, retex]);
  });

  const sortedRetexes = [];
  sortedRetexesMap.forEach(list => sortedRetexes.push(...list));
  return sortedRetexes;
}
