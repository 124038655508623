import React from 'react';

const NotSupportedBrowser = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)'
      }}
    >
      <div
        style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}
      >
        Browser Not Supported.
      </div>
      <div>
        Please try opening this application in latest version of Chrome, Firefox
        or Safari
      </div>
    </div>
  );
};

export default NotSupportedBrowser;
