import React from 'react';

export default function ImageConstraints({ dimenstions = '', className = '' }) {
  return (
    <div className={className}>
      <div className="d-flex justify-content-between">
        <div>
          Image Formats Supported:{' '}
          <span className="font-weight-bold">.png, .jpg</span>
        </div>
        <div>
          Recommended Size:{' '}
          <span className="font-weight-bold">1MB or less</span>
        </div>
      </div>
      {dimenstions && (
        <div className="mt-1">
          Recommended dimensions:{' '}
          <span className="font-weight-bold">{dimenstions}</span>
        </div>
      )}
    </div>
  );
}
