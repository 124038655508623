import React, { useContext, useEffect, useRef, useState } from 'react';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If, localStore } from 'utils';
import { verifyRetailer, login } from 'utils/services';
import { AuthContext } from 'context/AuthContext';
import { UiKit } from 'components';
import { ReactComponent as LockIcon } from 'assets/img/icons/lock-fill.svg';

export default function StoreSwitch({ className = '', itemClassName = '' }) {
  const [toStore, setToStore] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [needPassword, setNeedPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { userData, storeProperties, permittedStores } = useContext(
    AuthContext
  );
  const passwordInputRef = useRef(null);

  const email = _get(userData, 'email', '');
  const loggedInStoreName = _get(storeProperties, 'store_unique_name', '');
  const isProcessing = isSwitching || isLoggingIn || isValidating;

  useEffect(() => {
    if (isSwitching && toStore) {
      const timer = setTimeout(() => {
        localStore.put('rrloggedInStoreName', toStore);
        window.location.href = '/';
        clearTimeout(timer);
      }, [500]);
    }
  }, [toStore, isSwitching]);

  useEffect(() => {
    if (needPassword && passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, [needPassword]);

  const onStoreSwitch = store => {
    setToStore(store);
    setIsValidating(true);
    verifyRetailer(
      store,
      () => setIsSwitching(true),
      err => setNeedPassword(err.statusCode % 400 < 100),
      () => setIsValidating(false)
    );
  };

  const onLogin = e => {
    e.preventDefault();
    if (!password) return setPasswordError('Please enter the password');
    setIsLoggingIn(true);
    login(
      { email, password, retailer_name: toStore },
      () => {
        setIsSwitching(true);
        setNeedPassword(false);
      },
      () => {
        setPasswordError('Incorrect Password! Please try again.');
        const timer = setTimeout(() => {
          if (passwordInputRef) {
            passwordInputRef.current.focus();
            passwordInputRef.current.select();
          }
          clearTimeout(timer);
        }, 0);
      },
      () => setIsLoggingIn(false)
    );
  };

  const onPasswordChange = (_, val) => {
    setPassword(val);
    setPasswordError('');
  };

  const onCloseModal = () => {
    setToStore(null);
    setPassword('');
    setPasswordError('');
    setIsSwitching(false);
    setIsLoggingIn(false);
    setIsValidating(false);
    setNeedPassword(false);
  };

  const storesList = permittedStores.reduce((acc, storeUniqueName) => {
    if (storeUniqueName !== loggedInStoreName) {
      acc.push(
        <DropdownItem
          key={storeUniqueName}
          className={itemClassName}
          onClick={() => onStoreSwitch(storeUniqueName)}
        >
          {storeUniqueName}
        </DropdownItem>
      );
    }
    return acc;
  }, []);

  const modalClassName = cn(styles.modal, { [styles.expanded]: needPassword });

  return (
    <>
      <DropdownMenu right className={className}>
        <DropdownItem
          // key="static-option"
          className={styles.disabled}
        >
          Switch to another store:
        </DropdownItem>
        <div className={styles.container}>{storesList}</div>
      </DropdownMenu>
      <UiKit.Modal
        centered
        isOpen={!!toStore}
        onClose={onCloseModal}
        isProcessing={isProcessing}
        contentClassName={modalClassName}
        headerClassName={styles.header}
      >
        <If test={!needPassword}>
          <div className="d-flex align-items-center">
            {(isValidating || isSwitching) && (
              <i className="fas fa-spin fa-spinner" />
            )}
            <span className="ml-3">
              {isValidating ? (
                'Verifying access...'
              ) : isSwitching ? (
                <>
                  Switching to <strong>{toStore}</strong>
                </>
              ) : (
                ''
              )}
            </span>
          </div>
        </If>
        <If test={needPassword}>
          <form className={styles.loginForm} onSubmit={onLogin}>
            <span className="mb-3">
              Login to <strong>{toStore}</strong>
              <LockIcon className={styles.lockIcon} />
            </span>

            <div className={styles.helptext}>
              <span role="img" aria-label="info">
                💡
              </span>
              <p>
                You need to provide your password and log in only once per store
                and then you can switch seamlessly between all logged in stores.
              </p>
            </div>

            <UiKit.Label
              className={styles.label}
              text={
                <>
                  Please enter the password for <strong>{toStore}</strong> store
                </>
              }
            />
            <UiKit.Input
              type="password"
              placeholder="*************"
              inputRef={passwordInputRef}
              value={password}
              onChange={onPasswordChange}
              disabled={isLoggingIn}
              error={passwordError}
              className={styles.inputContainer}
            />
            <UiKit.Button
              customType="success"
              wrapperClassName={styles.loginButton}
              processing={isLoggingIn}
              processingText="Verifying..."
            >
              Login
            </UiKit.Button>
          </form>
        </If>
      </UiKit.Modal>
    </>
  );
}
