import React from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import cn from 'classnames';
import styles from './index.module.scss';

import { ReactComponent as LinkIcon } from 'assets/img/icons/external-link.svg';

/**
 * @param {Object} props
 * @param {import("utils/types").FeatureCardLink} props.link
 * @param {boolean} props.compact
 * @param {boolean} props.disabled
 * @param {boolean} props.hasAccess
 */
export default function FeatureLink({
  link,
  compact,
  disabled = false,
  hasAccess = true
}) {
  if (!link) return null;

  const url = _get(link, 'url', '');
  const btnText = _get(link, 'title', '');
  const isLoading = _get(link, 'isLoading', false);
  const isExternal = _get(link, 'external', false);
  const showIcon = _get(link, 'showIcon', false);
  const icon = _get(link, 'icon', <LinkIcon className={styles.icon} />);

  const linkClassName = cn(styles.link, {
    [styles.compact]: compact,
    [styles.loading]: isLoading,
    [styles.disabled]: disabled,
    [styles.locked]: !hasAccess
  });

  const btnContent = isLoading
    ? 'Loading...'
    : !hasAccess
    ? 'View Details'
    : btnText;

  const content = isExternal ? (
    <a
      className={linkClassName}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {btnContent} {showIcon && icon}
    </a>
  ) : (
    <Link className={linkClassName} to={url}>
      {btnContent} {showIcon && icon}
    </Link>
  );

  return <div className={styles.container}>{content}</div>;
}
