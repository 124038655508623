import React from 'react';
import cross from 'assets/img/icons/x-circle.svg';
const HeaderIcon = ({ title, customClass, closeModal }) => {
  return (
    <div className={customClass ? customClass : ''}>
      <div className="configureModal__header">
        <span className="title">{title}</span>
        <img
          src={cross}
          alt="crossIcon"
          onClick={closeModal}
          style={{
            position: 'absolute',
            right: '5px',
            top: '11px'
          }}
        />
      </div>
    </div>
  );
};

export default HeaderIcon;
