export default function getInputClassName({
  isSecondary,
  deletable,
  isDisabled,
  value,
  error
}) {
  // console.log('TRIM',typeof(value))
  const empty =
    !value || (typeof value === 'string' && value.trim().length === 0);
  let classes = [];
  let skip = false;
  if (deletable) isSecondary = true;

  if (isSecondary) classes.push('secondary-field');
  else classes.push('primary-field');

  if (isDisabled) {
    classes.push('locked');
    skip = true;
  }

  if (!skip) {
    if (empty) classes.push('empty');
    else classes.push('not-empty');

    if (error) classes.push('invalid');
  }

  return classes.join(' ');
}
