import STORE_ROUTES, { URL_PREFIX } from 'config/nav/store/routes';

const BASE_PATH = STORE_ROUTES.OVERVIEW.ROOT;

export default {
  URL_PREFIX,
  OVERVIEW: {
    ROOT: BASE_PATH
  },
  ITEM_SERVICE: {
    ROOT: `${URL_PREFIX}/service/:serviceId`,
    RELATED_REQUESTS: `${URL_PREFIX}/service/:serviceId/related`
  }
};
