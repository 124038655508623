import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { ProtectedLink } from 'components';
import { ReactComponent as InfoIcon } from 'assets/img/icons/warning_darker.svg';
import ROUTES from 'config/nav/admin/routes';

export default function UsageAlert({ remaining = 0 }) {
  const className = cn(styles.container, { [styles.danger]: remaining <= 3 });

  const tooltipMessage = `Your free plan ${
    remaining <= 0 ? 'has expired' : 'is about to expire'
  }. Please notify your superuser.`;

  return (
    <div className={className}>
      <div className={styles.content}>
        <InfoIcon />
        <p>Only {remaining} returns left for this month.</p>
      </div>
      <ProtectedLink
        _key="admin.submenu.settings.submenu.manage_subscription.enabled"
        to={ROUTES.SETTINGS.MANAGE_SUBSCRIPTION}
        className={styles.upgradeLink}
        tooltipPosition="right"
        tooltipFade={false}
        tooltipMessage={tooltipMessage}
      >
        Upgrade Plan Now
      </ProtectedLink>
    </div>
  );
}
