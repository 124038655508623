import React, { useContext } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';
import { ReactComponent as GreenLeafIcon } from 'assets/img/icons/green-leaf.svg';

export default function KeepItemBanner() {
  const { retex } = useContext(RetexContext);

  const isKeepItem = _get(retex, 'is_keep_item', false);

  if (!isKeepItem) return null;

  return (
    <UiKit.GridRow
      gap="15"
      templateColumns="30px 1fr"
      className={styles.container}
    >
      <GreenLeafIcon />
      <UiKit.GridRow templateColumns="1fr">
        <strong>This is a green return.</strong>
        <span>The shopper gets to keep this item.</span>
      </UiKit.GridRow>
    </UiKit.GridRow>
  );
}
