import React from 'react';
import _get from 'lodash/get';

import { If } from 'utils';
import { FeatureUpsell, PageScroll, Toggle } from 'components';
import { InfoLink } from 'components/Tables/CustomTable';
import { createTooltipId } from '../../methods';

export default function Card({
  data = {},
  index = 0,
  disabled = false,
  onToggle = () => {}
}) {
  const id = _get(data, 'id', '');
  const title = _get(data, 'title', '');
  const isLocked = _get(data, 'isLocked', '');
  const isEnabled = _get(data, 'is_enabled', false);
  const description = _get(data, 'description', '');
  const docUrl = _get(data, 'documentation_url', '');
  const featureType = _get(data, 'feature_type', '');
  const featureSlug = _get(data, 'feature_slug', false);

  const tooltipId = createTooltipId(title);
  const hashId = featureType.replace(/_/g, '-');

  const toggleHandler = () => onToggle(id);

  return (
    <li
      style={{ animationDelay: `${index * 0.08}s` }}
      className="additional-features__listItem"
    >
      <PageScroll.Section
        hashId={hashId}
        disableHighlight
        className="additional-features__section"
      >
        <div className="additional-features__highlightWrapper">
          <PageScroll.Highlight
            delay={700}
            className="additional-features__highlight"
          >
            <FeatureUpsell.Container
              hasAccess={!isLocked}
              className="additional-features__listItem--container"
            >
              <div className="additional-features__listItem--content">
                <div className="additional-features__listItem--header">
                  <h5>{title}</h5>
                  <If test={!isLocked} otherwise={<FeatureUpsell.LockBadge />}>
                    <Toggle
                      size="lg"
                      isActive={isEnabled}
                      onClick={toggleHandler}
                      disabled={disabled}
                    />
                  </If>
                </div>
                <div className="additional-features__listItem--description">
                  <span>Description</span>
                  <p>{description}</p>
                </div>
              </div>
              <div className="additional-features__listItem--footer">
                <div className="additional-features__listItem--upsellBtn">
                  <If test={featureSlug}>
                    <FeatureUpsell.Modal.Trigger
                      featureSlug={featureSlug}
                      className="w-100"
                      wrapperClassName="w-100"
                    />
                  </If>
                </div>
                <InfoLink
                  url={docUrl}
                  id={tooltipId}
                  // placement="left"
                  className="mx-0"
                />
              </div>
            </FeatureUpsell.Container>
          </PageScroll.Highlight>
        </div>
      </PageScroll.Section>
    </li>
  );
}
