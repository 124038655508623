import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import Title from './Title';
import Value from './Value';

export default function Card({ className = '', children = null }) {
  return <div className={cn(styles.container, className)}>{children}</div>;
}

Card.Title = Title;
Card.Value = Value;
