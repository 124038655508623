import React from 'react';
import styles from './index.module.scss';

import { InfoBanner } from 'components';
import { useConnectStore } from '../../context/ConnectStoreContext';

export default function AlertBanner() {
  const { isError, errorMessage } = useConnectStore();

  if (!isError) return null;

  return (
    <InfoBanner isInvalid iconSize="20" gap="16" className={styles.container}>
      {errorMessage}
    </InfoBanner>
  );
}
