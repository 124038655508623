import React from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import _isString from 'lodash/isString';
import styles from './index.module.scss';

export default function TableHighlight({
  data = {},
  text = '',
  attribute = '',
  attributes = null,
  onClick = () => {},
  className = ''
}) {
  let value = attribute
    ? _get(data, attribute, null)
    : attributes
    ? getReducedValue(data, attributes)
    : null;

  if (!_isString(value)) return null;

  if (text) {
    const lowerCasedValue = _toLower(value);
    const lowerCasedSearchText = _toLower(text);
    const fragments = lowerCasedValue.split(lowerCasedSearchText);

    if (fragments.length > 1) {
      let [prefix, suffix = ''] = fragments;

      prefix = value.substring(0, prefix.length);
      suffix = value.substring(prefix.length + text.length);
      const searchedText = value.substring(
        prefix.length,
        prefix.length + text.length
      );
      value = (
        <>
          {prefix}
          <em className={styles.match}>{searchedText}</em>
          {suffix}
        </>
      );
    }
  }

  return (
    <span className={cn(styles.container, className)} onClick={onClick}>
      {value}
    </span>
  );
}

function getReducedValue(data = {}, attributes = []) {
  return attributes
    .reduce((acc, attribute) => {
      const value = _get(data, attribute, null);
      if (typeof value === 'string') {
        acc.push(value);
      }
      return acc;
    }, [])
    .join(' ');
}
