import cn from 'classnames';
import React from 'react';

import EditAction from './EditAction';

export default function Card({
  title = '',
  description = '',
  onIconClick = () => {},
  children = null,
  childrenWrapperClass = '',
  childrenWrapperStyle = {}
}) {
  const childrenWrapperCn = cn('ThemeSettingsCard__contentFrame', {
    [childrenWrapperClass]: childrenWrapperClass
  });

  return (
    <div className="ThemeSettingsCard">
      <div className="ThemeSettingsCard__header">
        <div className="ThemeSettingsCard__headerText">
          <h3>
            <strong>{title}</strong>
          </h3>
          <EditAction onClick={onIconClick} />
        </div>
        <p>{description}</p>
      </div>
      <div className={childrenWrapperCn} style={childrenWrapperStyle}>
        {children}
      </div>
    </div>
  );
}
