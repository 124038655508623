import React, { useContext, useRef } from 'react';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';
import { shipmentTrackingNav as nav } from 'config/nav';
import Nav from '../Nav';
import StickyFooter from '../StickyFooter';

export default function ShipmentTrackingNav() {
  const { appType } = useContext(AuthContext);

  const mainNav = useRef(nav.sidebarTop()).current;
  const footerNav = useRef(nav.sidebarFooter()).current;

  if (!appType.shipmentTracking) return null;

  return (
    <Nav homePath="/" config={mainNav}>
      <StickyFooter>
        <Nav config={footerNav} className={styles.footerNav} />
      </StickyFooter>
    </Nav>
  );
}
