// Used in JS Docs in useAccess[].hasSubFeatureAccess function

export const FEATURE_SLUGS =
  'SHOPPER_GROUPS_PRO' ||
  'SHOPPER_GROUPS_BASIC' ||
  'REFUND_METHODS_OPM' ||
  'REFUND_METHODS_GIFT_CARD' ||
  'CATALOG_EXCHANGE' ||
  'EXCHANGES' ||
  'EXCHANGE_RECOMMENDATIONS_PRO' ||
  'EXCHANGE_RECOMMENDATIONS_BASIC' ||
  'EXCHANGE_INCENTIVES' ||
  'HONOR_INVENTORY_POLICY' ||
  'DISCOUNT_FORWARDING' ||
  'HONOR_ORIGINAL_PRICE_ON_VARIANT_EXCHANGE' ||
  'EXCHANGE_ORDER_SERVICEABILITY' ||
  'APPLY_TAX_FOR_EXCHANGES' ||
  'AUTO_APPROVE_LOCKED_EXCHANGES_WHEN_INVENTORY_BACK_IN_STOCK' ||
  'PROMO_MANAGEMENT' ||
  'AUTOMATION_RULES_PRO' ||
  'AUTOMATION_RULES_BASIC' ||
  'RETURN_PROCESSING_FEE' ||
  'UNIT_RESTOCKING_FEE' ||
  'BONUS_CASH' ||
  'PAYMENT_WAIVE_OFF' ||
  'REQUEST_REASONS_BASIC' ||
  'REQUEST_REASONS_PRO' ||
  'SERVICE_WINDOW_GLOBAL' ||
  'SERVICE_WINDOW_REFUND_METHOD_SPECIFIC' ||
  'SERVICE_WINDOW_ORDER_DATE' ||
  'SERVICE_WINDOW_SKU_BASED' ||
  'SERVICE_WINDOW_PRODUCT_TYPE_BASED' ||
  'EMAIL_ALERTS' ||
  'TAG_BASED_RULES_BASIC' ||
  'TAG_BASED_RULES_PRO' ||
  'GIFT_RETURNS' ||
  'BXGY_ORDER_SERVICEABILITY_PAID_AND_FREE_ITEMS' ||
  'BXGY_ORDER_SERVICEABILITY_PAID_ITEMS_ONLY' ||
  'MULTI_CURRENCY_ORDER' ||
  'SEND_LABEL_DOMESTIC' ||
  'SEND_LABEL_INTERNATIONAL' ||
  'SEND_LABEL_SHIPMENT_PICKUP' ||
  'SEND_LABEL_QR_SUPPORT' ||
  'SHIPPING_METHODS_SELF_MANAGED_RETURN' ||
  'SHIPPING_METHODS_IN_STORE_RETURN' ||
  'INTERNATIONAL_ORDERS_SERVICEABILITY' ||
  'OFFER_PACKING_SLIPS' ||
  'EDIT_SERVICE_ADDRESS' ||
  'ENTERPRISE_SHIPPING' ||
  'STANDARD_BOX_DIMENSIONS_DYNAMIC_PACKAGE_WEIGHT_SETUP' ||
  'SHIPPING_AGGREGATORS' ||
  'CUSTOM_THEME' ||
  'CUSTOM_FONT' ||
  'CUSTOM_LOGIN_PAGE' ||
  'EMAIL_TEMPLATES' ||
  'STORE_POLICY_POP_UP' ||
  'TRACKING_PAGE_AD_MANAGEMENT' ||
  'REPORTS' ||
  'REPORTS_PRO' ||
  'ANALYTICS_DASHBOARD' ||
  'ANALYTICS_INSIGHTS' ||
  'API_TOKENS' ||
  'APP_USERS' ||
  'CUSTOM_INTEGRATIONS' ||
  'CUSTOM_SHOPPER_PORTAL_URL' ||
  'GREEN_RETURNS';

export const FEATURE_IDS = 'SHOPPER_GROUPS' || 'EXCHANGE_RECOMMENDATIONS';

// ? UNCOMMENT the comment block below to get the type safety while making changes to the FEATURE_ACCESS_MAP object
/**
 *  @type {Object<string, FEATURE_SLUGS[]>}
 */
export const FEATURE_ACCESS_MAP = {
  SHOPPER_GROUPS: ['SHOPPER_GROUPS_PRO', 'SHOPPER_GROUPS_BASIC'],
  EXCHANGE_RECOMMENDATIONS: [
    'EXCHANGE_RECOMMENDATIONS_PRO',
    'EXCHANGE_RECOMMENDATIONS_BASIC'
  ]
};
