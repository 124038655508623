import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { localStore } from 'utils';

const CONTEXT_DEFAULT = {
  retainedFilters: null,
  setRetainedFilters: () => {}
};

const TableFiltersContext = createContext(CONTEXT_DEFAULT);

/**
 * @param {import('utils/types').TableFiltersStorageKey} [localStorageKey] Key to be used to store filters in local storage
 */
export const useTableFilters = localStorageKey => {
  const contextData = useContext(TableFiltersContext);
  return !localStorageKey ? CONTEXT_DEFAULT : contextData;
};

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children Children to be rendered
 * @param {import('utils/types').TableFiltersStorageKey} props.localStorageKey Key to be used to store filters in local storage
 */
export function TableFiltersProvider({
  children = null,
  localStorageKey = '',
  fallbackFilters = null
}) {
  const location = useLocation();
  const { listen } = useHistory();

  const [filters, setFilters] = useState(
    JSON.parse(localStore.get(localStorageKey)) || fallbackFilters
  );
  const mountPathnameRef = useRef(location.pathname);

  useEffect(() => {
    if (localStorageKey) {
      const unsubscribe = listen(newLocation => {
        const mounteRoute = mountPathnameRef.current;
        if (!newLocation.pathname.startsWith(mounteRoute)) {
          setFilters(currFilters => {
            const newFilters = { ...currFilters };
            delete newFilters.search;
            delete newFilters.page;
            localStore.put(localStorageKey, JSON.stringify(newFilters));
            return newFilters;
          });
        }
      });

      return () => unsubscribe();
    }
  }, [listen, localStorageKey]);

  const setRetainedFilters = useCallback(
    filters => {
      setFilters(filters);
      localStore.put(localStorageKey, JSON.stringify(filters));
    },
    [localStorageKey]
  );

  return (
    <TableFiltersContext.Provider
      value={{
        retainedFilters: filters,
        setRetainedFilters
      }}
    >
      {children}
    </TableFiltersContext.Provider>
  );
}
