import React from 'react';
import { Collapse } from 'reactstrap';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { CustomTableRows } from 'components/Tables/CustomTable';
import LineItemsTableRow from '../LineItemsTableRow';
import LoaderSkeleton from '../LoaderSkeleton';

export default function LineItemsTableRows({
  osId = '',
  osName = '',
  isOpen = false,
  retexes = [],
  processingId = null,
  isFetching = false,
  isDisabled = false,
  onDisable = () => {},
  onUpdateRetex = () => {},
  generateRowData = () => null,
  renderRetexTitle = () => null,
  onAfterProcessing = () => {}
}) {
  // const isLoading = isFetching && !retexes.length;

  const rows = retexes.map((retex, index) => {
    const id = _get(retex, 'id', index);
    const isProcessing = id === processingId;
    return (
      <LineItemsTableRow
        key={id}
        osId={osId}
        data={retex}
        osName={osName}
        onDisable={onDisable}
        onUpdate={onUpdateRetex}
        isDisabled={isDisabled}
        isProcessing={isProcessing}
        generateRowData={generateRowData}
        renderRetexTitle={renderRetexTitle}
        onAfterProcessing={onAfterProcessing}
      />
    );
  });

  return (
    <Collapse isOpen={isOpen} timeout={100}>
      <LoaderSkeleton isLoading={isFetching}>
        <CustomTableRows className={styles.container}>{rows}</CustomTableRows>
      </LoaderSkeleton>
    </Collapse>
  );
}
