import React, { useMemo } from 'react';
import cn from 'classnames';
import _isFunction from 'lodash/isFunction';
import styles from './index.module.scss';

import { InteractConfigWrapper, Toggle, UiKit } from 'components';

export default function IncentiveConfig({
  show = true,
  title = '',
  description = '',
  isActive = false,
  name = '',
  onToggle = null,
  containerClassName = '',
  contentClassName = '',
  children = null,
  highlight = false,
  onClick = () => {},
  helpText = '',
  childrenContentClassName = ''
}) {
  const containerClass = useMemo(() => {
    return cn(styles.container, {
      [containerClassName]: !!containerClassName,
      [styles.highlight]: highlight
    });
  }, [containerClassName, highlight]);

  const childrenContentClass = useMemo(() => {
    return cn(styles.content, {
      [childrenContentClassName]: !!childrenContentClassName
    });
  }, [childrenContentClassName]);

  const showToggle = useMemo(() => {
    return onToggle && _isFunction(onToggle);
  }, [onToggle]);

  if (!show) return null;

  return (
    <InteractConfigWrapper
      helptext={helpText}
      contentClassName={contentClassName}
    >
      <div className={containerClass} onClick={onClick}>
        <UiKit.GridRow gap="24" templateColumns="auto 1fr">
          <div className={styles.title}>
            <strong>{title}</strong>
            <span>{description}</span>
          </div>
          {showToggle && (
            <Toggle
              name={name}
              isActive={isActive}
              onClick={onToggle}
              className={styles.switch}
            />
          )}
        </UiKit.GridRow>
        <div className={childrenContentClass}>{children}</div>
      </div>
    </InteractConfigWrapper>
  );
}
