import _get from 'lodash/get';

const prefix = '/retailer';

export const API_ENDPOINTS = {
  masterData: '/retailer/masterData/',
  planFeatures: '/retailer/billing-plans/',
  login: '/auth/retailer/console/emailPassword/',
  logout: '/auth/retailer/logout/',
  configuration: '/retailer/configuration/',
  subscription: '/retailer/subscription/',
  retailerListByEmail: '/auth/retailer/console/emailLookup/',
  retailerVerify: '/auth/retailer/verify/',
  termsAndConditions: '/retailer/terms-and-conditions/',
  fileUpload: '/retailer/file-upload/',
  retex: {
    get: '/retailer/returnExchanges/',
    facets: '/retailer/returnExchanges/facets/',
    process: '/retailer/process-retex/'
  },
  requests: {
    getList: '/retailer/packages/list/',
    facets: '/retailer/packages/facets/',
    get: '/retailer/packages/',
    trigger_emails: '/retailer/trigger-email/',
    timeline: '/retailer/packages/[id]/timeline/',
    generate_label: '/retailer/packages/[id]/generate-label/'
  },
  retexes: {
    getList: '/retailer/order-services/[id]/retexes/',
    get: '/retailer/retexes/',
    timeline: '/retailer/retex/timeline',
    appliedRules: '/retailer/retexes/[id]/applied-rules/',
    manualDeduction: '/retailer/returnExchanges/manual-deduction/'
  },
  orderService: {
    get: '/retailer/order-services/',
    validate: '/customer/orderService/validate/',
    getInitAdditionalInfo: '/customer/orderServiceInitAdditionalInfo/'
  },
  init: {
    onboardingStatus: `${prefix}/retailerOnboardingStatus/`
  },
  onboarding: {
    serviceableDays: '/retailer/retailer_settings/init_attributes/',
    accepted: '/retailer/onboarding/accepted/',
    slack: {
      alert: '/internal/slack/message/'
    }
  },
  billing: {
    plans: '/retailer/subscription-plans/',
    stripe: {
      subscribe: '/retailer/subscribe/stripe/',
      createPaymentIntent: '/retailer/subscribe/stripe/payment-intent/',
      createCustomerPortalSession: '/retailer/subscribe/stripe/manage/'
    },
    shopify: {
      subscribe: '/retailer/subscribe/shopify/'
    }
  },
  retailerNotifications: '/communications/notifications/',
  authentication: {
    resetPassword: '/auth/retailer/console/reset_password/',
    requestLink: '/auth/retailer/console/request_password_reset/',
    externalLogin: '/auth/retailer/external-login/'
  },
  changePassword: '/auth/retailer/console/change_password/',

  // TODO: Cleanup code > DEPRECATED 👇
  // gettingStarted: {
  //   mark: '/retailer/gettingStarted/accepted'
  // },
  // -------------------

  overview: {
    requestCount: {
      get: '/retailer/packages/count/'
      // _get: '/retailer/returnExchanges/count/',
    },
    metrics: {
      get: '/dashboard/overview/'
    },
    orders: {
      updateStatus: {
        qualification: '/retailer/qualificationApproval/',
        approveExchange: '/retailer/approveExchange/',
        refund: '/retailer/approveRefund/',
        rejectOrderService: '/retailer/order_service/rejection'
      }
    },
    retex: {
      cancel: `${prefix}/cancelRequest/`,
      hold: `${prefix}/order_service/hold/`
    },
    stats: {
      orders: {
        numbers: '/retailer/overview/stats',
        charts: '/retailer/overview/daywise/stats'
      }
    }
  },
  exception: {
    create: '/retailer/qualificationException/'
  },
  retailerNotes: {
    get: '/retailer/request-notes/',
    post: '/retailer/request-notes/',
    delete: '/retailer/request-notes/'
  },
  shopperComments: {
    get: '/customer/request-notes/'
  },
  elastic: {
    orders: {
      get: '/retailer/es/orders/list/',
      facets: '/retailer/es/orders/facets/'
    }
  },
  orders: {
    get: '/retailer/orders/',
    facets: '/retailer/orders/facets/',
    getDetailsWithShopifyId: '/retailer/orders/oid/',
    getFulfilments: {
      v1: '/retailer/orders/[id]/fulfillments/',
      v2: '/retailer/order/[id]/fulfillments/'
    },
    __STALE__getRetexes: '/retailer/orders/[id]/returnexchanges/',
    getRetexes: '/retailer/orders/[id]/retexes/',
    getOrder: '/customer/orderServiceInitialization/',
    createException: '/retailer/rejectionException/',
    // createRequest: '/customer/orderService/',  // TODO: DEPRECATED - To be removed in time
    getOrderService: {
      default: '/customer/orderService/',
      temp: '/customer/orderService/temp/'
    },
    packages: '/retailer/orders/[id]/packages/',
    requests: {
      get: '/customer/requests/',
      post: '/customer/requests/',
      put: '/customer/requests/',
      address: '/customer/requests/[id]/address/',
      shipppingMethods: {
        get: '/customer/requests/[id]/shipping-methods/',
        put: '/customer/requests/[id]/shipping-methods/',
        enabled: {
          get: '/customer/shipping-methods/enabled/'
        }
      },
      refundMethods: {
        get: '/customer/requests/[id]/refund-methods/',
        put: '/customer/requests/[id]/refund-methods/'
      }
    },
    totalSavings: {
      get: '/customer/orderService/[id]/totalSavings/'
    },
    getServiceAdditionalInfo: {
      default: '/customer/serviceAdditionalInfo/',
      temp: '/customer/serviceAdditionalInfo/temp/'
    },
    getShippingRates: '/customer/orderService/[serviceId]/shippingRates/',
    getHomePickupInfo: '/customer/package-pickup/',
    getQrLabelSupportedLocations: '/customer/carrierFacility/',
    getRetailerStoreInfo: '/customer/retailerStore/',
    cancelRequest: '/retailer/cancelRequest/',
    resendLabel: '/retailer/resendLabel/',
    area: {
      cities: '/shipping/area/cities/',
      countries: '/shipping/area/countries/',
      states: '/shipping/area/states/'
    }
  },
  customer: {
    order: '/customer/order/'
  },
  orderVariants: {
    get: '/retailer/order_variant_maps/',
    post: '/retailer/order-variant-maps/'
  },
  products: {
    get: '/retailer/products/',
    post: '/retailer/products/',
    getAll: '/retailer/products-search/',
    getCatalog: '/retailer/products-search-catalogue/'
  },
  productVaraints: {
    get: '/retailer/product_variants/',
    post: '/retailer/product_variants/'
  },
  settings: {
    general: {
      branding: {
        profile: '/retailer/storeProperties/'
      },
      serviceRequestPrefix: '/retailer/storeProperties/miscellaneous/'
    },
    billing: {
      getPaymentMethods: '/retailer/payment_methods/',
      getPaymentHistory: '/retailer/billing_history/'
    },
    carrier_config: {
      shipping: {
        getActiveCarriers: '/shipping/carriers/active_carriers/',
        getAllAvailableCarriers: '/shipping/carriers/available_carriers/',
        getAllAvailableZones: '/shipping/zones/',
        updateSla: '/shipping/carriers/active_carriers/bulk_weight_class/'
      },
      box: {
        list: '/shipping/package/',
        default: '/shipping/backup-box/'
      },
      rule: {
        list: '/shipping/rules/reverse/',
        getLanes: '/shipping/lanes/'
      }
    },
    locations: {
      stores: {
        list: '/retailer/store/',
        create: '/retailer/store/',
        delete: '/retailer/store/',
        update: '/retailer/store/'
      },
      warehouses: {
        list: '/retailer/warehouses/',
        create: '/retailer/warehouses/',
        delete: '/retailer/warehouses/',
        update: '/retailer/warehouses/'
      },
      facilities: {
        list: '/shipping/facilities/',
        create: '/shipping/facilities/',
        update: '/shipping/facilities/',
        defaultWarehouse: '/shipping/facilities/default/',
        configurableProductSearch:
          '/shipping/facilities/configurable/products/',
        requestReasons: '/shipping/facilities/request-reasons/',

        locationsMap: {
          getContinent: '/shipping/area/continents/',
          getCountries: '/shipping/area/countries/',
          getStates: '/shipping/area/states/',
          getCities: '/shipping/area/cities/'
        }
      },
      zones: {
        list: '/shipping/zones/',
        create: '/shipping/zones/',
        update: '/shipping/zones/'
      },
      remoteLocations: '/retailer/locations/'
    },
    shipmentIntegrations: {
      carriers: {
        list: '/shipping/carriers/active_carriers/',
        slaLookup: '/shipping/carriers/lookup_sla/'
      }
    },
    policy: {
      get: '/retailer/retailer_settings/init_attributes/',
      update: '/retailer/retailer_settings/init_attributes/'
    },
    rules: {
      store: '/retailer/storeRule/',
      custom: '/retailer/customRule/',
      data: '/retailer/masterData/'
    },
    qc: {
      get: '/retailer/warehouseQualityCheckRule/'
    },
    users: {
      get: '/retailer/user/',
      post: '/retailer/user/',
      put: '/retailer/user/',
      delete: '/retailer/user/',
      permissions: '/retailer/user-permission/'
    },
    refund: {
      getExchangeRules: '/retailer/updateExtraSettingParam/',
      putExchangeRules: '/retailer/updateExtraSettingParam/'
    },
    account: {
      getAll: '/retailer/aspenAccount/'
    },
    shipmentTracking: {
      getCampaign: '/retailer/shipment/ads',
      updateCampaign: '/retailer/shipment/ads',
      getStatus: '/retailer/shipment/tracking/toggle',
      updateStatus: '/retailer/shipment/tracking/toggle'
    },
    shipping: {
      lanes: {
        get: '/shipping/lanes/',
        post: '/shipping/lanes/',
        patch: '/shipping/lanes/'
      },
      lookup: {
        get: '/shipping/coordinateLookup/'
      },
      bulkLookup: {
        processes: '/shipping/bulkDryRun/',
        process: '/shipping/bulkDryRun/[id]/',
        resloveAddress: '/shipping/bulkDryRun/addresses/',
        downloadReport: '/reports/retailer/bulk_dry_run/'
      }
    }
  },
  configure: {
    automationRules: {
      get: '/retailer/automation-rules/',
      post: '/retailer/automation-rules/',
      put: '/retailer/automation-rules/',
      delete: '/retailer/automation-rules/',
      getActions: '/retailer/automation-rules/actions/'
    },
    reasons: {
      get: '/retailer/serviceRequestReasons/',
      put: '/retailer/serviceRequestReasons/',
      post: '/retailer/serviceRequestReasons/',
      delete: '/retailer/serviceRequestReasons/',
      reorder: '/retailer/serviceReasonsOrdering/',
      getProductTypes: '/retailer/productTypes/'
    },
    exchangeRecommendation: {
      get: '/retailer/recommendations/',
      post: '/retailer/recommendations/',
      put: '/retailer/recommendations/',
      patch: '/retailer/recommendations/',
      delete: '/retailer/recommendations/',
      rules: {
        get: '/retailer/recommendation-rules/'
      },
      reasons: {
        get: '/retailer/recommendation-reasons/'
      },
      customer: {
        get: '/customer/product-recommendations/'
      }
    },
    exchangeIncentives: {
      get: '/retailer/exchange-incentive/',
      patch: '/retailer/exchange-incentive/',
      types: {
        get: '/retailer/exchange-incentive/types/'
      }
    },
    featureFlags: {
      get: '/retailer/features/',
      patch: '/retailer/features/'
    },
    deductions: {
      shipmentMethod: '/retailer/retailerShippingMethod/',
      shipmentMethodDetails: '/retailer/retailerShippingMethod/detail/'
    },
    refundMethods: {
      get: '/retailer/payment-methods/',
      put: '/retailer/payment-methods/',
      post: '/retailer/payment-methods/',
      delete: '/retailer/payment-methods/'
    },
    catalogSettings: {
      GET: '/retailer/catalogue_settings/',
      PUT: '/retailer/catalogue_settings/',
      sync: '/retailer/catalogue_settings/sync/'
    },
    paymentWaiveOff: {
      get: '/retailer/OrderServiceThresholds/',
      put: '/retailer/OrderServiceThresholds/'
    },
    processingFee: {
      get: '/retailer/processing/deduction/',
      put: '/retailer/processing/deduction/'
    },
    serviceWindow: {
      global: '/retailer/service-window/global/',
      exceptions: {
        orderDate: '/retailer/serviceability/extension/',
        productSkus: {
          root: '/retailer/service-window/product-variants/',
          validate: '/retailer/service-window/product-variants/validate/'
        },
        productTypes: {
          root: '/retailer/service-window/product-types/',
          validate: '/retailer/service-window/product-types/validate/'
        }
      }
    },
    exchangeCredits: {
      get: '/retailer/processing/credit/',
      put: '/retailer/processing/credit/'
    },
    emailAlerts: {
      get: '/retailer/email-alerts/',
      post: '/retailer/email-alerts/'
    },
    emailCommunications: {
      templates: {
        list: '/communications/email/settings/',
        enable: '/communications/email/settings/',
        details: '/communications/email/templates/',
        update: '/communications/email/templates/',
        components: '/communications/email/components/',
        sendEmail: '/communications/email/test-email/'
      }
    },
    aggregator: {
      list: '/shipping/aggregators/',
      details: '/shipping/aggregators/',
      update: '/shipping/aggregators/',
      setup_status: '/shipping/aggregators/setup-status/',
      carriers_list: '/shipping/aggregators/[id]/carriers/',
      sync_carriers: '/shipping/aggregators/[id]/sync/',
      sla_update: '/shipping/aggregators/carriers/'
    },
    feesAndIncentives: {
      orderProcessingFees: '/retailer/return-processing-fee/',
      unitRestockingFees: '/retailer/unit-restocking-fee/',
      refundMethodProcessingFees:
        '/retailer/deductions/refund-method-processing-fees/',
      shippingFees: '/retailer/deductions/shipping-fees/',
      incentiveAmounts: '/retailer/deductions/incentive-amounts/'
    },
    tagRules: {
      get: '/retailer/tag-rules/',
      post: '/retailer/tag-rules/',
      delete: '/retailer/tag-rules/',
      facets: '/retailer/tag-rules/facets/',
      actionTypeMap: '/retailer/tag-rules/action-type-map/'
    },
    disposition: {
      get: '/retailer/disposition/',
      put: '/retailer/disposition/'
    },
    shopperGroups: {
      get: '/retailer/shopper-groups/',
      delete: '/retailer/shopper-groups/',
      put: '/retailer/shopper-groups/',
      incentives: {
        get: '/retailer/shopper-groups/[id]/incentives/'
      },
      count: {
        get: '/retailer/shopper-groups/count/'
      },
      priorities: {
        put: '/retailer/shopper-groups/priorities/'
      }
    },
    configuredShopperGroups: {
      get: '/retailer/shopper-group-configuration/'
    },
    bonusCashIncentive: {
      get: '/retailer/bonus-cash-incentive/',
      put: '/retailer/bonus-cash-incentive/',
      post: '/retailer/bonus-cash-incentive/',
      delete: '/retailer/bonus-cash-incentive/'
    }
  },
  storePolicy: {
    get: '/retailer/custom_popup/',
    post: '/retailer/custom_popup/'
  },
  shipments: {
    get: '/retailer/fulfillments/'
  },
  storeReturns: {
    storeInfo: '/retailer/physicalStoreInfo/',
    list: '/retailer/storeOrderService/',
    getServiceDetails: '/retailer/storeOrderServiceDetails/',
    putServiceDetails: '/retailer/storeOrderService/'
  },
  warehouse: {
    storeInfo: '/retailer/physicalStoreInfo/',
    overview: {
      markAsDelivered: '/retailer/approveDelivery/',
      '3PlException': '/logistics/tpl_exception/'
    },
    inspection: {
      getRules: '/retailer/warehouseQualityCheck/',
      putRules: '/retailer/warehouseQualityCheck/',
      authorize: '/retailer/warehouseOrderService/'
    }
  },
  revenue: {
    promo: {
      get: '/retailer/promo/',
      create: '/retailer/promo/',
      delete: '/retailer/promo/',
      update: '/retailer/promo/',
      markVariants: '/retailer/mark/promo/variants/'
    }
  },
  analytics: {
    dashboard: {
      retex: '/dashboard/analytics/retex-analysis-by-date/',
      processedRequests: '/dashboard/analytics/processed-requests-breakdown/',
      ageOfOrders:
        '/dashboard/analytics/service-window-retex-analysis-by-date/',
      timeSinceOrder:
        '/dashboard/analytics/service-window-retex-analysis-by-days-since-order-placed/',
      shopperTypeOrders:
        '/dashboard/analytics/orders-revenue-breakdown-by-customer-type/',
      shopperTypeDisposition: '/dashboard/analytics/orders-by-customer-type/',
      deviceTypes: '/dashboard/analytics/device-type/',
      timeToReturn: '/dashboard/analytics/time-taken-return-package/'
    },
    insights: {
      highestOrderVolume: '/dashboard/insights/most-bought-products-breakdown/',
      mostReturnedProducts:
        '/dashboard/insights/most-returned-products-breakdown/',
      productsVsRequestReasons:
        '/dashboard/insights/products-vs-request-reasons/',
      productSearch: '/dashboard/insights/products/search/',
      topRequestReasons: '/dashboard/insights/top-request-reasons/',
      topRequestReasonsExport:
        '/dashboard/insights/top-request-reasons/export/',
      topReturnedProducts: '/dashboard/insights/top-returned-products/',
      topReturnedProductsExport:
        '/dashboard/insights/top-returned-products/export/'
    },
    userReports: {
      cannedReports: {
        get: '/retailer-reports/canned-reports/'
      },
      retailerReports: {
        get: '/retailer-reports/reports/',
        post: '/retailer-reports/reports/',
        patch: '/retailer-reports/reports/',
        history: '/retailer-reports/reports/[id]/previous/',
        zipDownload: '/retailer-reports/download/zip/',
        webhookValidate: '/retailer-reports/webhook/status/'
      }
    }
  },
  shipmentTracking: {
    config: {
      get: '/shipment-tracking/tracking-page/config/',
      put: '/shipment-tracking/tracking-page/config/'
    },
    recommendations: {
      get: '/shipment-tracking/tracking-page/recommendation-rules/'
    }
  },
  tracking_rabbit: {
    shipment_tracking_status: {
      get: '/shipment-tracking/statistics/shipping-status/'
    },
    tracking_page_events: {
      post: '/shipment-tracking/analytics/'
    },
    tracking_email_events: {
      post: '/shipment-tracking/analytics/'
    }
  },
  manage_apis: {
    public_api_token: {
      get: '/retailer/public-api-token/',
      post: '/retailer/public-api-token/',
      put: '/retailer/public-api-token/',
      delete: '/retailer/public-api-token/'
    }
  },
  notifications: {
    acknowledge: '/retailer/notification/acknowledge/'
  }
};

export function API(key) {
  const endPoint = _get(API_ENDPOINTS, key, null);

  if (!endPoint) throw new Error('Key not found in the API endpoints map');

  return endPoint;

  // const keyArray = key.split('.');
  // return findValue(keyArray, API_ENDPOINTS);
}

// function findValue(arr, obj) {
//   if (arr.length === 1) {
//     return obj[arr[0]];
//   }
//   var key = arr.splice(0, 1)[0];
//   return findValue(arr, obj[key]);
// }
