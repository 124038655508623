import React from 'react';
import cn from 'classnames';
import _isFunction from 'lodash/isFunction';
import styles from './index.module.scss';

import { Toggle, PageScroll, FeatureUpsell } from 'components';
import Actions from '../Tables/CustomTable/Actions';
import { useAccess } from 'utils/hooks';
import { If } from 'utils';

export default function SettingsCard({
  title = '',
  popupText = '',
  shepherdId = '',
  featureSlug = '',
  isActive = false,
  showActions = true,
  isToggleDisabled = false,
  isHighlighted = false,
  onToggle = null,
  onEdit = null,
  wrapperClassName = '',
  cardClassName = '',
  contentClassName = '',
  titleClassName = '',
  children = null,
  disabled = false
}) {
  const { hasSubFeatureAccess } = useAccess();

  const hasAccess = featureSlug ? hasSubFeatureAccess(featureSlug) : true;
  const containerClass = cn(
    styles.container,
    {
      [styles.fixTopLeftCorner]: !!popupText,
      [styles.disabled]: hasAccess && disabled,
      [styles.locked]: !hasAccess
    },
    wrapperClassName
  );

  const cardClass = cn(styles.card, cardClassName);
  const contentClass = cn(styles.content, contentClassName);
  const titleClass = cn('font-weight-bold', titleClassName);

  return (
    <div className={containerClass}>
      <If test={popupText}>
        <span className={styles.popup}>
          <strong>{popupText}</strong>
        </span>
      </If>
      <PageScroll.Highlight shepherdId={shepherdId} disabled={!isHighlighted}>
        <FeatureUpsell.Container hasAccess={hasAccess} className={cardClass}>
          <div className={styles.title}>
            <div className={titleClass}>{title}</div>
            <If test={hasAccess} otherwise={<FeatureUpsell.LockBadge />}>
              <If test={showActions}>
                <div className={styles.actions}>
                  <If test={_isFunction(onToggle)}>
                    <Toggle
                      size="lg"
                      isActive={isActive}
                      onClick={onToggle}
                      disabled={disabled || isToggleDisabled}
                    />
                  </If>
                  <If test={_isFunction(onEdit)}>
                    <Actions
                      customClass="ml-3"
                      permissions={{ edit: !disabled }}
                      edit={onEdit}
                    />
                  </If>
                </div>
              </If>
            </If>
          </div>
          <If test={children}>
            <div className={contentClass}>{children}</div>
          </If>
          <If test={featureSlug}>
            <FeatureUpsell.Modal.Trigger
              featureSlug={featureSlug}
              className="mt-4 w-100"
            />
          </If>
        </FeatureUpsell.Container>
      </PageScroll.Highlight>
    </div>
  );
}
