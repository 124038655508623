import React, { useContext, useState, useRef } from 'react';
import { Tooltip } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { formatPrice } from 'utils/formatters';
import { updateManualDeduction } from 'utils/services';
import { CustomToast, InfoBanner, UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';
import { getEstimatedRefund } from './methods';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/checkmark-purple.svg';
import { ReactComponent as CrossIcon } from 'assets/img/icons/close.svg';

export default function ManualDeduction({
  containerClassName = '',
  labelClassName = '',
  valueClassName = ''
}) {
  const {
    retex,
    orderService,
    isProcessing,
    onUpdateRetex,
    setIsDisabled,
    onUpdateOrderService
  } = useContext(RetexContext);
  const inputRef = useRef(null);

  const id = _get(retex, 'id', '');
  const status = _get(retex, 'status', '');
  const currency = _get(retex, 'currency', 'USD');
  const onHold = _get(orderService, 'on_hold', false);
  const osAmount = _get(orderService, 'payment_info.amount', 0);
  const manualDeduction = _get(retex, 'amount_breakdown.manual_deduction', 0);

  const [deduction, setDeduction] = useState(`${manualDeduction}`);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isInvalidDeduction, setIsInvalidDeduction] = useState(false);

  const estimatedRefund = getEstimatedRefund(retex);
  const isRefundPending = !onHold && status === 'AWAITING_REFUND_APPROVAL';
  const refundableAmount = Math.max(
    Math.min(estimatedRefund, Math.abs(osAmount) + manualDeduction),
    0
  );
  const formattedManualDeduction = formatPrice(manualDeduction, currency);
  const formattedRefundableAmount = formatPrice(refundableAmount, currency);
  const preventDeduction = isSaving || isProcessing || refundableAmount === 0;

  const onSaveDeduction = e => {
    e.preventDefault();
    if (preventDeduction) return;
    if (!isEditing) return setIsEditing(true);
    if (isInvalidDeduction || +deduction === manualDeduction) {
      setDeduction(`${manualDeduction}`);
      setIsInvalidDeduction(false);
      return setIsEditing(false);
    }

    setIsSaving(true);
    setIsDisabled(true);
    updateManualDeduction(
      { routeParam: id, manual_deduction: +deduction },
      data => {
        CustomToast({
          type: 'success',
          msg: 'Successfully updated the manual deduction.'
        });
        const osAmount = _get(data, 'os_amount', 0);
        const retexData = {
          amount_breakdown: {
            amount: _get(data, 'final_amount', 0),
            manual_deduction: _get(data, 'manual_deduction', 0)
          }
        };
        onUpdateRetex(retexData);
        onUpdateOrderService({ payment_info: { amount: osAmount } });
        setIsEditing(false);
      },
      err => {
        CustomToast({
          isNotified: err.notified,
          type: 'error',
          msg: 'Failed to update manual deduction'
        });
      },
      () => {
        setIsSaving(false);
        setIsDisabled(false);
      }
    );
  };

  const toggleTooltip = () => setShowTooltip(show => !show);

  const onDeductionAmountChange = (_, value) => {
    const amount = value ? Math.abs(value) : 0;
    setDeduction(`${amount}`);
    setIsInvalidDeduction(+amount > refundableAmount);
  };

  const inputClassName = cn(styles.input, {
    [styles.invalid]: isInvalidDeduction
  });

  const buttonClassName = cn(styles.editBtn, {
    green: isEditing && !isInvalidDeduction,
    [styles.invalid]: isEditing && isInvalidDeduction
  });

  const wrapperClassName = cn(styles.container, containerClassName);
  const contentClassName = cn(styles.row, containerClassName);
  const alertMessage =
    refundableAmount >= 0
      ? `The maximum amount that you can deduct from this request is ${formattedRefundableAmount}.`
      : 'Deductions not applicable as customer is supposed to pay for the exchange(s) in this package.';

  return (
    <div className={wrapperClassName}>
      <div className={contentClassName}>
        <div className={labelClassName}>Manual Deduction</div>
        <form className={styles.edit} onSubmit={onSaveDeduction}>
          {isRefundPending && refundableAmount >= 0 && (
            <>
              <button
                id="edit-deduction-btn"
                disabled={preventDeduction}
                onMouseOver={toggleTooltip}
                onMouseOut={toggleTooltip}
                className={buttonClassName}
              >
                {isEditing ? (
                  isInvalidDeduction ? (
                    <CrossIcon className={styles.crossIcon} />
                  ) : (
                    <CheckIcon className={styles.checkIcon} />
                  )
                ) : (
                  <EditIcon />
                )}
              </button>
              <Tooltip
                placement="top"
                trigger="hover"
                isOpen={showTooltip}
                target="edit-deduction-btn"
                toggle={toggleTooltip}
                fade={false}
              >
                {isEditing
                  ? isInvalidDeduction
                    ? 'Cancel'
                    : 'Save'
                  : 'Click here to edit this amount'}
              </Tooltip>
            </>
          )}
          {isEditing ? (
            <UiKit.Input
              inputRef={inputRef}
              type="number"
              step="0.01"
              value={deduction}
              onChange={onDeductionAmountChange}
              error={isInvalidDeduction}
              inputClassName={inputClassName}
              disabled={isSaving}
            />
          ) : (
            <span className={valueClassName}>- {formattedManualDeduction}</span>
          )}
        </form>
      </div>
      {isRefundPending && (
        <InfoBanner
          gap={15}
          iconSize={24}
          isInvalid={isInvalidDeduction}
          className={styles.alert}
          iconClassName={styles.icon}
          messageClassName={styles.alertMessage}
        >
          {alertMessage}
        </InfoBanner>
      )}
    </div>
  );
}
