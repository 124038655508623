import {
  useState,
  useRef,
  useContext,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _clone from 'lodash/cloneDeep';

import { CustomToast } from 'components';
import { AuthContext } from 'context/AuthContext';
import { getFeatureFlags, updateFeatureFlag } from 'utils/services';

export default function useFeatures({
  enabled = true,
  includeFeatures = [],
  excludeFeatures = []
} = {}) {
  const [features, setFeatures] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const modalData = useRef(null);
  const { updateState } = useContext(AuthContext);

  const includeFilters = useMemo(() => {
    return _isArray(includeFeatures) ? includeFeatures.toString() : '';
  }, [includeFeatures]);

  const excludeFilters = useMemo(() => {
    return _isArray(excludeFeatures) ? excludeFeatures.toString() : '';
  }, [excludeFeatures]);

  const loadData = useCallback(() => {
    if (enabled) {
      setIsError(false);
      setIsLoading(true);
      const params = {};
      if (includeFilters) params.filter = includeFilters;
      if (excludeFilters) params.exclude = excludeFilters;

      getFeatureFlags(
        params,
        features => setFeatures(features),
        () => setIsError(true),
        () => setIsLoading(false)
      );
    }
  }, [enabled, includeFilters, excludeFilters]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (_isArray(features) && !_isEmpty(features)) {
      const exchangeFeature = features.find(({ feature_type }) => {
        return feature_type === 'is_exchange_allowed';
      });
      if (exchangeFeature) {
        const isExchangeAllowed = _get(exchangeFeature, 'is_enabled', true);
        updateState({ isExchangeAllowed });
      }
    }
  }, [features, updateState]);

  const toggleFeature = () => {
    const featureId = _get(modalData.current, 'id', '');

    if (featureId) {
      const index = features.findIndex(({ id }) => id === featureId);
      const keyPath = [index, 'is_enabled'];
      const isEnabled = _get(features, keyPath, false);
      const newState = !isEnabled;

      setIsProcessing(true);
      updateFeatureFlag(
        { routeParam: featureId, is_enabled: newState },
        () => onToggleSuccess(keyPath, newState),
        err => onToggleFailure(err, newState),
        () => setIsProcessing(false)
      );
    }
  };

  const onToggleSuccess = (keyPath, isEnabled) => {
    const keyword = isEnabled ? 'enabled' : 'disabled';
    const featureTitle = _get(modalData.current, 'title', '');

    //update the feature
    const featuresList = _clone(features);
    _set(featuresList, keyPath, isEnabled);
    setFeatures(featuresList);

    // close the modal and clear modal data
    closeConfirmModal();
    const timer = setTimeout(() => {
      postConfirmModalClose();
      clearTimeout(timer);
    }, 300);

    CustomToast({
      type: 'success',
      msg: `Sucessfully ${keyword} the ${featureTitle} feature.`
    });
  };

  const onToggleFailure = (err, isEnabled) => {
    const keyword = isEnabled ? 'enable' : 'disable';
    const featureTitle = _get(modalData.current, 'title', '');
    CustomToast({
      isNotified: err.notified,
      type: 'error',
      msg: `Unable to ${keyword} the ${featureTitle} feature at the moment. Please try later!`
    });
  };

  const openConfirmModal = featureId => {
    const feature = features.find(({ id }) => id === featureId);
    if (feature) {
      modalData.current = feature;
      setIsConfirmModalOpen(true);
    }
  };

  const closeConfirmModal = () => setIsConfirmModalOpen(false);

  const postConfirmModalClose = () => (modalData.current = null);

  return {
    features,
    isError,
    isLoading,
    onReload: loadData,

    // Confrim Modal
    isConfirmModalOpen,
    modalData: modalData.current,
    isProcessing,
    openConfirmModal,
    closeConfirmModal,
    postConfirmModalClose,
    toggleFeature
  };
}
