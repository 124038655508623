import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import _isPlainObject from 'lodash/isPlainObject';
import styles from './index.module.scss';

import RecordsLoader from './RecordsLoader';
import { ReactComponent as TriangleIcon } from 'assets/img/icons/value-up.svg';

export default function CycleRecords({
  id = null,
  ids = [],
  dataMap = {},
  isFetching = false,
  onChange = () => {}
}) {
  const [dataset, setDataset] = useState({});

  const totalRecords = ids.length;
  const disabled = totalRecords <= 1;
  const data = _get(dataset, id, {});
  const position = _get(data, 'position', 1);

  useEffect(() => {
    if (!isFetching && _isPlainObject(dataMap)) {
      const dataset = Object.entries(dataMap).reduce(
        (acc, [key, value], index) => {
          acc[key] = { ...value, position: index + 1 };
          return acc;
        },
        {}
      );
      setDataset(dataset);
    }
  }, [isFetching, dataMap]);

  const gotoRecord = index => {
    const id = ids[index];
    const record = _get(dataset, id, null);
    onChange(record);
  };

  const gotoPrevious = () => {
    if (disabled) return;
    const index = position - 2 < 0 ? totalRecords - 1 : position - 2;
    gotoRecord(index);
  };

  const gotoNext = () => {
    if (disabled) return;
    const index = position >= totalRecords ? 0 : position;
    gotoRecord(index);
  };

  return isFetching ? (
    <RecordsLoader />
  ) : (
    <div className={styles.container}>
      <span className={styles.title}>
        Request {position} of {totalRecords}
      </span>
      <div className={styles.actions}>
        <button disabled={disabled} onClick={gotoPrevious}>
          <TriangleIcon />
        </button>
        <button disabled={disabled} onClick={gotoNext}>
          <TriangleIcon />
        </button>
      </div>
    </div>
  );
}

CycleRecords.Loader = RecordsLoader;
