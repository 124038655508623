import _cloneDeep from 'lodash/cloneDeep';

import { get, localStore, logError } from 'utils';

export default function getMasterData(
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  get({ apiKey: 'masterData', noTokenRequired: true })
    .then(({ data }) => {
      if (!data) {
        throw new Error('Failed to fetch the master data');
      }
      const formattedData = formatData(data);
      if (!formattedData) {
        throw new Error('Failed to format the the master data');
      }
      localStore.put('dictionary', JSON.stringify(formattedData));
      onSuccess(data);
    })
    .catch(err => {
      onError(err);
      logError(err);
    })
    .finally(onFinally);
}

function formatData(data) {
  const masterData = _cloneDeep(data);
  const object = {};
  try {
    var keys = Object.keys(masterData);
    keys.forEach(key => {
      var itemObj = {};
      var itemArr = [];
      if (Array.isArray(masterData[key])) {
        masterData[key].forEach(item => {
          if (typeof item === 'string') {
            itemArr.push(item);
          } else {
            itemObj[item.value] = item.text;
          }
        });
      } else {
        itemObj = masterData[key];
      }
      if (itemArr.length) {
        object[key] = Object.assign([], itemArr);
      } else {
        object[key] = Object.assign({}, itemObj);
      }
    });
    return {
      ...object,
      paymentWaiveOff: {
        infoText: 'We have waived off all the charges for this exchange order'
      }
    };
  } catch (e) {
    return null;
  }
}
