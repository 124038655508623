import React, { useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

export default function PieChartTooltip({
  datum = {},
  className = '',
  formatTitle = () => '',
  formatValue = () => ''
}) {
  const data = _get(datum, 'data', '');
  const color = _get(datum, 'color', '');
  const label = _get(data, 'label', '');
  const formattedLabel = formatTitle(data) || label;
  const value = _get(data, 'value', '');
  const arcAngle = _get(datum, 'arc.angleDeg', 0);
  const formattedValue = formatValue(data) || value;

  const percentage = useMemo(() => {
    const percent = (arcAngle / 360) * 100;
    return `${percent.toFixed(2)}%`;
  }, [arcAngle]);

  const wrapperClassName = cn(styles.tooltip, { [className]: !!className });

  return (
    <div className={wrapperClassName}>
      <div className={styles.title}>
        <div style={{ backgroundColor: color }} />
        <strong>{formattedLabel}</strong>
      </div>
      <ul className={styles.content}>
        <li>{formattedValue}</li>
        <li>Precentage: {percentage}</li>
      </ul>
    </div>
  );
}
