import getScrollFrame from './getScrollFrame';

export default function scrollToTop(scrollOptions = {}) {
  const scrollFrame = getScrollFrame();

  if (scrollFrame) {
    scrollFrame.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
      ...scrollOptions
    });
  }
}
