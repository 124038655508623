export function waitForElementToRenderOnDom(id) {
  return new Promise(resolve => {
    if (document.getElementById(id)) {
      return resolve(document.getElementById(id));
    }

    const observer = new MutationObserver(mutations => {
      if (document.getElementById(id)) {
        observer.disconnect();
        resolve(document.getElementById(id));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

export function checkIfElementRemovedFromDom(id) {
  var inDom = document.body.contains(document.getElementById(id));
  return new Promise(resolve => {
    const observer = new MutationObserver(function(mutations) {
      if (document.body.contains(document.getElementById(id))) {
        if (!inDom) {
        }
        inDom = true;
      } else if (inDom) {
        inDom = false;
        resolve(id);
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}
