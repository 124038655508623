import React, { useRef } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';
import styles from './index.module.scss';

import { InfoLink } from 'components/Tables/CustomTable';
import { THEME } from '../stub';
import { ReactComponent as CheckIcon } from 'assets/img/icons/green-check.svg';

export default function Card({ data = {}, className = '' }) {
  const id = useRef(`feature-${uuidv4()}`).current;

  const title = _get(data, 'title', '');
  const includes = _get(data, 'includes', []);
  const docUrl = _get(data, 'docUrl', '');
  const images = _get(data, 'images', []).reverse();
  const theme = _get(data, 'theme', THEME.RED);

  const itemsRender = includes.map((item, index) => (
    <li key={index} className={styles.infoItem}>
      <div className={styles.checkIcon}>
        <CheckIcon />
      </div>
      <span className={styles.description}>{item}</span>
    </li>
  ));

  const imagesRender = images.map((image, index) => (
    <img key={index} src={image} alt="Feature" />
  ));

  return (
    <div className={cn(styles.container, className, styles[theme])}>
      <div className={styles.header}>
        <h5>{title}</h5>
        <InfoLink url={docUrl} id={id} className={styles.infoIcon} />
      </div>
      <ul className={styles.info}>{itemsRender}</ul>
      <div className={styles.images}>{imagesRender}</div>
    </div>
  );
}
