import { series, reflectAll } from 'async';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { RETEX_STATUS } from 'dictionary';
import approveRetex from './approveRetex';

export default function approveAdminPendingRetexes(
  approve = true,
  retexes = [],
  onProcessing = () => {},
  onUpdateRetex = () => {},
  onFinally = () => {}
) {
  if (!_isArray(retexes)) return;

  const processRetex = (retex = {}) => (callback = () => {}) => {
    const id = _get(retex, 'id', null);
    const isRestocking = _get(retex, 'will_restock', true);
    const comment = _get(retex, 'disposition.comment', '');
    const customComment = _get(retex, 'disposition.custom_comment', '');

    onProcessing(id);
    approveRetex(
      {
        params: {
          routeParam: id,
          is_approved: approve,
          disposition: {
            comment: comment,
            custom_comment: customComment,
            mode: isRestocking ? 'resale' : 'scrap'
          }
        },
        config: { headers: { 'X-Override-Request-State': true } }
      },
      data => {
        onUpdateRetex(id, data);
        callback(null);
      },
      err => callback(err)
    );
  };

  const apiFunctions = retexes.reduce((acc, retex) => {
    const status = _get(retex, 'status', null);
    const isAllowed = _get(retex, 'is_checked', false);
    const isValid =
      status === RETEX_STATUS.AWAITING_REFUND_APPROVAL ||
      status === RETEX_STATUS.AWAITING_EXCHANGE_APPROVAL;

    if (isAllowed && isValid) acc.push(processRetex(retex));
    return acc;
  }, []);

  series(reflectAll(apiFunctions), (_, results) => onFinally(results));
}
