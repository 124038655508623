import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';
import RetexNav from './RetexNav';
import ShipmentTrackingNav from './ShipmentTrackingNav';

export default function Sidebar({ className = '' }) {
  const { appType } = useContext(AuthContext);

  const Navbar = useMemo(() => {
    if (appType.retex) return RetexNav;
    else if (appType.shipmentTracking) return ShipmentTrackingNav;
    return () => null;
  }, [appType]);

  return (
    <aside className={cn(styles.container, className)}>
      <Navbar />
    </aside>
  );
}
