import React from 'react';

export default function Gear() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g style={{ fill: 'var(--icon-color)' }} fillRule="nonzero">
          <g>
            <path
              d="M8 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3zm0-1c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zm6-2c0 .363-.036.727-.104 1.094l1.601.925c.24.138.321.444.183.683l-1.5 2.598c-.138.24-.444.321-.683.183l-1.6-.924c-.56.478-1.202.849-1.896 1.094V15.5c0 .276-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5v-1.847c-.694-.245-1.337-.616-1.896-1.094l-1.6.923c-.24.138-.545.056-.683-.183l-1.5-2.598c-.138-.24-.056-.545.183-.683l1.6-.924C2.035 8.734 2.001 8.367 2 8c0-.365.036-.729.105-1.094l-1.6-.924c-.24-.138-.321-.444-.183-.683l1.5-2.598c.138-.24.444-.321.683-.183l1.599.923c.56-.478 1.202-.849 1.896-1.094V.5c0-.276.224-.5.5-.5h3c.276 0 .5.224.5.5v1.847c.694.245 1.337.616 1.896 1.094l1.599-.923c.24-.138.545-.056.683.183l1.5 2.598c.138.24.056.545-.183.683l-1.6.924c.07.365.105.73.105 1.094zm-.92 1.778c-.193-.111-.289-.336-.236-.552C12.947 8.808 13 8.4 13 8c0-.404-.053-.809-.157-1.222-.054-.217.041-.443.235-.555l1.484-.857-1-1.732-1.482.856c-.194.112-.438.082-.599-.074-.593-.577-1.32-.997-2.117-1.222C9.149 3.134 9 2.937 9 2.713V1H7v1.712c0 .223-.148.42-.363.481-.797.226-1.525.646-2.119 1.223-.16.155-.405.186-.598.074l-1.482-.856-1 1.732 1.484.857c.194.112.29.338.235.555-.102.4-.155.81-.157 1.222 0 .403.053.81.157 1.222.054.217-.041.443-.235.555l-1.484.857 1 1.732 1.483-.856c.194-.112.438-.082.599.074.593.577 1.32.997 2.117 1.222.215.06.364.257.364.481V15h2v-1.712c0-.224.149-.42.364-.481.796-.226 1.524-.646 2.118-1.222.16-.155.405-.186.598-.074l1.483.856 1-1.732-1.484-.857z"
              transform="translate(-16 -436) translate(16 436)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
