import React, { forwardRef } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

/**
 * @typedef {object} Props
 * @property {React.LegacyRef<HTMLDivElement>} ref
 * @property {number} value
 * @property {string} className
 */

/**
 * @type {React.FC<Props>}
 */
export default forwardRef(function Progress(
  { value = 0, className = '' },
  ref
) {
  return (
    <div ref={ref} className={cn(styles.container, className)}>
      <div className={styles.progress} style={{ width: `${value * 100}%` }} />
    </div>
  );
});
