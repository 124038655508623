import React, { useContext, useRef } from 'react';

import { IncentiveConfig, UiKit } from 'components';
import { Provider, Actions, Helptext } from './components';
import { ShopperGroupSelectorContext } from './context';
import { getStyles } from './methods';

export default function ShopperGroupSelector() {
  const {
    selectedOption,
    setSelectedOption,
    onChange,
    optionsList,
    isFetching,
    hasAccess,
    isConfigurable
  } = useContext(ShopperGroupSelectorContext);
  const overridingStyles = useRef(getStyles()).current;

  const changeHandler = option => {
    setSelectedOption(option);
    onChange(option);
  };

  if (!hasAccess) return null;

  return (
    <IncentiveConfig
      childrenContentClassName="mt-0"
      containerClassName="py-0"
      show={isConfigurable}
      helpText={<Helptext />}
    >
      <UiKit.Select
        label={
          <>
            Select <strong>Global Settings</strong> or a specific shopper group
            to configure
          </>
        }
        options={optionsList}
        value={selectedOption}
        isLoading={isFetching}
        onChange={changeHandler}
        overridingStyles={overridingStyles}
        isDisabled={!isConfigurable || !hasAccess}
      />
    </IncentiveConfig>
  );
}

ShopperGroupSelector.Provider = Provider;
ShopperGroupSelector.Context = ShopperGroupSelectorContext;
ShopperGroupSelector.Consumer = ShopperGroupSelectorContext.Consumer;
ShopperGroupSelector.Actions = Actions;
