import React from 'react';
import { AuthConsumer } from 'context/AuthContext';

const connectError = ConnectedComponent => {
  return props => (
    <AuthConsumer>
      {context => (
        <ConnectedComponent
          {...props}
          hasError={context.hasError}
          errorObject={context.errorObject}
          generateAppError={context.generateAppError}
        />
      )}
    </AuthConsumer>
  );
};

export default connectError;
