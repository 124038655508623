import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';

/**
 * @typedef {Object} Props
 * @property {string} _key The permission key to lookup in display menu configuration
 * @property {import('react-router-dom').LinkProps} to url fragment representing location where user shall be redirected to
 * @property {boolean} replace When true, clicking the link will replace the current entry in the history stack instead of adding a new one.
 * @property {React.RefObject} innerRef React Ref to the link elment
 * @property {boolean} isExternal When true, native HTML Anchor element will be used instead of React Router Link
 * @property {string} id HTML element id attribute
 * @property {string} className The link class name, "string" or "syles.{class}" from css module
 * @property {string} tooltipMessage The msg shown in case user do not have access to the feature
 * @property {"left" | "right" | "top" | "bottom"} tooltipPosition The position of the tooltip
 * @property {boolean} tooltipFade If false, Fade-In animtaion is removed from the tooltip
 * @property {boolean} tooltipAutohide Optionally hide tooltip when hovering over tooltip content - default true
 * @property {boolean} tooltipClassName Apply class to the tooltip
 * @property {boolean} tooltipInnerClassName Apply class to the inner-tooltip
 * @property {boolean} tooltipArrowClassName Apply class to the arrow-tooltip ('arrow' by default)
 * @property {Object} tooltipDelay Default { show: 0, hide: 250 }
 * @property {React.ReactElement} children The React node or nodes
 */

/**
 * @param {Props} Props React component Props
 */
export default function ProtectedLink({
  _key = '',
  to = '',
  replace = false,
  innerRef = null,
  disabled = false,
  isExternal = false,
  id = 'protected-link',
  className = '',
  hideTooltip = false,
  tooltipMessage = 'You don’t have access to this part of the portal. Please contact your super admin.',
  tooltipPosition = 'top',
  tooltipFade = true,
  tooltipAutohide = true,
  tooltipClassName = '',
  tooltipInnerClassName = '',
  tooltipArrowClassName = '',
  tooltipDelay = { show: 0, hide: 250 },
  children = null
}) {
  const { configuration } = useContext(AuthContext);

  const hasAccess = !disabled && _get(configuration, _key, false);

  return hasAccess ? (
    isExternal ? (
      <a
        id={id}
        href={to}
        ref={innerRef}
        target="_blank"
        rel="noopener noreferrer"
        className={cn(styles.link, className)}
      >
        {children}
      </a>
    ) : (
      <Link
        to={to}
        id={id}
        replace={replace}
        innerRef={innerRef}
        className={cn(styles.link, className)}
      >
        {children}
      </Link>
    )
  ) : (
    <>
      <div
        className={cn(styles.link, styles.disabled, className)}
        id={id}
        ref={innerRef}
      >
        {children}
      </div>
      {!hideTooltip && (
        <UncontrolledTooltip
          target={id}
          placement={tooltipPosition}
          fade={tooltipFade}
          delay={tooltipDelay}
          autohide={tooltipAutohide}
          className={tooltipClassName}
          innerClassName={tooltipInnerClassName}
          arrowClassName={tooltipArrowClassName}
        >
          {tooltipMessage}
        </UncontrolledTooltip>
      )}
    </>
  );
}
