import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import icon from 'assets/img/illustrations/deploymentAlert@2x.png';
const DeploymentModal = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} className="text-center DeploymentModal">
      <ModalBody className="DeploymentModal__body">
        <img src={icon} alt="deployment alert icon" className="img-fluid" />
        <p>A newer version is available. Refresh now to continue. </p>
        <Button
          color="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload Page
        </Button>{' '}
      </ModalBody>
    </Modal>
  );
};

export default DeploymentModal;
