const HOLD = [
  {
    label: 'Refund failure',
    value: 'refund-failure'
  },
  {
    label: 'Pending customer response',
    value: 'pending-customer-response'
  },
  {
    label: 'Refund and order amount mismatch',
    value: 'mismatch'
  },
  {
    label: 'Exchange item not in stock',
    value: 'not-in-stock'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export default HOLD;
