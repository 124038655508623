import React from 'react';
const OptionIcon = ({ selected, disabled }) => {
  let icon = '';

  if (!selected) {
    icon = 'far fa-circle';
  } else {
    icon = 'fas fa-check-circle';
  }
  if (disabled) {
    icon = 'fas fa-circle';
  }
  return (
    <span
      className={
        'option-icon' +
        (selected ? ' selected' : '') +
        (disabled ? ' disabled' : '')
      }
    >
      <i className={icon} />
    </span>
  );
};
export default OptionIcon;
