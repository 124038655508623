import React, { useMemo } from 'react';
import cn from 'classnames';

export default function Loader({ customClass = '' }) {
  const className = useMemo(() => {
    return cn('spinner', { [customClass]: !!customClass });
  }, [customClass]);

  return (
    <div className={className}>
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  );
}
