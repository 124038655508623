import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { useEcomPlatform } from 'utils/hooks';
import { UiKit } from 'components';
import { OrderServiceContext } from 'components/OrderServiceDetails';
import { formatDateTime } from '../../methods';
import ORDER_ROUTES from 'views/Orders/stubs/routes';
import { If } from 'utils';

const ORDER_ROUTE = ORDER_ROUTES.ORDER_DETAILS;

export default function Header() {
  const { ecommPlatformName } = useEcomPlatform();
  const { orderService, osStatuses, approvalTypes } = useContext(
    OrderServiceContext
  );

  const name = _get(orderService, 'name', '');
  const status = _get(orderService, 'status', '');
  const createdAt = _get(orderService, 'created_at', '');
  const approvalType = _get(orderService, 'approval_type', '');
  const orderInfo = _get(orderService, 'order_info', {});
  const orderId = _get(orderInfo, 'id', '');
  const orderName = _get(orderInfo, 'name', '');
  const ecommOrderUrl = _get(orderInfo, 'ecommerce_order_url', '');
  const formattedStatus = _get(osStatuses, status, '');
  const formattedApprovalType = _get(approvalTypes, approvalType, '');

  const createdDate = formatDateTime(createdAt);
  const orderDetailsUrl = ORDER_ROUTE.replace(':orderId', orderId);

  return (
    <UiKit.GridRow templateColumns="1fr" gap="4" className={styles.container}>
      <UiKit.GridRow templateColumns="1fr auto" gap="18">
        <h3>{name}</h3>
        <strong>{orderName}</strong>
      </UiKit.GridRow>
      <UiKit.GridRow templateColumns="1fr auto" gap="18">
        <UiKit.GridRow templateColumns="1fr" gap="4">
          <p>Created on: {createdDate}</p>
          <p>
            <strong>{formattedStatus}</strong>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <strong>{formattedApprovalType} Approval</strong>
          </p>
        </UiKit.GridRow>
        <UiKit.GridRow
          templateColumns={ecommOrderUrl ? 'auto auto' : 'auto'}
          gap="12"
        >
          <Link to={orderDetailsUrl}>Order Details</Link>
          <If test={ecommOrderUrl}>
            <a href={ecommOrderUrl} target="_blank" rel="noopener noreferrer">
              View on {ecommPlatformName}
            </a>
          </If>
        </UiKit.GridRow>
      </UiKit.GridRow>
    </UiKit.GridRow>
  );
}
