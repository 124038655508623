import React, { useRef } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { useAccess } from 'utils/hooks';
import { UiKit } from 'components';
import Action from '../Action';
import COPIES from './copies';
import basketImage from 'assets/img/feature-gating/basket.png';

/**
 * @param {object} props;
 * @param {string} props.className
 * @param {'success'|'outline'} props.buttonType
 * @param {boolean} props.compact Renders a variant of the the banner. Default false.
 * @param {import('react').ReactNode} props.fallback The fallback to render if the store already has the access to the feature.
 * @param {(import('dictionary').FEATURE_IDS)} props.featureId Feature Id to check the if store has access to the feature.
 * @param {(import('dictionary').FEATURE_SLUGS)} props.featureSlug Feature slug to check the if store has access to the feature.
 */
export default function Banner({
  featureId,
  featureSlug,
  className = '',
  buttonType = '',
  compact = false,
  fallback = null
}) {
  if (!featureSlug && !featureId) {
    throw new Error(
      '"featureSlug" OR "featureId" must be provided as prop in <FeatureUpsell.Banner /> component'
    );
  }

  const {
    hasSubFeatureAccess,
    hasFeatureAccess,
    getPlanInfoToAccess
  } = useAccess();

  const hasAccess = useRef(
    hasFeatureAccess(featureId) || hasSubFeatureAccess(featureSlug)
  ).current;

  const { requiredPlan } = useRef(
    getPlanInfoToAccess({
      id: featureId,
      slugs: [featureSlug]
    })
  ).current;

  const copiesKey = featureId || featureSlug;
  const icon = _get(COPIES[copiesKey], 'icon', basketImage);
  const title = _get(COPIES[copiesKey], 'title', '');
  const description = _get(COPIES[copiesKey], 'description', '');
  const templateColumns = compact ? '72px 1fr' : '100px 1fr';
  const gap = compact ? '16' : '24';

  return (
    <If test={!hasAccess} otherwise={fallback}>
      <UiKit.GridRow
        gap={gap}
        templateColumns={templateColumns}
        className={cn(
          styles.container,
          { [styles.compact]: compact },
          className
        )}
      >
        <div className={styles.icon}>
          <img src={icon} alt="icon" />
        </div>
        <div className={styles.info}>
          <div className={styles.content}>
            <h5>{title}</h5>
            <p>{description}</p>
          </div>
          <Action
            compact={compact}
            planData={requiredPlan}
            className={styles.action}
            buttonType={buttonType}
          />
        </div>
      </UiKit.GridRow>
    </If>
  );
}
