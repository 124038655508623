import React, { useMemo, useRef } from 'react';
import _get from 'lodash/get';
import cn from 'classnames';
import styles from './index.module.scss';

import { Pill } from './components';
import { PillSwitchContext } from './context';

function PillSwitch({
  value = null,
  pillWidth = 100,
  disabled = false,
  className = '',
  tabClassName = '',
  activeTabClassName = '',
  floatingPillClassName = '',
  onChange = () => {},
  children = null
}) {
  const activeIndex = useMemo(() => {
    const childElements = children || [];
    const indexMap = childElements.reduce((acc, child, index) => {
      const pillValue = _get(child, 'props.value');
      acc[pillValue] = index;
      return acc;
    }, {});
    return _get(indexMap, value, -1);
  }, [value, children]);

  const containerClassName = useRef(
    cn(styles.container, { [className]: !!className })
  ).current;

  const activePillClassName = useRef(
    cn(styles.floatingPill, {
      [floatingPillClassName]: !!floatingPillClassName
    })
  ).current;

  const pillCSS = useRef({ width: `${pillWidth}px` }).current;

  return (
    <PillSwitchContext.Provider
      value={{
        activeValue: value,
        isDisabled: disabled,
        pillCSS,
        tabClassName,
        activeTabClassName,
        onChange
      }}
    >
      <ol className={containerClassName}>
        <li
          key="active-index-bg"
          className={activePillClassName}
          style={{ '--index': activeIndex, ...pillCSS }}
        />
        {children}
      </ol>
    </PillSwitchContext.Provider>
  );
}

PillSwitch.Pill = Pill;

export default PillSwitch;
