import _isArray from 'lodash/isArray';

import { get, logError } from 'utils';

export default function getRetexAppliedRules(
  retexId = '',
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  return get(
    { apiKey: 'retexes.appliedRules' },
    { params: { routeParams: { id: retexId } } }
  )
    .then(({ data }) => {
      const rules = _isArray(data) ? data : [];
      onSuccess(rules);
    })
    .catch(err => {
      logError(err);
      onError(err);
    })
    .finally(onFinally);
}
