import _empty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

import { post, logError } from 'utils';

export default function getProductVariants(
  {
    variantIds = [],
    productIds = [],
    isDeleted = false,
    showOutOfStock = false
  },
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  const hasNoIds = _empty(variantIds) && _empty(productIds);
  if (hasNoIds) return onSuccess([]);

  post(
    { apiKey: 'productVaraints.post' },
    {
      params: {
        variant_ids: variantIds,
        product_ids: productIds,
        is_deleted: isDeleted,
        out_of_stock_variants: showOutOfStock
      }
    }
  )
    .then(({ data }) => {
      const productVaraints = _isArray(data) ? data : [];
      onSuccess(productVaraints);
    })
    .catch(error => {
      logError(
        new Error({
          error,
          meta: {
            variantIds,
            productIds,
            errorMsg: 'fetching product variants failed'
          }
        })
      );
      onError(error);
    })
    .finally(onFinally);
}
